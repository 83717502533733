import { Firestore } from '../lib/firebase';
import captureVideoFrame from 'capture-video-frame';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import axios from 'axios';

import QuestionType, { getQuestionTitle } from '../constants/questionType';
import { getClientIds } from './Managers';
import SocialLensLogo from '../assets/image/sociallens.png';
import PlaceholderIcon from '../assets/image/placeholder.jpg';
import { getDownloadUrlWithFilename, getTypeFile } from '../utils/url';
import { forEach } from 'lodash';
import CampaignType from './../constants/campaignType';
import htmlToPdfmake from 'html-to-pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { map } = require('p-iteration');

let API_URL = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION;

export const getCampaigns = async () => {
  let campaignsCollection = Firestore.collection('campaigns');

  try {
    const login_manager = window.localStorage.getItem('login_manager');
    if (!login_manager) return [];
    const { admin } = JSON.parse(login_manager);
    let snapshot;
    if (admin) {
      snapshot = await campaignsCollection.get();
    } else {
      const clientIds = await getClientIds();
      if (clientIds.length === 0) return [];
      snapshot = await campaignsCollection.where('client_id', 'in', clientIds).get();
    }
    let campaigns = [];

    let userDocs = await Firestore.collection('users').get();
    let userMap = {};
    userDocs.forEach(async (snapshot) => {
      let user = snapshot.data();
      userMap[user.email ? user.email.toLowerCase() : user.email] = user;
    });

    let pGroups = await Firestore.collection('participant_groups').get();
    let pGroupMap = {};
    pGroups.forEach((snap) => {
      const group = snap.data();
      pGroupMap[snap.id] = group;
    });
    let clients = await Firestore.collection('clients').get();
    let clientsMap = {};
    clients.forEach((snap) => {
      const client = snap.data();
      clientsMap[snap.id] = client;
    });

    let answers = await Firestore.collection('answers').get();
    let answersMap = {};
    answers.forEach((snap) => {
      const answer = snap.data();
      answersMap[snap.id] = answer;
    });

    await map(snapshot.docs, async (campaign) => {
      let campaignData = await campaign.data();
      if (campaignData.participant_group_id) {
        let participantData = pGroupMap[campaignData.participant_group_id];
        let clientData = clientsMap[campaignData.client_id];
        const getParticipantUserId = (email) => {
          const emailLowerCase = email ? email.toLowerCase() : null;
          return userMap[emailLowerCase] ? userMap[emailLowerCase].id : null;
        };

        let participantIds = participantData.participant_list.map((participant) =>
          getParticipantUserId(participant.email),
        );

        let answers = participantIds.map((participantId) => {
          return participantId ? answersMap[`${campaignData.id}-${participantId}`] : null;
        });

        let campaign = {
          id: campaignData.id,
          client_id: campaignData.client_id,
          company_name: clientData.org,
          name: campaignData.name,
          participant_group_id: campaignData.participant_group_id,
          participant_group: participantData,
          division: participantData.division,
          type: campaignData.type,
          status: campaignData.status,
          answers,
          questions: campaignData.questions,
          checked: true,
          updatedAt: campaignData.updatedAt,
          createdAt: campaignData.createdAt,
        };
        campaigns.push(campaign);
      } else if (!campaignData.participant_group_id && campaignData.type === CampaignType.OPEN) {
        let clientData = clientsMap[campaignData.client_id];
        let listAnswers = Object.keys(answersMap).reduce((data, key) => {
          if (key.indexOf(campaignData.id) !== -1) {
            data.push(answersMap[key]);
          }
          return data;
        }, []);
        let campaign = {
          id: campaignData.id,
          client_id: campaignData.client_id,
          company_name: clientData.org,
          name: campaignData.name,
          participant_group_id: null,
          participant_group: [],
          division: null,
          type: campaignData.type,
          status: campaignData.status,
          answers: listAnswers,
          questions: campaignData.questions,
          checked: true,
          updatedAt: campaignData.updatedAt,
          createdAt: campaignData.createdAt,
        };
        campaigns.push(campaign);
      }
    });

    return campaigns;
  } catch (error) {
    throw error;
  }
};

export const getAnswers = async (campaignId, userId) =>
  new Promise(async (resolve, reject) => {
    let answerDoc = await Firestore.collection('answers').doc(`${campaignId}-${userId}`);

    answerDoc.onSnapshot((snapshot) => {
      let answerData = snapshot.data();
      resolve(answerData);
    });
  });

export const getPDF = async (campaign, curUser, answers) => {
  forEach(answers.medias, (media, key) => {
    answers.medias[key] = media.filter((f) => f.downloadUrl);
  });

  // generating the participant index fro the current user
  var username = curUser.name;
  username = username.replace(/ /g, '');
  let index = 0;

  if (campaign.type !== CampaignType.OPEN) {
    index = campaign.participant_group.participant_list.findIndex(
      (item) => item.email === curUser.email,
    );
  }

  let SociallensLogoDataUrl = await toDataUrl(SocialLensLogo);
  let PlaceholderIconDataUrl = await toDataUrl(PlaceholderIcon);
  let logoUrl = PlaceholderIconDataUrl;
  if (campaign.logo) {
    logoUrl = await toDataUrl(campaign.logo);
  }

  // calculating if completed
  let completed = true;

  campaign.questions.forEach((question, indexQuestion) => {
    let answer = answers.answers[indexQuestion];
    if (question.type === QuestionType.INSTRUCTION_QUESTION && !answer) {
      completed = false;
    } else if (question.type === QuestionType.OPEN_TEXT_QUESTION && answer === '') {
      completed = false;
    } else if (question.type === QuestionType.ONE_CHOICE_QUESTION && answer === -1) {
      completed = false;
    } else if (question.type === QuestionType.MULTIPLE_CHOICE_QUESTION && answer.length === 0) {
      completed = false;
    } else if (question.type === QuestionType.RANKING_CHOICE_QUESTION && answer.length === 0) {
      completed = false;
    }
    // No need to check completed on signature question type because it has no answer
  });
  // composing the pdf definition
  let content = [
    {
      columns: [
        {
          // auto-sized columns have their widths based on their content
          fit: [100, 100],
          image: logoUrl,
        },
        {
          width: '*',
          fit: [200, 75],
          image: SociallensLogoDataUrl,
          alignment: 'right',
          margin: [0, 15],
        },
      ],
    },
    '\n\n',
    {
      table: {
        widths: ['*'],
        body: [
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Name:',
                  style: 'sm',
                },
                {
                  text: curUser.name,
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Organization:',
                  style: 'sm',
                },
                {
                  text: campaign.client.org,
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Campaign:',
                  style: 'sm',
                },
                {
                  text: campaign.name,
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Description:',
                  style: 'sm',
                },
                {
                  text: campaign.description,
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Date Submitted:',
                  style: 'sm',
                },
                {
                  text: moment().format('YYYY-MM-DD hh:mm a'),
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Status:',
                  style: 'sm',
                },
                {
                  text: completed ? 'Complete' : 'Incomplete',
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: '\n\n',
              border: [false, false, false, true],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return 1;
        },
        hLineColor: function (i, node) {
          return '#ddd';
        },
        hLineStyle: function (i, node) {
          if (i === 7) {
            return null;
          }
          return { dash: { length: 5, space: 2.5 } };
        },
      },
    },
  ];

  let tasks = campaign.questions.map(
    (question, qIndex) =>
      new Promise(async (resolve, reject) => {
        const isSignatureQuestion = question.type === QuestionType.SIGNATURE_QUESTION;
        let questionText = question.question;
        if (question.questionHtml) {
          // pdfMake does not support rgba
          const newQuestion = question.questionHtml.replaceAll(
            'rgba(0, 0, 0, 0)',
            'rgb(255, 255, 255)',
          );
          questionText = htmlToPdfmake(newQuestion);
        }
        if (Array.isArray(questionText)) {
          questionText.forEach((item) => {
            if (Array.isArray(item.text)) {
              item.text = [
                ...item.text,
                {
                  nodeName: 'BR',
                  text: '\n',
                },
              ];
            }
          });
        }
        let table = {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: '\n',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  columns: [
                    {
                      width: 'auto',
                      text: getQuestionTitle(question.type),
                      style: 'smbold',
                    },
                    {
                      text: question.questionTitle ? `- ${question.questionTitle}\n` : '',
                    },
                  ],
                  columnGap: 4,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 1;
            },
            hLineColor: function (i, node) {
              return '#ddd';
            },
            hLineStyle: function (i, node) {
              return { dash: { length: 5, space: 2.5 } };
            },
          },
        };
        // Generate different layout for signature question type
        if (isSignatureQuestion) {
          table.table.body.push([
            {
              width: 'auto',
              text: 'Legal:',
              style: 'sm',
              border: [false, false, false, false],
            },
          ]);
          table.table.body.push([
            {
              text: questionText,
              border: [false, false, false, false],
            },
          ]);
        } else {
          table.table.body.push([
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Question:',
                  style: 'sm',
                },
                {
                  text: questionText,
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ]);
        }

        // adding question media

        if (question.media) {
          let mediaTable = {
            table: {
              heights: function (row) {
                if (row % 2) {
                  return 12;
                } else {
                  return 'auto';
                }
              },
              body: [],
            },
            layout: 'noBorders',
            border: [false, false, false, false],
          };
          if (question.media_type.includes('image/')) {
            let mediaDataUrl = await toDataUrl(question.media.downloadUrl);
            mediaTable.table.body.push([
              {
                width: 300,
                image: mediaDataUrl,
              },
            ]);
            mediaTable.table.body.push([
              {
                text: 'image',
                style: 'xsm',
                alignment: 'center',
              },
            ]);
          } else if (question.media_type === 'youtube') {
            let url = question.media.replace('https://youtu.be', 'http://img.youtube.com/vi');
            url = url + '/mqdefault.jpg';
            let { data } = await axios.post(`${API_URL}/getDataUrl`, { url });
            mediaTable.table.body.push([
              {
                width: 300,
                image: data.data,
                link: question.media,
              },
            ]);
            mediaTable.table.body.push([
              {
                text: 'video',
                style: 'xsm',
                alignment: 'center',
              },
            ]);
          } else {
            let res = captureVideoFrame(`${qIndex}`);
            let mediaDataUrl = res.dataUri;
            mediaTable.table.body.push([
              {
                width: 300,
                image: mediaDataUrl,
                link: getDownloadUrlWithFilename(
                  question.media.downloadUrl,
                  `${campaign.name}question${qIndex + 1}.${getTypeFile(question.media.type)}`,
                ),
              },
            ]);
            mediaTable.table.body.push([
              {
                text: 'video',
                style: 'xsm',
                alignment: 'center',
              },
            ]);
          }
          table.table.body.push([mediaTable]);
        }

        table.table.body.push([
          {
            text: '\n',
            border: [false, false, false, false],
          },
        ]);
        // adding question answers
        if (question.type === QuestionType.INSTRUCTION_QUESTION) {
          //instruction question
          if (answers.answers[qIndex]) {
            table.table.body.push([
              {
                text: answers.answers[qIndex] ? 'WATCHED' : 'NOT WATCHED',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          } else {
            table.table.body.push([
              {
                text: 'No Answer',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          }
        } else if (question.type === QuestionType.OPEN_TEXT_QUESTION) {
          //open text question
          if (answers.answers[qIndex]) {
            table.table.body.push([
              {
                text: `${answers.answers[qIndex]}`,
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          } else {
            table.table.body.push([
              {
                text: 'No Answer',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          }
        } else if (question.type === QuestionType.ONE_CHOICE_QUESTION) {
          //single choice
          let list = question.answers.map((answer, answerIndex) =>
            answers.answers[qIndex] === answerIndex
              ? { text: answer, style: 'answer' }
              : { text: answer, style: 'sm', listType: 'circle' },
          );
          table.table.body.push([
            {
              ul: list,
              border: [false, false, false, false],
            },
          ]);
          if (answers.answers[qIndex] === -1) {
            table.table.body.push([
              {
                text: 'No Answer',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          }
        } else if (question.type === QuestionType.MULTIPLE_CHOICE_QUESTION) {
          // multiple choice
          let list = question.answers.map((answer, answerIndex) =>
            (answers.answers[qIndex] || []).includes(answerIndex)
              ? { text: answer, style: 'answer' }
              : { text: answer, style: 'sm', listType: 'circle' },
          );
          table.table.body.push([
            {
              ul: list,
              border: [false, false, false, false],
            },
          ]);
          if (answers.answers[qIndex] && answers.answers[qIndex].length === 0) {
            table.table.body.push([
              {
                text: 'No Answer',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          }
        } else if (question.type === QuestionType.RANKING_CHOICE_QUESTION) {
          // ranking choice
          let list = answers.answers[qIndex].map((item) => {
            return { text: question.answers[item], style: 'answer' };
          });
          table.table.body.push([
            {
              ul: list,
              border: [false, false, false, false],
            },
          ]);
          if (answers.answers[qIndex] && answers.answers[qIndex].length === 0) {
            table.table.body.push([
              {
                text: 'No Answer',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          }
        } else if (question.type === QuestionType.SIGNATURE_QUESTION) {
          // signature question only have image, no answer
          if (answers.medias && answers.medias[qIndex] && answers.medias[qIndex].length === 0) {
            table.table.body.push([
              {
                text: 'No Signature',
                style: 'answer',
                border: [false, false, false, false],
              },
            ]);
          }
        }
        // Media files: xx
        table.table.body = table.table.body.concat([
          [
            {
              text: '\n',
              border: [false, false, false, false],
            },
          ],
          [
            {
              columns: [
                {
                  width: 'auto',
                  text: 'Media files:',
                  style: 'sm',
                },
                {
                  text:
                    answers.medias[qIndex] && answers.medias[qIndex].length
                      ? answers.medias[qIndex].length
                      : 'None',
                  style: 'smbold',
                },
              ],
              columnGap: 5,
              border: [false, false, false, false],
            },
          ],
        ]);

        // adding medias that user uploaded
        if (answers.medias[qIndex] && answers.medias[qIndex].length > 0) {
          let mediaTasks = answers.medias[qIndex].map(
            (media, mIndex) =>
              new Promise(async (resolve, reject) => {
                if (!media.downloadUrl) {
                  let imageDataUrl = PlaceholderIconDataUrl;
                  resolve(imageDataUrl);
                } else if (media.type === 'image') {
                  let imageDataUrl = await toDataUrl(media.downloadUrl);
                  resolve(imageDataUrl);
                } else {
                  let res = captureVideoFrame(`${qIndex}-${mIndex}`);
                  resolve(res.dataUri);
                }
              }),
          );

          let medias = await Promise.all(mediaTasks);
          const mediaTableTest = {
            table: {
              widths: [150, 150, 150],
              heights: function (row) {
                return row % 2 ? 12 : 'auto';
              },
              body: [],
            },
            layout: 'noBorders',
            border: [false, false, false, false],
          };
          for (var i = 0; i < medias.length; i += 3) {
            let columns = [];
            let labelColumns = [];

            if (medias[i]) {
              if (answers.medias[qIndex][i].type.indexOf('video') !== -1) {
                columns.push({
                  fit: [150, 150],
                  image: medias[i],
                  link: getDownloadUrlWithFilename(
                    answers.medias[qIndex][i].downloadUrl,
                    `${campaign.name}${username}${qIndex}_${i}.${getTypeFile(
                      answers.medias[qIndex][i].type,
                    )}`,
                  ),
                  alignment: 'center',
                });
              } else {
                columns.push({
                  fit: [150, 150],
                  image: medias[i],
                  alignment: 'center',
                });
              }
              labelColumns.push({
                text: answers.medias[qIndex][i].type,
                style: 'xsm',
                alignment: 'center',
              });
            }
            if (medias[i + 1]) {
              if (answers.medias[qIndex][i + 1].type.indexOf('video') !== -1) {
                columns.push({
                  fit: [150, 150],
                  image: medias[i + 1],
                  link: getDownloadUrlWithFilename(
                    answers.medias[qIndex][i + 1].downloadUrl,
                    `${campaign.name}${username}${qIndex}_${i + 1}.${getTypeFile(
                      answers.medias[qIndex][i + 1].type,
                    )}`,
                  ),
                  alignment: 'center',
                });
              } else {
                columns.push({
                  fit: [150, 150],
                  image: medias[i + 1],
                  alignment: 'center',
                });
              }
              labelColumns.push({
                text: answers.medias[qIndex][i + 1].type,
                style: 'xsm',
                alignment: 'center',
              });
            } else {
              columns.push({
                text: '',
              });
              labelColumns.push({
                text: '',
              });
            }
            if (medias[i + 2]) {
              if (answers.medias[qIndex][i + 2].type.indexOf('video') !== -1) {
                columns.push({
                  fit: [150, 150],
                  image: medias[i + 2],
                  link: getDownloadUrlWithFilename(
                    answers.medias[qIndex][i + 2].downloadUrl,
                    `${campaign.name}${username}${qIndex}_${i + 2}.${getTypeFile(
                      answers.medias[qIndex][i + 2].type,
                    )}`,
                  ),
                  alignment: 'center',
                });
              } else {
                columns.push({
                  fit: [150, 150],
                  image: medias[i + 2],
                  alignment: 'center',
                });
              }
              labelColumns.push({
                text: answers.medias[qIndex][i + 2].type,
                style: 'xsm',
                alignment: 'center',
              });
            } else {
              columns.push({
                text: '',
              });
              labelColumns.push({
                text: '',
              });
            }
            mediaTableTest.table.body.push(columns);
            mediaTableTest.table.body.push(labelColumns);
          }
          if (mediaTableTest) {
            table.table.body.push([mediaTableTest]);
          }
        }
        // adding border
        table.table.body.push([
          {
            text: '\n',
            border: [false, false, false, true],
          },
        ]);
        resolve(table);
      }),
  );

  let tables = await Promise.all(tasks);

  content = content.concat(tables);

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    footer: function (currentPage, pageCount) {
      let str = `Response ${index + 1}: `;
      str += 'Page ' + currentPage.toString() + ' of ' + pageCount;

      return [{ text: str, alignment: 'right', style: 'footer' }];
    },
    content,
    styles: {
      xsm: {
        fontSize: 8,
        color: 'grey',
      },
      sm: {
        fontSize: 12,
      },
      smbold: {
        fontSize: 12,
        bold: true,
      },
      answer: {
        fontSize: 14,
        bold: true,
        italics: true,
      },
      footer: {
        fontSize: 10,
        bold: true,
        color: 'grey',
        margin: [0, 0, 40, 0],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download(`answer-${campaign.name}-${curUser.name}.pdf`);
};

export const sendEmail = async (campaign_id, status, content) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/sendReportEmail`, {
        campaign_id,
        content,
        status,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const toDataUrl = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.send(null);
  });
};
