import { filter, reduce, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { reportAction } from '../../../store/report';

export const useSummaryReportMultipleChoice = () => {
  const dispatch = useDispatch();
  const questionSelected = useSelector((state) => state.report.questionSelected);
  const wordFrequencyTable = useSelector((state) => state.report.wordFrequencyTable);
  const userAnswers = useSelector((state) => state.report.userAnswers);
  const updateParticipantsSuccess = useSelector((state) => state.report.updateParticipantsSuccess);

  const [userSummary, setUserSummary] = useState([]);
  const [userAnswered, setUserAnswered] = useState([]);
  const [showSummaryDetail, setShowSummaryDetail] = useState(false);

  const detailSummaryUserMultipleChoice = (index) => async (event) => {
    setShowSummaryDetail(true);
    event.stopPropagation();
    if (!questionSelected) return;
    const newUserSummary = userSummary.filter((u) => {
      const userAnswer = u.answer.answers[questionSelected.questionIndex];
      return userAnswer && userAnswer.includes(index);
    });
    setUserAnswered(() => newUserSummary.map((f) => ({ ...f, checked: false })));
  };

  const { userOptionSummary, totalNumberOfRespondents } = useMemo(() => {
    const totalSummary = questionSelected.answers.map((val, index) => {
      const count = filter(userSummary, (userAnswer) => {
        const arrAnswer = userAnswer.answer.answers[questionSelected.questionIndex];
        return arrAnswer && arrAnswer.indexOf(index) !== -1;
      });
      return count.length;
    });
    const total = totalSummary.reduce((total, current, idx) => {
      return (total += current);
    }, 0);
    const userOptionSummary = questionSelected.answers.map((value, index) => {
      return {
        option: value,
        numberOfRespondents: totalSummary[index],
        defaultIndex: index,
      };
    });
    const totalNumberOfRespondents = total;
    return { userOptionSummary, totalNumberOfRespondents };
  }, [userSummary, questionSelected]);

  useEffect(() => {
    const wordSelected = wordFrequencyTable.filter((f) => f.checked);
    const users = uniq(
      reduce(
        wordSelected,
        (prev, current) => {
          return prev.concat(current.users.map((f) => f.userId));
        },
        [],
      ),
    );
    if (users && users.length > 0) {
      const userFilter = userAnswers.filter((f) => users.includes(f.userId));
      setUserSummary(userFilter);
    } else {
      setUserSummary(userAnswers);
    }

    if (updateParticipantsSuccess) {
      dispatch(reportAction.setUpdateParticipantsSuccess(false));
    }
    setShowSummaryDetail(false);
  }, [wordFrequencyTable, userAnswers]);

  useEffect(() => {
    if (userSummary && userSummary.length === 0) {
      setShowSummaryDetail(false);
    }
  }, [userOptionSummary]);

  return {
    showSummaryDetail,
    questionSelected,
    userOptionSummary,
    totalNumberOfRespondents,
    userSummary,
    userAnswers,
    userAnswered,
    detailSummaryUserMultipleChoice,
  };
};
