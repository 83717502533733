import React from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../components';
import { Auth } from './../../lib/firebase';

import styles from './LoginContainer.module.scss';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
    };
  }

  handleInputChanged = (type) => (e) => {
    this.setState({
      [type]: e.target.value,
    });
  };

  handleLoginSubmit = async () => {
    this.setState({ loading: true });
    try {
      await Auth.signInWithEmailAndPassword(this.state.email, this.state.password);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({
        email: '',
        password: '',
        loading: false,
      });
      alert(error.message);
    }
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.loginContainer}>
          <h2> Welcome to Lens Engage </h2>
          <input
            type="email"
            placeholder="Type email here"
            value={this.state.email}
            onChange={this.handleInputChanged('email')}
          />
          <input
            type="password"
            placeholder="Type password here"
            value={this.state.password}
            onChange={this.handleInputChanged('password')}
          />
          <div className={styles.btn} onClick={this.handleLoginSubmit}>
            {this.state.loading ? (
              <>
                <div className={styles.spinLoader} />
                &nbsp;&nbsp;&nbsp;
              </>
            ) : null}
            Log In
          </div>
        </div>
      </div>
    );
  }
}

LoginContainer.contextType = AppContext;

LoginContainer.propTypes = {
  history: PropTypes.object,
};

export default LoginContainer;
