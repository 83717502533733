import { Firestore } from '../lib/firebase';
import { getClients } from './Client';
import { getUserById } from './Users';
import { isAdmin } from './Managers';
import moment from 'moment';
import { unionBy, reverse } from 'lodash';

let collection = Firestore.collection('user_history');

const historyData = async (userId) => {
  const userRef = await collection.doc(userId);
  const userDoc = await userRef.get();
  const userExist = userDoc.exists;
  if (userExist) {
    return userDoc.data();
  }
  return null;
};

const addHistoryByUserId = async (userId) => {
  const [user, allClients] = await Promise.all([getUserById(userId), getClients(isAdmin())]);
  if (!user) return null;
  const userHistory = allClients.reduce((prev, current) => {
    if (Array.isArray(current.participant_groups)) {
      const result = [];
      current.participant_groups.forEach((participant_group) => {
        if (Array.isArray(participant_group.participant_list)) {
          const found = participant_group.participant_list.find((f) => {
            if (f && f.email && user && user.email) {
              return f.email.toLowerCase() === user.email.toLowerCase();
            }
            return false;
          });
          if (found) {
            result.push({
              participant_group_id: participant_group.id,
              participant_group_name: participant_group.name,
            });
          }
        }
      });
      if (result.length > 0) {
        prev.push({
          client_id: current.id,
          client_name: current.org,
          participant_groups: result,
        });
      }
    }
    return prev;
  }, []);
  const userHistoryData = await historyData(userId);
  // case update
  if (userHistoryData) {
    const data = {
      clients: [],
      updatedAt: moment().unix(),
      email: user && user.email ? user.email.toLowerCase() : '',
      userRef: user.id,
    };
    if (Array.isArray(userHistoryData.clients)) {
      userHistory.forEach((client) => {
        const clientData = {
          client_id: client.client_id,
          client_name: client.client_name,
        };
        const found = userHistoryData.clients.find((f) => f.client_id === client.client_id);
        if (found) {
          const reverseParticipant = reverse(found.participant_groups);
          const afterUnion = unionBy(
            client.participant_groups,
            reverseParticipant,
            'participant_group_id',
          );
          clientData.participant_groups = reverse(afterUnion);
        } else {
          clientData.participant_groups = client.participant_groups;
        }
        data.clients.push(clientData);
      });
    } else {
      userHistoryData.clients = userHistory;
    }
    await collection.doc(userId).update(data);
  } else {
    const data = {
      clients: userHistory,
      updatedAt: moment().unix(),
      email: user && user.email ? user.email.toLowerCase() : '',
      userRef: user.id,
    };
    await collection.doc(userId).set(data);
  }
  return userHistory;
};

export const syncHistoryByEmail = async (email) => {
  const snapshot = await collection.where('email', '==', email.toLowerCase()).get();
  const listData = [];
  snapshot.forEach((doc) => {
    listData.push(doc.data());
  });
  const userHistory = listData[0];
  if (userHistory) {
    await addHistoryByUserId(userHistory.userRef);
  }
};

export const getUserHistory = async (userId) => {
  try {
    const data = await historyData(userId);
    if (data) {
      return data;
    } else {
      await addHistoryByUserId(userId);
      return historyData(userId);
    }
  } catch (error) {
    return {};
  }
};
