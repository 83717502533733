import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import uuid from 'uuid/v4';
import moment from 'moment';
import { BackButton } from '../../../components/Button';

import { AppContext } from '../../../components';
import { ClientController, SessionController } from '../../../controllers';

import styles from './SessionAddContainer.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import selectStyles from '../../../theme/select.styles';

class SessionAddContainer extends React.Component {
  constructor(props) {
    super(props);

    let today = moment();
    let end = moment().add(7, 'days');

    this.state = {
      clients: [],
      participant_groups: [],
      orgList: [],
      groupList: [],
      selectedOrg: {},
      selectedGroup: {},
      // campaign data
      basic: {
        name: '',
        marketing_name: '',
        org: '',
        contact: '',
        from: today.toDate(),
        to: end.toDate(),
        participant_group: '',
        location: '',
        description: '',
        logo: null,
      },
      sessions: [this.generateNewSession()],
    };

    this.fileInputs = {};
    this.logoInput = {};
  }

  async componentDidMount() {
    let { basic } = this.state;

    let clients = await ClientController.getClients(
      (this.context.manager && this.context.manager.admin) || false,
    );
    let participant_groups = [],
      orgList = [],
      groupList = [];
    if (clients.length > 0) {
      participant_groups = await ClientController.getParticipantGroupsByClientId(clients[0].id);
      basic.org = clients[0].id;
      basic.contact = clients[0].contact;

      orgList = clients.map((client) => ({
        value: client.id,
        label: client.org,
      }));
    }

    if (participant_groups.length) {
      basic.participant_group = participant_groups[0].id;
      basic.location = participant_groups[0].division;

      groupList = participant_groups.map((group) => ({
        value: group.id,
        label: group.name,
      }));
    }

    this.setState({
      clients,
      participant_groups,
      basic,
      orgList,
      groupList,
      selectedOrg: orgList.length ? orgList[0] : {},
      selectedGroup: groupList.length ? groupList[0] : {},
    });
  }

  generateNewSession = () => ({
    id: uuid(),
    title: '',
    media: null,
  });

  addClicked = async () => {
    // validation for basic info
    let { basic, sessions } = this.state;

    if (!basic.name) {
      alert('Name is empty or invalid.');
      return;
    }
    if (!basic.marketing_name) {
      alert('Marketing name is empty or invalid.');
      return;
    }
    if (!basic.org) {
      alert('Please select an organization.');
      return;
    }
    if (!basic.participant_group) {
      alert('Please select a participant group.');
      return;
    }
    if (!basic.description) {
      alert('Description is empty or invalid.');
      return;
    }

    // validation for sessions
    for (let i = 0; i < sessions.length; i++) {
      if (!sessions[i].title) {
        alert(`Session ${i + 1}'s title is empty or invalid.`);
        return;
      }
      if (!sessions[i].media) {
        alert(`Session ${i + 1}'s video is empty or invalid.`);
        return;
      }
    }

    this.context.showLoading();
    try {
      // adding a session
      await SessionController.addSession({
        basic,
        sessions,
      });

      this.context.hideLoading();
      this.props.history.goBack();
    } catch (error) {
      this.context.hideLoading();
      alert(error.message);
    }
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  basicInfoChanged = (key) => async (e) => {
    let { basic } = this.state;

    if (key === 'org') {
      let selectedOrg = e;
      basic[key] = e.value;
      let index = this.state.clients.findIndex((client) => client.id === e.value);
      basic['contact'] = this.state.clients[index].contact;

      let participant_groups = await ClientController.getParticipantGroupsByClientId(e.value);
      let groupList = [];
      if (participant_groups.length) {
        basic.participant_group = participant_groups[0].id;
        basic.location = participant_groups[0].division;

        groupList = participant_groups.map((group) => ({
          value: group.id,
          label: group.name,
        }));
      } else {
        basic.participant_group = '';
        basic.location = '';
      }

      this.setState({
        selectedOrg,
        basic,
        participant_groups,
        groupList,
        selectedGroup: groupList.length ? groupList[0] : {},
      });
    } else if (key === 'participant_group') {
      let selectedGroup = e;
      basic[key] = e.value;
      let index = this.state.participant_groups.findIndex((group) => group.id === e.value);
      basic['location'] = this.state.participant_groups[index].division;
      this.setState({
        selectedGroup,
        basic,
      });
    } else if (key === 'from' || key === 'to') {
      basic[key] = e;
      this.setState({ basic });
    } else {
      basic[key] = e.target.value;
      this.setState({ basic });
    }
  };

  uploadLogoClicked = () => {
    this.logoInput.click();
  };

  logoFileUploadChange = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let { basic } = this.state;
      basic.logo = files[0];
      this.setState({ basic });
    }
  };

  uploadClicked = (id) => () => {
    this.fileInputs[id].click();
  };

  fileUploadChange = (index) => (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let { sessions } = this.state;
      sessions[index].media = files[0];
      this.setState({ sessions });
    }
  };

  sessionChanged = (key, index) => (e) => {
    let { sessions } = this.state;
    sessions[index][key] = e.target.value;
    this.setState({ sessions });
  };

  removeLogo = () => {
    let { basic } = this.state;
    basic['logo'] = null;
    this.setState({ basic });
  };

  addSession = () => {
    let { sessions } = this.state;
    const last = sessions[sessions.length - 1];
    if (!last.title) {
      alert('Session title is empty or invalid.');
      return;
    }
    if (!last.media) {
      alert('Session video is empty or invalid.');
      return;
    }
    sessions.push(this.generateNewSession());
    this.setState({ sessions });
  };

  removeSessionMedia = (index) => () => {
    let { sessions } = this.state;
    sessions[index].media = null;
    this.setState({ sessions });
  };

  removeSession = (index) => () => {
    if (window.confirm('Are you sure you want to remove?')) {
      let { sessions } = this.state;
      sessions.splice(index, 1);
      this.setState({ sessions });
    }
  };

  renderSession = (session, index) => {
    return (
      <div key={`${index}`} className={styles.sessionContainer}>
        <div className={styles.title}>
          <h2> Session {index + 1} </h2>
          {this.state.sessions.length > 1 && (
            <span onClick={this.removeSession(index)}>
              <i className="fa fa-minus-circle" />
            </span>
          )}
        </div>
        <div className={styles.inputItem}>
          <span>Title</span>
          <input
            placeholder="Type text here"
            value={session.title}
            onChange={this.sessionChanged('title', index)}
          />
        </div>
        <div className={styles.mediaContainer}>
          <span>Media</span>
          <div className={styles.media}>
            <div className={styles.upload}>
              <div className={styles.btnUpload} onClick={this.uploadClicked(session.id, 'local')}>
                Upload Video
              </div>
            </div>
            {session.media ? (
              <div className={styles.mediaView}>
                <video
                  controlsList="nodownload"
                  width="200"
                  controls
                  className={styles.media}
                  src={URL.createObjectURL(session.media)}
                />
                <div onClick={this.removeSessionMedia(index)}>
                  <i className="fa fa-minus-circle" />
                </div>
              </div>
            ) : (
              <div className={styles.nomedia}>No video is uploaded</div>
            )}
            <input
              ref={(ref) => (this.fileInputs[session.id] = ref)}
              type="file"
              className={styles.file}
              accept="video/mp4,video/x-m4v,video/*"
              onChange={this.fileUploadChange(index)}
            />
          </div>
        </div>
        {index === this.state.sessions.length - 1 && (
          <div className={styles.btnAddMore} onClick={this.addSession}>
            Add More Session
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
          {/* <div className={styles.btnBack} onClick={this.goBack}>
            <i className={`fa fa-arrow-left ${styles.icon}`} />
            Back
          </div> */}
        </div>
        <h1> Add a new session </h1>
        <table>
          <thead>
            <tr>
              <td>
                <h2>Basic Information</h2>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className={styles.inputItem}>
                  <span>Name</span>
                  <input value={this.state.basic.name} onChange={this.basicInfoChanged('name')} />
                </div>
              </td>
              <td>
                <div className={styles.inputItem}>
                  <span>Marketing Name</span>
                  <input
                    value={this.state.basic.marketing_name}
                    onChange={this.basicInfoChanged('marketing_name')}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.inputItem}>
                  <span>Organization</span>
                  <div className={styles.select}>
                    <Select
                      styles={selectStyles}
                      value={this.state.selectedOrg}
                      onChange={this.basicInfoChanged('org')}
                      options={this.state.orgList}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.inputItem}>
                  <span>Contact</span>
                  <input disabled value={this.state.basic['contact']} />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.inputItem}>
                  <span>Starting Date</span>
                  <DatePicker
                    className={styles.datepicker}
                    selected={this.state.basic.from}
                    onChange={this.basicInfoChanged('from')}
                  />
                </div>
              </td>
              <td>
                <div className={styles.inputItem}>
                  <span>Ending Date</span>
                  <DatePicker
                    className={styles.datepicker}
                    selected={this.state.basic.to}
                    onChange={this.basicInfoChanged('to')}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.inputItem}>
                  <span>Participant Group</span>
                  <div className={styles.select}>
                    <Select
                      styles={selectStyles}
                      value={this.state.selectedGroup}
                      onChange={this.basicInfoChanged('participant_group')}
                      options={this.state.groupList}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.inputItem}>
                  <span>Division / Location</span>
                  <input disabled value={this.state.basic['location']} />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.logoItem}>
                  <span>Logo</span>
                  <div className={styles.logoContainer}>
                    <div className={styles.upload}>
                      <div className={styles.btnUpload} onClick={this.uploadLogoClicked}>
                        Upload Logo
                      </div>
                    </div>
                    {this.state.basic.logo ? (
                      <div className={styles.logo}>
                        <img src={URL.createObjectURL(this.state.basic.logo)} alt="logo" />
                        <div onClick={this.removeLogo}>
                          <i className="fa fa-minus-circle" />
                        </div>
                      </div>
                    ) : (
                      <span>No logo is uploaded</span>
                    )}
                    <input
                      ref={(ref) => (this.logoInput = ref)}
                      type="file"
                      className={styles.file}
                      accept="image/*"
                      onChange={this.logoFileUploadChange}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.textareaItem}>
                  <span>Description</span>
                  <textarea
                    className={styles.textContainer}
                    value={this.state.basic['description']}
                    onChange={this.basicInfoChanged('description')}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.sessions.map((session, index) => this.renderSession(session, index))}
        <div className={styles.btnGroup}>
          <div className={styles.btnSave} onClick={this.addClicked}>
            Save
          </div>
          <div className={styles.btnCancel} onClick={this.cancelClicked}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}

SessionAddContainer.contextType = AppContext;

SessionAddContainer.propTypes = {
  history: PropTypes.object,
};

export default SessionAddContainer;
