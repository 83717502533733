import { Button, Table } from 'antd';
import { forEach } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { TranscriptionController } from '../../../../controllers';
import AppContext from '../../../AppContext';
import { MediaItem } from '../SummaryMedia/MediaItem';
import styles from './SummaryClips.module.scss';

const isArrayHasMoreThan2 = (array) => {
  if (Array.isArray(array)) {
    return array.length >= 2;
  }
  return false;
};

export const SummaryClips = React.memo((props) => {
  const { createdClips, onDownloadVideo, onRemoveVideo } = props;
  const [mergedVideo, setMergedVideo] = useState({});
  const appContext = useContext(AppContext);

  const videoColumns = [
    {
      title: 'Clips',
      dataIndex: 'clips',
      key: 'clips',
      align: 'center',
      render: (_, record, index) => {
        return (
          <div className={styles.videoWrap}>
            <p className={styles.clipTitle}>Clip {index + 1}</p>
            <MediaItem
              media={record}
              onRemove={() => onRemoveVideo(index)}
              onDownloadVideo={(downloadUrl, funcDone) => {
                onDownloadVideo(downloadUrl, `Clip ${index + 1}`, funcDone);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleMerge = async () => {
    let gsUrls = [];
    forEach(createdClips, function (data) {
      if (data.gsUrl) {
        gsUrls = [...gsUrls, data.gsUrl];
      }
    });
    if (isArrayHasMoreThan2(gsUrls)) {
      try {
        appContext.showLoading();
        setMergedVideo({});
        let { data } = await TranscriptionController.mergeClip(gsUrls);
        const dataObj = data[0];
        setMergedVideo(dataObj);
      } catch (error) {
        alert(error.message);
      } finally {
        appContext.hideLoading();
      }
    }
  };

  const renderFooter = () => {
    return (
      <div className={styles.videoWrap}>
        <Button disabled={!isArrayHasMoreThan2(createdClips)} type="primary" onClick={handleMerge}>
          Merge
        </Button>
        {mergedVideo.downloadUrl && (
          <MediaItem
            media={mergedVideo}
            onDownloadVideo={(downloadUrl, funcDone) => {
              onDownloadVideo(downloadUrl, `Video Merged`, funcDone);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Table
      className={styles.table}
      bordered
      columns={videoColumns}
      dataSource={createdClips}
      pagination={{ position: ['none', 'none'] }}
      footer={renderFooter}
    />
  );
});

SummaryClips.propTypes = {
  createdClips: PropTypes.array,
  onDownloadVideo: PropTypes.func,
  onRemoveVideo: PropTypes.func,
};
