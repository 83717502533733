import React from 'react';
import { Spin } from 'antd';
import AppContext from '../AppContext';

import styles from './LoadingView.module.scss';

export default class LoadingView extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ loading }) =>
          loading && (
            <div className={styles.wrapper}>
              <Spin size="large" />
              {/* <div className={styles.loader} /> */}
            </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
