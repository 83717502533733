import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';
import { BackButton } from '../../../components/Button';
import { AppContext } from '../../../components';
import { ClientController } from '../../../controllers';
import styles from './ClientListContainer.module.scss';
import { setParams, getParamsUrl } from '../../../utils/url';

class ClientListContainer extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      data: [],
      keyword: '',
      loading: false,
      table: {
        page: searchParams && searchParams.page ? +searchParams.page : 1,
        size: searchParams && searchParams.size ? +searchParams.size : 10,
      },
    };

    this.columns = [
      {
        title: 'Organization',
        dataIndex: 'org',
        align: 'center',
        sorter: (a, b) => (a.org > b.org ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'org'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Participants',
        align: 'center',
        render: (text, record, index) => record.participants.length,
      },
      {
        title: 'Participant Groups',
        align: 'center',
        render: (text, record, index) => record.participant_group_ids.length,
      },
      {
        title: 'Divisions / Locations',
        dataIndex: 'contact',
        align: 'center',
        sorter: (a, b) => (a.contact > b.contact ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'contact'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Active Campaigns',
        dataIndex: 'campaign',
        align: 'center',
        sorter: (a, b) => (a.campaign > b.campaign ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'campaign'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (text, record, index) => (
          <Switch onChange={this.statusChanged(record)} checked={record.status} />
        ),
      },
    ];
  }

  async componentDidMount() {
    await this.reload();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.reload();
    }
  }

  getKeyworkValue = () => {
    const paramsURl = getParamsUrl();
    return paramsURl && paramsURl.keyword ? paramsURl.keyword : '';
  };

  reload = async () => {
    const keyword = this.getKeyworkValue();
    this.setState({ loading: true, keyword });

    let data = await ClientController.getClients(
      (this.context.manager && this.context.manager.admin) || false,
    );

    data = data
      .filter((client) => client.org.toLowerCase().includes(this.getKeyworkValue().toLowerCase()))
      .map((client) => {
        let item = { ...client };
        let emailSet = new Set();
        client.participant_groups.forEach((group) => {
          for (let participant of group.participant_list) {
            emailSet.add(participant.email);
          }
        });
        item.participants = Array.from(emailSet);
        return item;
      });
    this.setState({
      data,
      loading: false,
    });
  };

  addClicked = () => {
    this.props.history.push('/clients/add');
  };

  editClicked = (clientId) => {
    this.props.history.push(`/clients/edit/${clientId}`);
  };

  statusChanged = (client) => async (checked, event) => {
    event.stopPropagation();

    if (client.status) {
      if (window.confirm('Do you want to deactivate this client?')) {
        await ClientController.deactivateClient(client.id);
        await this.reload();
      }
    } else {
      if (window.confirm('Do you want to activate this client?')) {
        await ClientController.activateClient(client.id);
        await this.reload();
      }
    }
  };

  searchInputChanged = (e) => {
    if (!e.target.value) {
      const newParams = setParams({}, true);
      this.props.history.replace({
        search: `?${newParams}`,
      });
    } else {
      this.setState({
        keyword: e.target.value,
      });
    }
  };

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      // enter pressed
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
    }
  };

  tableChange = (pagination, sorter) => {
    const newParams = setParams({
      page: pagination.current,
      size: pagination.pageSize,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
    });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  render() {
    const { data, loading, table } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchbar}>
            <i className={`fa fa-search ${styles.iconSearch}`} />
            <input
              type="text"
              placeholder="Type organization name here and press enter to get the result..."
              value={this.state.keyword}
              onChange={this.searchInputChanged}
              onKeyPress={this.searchInputKeyPressed}
            />
          </div>
          <div onClick={this.addClicked}>
            <i className={`fa fa-plus ${styles.icon}`} />
            Add
          </div>
          <BackButton history={this.props.history} />
        </div>
        <Table
          onChange={(pagination, filter, sorter) => {
            this.tableChange(pagination, sorter);
          }}
          columns={this.columns}
          dataSource={data}
          rowKey={(item) => item.id}
          bordered
          size="middle"
          pagination={{
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            defaultCurrent: table.page,
            defaultPageSize: table.size,
          }}
          onRow={(record) => ({
            onClick: (event) => this.editClicked(record.id),
          })}
          loading={loading}
        />
      </div>
    );
  }
}

ClientListContainer.contextType = AppContext;

ClientListContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default ClientListContainer;
