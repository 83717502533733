import { Button, Input, Modal, Select, Table } from 'antd';
import * as EmailValidator from 'email-validator';
import { css, cx } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { AppContext } from '../../components';
import { ManagerController, UserController } from '../../controllers';
import styles from './ManagersContainer.module.scss';
import { BackButton } from '../../components/Button';

import { setParams, getParamsUrl } from '../../utils/url';

const stylePassword = (props) => css`
  &::-webkit-input-placeholder {
    -webkit-text-security: ${props && 'disc !important'};
  }
`;

class ManagersContainer extends React.Component {
  action = {
    add: 'add',
    edit: 'edit',
  };

  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      loading: false,
      managers: [],
      visible: false,
      fullName: '',
      email: '',
      password: '',
      managerId: '',
      clients: [],
      clientsSelect: [],
      actionStatus: this.action.add,
      page: {
        current: searchParams && searchParams.currentPage ? +searchParams.currentPage : 1,
      },
    };

    this.columns = [
      {
        title: 'No',
        dataIndex: 'name',
        align: 'center',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'fullName',
        align: 'center',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
      },
      {
        title: 'Admin',
        dataIndex: 'admin',
        align: 'center',
        render: (text, record, index) => (record.admin ? 'admin' : ''),
      },
      {
        title: 'Action',
        align: 'center',
        render: (text, record, index) => (
          <div className={styles.action}>
            {!record.admin ? (
              <>
                <div
                  className={styles.actionBtn}
                  onClick={() => this.handleActionSubmit('delete', record)}
                >
                  <i className="fa fa-trash" />
                </div>
                <div
                  className={styles.actionBtn}
                  onClick={() => this.handleActionSubmit('edit', record)}
                >
                  <i className="fa fa-edit" />
                </div>
              </>
            ) : null}
          </div>
        ),
      },
    ];
  }

  handleActionSubmit = (event, dManager) => {
    if (event === 'delete') {
      if (window.confirm('Are you sure you want to delete this manager?')) {
        const manager = JSON.parse(window.localStorage.getItem('login_manager'));
        ManagerController.deleteManager(manager.userId, dManager.userId)
          .then((res) => {
            this.reload();
          })
          .catch((error) => {
            alert(error.toString());
            this.setState({ loading: false });
          });
      }
    } else if (event === 'reset_password') {
    } else if (event === 'edit') {
      this.setState({
        fullName: dManager.fullName,
        email: dManager.email,
        password: '',
        visible: true,
        clientsSelect: dManager.clientIds || [],
        managerId: dManager.userId,
        actionStatus: this.action.edit,
      });
    }
  };

  componentDidMount() {
    this.reload();
  }

  async reload() {
    this.setState({ loading: true });
    const managers = await ManagerController.getAllManagers();
    const clients = await ManagerController.getAllClients();
    this.setState({ managers, clients, loading: false });
  }

  addClicked = () => {
    this.setState({
      fullName: '',
      email: '',
      password: '',
      visible: true,
      clientsSelect: [],
    });
  };

  onAddManager = (params) => {
    ManagerController.addManager(params)
      .then((res) => {
        this.reload();
        this.setState({
          visible: false,
          fullName: '',
          email: '',
          password: '',
        });
      })
      .catch((error) => {
        alert(error.toString());
        this.setState({ loading: false });
      });
  };

  handleSave = async () => {
    const { fullName, email, password } = this.state;
    if (!fullName || !email) {
      alert('Please fill in all information.');
      return;
    }

    if (this.state.actionStatus === this.action.add && !password) {
      alert('Please fill in all information.');
      return;
    }

    if (!EmailValidator.validate(email)) {
      alert('The email address is badly formatted.');
      return;
    }
    this.setState({ loading: true });
    const manager = JSON.parse(window.localStorage.getItem('login_manager'));
    const params = {
      id: manager.userId,
      fullName: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      clientIds: this.state.clientsSelect,
      managerId: this.state.managerId,
    };
    let isAdd = this.state.actionStatus === this.action.add;
    if (isAdd) {
      const user = await UserController.getUserByEmail(params.email);
      if (user) {
        Modal.confirm({
          title: 'Confirm',
          centered: true,
          content:
            'The email address is already in use by another account. Do you want to change the password of this account?',
          onOk: () => this.onAddManager(params),
          onCancel: () => {
            this.onAddManager({ ...params, forceChange: false });
          },
        });
      } else {
        this.onAddManager(params);
      }
    } else {
      // const promise = isAdd
      //   ? ManagerController.addManager(params)
      //   : ManagerController.editManager(params);

      ManagerController.editManager(params)
        .then(() => {
          this.reload();
          this.setState({
            visible: false,
            fullName: '',
            email: '',
            password: '',
          });
        })
        .catch((error) => {
          alert(error.toString());
          this.setState({ loading: false });
        });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      fullName: '',
      email: '',
      password: '',
      actionStatus: this.action.add,
    });
  };

  handleChange = (value) => {
    this.setState({ clientsSelect: value });
  };

  filterOption = (inputValue, option) => {
    const optionValue = (option && option.props && option.props.children) || '';
    if (optionValue.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) return true;
    return false;
  };

  changePage = (page, size) => {
    const newParams = setParams({ currentPage: page, pageSize: size });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  render() {
    const { clients, clientsSelect, page } = this.state;
    return (
      <div className={styles.wrapper}>
        <div align="right" className={styles.actionBar}>
          <div className={styles.btnAdd} onClick={this.addClicked}>
            <i className={`fa fa-plus ${styles.icon}`} /> Add
          </div>
          <BackButton history={this.props.history} />
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.managers}
          rowKey={(record, index) => record.userId}
          pagination={{
            onChange: this.changePage,
            defaultCurrent: page.current,
          }}
          bordered
          size="middle"
        />
        <Modal
          title={this.state.actionStatus === this.action.add ? 'Add Manager' : 'Edit Manager'}
          visible={this.state.visible}
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.loading}
              onClick={this.handleSave}
            >
              Confirm
            </Button>,
          ]}
        >
          <Input
            placeholder="Full Name"
            onChange={(e) => this.setState({ fullName: e.target.value })}
            value={this.state.fullName}
          />
          <br />
          <br />
          <Input
            placeholder="Email"
            type="email"
            disabled={this.state.actionStatus === this.action.edit}
            onChange={(e) => this.setState({ email: e.target.value })}
            value={this.state.email}
          />
          <br />
          <br />
          <Input
            className={cx(stylePassword(this.state.actionStatus === this.action.edit))}
            placeholder={
              this.state.actionStatus === this.action.edit ? '&#9679;&#9679;' : 'Initial Password'
            }
            type="password"
            onChange={(e) => this.setState({ password: e.target.value })}
            value={this.state.password}
          />
          <br />
          <br />
          <Select
            style={{
              width: '100%',
            }}
            mode="multiple"
            value={clientsSelect}
            optionFilterProp="org"
            placeholder="Clients"
            filterOption={this.filterOption}
            onChange={(v) => this.handleChange(v)}
          >
            {clients.map((client) => (
              <Select.Option key={client.id} value={client.id}>
                {client.org}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      </div>
    );
  }
}

ManagersContainer.contextType = AppContext;

ManagersContainer.propTypes = {
  history: PropTypes.object,
};

export default ManagersContainer;
