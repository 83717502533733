import PropTypes from 'prop-types';
import React from 'react';
import styles from './Word.module.scss';

export const Word = React.memo(
  ({ children, isSelect = false, isHover = false, onMouseDown, onMouseEnter, className = '' }) => {
    const classNames = `${isSelect ? styles.selected : ''} ${isHover ? styles.hovered : ''}`;

    return (
      <span
        className={`${classNames} ${className}`}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </span>
    );
  },
);

Word.propTypes = {
  children: PropTypes.any,
  isSelect: PropTypes.bool,
  isHover: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  className: PropTypes.string,
};
