import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Progress } from 'antd';
import { css, cx } from 'emotion';

const style = css`
  .uploading-percent {
    font-size: 25px;
    color: white;
  }
  .ant-modal-content {
    background: transparent;
    box-shadow: unset;
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const UploadingProgress = (props) => {
  const { percent, isShow } = props;

  return (
    <Modal visible={isShow} footer={null} closable={false} centered className={cx(style)}>
      <span className="uploading-percent">Uploading: {percent}%</span>
      <Progress status="active" strokeWidth={10} percent={percent} showInfo={false} />
    </Modal>
  );
};

UploadingProgress.propTypes = {
  percent: PropTypes.number,
  isShow: PropTypes.bool,
  color: PropTypes.string,
};

export default UploadingProgress;
