import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spin, Checkbox } from 'antd';

import { AppContext } from '../../../components';
import {
  CampaignController,
  ReportController,
  AnswerController,
  UserController,
} from '../../../controllers';
import QuestionType, { getQuestionTitle } from '../../../constants/questionType';
import CampaignColor from '../../../constants/campaignColor';
import EditorCustom from '../../../components/common/Editor';

import styles from './ReportAnswersContainer.module.scss';
import uuid from 'uuid/v4';
import CampaignType from '../../../constants/campaignType';
import { getDownloadVideo } from './../../../utils/url';
import FeedbackConversation from '../../../components/ReportAnswers/FeedbackConversation/FeedbackConversation';
import moment from 'moment';
import { getCreateAtFromFeedbackHistory } from '../../../utils/getData';

class ReportAnswersContainer extends React.Component {
  colors = [];
  constructor(props) {
    super(props);
    this.colors = CampaignColor;
    this.state = {
      campaignId: props.match.params.id,
      userId: props.match.params.userId,
      curUser: null,
      campaign: null,
      answers: null,
      isCompleted: false,
      feedbacks: [],
      loadingDownloadBtn: {},
      loading: false,
      requiredText: [],
      requiredVideo: [],
    };

    this.video = {};
  }

  async componentDidMount() {
    let { campaignId, userId } = this.state;

    this.setState({ loading: true });
    // loading campaign data
    let campaign = await CampaignController.getCampaignById(this.state.campaignId);
    let curUser;
    if (campaign.type === CampaignType.OPEN) {
    } else {
      // getting current user
      let index = 0;
      if (userId.startsWith('NaN')) {
        index = parseInt(userId.substring(3));
      } else {
        index = campaign.participant_group.participant_list.findIndex(
          (item) => item.userId === userId,
        );
      }
      curUser = campaign.participant_group.participant_list[index];
      this.setState({ curUser });
    }

    this.setState({ campaign });

    let feedbacks = [];
    let requiredText = [];
    let requiredVideo = [];
    let isCompleted = true;

    // loading answers from the user
    let answers = null;
    if (!userId.startsWith('NaN')) {
      answers = await ReportController.getAnswers(campaignId, userId);
      if (answers && answers.feedbacks && answers.feedbacks.length) {
        feedbacks = answers.feedbacks;
        // map feedback to comments
        const userManager = JSON.parse(localStorage.getItem('login_manager'));
        delete userManager.admin;
        if (!answers.comments) {
          answers.comments = {};
          if (Array.isArray(feedbacks)) {
            feedbacks.forEach((feedback, index) => {
              if (!answers.comments[index]) {
                answers.comments[index] = [];
              }
              if (feedback) {
                answers.comments[index].push({
                  feedback,
                  ...userManager,
                  createAt: answers.updatedAt,
                });
              }
            });
            let answerRefId = `${campaignId}-${userId}`;
            await AnswerController.updateAnswer(answerRefId, { comments: answers.comments });
          }
        }
        if (Array.isArray(answers.feedbacks)) {
          requiredText = answers.feedbacks.map((_, index) => {
            const feedbackHistory = answers.feedbackHistory[index];
            if (
              feedbackHistory &&
              feedbackHistory[feedbackHistory.length - 1] &&
              feedbackHistory[feedbackHistory.length - 1].required_text
            )
              return true;
            return false;
          });
          requiredVideo = answers.feedbacks.map((_, index) => {
            const feedbackHistory = answers.feedbackHistory[index];
            if (
              feedbackHistory &&
              feedbackHistory[feedbackHistory.length - 1] &&
              feedbackHistory[feedbackHistory.length - 1].required_video
            )
              return true;
            return false;
          });
        }
      } else {
        feedbacks = campaign.questions.map((question) => '');
        requiredText = campaign.questions.map(() => false);
        requiredVideo = campaign.questions.map(() => false);
      }
      if (!answers) {
        // user exists but not answered yet
        answers = this.initializeAnswers();
        isCompleted = false;
      }
    } else {
      // user doesn't exist
      answers = this.initializeAnswers();
      feedbacks = campaign.questions.map((question) => '');
      isCompleted = false;
    }

    if (campaign.type === CampaignType.OPEN) {
      curUser = {
        name: answers.name,
        email: answers.name,
      };
      await this.setState({ curUser });
    }
    this.setState({ answers, feedbacks, isCompleted, loading: false, requiredText, requiredVideo });
  }

  isRequiredByType = (questionType) => {
    if (
      questionType === QuestionType.OPEN_TEXT_QUESTION ||
      questionType === QuestionType.BLANK_QUESTION
    ) {
      return {
        isText: true,
        isVideo: true,
        isSignature: false,
      };
    }
    if (questionType === QuestionType.SIGNATURE_QUESTION) {
      return {
        isText: false,
        isVideo: false,
        isSignature: true,
      };
    }

    return {
      isText: false,
      isVideo: true,
      isSignature: false,
    };
  };

  // generate an empty answer
  initializeAnswers = () => {
    let { campaign } = this.state;
    let answers = {
      answers: [],
      medias: [],
      reward: 0,
      complete: false,
    };

    answers.answers = campaign.questions.map((question) => {
      if (question.type === QuestionType.INSTRUCTION_QUESTION) {
        return false;
      } else if (question.type === QuestionType.OPEN_TEXT_QUESTION) {
        return '';
      } else if (question.type === QuestionType.ONE_CHOICE_QUESTION) {
        return -1;
      } else if (question.type === QuestionType.MULTIPLE_CHOICE_QUESTION) {
        return [];
      } else if (question.type === QuestionType.RANKING_CHOICE_QUESTION) {
        return [];
      }
      return null;
    });
    answers.medias = campaign.questions.map((question) => {
      return [];
    });

    return answers;
  };

  downloadClicked = async () => {
    let { campaign, curUser, answers } = this.state;
    this.context.showLoading();
    await ReportController.getPDF(campaign, curUser, answers);
    this.context.hideLoading();
  };

  downloadSignatureClicked = (indexQuestion, indexMedia) => async () => {
    let { campaign, curUser, answers } = this.state;

    const signatureMedia =
      (answers.medias &&
        answers.medias[indexQuestion] &&
        answers.medias[indexQuestion][indexMedia]) ||
      [];

    // Returns data with only the signature question
    const signatureAnswers = {
      ...answers,
      answers: {
        0: answers.answers[indexQuestion],
      },
      feedbackHistory: {
        0: answers.feedbackHistory[indexQuestion],
      },
      medias: {
        0: [signatureMedia],
      },
    };

    const signatureCampaign = {
      ...campaign,
      questions: [campaign.questions[indexQuestion]],
    };

    this.context.showLoading();
    await ReportController.getPDF(signatureCampaign, curUser, signatureAnswers);
    this.context.hideLoading();
  };

  isSignatureQuestion = (indexQuestion) => {
    let { campaign } = this.state;
    if (
      campaign &&
      campaign.questions &&
      campaign.questions[indexQuestion] &&
      campaign.questions[indexQuestion].type === QuestionType.SIGNATURE_QUESTION
    )
      return true;
    return false;
  };

  completeClicked = async () => {
    let { answers, campaign, userId } = this.state;
    answers.completed = 'completed';
    let answerRefId = `${campaign.id}-${userId}`;
    this.context.showLoading();
    try {
      await AnswerController.updateAnswer(answerRefId, { completed: answers.completed });
      this.setState({ answers });
      this.context.hideLoading();
    } catch (error) {
      this.context.hideLoading();
      alert(error.message);
    }
  };

  rejectClicked = async () => {
    this.context.showLoading();
    let { answers, campaign, userId, feedbacks, requiredText, requiredVideo } = this.state;

    if (Array.isArray(feedbacks)) {
      const allFeedbackEmpty = feedbacks.some((f, index) => {
        return !!f || requiredText[index] || requiredVideo[index];
      });
      if (!allFeedbackEmpty) {
        alert('Please provide at least 1 feedback.');
        this.context.hideLoading();
        return;
      }

      const indexError = feedbacks.findIndex((v, index) => {
        // filter signature question type form checking require video/text
        if (
          campaign.questions &&
          campaign.questions[index] &&
          campaign.questions[index].type === QuestionType.SIGNATURE_QUESTION
        )
          return false;

        if (v && !requiredText[index] && !requiredVideo[index]) return true;
        if (!v && (requiredText[index] || requiredVideo[index])) {
          return true;
        }
        return false;
      });
      if (indexError !== -1) {
        if (feedbacks[indexError]) {
          alert('Please check "Require Video" and/or "Require Text" to request feedback.');
        } else {
          alert("Feedback can't be empty.");
        }
        this.context.hideLoading();
        return;
      }
    }

    answers.feedbacks = feedbacks;
    answers.completed = 'rejected';
    if (!answers.comments) {
      answers.comments = {};
    }
    if (!answers.feedbackHistory) {
      answers.feedbackHistory = {};
    }
    const userManager = JSON.parse(localStorage.getItem('login_manager'));
    const user = await UserController.getUserById(userManager.userId);

    delete userManager.admin;
    if (Array.isArray(feedbacks)) {
      feedbacks.forEach((feedback, index) => {
        //#region Comment
        if (!answers.comments[index]) {
          answers.comments[index] = [];
        }
        if (feedback) {
          answers.comments[index].push({
            feedback,
            ...userManager,
            createAt: moment().unix(),
          });
        }
        //#endregion Comment
        //#region Feedback history
        if (!answers.feedbackHistory[index]) {
          answers.feedbackHistory[index] = [];
        }
        if (feedback) {
          const dataFeedback = {
            ...userManager,
            required_video: requiredVideo[index],
            required_text: requiredText[index],
            avatar: user ? user.avatar : '',
            text: feedback,
            createAt: moment().unix(),
          };

          // Remove require video/text form signature question
          if (
            campaign.questions &&
            campaign.questions[index] &&
            campaign.questions[index].type === QuestionType.SIGNATURE_QUESTION
          ) {
            delete dataFeedback.required_video;
            delete dataFeedback.required_text;
          }

          answers.feedbackHistory[index].push(dataFeedback);
        }
        //#endregion Feedback history
      });
    }
    let answerRefId = `${campaign.id}-${userId}`;
    // this.context.showLoading();
    try {
      const dataUpdate = {
        feedbacks: answers.feedbacks,
        completed: answers.completed,
        comments: answers.comments,
        feedbackHistory: answers.feedbackHistory,
      };
      await AnswerController.updateAnswer(answerRefId, dataUpdate);
      this.setState({ answers });
      this.context.hideLoading();
    } catch (error) {
      this.context.hideLoading();
      alert(error.message);
    }
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  handleFeedbackChanged = (index) => (event) => {
    let { feedbacks } = this.state;
    feedbacks[index] = event.target.value;
    this.setState({ feedbacks });
  };

  handleBricks = (text, replace = '_____') => {
    const arr = text.split('[**]');
    if (arr.length > 1) {
      const newText = arr.reduce((data, current, index) => {
        data += current;
        if (index !== arr.length - 1) {
          data += ` ${replace}`;
        }
        return data;
      }, '');
      return newText;
    }
    return text;
  };

  handleAnswerBlank = (question, answer) => {
    if (!answer) {
      if (question.type === QuestionType.BLANK_QUESTION) {
        return 'No answer yet';
      }
      return question.question;
    }
    const indexOption = question.question.indexOf('[*]');
    const indexBrick = question.question.indexOf('[**]');

    const answerBricks = answer[1];
    const answerSelected = question.answers[answer[0]];
    if (indexOption > indexBrick) {
      return `${answerBricks}; ${answerSelected}`;
    } else {
      return `${answerSelected}; ${answerBricks}`;
    }
  };

  downloadVideo = (downloadUrl, index) => {
    const { loadingDownloadBtn, campaign, curUser } = this.state;
    loadingDownloadBtn[index] = true;
    this.setState({ loadingDownloadBtn });

    const stopLoadingBtn = () => {
      loadingDownloadBtn[index] = false;
      this.setState({ loadingDownloadBtn });
    };
    const nameDownloadVideo = `${campaign.name}-${curUser.name}-${index}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, stopLoadingBtn);
  };

  renderAnswerPreview = (question) => {
    if (question.type !== QuestionType.BLANK_QUESTION && !question.questionHtml) {
      return question.question;
    }
    const { answers } = question;
    const answerText = answers.reduce((data, current, index) => {
      if (current.trim()) {
        if (index !== 0) {
          data.push(<span key={uuid()}>&nbsp;/&nbsp;</span>);
        }
        data.push(
          <span key={uuid()} style={{ color: this.colors[index % this.colors.length] }}>
            {current}{' '}
          </span>,
        );
      }
      return data;
    }, []);
    if (question.questionHtml) {
      let arrReplaceAnswer = question.questionHtml.split('[*]');
      const newArrReplace = arrReplaceAnswer.reduce((arr, current, index) => {
        const newCurrent = this.handleBricks(current);
        arr.push(newCurrent);
        if (index !== arrReplaceAnswer.length - 1) {
          arr.push('&nbsp;[&nbsp;');
          arr.push(answerText);
          arr.push('&nbsp;]&nbsp;');
        }
        return arr;
      }, []);
      return newArrReplace[0];
    } else {
      let arrReplaceAnswer = question.question.split('[*]');
      const newArrReplace = arrReplaceAnswer.reduce((arr, current, index) => {
        const newCurrent = this.handleBricks(current);
        arr.push(<span key={uuid()}>{newCurrent}</span>);
        if (index !== arrReplaceAnswer.length - 1) {
          arr.push(<span key={uuid()}>&nbsp;[&nbsp;</span>);
          arr.push(answerText);
          arr.push(<span key={uuid()}>&nbsp;]&nbsp;</span>);
        }
        return arr;
      }, []);
      return newArrReplace;
    }
  };

  setRequiredVideo = (index, isRequired) => {
    const { requiredVideo } = this.state;
    requiredVideo[index] = isRequired;
    this.setState({ requiredVideo });
  };

  setRequiredText = (index, isRequired) => {
    const { requiredText } = this.state;
    requiredText[index] = isRequired;
    this.setState({ requiredText });
  };

  render() {
    let {
      campaign,
      curUser,
      answers,
      feedbacks,
      isCompleted,
      loadingDownloadBtn,
      loading,
      requiredText,
      requiredVideo,
    } = this.state;
    if (loading) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.spin}>
            <Spin />
          </div>
        </div>
      );
    }
    if (!answers) {
      return <div className={styles.wrapper} />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2>Answers from {curUser.name || 'No Name'}</h2>
          {isCompleted &&
            (answers.completed ? (
              answers.completed === 'completed' ? (
                <div className={styles.markCompleted}>COMPLETED</div>
              ) : (
                <div className={styles.markRejected}>REJECTED</div>
              )
            ) : (
              <div className={styles.headerBtnGroup}>
                <Button type="primary" className={styles.headerBtn} onClick={this.completeClicked}>
                  Complete
                </Button>
                <Button type="danger" className={styles.headerBtn} onClick={this.rejectClicked}>
                  Reject
                </Button>
              </div>
            ))}
        </div>

        {campaign.questions.map((question, qIndex) => (
          <div key={qIndex} className={styles.questionContainer}>
            <span
              style={{
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              <div className={styles.typeReport}>
                {getQuestionTitle(question.type)}
                {question.questionTitle ? (
                  <span className={styles.questionTitle}>&nbsp;- {question.questionTitle}</span>
                ) : null}
              </div>
            </span>
            {question.questionHtml ? (
              <EditorCustom value={this.renderAnswerPreview(question)} isView={true} />
            ) : (
              <h3>{this.renderAnswerPreview(question)}</h3>
            )}

            {question.media &&
              (question.media_type === 'youtube' ? (
                <iframe
                  title="youtube_video"
                  id={`${qIndex}`}
                  className={styles.media}
                  width="400"
                  src={question.media.replace('https://youtu.be', 'https://www.youtube.com/embed')}
                  frameBorder="0"
                  allowFullScreen
                />
              ) : question.media_type.includes('image') ? (
                <img className={styles.media} alt="media" src={question.media.downloadUrl} />
              ) : (
                <video
                  controlsList="nodownload"
                  id={`${qIndex}`}
                  width="400"
                  controls
                  className={styles.media}
                  src={question.media.downloadUrl}
                  crossOrigin="*"
                />
              ))}
            {question.type === QuestionType.INSTRUCTION_QUESTION &&
              (answers.answers[qIndex] ? (
                <div className={styles.answerContainer}>
                  <h4>Answer:</h4>
                  WATCHED
                </div>
              ) : (
                <h4>NOT WATCHED</h4>
              ))}
            {question.type === QuestionType.OPEN_TEXT_QUESTION &&
              (answers.answers[qIndex] ? (
                <div className={styles.answerContainer}>
                  <h4>Answer:</h4>
                  {answers.answers[qIndex]}
                </div>
              ) : (
                <h4>No Answer</h4>
              ))}
            {question.type === QuestionType.ONE_CHOICE_QUESTION &&
              (answers.answers[qIndex] === -1 ? (
                <div className={styles.answerContainer}>
                  <h4>No Answer</h4>
                </div>
              ) : (
                <div className={styles.answerContainer}>
                  <h4>Answer:</h4>
                  {question.answers.map((answer, answerIndex) => (
                    <div key={answerIndex} className={styles.answerItem}>
                      <input
                        type="radio"
                        name={qIndex}
                        value={answer}
                        disabled
                        checked={answers.answers[qIndex] === answerIndex}
                      />
                      <span>{answer}</span>
                    </div>
                  ))}
                </div>
              ))}
            {question.type === QuestionType.BLANK_QUESTION &&
              (answers.answers[qIndex] === -1 ? (
                <div className={styles.answerContainer}>
                  <h4>No Answer</h4>
                </div>
              ) : (
                <div className={styles.answerContainer}>
                  <h4>Answer:</h4>
                  {this.handleAnswerBlank(question, answers.answers[qIndex])}
                </div>
              ))}
            {question.type === QuestionType.MULTIPLE_CHOICE_QUESTION &&
              (!answers.answers[qIndex] || answers.answers[qIndex].length === 0 ? (
                <h4>No Answer</h4>
              ) : (
                <div className={styles.answerContainer}>
                  <h4>Answer:</h4>
                  {question.answers.map((answer, answerIndex) => (
                    <div key={answerIndex} className={styles.answerItem}>
                      <input
                        type="checkbox"
                        name={qIndex}
                        value={answer}
                        disabled
                        checked={answers.answers[qIndex].includes(answerIndex)}
                      />
                      <span>{answer}</span>
                    </div>
                  ))}
                </div>
              ))}
            {question.type === QuestionType.RANKING_CHOICE_QUESTION &&
              (!answers.answers[qIndex] || answers.answers[qIndex].length === 0 ? (
                <h4>No Answer</h4>
              ) : (
                <div className={styles.answerContainer}>
                  <h4>Answer:</h4>
                  {answers.answers[qIndex].map((item) => (
                    <div key={item} className={styles.answerItem}>
                      <span>
                        {item + 1}. {question.answers[item]}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            {question.type === QuestionType.SIGNATURE_QUESTION &&
              answers.medias &&
              answers.medias[qIndex] &&
              answers.medias[qIndex].length === 0 && <h4>No Signature</h4>}
            {answers.medias && answers.medias[qIndex] && answers.medias[qIndex].length > 0 && (
              <div className={styles.userMediaContainer}>
                <div className={styles.overflow}>
                  {answers.medias[qIndex]
                    .filter((f) => !!f)
                    .map((media, mIndex) => {
                      if (media.type && media.type.includes('image')) {
                        if (this.isSignatureQuestion(qIndex)) {
                          return (
                            <div className={styles.videoGroup} key={mIndex}>
                              <img
                                key={mIndex}
                                className={styles.userMediaSignature}
                                alt="user_image"
                                src={media.downloadUrl}
                              />
                              <Button
                                type="primary"
                                onClick={this.downloadSignatureClicked(qIndex, mIndex)}
                              >
                                Download
                              </Button>
                            </div>
                          );
                        }
                        return (
                          <img
                            key={mIndex}
                            className={styles.userMedia}
                            alt="user_image"
                            src={media.downloadUrl}
                          />
                        );
                      } else {
                        if (!media || !media.downloadUrl) {
                          return null;
                        }

                        let videoCreateAt = '';
                        if (answers.feedbackHistory) {
                          const createAt = getCreateAtFromFeedbackHistory(
                            media.gsUrl,
                            answers.feedbackHistory,
                          );
                          if (createAt) {
                            videoCreateAt = moment.unix(createAt).format('lll');
                          }
                        }

                        return (
                          <div className={styles.videoGroup} key={mIndex}>
                            <p className={styles.dateTime}>{videoCreateAt}</p>
                            <video
                              controlsList="nodownload"
                              id={`${qIndex}-${mIndex}`}
                              height="150"
                              controls
                              className={styles.userMedia}
                              src={media.downloadUrl}
                              crossOrigin="anonymous"
                            />
                            <Button
                              type="primary"
                              loading={loadingDownloadBtn[`${qIndex + 1}_${mIndex}`]}
                              onClick={() =>
                                this.downloadVideo(media.downloadUrl, `${qIndex + 1}_${mIndex}`)
                              }
                            >
                              Download
                            </Button>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            )}
            <div className={styles.feedbackContainer}>
              <div className={styles.feedbackContainerTitle}>
                <h4>Feedback</h4>
                <FeedbackConversation
                  feedbackConversation={answers.comments && answers.comments[qIndex]}
                />
              </div>
              <textarea
                className={styles.feedback}
                disabled={answers.completed}
                value={feedbacks[qIndex]}
                onChange={this.handleFeedbackChanged(qIndex)}
              />
            </div>
            <div className={styles.wrapperCheckbox}>
              {this.isRequiredByType(question.type).isVideo && (
                <Checkbox
                  className={styles.requireVideoCheckbox}
                  checked={requiredVideo[qIndex]}
                  onChange={(evt) => this.setRequiredVideo(qIndex, evt.target.checked)}
                  disabled={answers.completed}
                >
                  Require Video
                </Checkbox>
              )}
              {this.isRequiredByType(question.type).isText && (
                <Checkbox
                  checked={requiredText[qIndex]}
                  onChange={(evt) => this.setRequiredText(qIndex, evt.target.checked)}
                  disabled={answers.completed}
                >
                  Require Text
                </Checkbox>
              )}
              {this.isRequiredByType(question.type).isSignature && (
                <Checkbox checked={true} disabled={true}>
                  Require Signature
                </Checkbox>
              )}
            </div>
          </div>
        ))}
        <div className={styles.btnGroup}>
          <Button type="primary" className={styles.btn} onClick={this.downloadClicked}>
            Download
          </Button>
          <Button type="danger" className={styles.btn} onClick={this.cancelClicked}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

ReportAnswersContainer.contextType = AppContext;

ReportAnswersContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ReportAnswersContainer;
