import { Storage } from '../lib/firebase';
import queryString from 'query-string';
import { clone } from 'lodash';
import { youtubeRegExp } from '../utils/validation';

export const getDownloadUrlWithFilename = (url, filename) => {
  const baseUrl = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/downloadWithSpecifiedName`;
  if (!url) return null;
  var url1 = url.replace('%2F', '**');
  url1 = url1.replace('&', '***');
  var filename1 = filename.replace(/\s/g, '%20');
  return `${baseUrl}?url=${url1}&filename=${filename1}`;
};

export const getTypeFile = (type) => {
  const arr = type.split(';');
  const typeDefault = arr[0];
  const result = typeDefault.split('/');

  if (result[1]) {
    const videoType =
      /WEBM|MPG|MP2|MPEG|MPE|MPV|OGG|MP4|M4P|M4V|AVI|WMV|MOV|QT|FLV|SWF|AVCHD/i.test(
        result[1].toUpperCase(),
      );
    if (videoType) {
      return result[1];
    }
  }
  return 'mov';
};

export const getDownloadVideo = (downloadUrl, videoName, callback) => {
  Storage.refFromURL(downloadUrl)
    .getMetadata()
    .then((data) => {
      const { contentType } = data;
      const typeFile = getTypeFile(contentType);
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function (event) {
        var blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${videoName}.${typeFile}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        callback();
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    });
};

export const hasWhiteSpace = (s) => {
  return s.indexOf(' ') >= 0;
};

export const getParamsUrl = () => {
  if (typeof window !== 'undefined') {
    const { search } = window.location;
    const parse = queryString.parse(search);
    return parse;
  }
  return {};
};

export const setParams = (params, removeOldParams = false) => {
  let newParams = {};
  if (removeOldParams) {
    newParams = params;
  } else {
    const currentParams = getParamsUrl() || {};
    newParams = Object.assign(currentParams, params);
  }
  const search = queryString.stringify(newParams);
  return search;
};

export const isUrl = (url) => {
  if (!url || !url.trim()) return false;
  let detectUrl;
  try {
    detectUrl = new URL(url);
  } catch (error) {
    return false;
  }
  if (detectUrl && (detectUrl.protocol === 'http:' || detectUrl.protocol === 'https:')) {
    return true;
  }
  return true;
};

export const isYoutubeLink = (link) => {
  const linkClone = clone(link);
  const youtubeLinks = linkClone.match(youtubeRegExp);
  if (youtubeLinks && youtubeLinks[1]) return true;
  return false;
};

export const getYoutubeLink = (link) => {
  const linkClone = clone(link);
  const youtubeLinks = linkClone.match(youtubeRegExp);
  if (youtubeLinks && youtubeLinks[1]) {
    const youtubeId = youtubeLinks[1];
    return `https://www.youtube.com/embed/${youtubeId}`;
  }
  return link;
};
