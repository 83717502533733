import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Avatar from 'react-avatar';
import moment from 'moment';
import { LinkItUrl } from 'react-linkify-it';
import CampaignType from '../../../constants/campaignType';

import styles from './ChatDetailsContainer.module.scss';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('login_manager')),
    };
  }
  renderMedia = (message, campaignType) => {
    return (
      <>
        <div>
          <LinkItUrl>{message.text}</LinkItUrl>
        </div>
        {message.videoType === 'youtube' && (
          <iframe
            title="youtube"
            className={styles.messageVideo}
            src={
              message.video.includes('www.youtube.com/watch?v=')
                ? message.video.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')
                : message.video.replace('youtu.be/', 'www.youtube.com/embed/')
            }
            frameBorder="0"
            allowFullScreen
          />
        )}
        {message.videoType === 'video' && (
          <video
            controlsList="nodownload"
            controls
            className={styles.messageVideo}
            src={message.video}
          />
        )}
        {message.image && (
          <img className={styles.messageImage} src={message.image} alt="question_image" />
        )}
        <span>
          {campaignType === CampaignType.CONVERSATION
            ? moment(message.createdAt).format('lll')
            : moment.unix(message.createdAt).format('lll')}
        </span>
      </>
    );
  };

  render() {
    let { message, campaignType } = this.props;
    const { user } = this.state;

    if (message.user._id === user.userId) {
      // admin
      return (
        <div className={cn(styles.messageContainer, styles.messageLContainer)}>
          <div className={styles.messageItem}>
            <Avatar
              name={message.user.name}
              alt="avatar"
              size={40}
              round
              src={message.user.avatar}
              className={styles.adminAvatar}
            />
            <div className={cn(styles.messageBody, styles.messageLBody)}>
              {this.renderMedia(message, campaignType)}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={cn(styles.messageContainer, styles.messageRContainer)}>
          <div className={styles.messageItem}>
            <div className={cn(styles.messageBody, styles.messageRBody)}>
              {this.renderMedia(message, campaignType)}
            </div>
            <Avatar name={message.user.name} src={message.user.avatar} size="40" round />
          </div>
        </div>
      );
    }
  }
}

Message.propTypes = {
  message: PropTypes.object,
  campaignType: PropTypes.string,
};

export default Message;
