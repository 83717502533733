const QuestionType = {
  INSTRUCTION_QUESTION: 'INSTRUCTION',
  OPEN_TEXT_QUESTION: 'OPEN_TEXT',
  ONE_CHOICE_QUESTION: 'ONE_CHOICE',
  MULTIPLE_CHOICE_QUESTION: 'MULTIPLE_CHOICE',
  RANKING_CHOICE_QUESTION: 'RANKING_CHOICE',
  BLANK_QUESTION: 'BLANK',
  SIGNATURE_QUESTION: 'SIGNATURE',
};

export const getQuestionTitle = (type) => {
  switch (type) {
    case QuestionType.INSTRUCTION_QUESTION:
      return 'Instruction';
    case QuestionType.OPEN_TEXT_QUESTION:
      return 'Open Text';
    case QuestionType.ONE_CHOICE_QUESTION:
      return 'One Choice';
    case QuestionType.MULTIPLE_CHOICE_QUESTION:
      return 'Multiple Choice';
    case QuestionType.RANKING_CHOICE_QUESTION:
      return 'Ranking Choice';
    case QuestionType.BLANK_QUESTION:
      return 'Fill in the Blank';
    case QuestionType.SIGNATURE_QUESTION:
      return 'Signature';
    default:
      return '';
  }
};

export default QuestionType;
