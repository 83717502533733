import { Col, Row } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import QuestionType from '../../../../constants/questionType';
import { getCreateAtFromFeedbackHistory } from '../../../../utils/getData';
import { SummaryAnswer } from '../SummaryAnswer/SummaryAnswer';
import { SummaryMedia } from '../SummaryMedia/SummaryMedia';
import { SummaryTranscriptions } from '../SummaryTranscriptions/SummaryTranscriptions';
import styles from './SummaryTableItem.module.scss';

export const SummaryTableItem = React.memo(
  ({ record, questionSelected, onFeedback, onDownloadVideo, onCreateClip }) => {
    const answer = useMemo(() => {
      let answer = '';
      if (questionSelected.type === QuestionType.BLANK_QUESTION) {
        answer =
          record.answer &&
          record.answer.answers &&
          record.answer.answers[questionSelected.questionIndex] &&
          record.answer.answers[questionSelected.questionIndex][1];
      }
      if (questionSelected.type === QuestionType.OPEN_TEXT_QUESTION) {
        answer =
          record.answer &&
          record.answer.answers &&
          record.answer.answers[questionSelected.questionIndex];
      }
      return answer;
    }, [record, questionSelected]);

    const medias = useMemo(() => {
      if (
        record.answer.medias &&
        record.answer.medias[questionSelected.questionIndex] &&
        record.answer.medias[questionSelected.questionIndex].length > 0
      ) {
        let newMedias = record.answer.medias[questionSelected.questionIndex];

        if (record.answer && record.answer.feedbackHistory) {
          newMedias = newMedias.map((mediaItem) => {
            const createAt = getCreateAtFromFeedbackHistory(
              mediaItem.gsUrl,
              record.answer.feedbackHistory,
            );
            if (createAt) {
              return {
                ...mediaItem,
                createAt: moment.unix(createAt).format('lll'),
              };
            }
            return mediaItem;
          });
        }

        return newMedias;
      }
      return null;
    }, [record, questionSelected]);

    const gsUrls = useMemo(() => {
      if (!medias) {
        return null;
      }
      return medias.map((media) => media.gsUrl);
    }, [medias]);

    const transcriptions = useMemo(() => {
      if (!medias) {
        return null;
      }
      let transcriptionsIndex = 0;
      const getInProgressItem = (videoIndex) => {
        const index = transcriptionsIndex;
        transcriptionsIndex++;
        return {
          type: 'progress',
          index: index,
          data: `#${videoIndex} video transcript is being processed`,
        };
      };
      const getNAItem = () => {
        const index = transcriptionsIndex;
        transcriptionsIndex++;
        return {
          type: 'N/A',
          index: index,
        };
      };
      const getBreakItem = () => {
        const index = transcriptionsIndex;
        transcriptionsIndex++;
        return {
          type: 'break',
          index: index,
        };
      };
      const getEnterItem = () => {
        const index = transcriptionsIndex;
        transcriptionsIndex++;
        return {
          type: 'enter',
          index: index,
        };
      };
      const getTextItem = (gsUrl, wordData) => {
        const index = transcriptionsIndex;
        transcriptionsIndex++;
        return {
          type: 'text',
          index: index,
          gsUrl: gsUrl,
          data: wordData,
        };
      };
      // Remove all the empty array of media.words
      const newMedias = medias.map((media) => ({
        gsUrl: media.gsUrl,
        transcriptionStatus: media.transcriptionStatus,
        words: media.words
          ? media.words.filter((wordArrayItem) => wordArrayItem.words.length >= 1)
          : [],
      }));

      const transcriptions = newMedias.reduce((previousMedias, media, indexMedias) => {
        const isLastMedias = newMedias.length - 1 === indexMedias;

        if (media.transcriptionStatus === 'progress') {
          if (!isLastMedias) {
            return [...previousMedias, getInProgressItem(indexMedias + 1), getEnterItem()];
          }
          return [...previousMedias, getInProgressItem(indexMedias + 1)];
        }

        const mediaWords = media.words;
        if (mediaWords.length >= 1) {
          const arrayMedias = mediaWords.reduce((previousWords, words, index) => {
            let arrayWords = [];
            if (index !== 0) {
              arrayWords.push(getBreakItem());
            }
            let listWords = words.words.map((word) => {
              const wordData = JSON.parse(word);
              return getTextItem(media.gsUrl, wordData);
            });
            arrayWords.push(...listWords);
            return [...previousWords, ...arrayWords];
          }, []);
          if (!isLastMedias) {
            arrayMedias.push(getEnterItem());
          }
          return [...previousMedias, ...arrayMedias];
        }

        if (!isLastMedias) {
          return [...previousMedias, getNAItem(), getEnterItem()];
        }

        return [...previousMedias, getNAItem()];
      }, []);

      return transcriptions;
    }, [medias]);

    return (
      <div className={styles.userSummaryTable}>
        <Row>
          <Col xs={24} xl={12} xxl={8} className={styles.flexCol}>
            <div className={styles.userName}>
              <p className={styles.headerTitle}>{record.name}</p>
            </div>
            <div className={`${styles.media} ${styles.grow}`}>
              <SummaryMedia medias={medias} onDownloadVideo={onDownloadVideo} />
            </div>
          </Col>
          <Col xs={24} xl={12} xxl={16} className={styles.flexCol}>
            <div className={styles.textAnswer}>
              <p className={styles.headerTitle}>Answer</p>
            </div>
            <div className={`${styles.answer} ${styles.grow}`}>
              <SummaryAnswer answer={answer} onFeedback={onFeedback} />
            </div>
          </Col>
          <Col span={24} className={styles.textTranscript}>
            <p className={styles.headerTitle}>Transcript</p>
          </Col>
          <Col span={24} className={styles.transcript}>
            <SummaryTranscriptions
              gsUrls={gsUrls}
              transcriptions={transcriptions}
              onCreateClip={onCreateClip}
            />
          </Col>
        </Row>
      </div>
    );
  },
);

SummaryTableItem.propTypes = {
  record: PropTypes.object,
  questionSelected: PropTypes.object,
  onFeedback: PropTypes.func,
  onDownloadVideo: PropTypes.func,
  onCreateClip: PropTypes.func,
};
