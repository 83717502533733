import { reduce, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { reportAction } from '../../../store/report';

export const useSummaryReportRankingChoice = () => {
  const dispatch = useDispatch();
  const questionSelected = useSelector((state) => state.report.questionSelected);
  const wordFrequencyTable = useSelector((state) => state.report.wordFrequencyTable);
  const userAnswers = useSelector((state) => state.report.userAnswers);
  const updateParticipantsSuccess = useSelector((state) => state.report.updateParticipantsSuccess);

  const [userSummary, setUserSummary] = useState([]);
  const [userAnswered, setUserAnswered] = useState([]);
  const [showSummaryDetail, setShowSummaryDetail] = useState(false);

  const detailSummaryRankingChoice = (record, index) => async (event) => {
    setShowSummaryDetail(true);
    event.stopPropagation();
    const userSummaryData = userSummary.filter((u) => {
      if (
        u.answer.answers &&
        u.answer.answers[questionSelected.questionIndex] &&
        u.answer.answers[questionSelected.questionIndex][record.defaultIndex] === index - 1
      ) {
        return true;
      }
      return false;
    });
    setUserAnswered(userSummaryData.map((f) => ({ ...f, checked: false })));
  };

  const { userOptionSummary, totalNumberOfRespondents } = useMemo(() => {
    let userOptionSummary = [];
    for (let index = 0; index < questionSelected.answers.length; index++) {
      userOptionSummary.push([]);
      for (let index2 = 0; index2 < questionSelected.answers.length; index2++) {
        userOptionSummary[index].push(0);
      }
    }
    userSummary.forEach((u) => {
      const listAnswerOfUser = u.answer.answers[questionSelected.questionIndex];
      if (listAnswerOfUser) {
        listAnswerOfUser.forEach((a, index) => {
          userOptionSummary[index][a]++;
        });
      }
    });
    const data = userOptionSummary.reduce((totalUser, item) => {
      return [...totalUser, ...item];
    }, []);
    const total = data.reduce((total, current) => {
      return (total += current);
    }, 0);
    const totalNumberOfRespondents = total;
    return { userOptionSummary, totalNumberOfRespondents };
  }, [userSummary, questionSelected]);

  useEffect(() => {
    const wordSelected = wordFrequencyTable.filter((f) => f.checked);
    const users = uniq(
      reduce(
        wordSelected,
        (prev, current) => {
          return prev.concat(current.users.map((f) => f.userId));
        },
        [],
      ),
    );
    if (users && users.length > 0) {
      const userFilter = userAnswers.filter((f) => users.includes(f.userId));
      setUserSummary(userFilter);
    } else {
      setUserSummary(userAnswers);
    }

    if (updateParticipantsSuccess) {
      dispatch(reportAction.setUpdateParticipantsSuccess(false));
    }
    setShowSummaryDetail(false);
  }, [wordFrequencyTable, userAnswers]);

  useEffect(() => {
    if (userSummary && userSummary.length === 0) {
      setShowSummaryDetail(false);
    }
  }, [userOptionSummary]);

  return {
    showSummaryDetail,
    questionSelected,
    userOptionSummary,
    totalNumberOfRespondents,
    userSummary,
    userAnswers,
    userAnswered,
    detailSummaryRankingChoice,
  };
};
