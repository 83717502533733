import React from 'react';
import { Button } from 'antd';
import styles from './BackButton.module.scss';
import PropTypes from 'prop-types';
import { setParams, getParamsUrl } from '../../utils/url';
export const BackButton = (props) => {
  const prevPageIsLogin = React.useMemo(() => {
    const prePage = localStorage.getItem('prevPage');
    if (prePage === 'login') return true;
    return false;
  }, []);

  const isDisableGoBack = React.useMemo(() => {
    if (window.history.length === 1) return true;
    return false;
  }, []);

  const isFirstPage = React.useMemo(() => {
    if (prevPageIsLogin || isDisableGoBack) {
      const paramUrl = setParams({ firstPage: '1' });
      props.history.replace({
        search: `?${paramUrl}`,
      });
      return true;
    }
    const params = getParamsUrl();
    if (params && params.firstPage === '1') return true;
    return false;
  }, [prevPageIsLogin, isDisableGoBack]);

  const onGoBack = () => {
    props.history.goBack();
  };
  return (
    <Button disabled={isFirstPage} className={styles.backBtn} onClick={onGoBack}>
      <i className={`fa fa-arrow-left ${styles.icon}`} />
      Back
    </Button>
  );
};

BackButton.propTypes = {
  history: PropTypes.object.isRequired,
};
