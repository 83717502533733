import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import uuid from 'uuid/v4';
import moment from 'moment';

import { AppContext } from '../../../components';
import { CampaignController, ConversationController, UserController } from '../../../controllers';
import { Firestore, Storage } from '../../../lib/firebase';
import { isYoutubeLink } from './../../../utils/url';

import Message from './Message';
import CampaignType from '../../../constants/campaignType';
import { get, orderBy } from 'lodash';
import { ChatDetailHeader } from './../../../components';
import { MediaLocal } from '../../../components/Media/MediaItem';
import styles from './ChatDetailsContainer.module.scss';
import { Button, Empty, Spin } from 'antd';

let collection = Firestore.collection('conversation');

class ChatDetailsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      messages: [],
      host: {
        _id: 1,
        name: 'Julie',
        avatar:
          'https://firebasestorage.googleapis.com/v0/b/social-lens-3a3d5.appspot.com/o/avatar%2Fjulie.jpeg?alt=media&token=5c0190b0-2fed-4595-8f8a-60e124981352',
      },
      unsubscribe: () => {},
      campaign: {},
      file: null,
      sending: false,
      progress: 0,
      users: [],
    };
  }

  async componentDidMount() {
    // this.context.showLoading();
    this.setState({ spin: true });
    let campaignId = this.props.match.params.id;
    let campaign = await CampaignController.getCampaignById(campaignId);
    await this.getHost();
    this.setState(
      {
        campaign,
      },
      async () => {
        const promise = [this.initializeMessages()];
        // await this.initializeMessages();
        if (campaign.type === CampaignType.CHAT_GROUP) {
          promise.push(this.userGroupAvatar);
          // await this.userGroupAvatar();
        } else if (campaign.type === CampaignType.CHAT_11) {
          promise.push(this.user11Avatar);
          // await this.user11Avatar();
        }
        await Promise.all(promise);
        this.setState({ spin: false });
      },
    );
  }

  getHost = async () => {
    const userManager = JSON.parse(window.localStorage.getItem('login_manager'));
    const user = await UserController.getUserById(userManager.userId);
    this.setState({
      host: {
        _id: userManager.userId,
        name: userManager.fullName,
        avatar: user ? user.avatar : null,
      },
    });
  };

  userGroupAvatar = async () => {
    const participantList = get(this.state, 'campaign.participant_group.participant_list', []);
    const promise = participantList.map((participant) => {
      if (participant.userId !== 'NaN') {
        return UserController.getUserById(participant.userId);
      } else return null;
    });
    const userList = await Promise.all(promise);
    const users = participantList.map((participant, index) => {
      return {
        ...participant,
        user: userList[index],
      };
    });
    this.setState({ users });
  };

  user11Avatar = async () => {
    const email = this.state.campaign.email;
    if (email) {
      const user = await UserController.getUserByEmail(email);
      if (user) {
        this.setState({
          users: [
            {
              email: user.email,
              name: user.firstName + ' ' + user.lastName,
              userId: user.id,
              user: user,
            },
          ],
        });
      }
    }
  };

  initializeMessages = async () => {
    let { campaign } = this.state;
    let conversationRef = collection.doc(campaign.id);
    let doc = await conversationRef.get();

    if (doc.exists) {
      let unsubscribe = conversationRef.onSnapshot((snapshot) => {
        let messages = snapshot.data().messages;
        // add questions
        let questionMessages = [];
        campaign.questions.forEach((question, index) => {
          let message = {
            _id: index + 1,
            text: question.question,
            user: this.state.host,
          };
          if (question.media_type) {
            if (question.media_type === 'youtube') {
              message.video = question.media;
              message.videoType = 'youtube';
            } else if (question.media_type.includes('video')) {
              message.video = question.media.downloadUrl;
              message.videoType = 'video';
            } else if (question.media_type.includes('image')) {
              message.image = question.media.downloadUrl;
            }
          }
          questionMessages.unshift(message);
        });
        messages = messages.concat(questionMessages);
        this.setState({ messages: orderBy(messages, 'createdAt', 'asc') }, () => {
          this.scrollToBottom();
        });
      });
      this.setState({
        unsubscribe,
      });
      this.context.hideLoading();
    } else {
      let messages = [];
      campaign.questions.forEach((question, index) => {
        let message = {
          _id: index + 1,
          text: question.question,
          user: this.state.host,
        };
        if (question.media_type) {
          if (question.media_type === 'youtube') {
            message.video = question.media;
            message.videoType = 'youtube';
          } else if (question.media_type.includes('video')) {
            message.video = question.media.downloadUrl;
            message.videoType = 'video';
          } else if (question.media_type.includes('image')) {
            message.image = question.media.downloadUrl;
          }
        }
        messages.unshift(message);
      });
      this.setState({
        messages,
      });
      this.context.hideLoading();
    }
  };

  componentWillUnmount() {
    this.state.unsubscribe();
  }

  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  handleSend = async (e) => {
    const campaignType = this.state.campaign.type;
    if (!this.state.message && !this.state.file) {
      this.setState({ message: '' });
      return;
    }
    this.setState({ message: '', sending: true });

    let messageData = {
      _id: uuid(),
      createdAt: campaignType === CampaignType.CONVERSATION ? new Date() : moment().unix(),
      text: this.state.message,
      user: this.state.host,
    };

    // uploading file
    if (this.state.file && this.state.file.type !== 'youtube') {
      let ref = Storage.ref(`media/${moment().valueOf()}`);
      let task = ref.put(this.state.file);
      task.on(
        'state_changed',
        (snapshot) => {
          var progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.setState({ progress });
        },
        (error) => {
          alert(error.message);
        },
        () => {
          task.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
            this.setState({ progress: 100 });

            if (this.state.file.type.includes('video')) {
              messageData.videoType = 'video';
              messageData.video = downloadUrl;
            } else if (this.state.file.type.includes('image')) {
              messageData.image = downloadUrl;
            }
            await ConversationController.sendMessage(messageData, this.state.campaign.id);
            await CampaignController.updateLastMessage(this.state.campaign.id, messageData);
            this.setState({ sending: false, file: null });
          });
        },
      );
    } else if (this.state.file && this.state.file.type === 'youtube') {
      messageData.videoType = 'youtube';
      messageData.video = this.state.file.url.replace('www.youtube.com/watch?v=', 'youtu.be/');
      await ConversationController.sendMessage(messageData, this.state.campaign.id);
      await CampaignController.updateLastMessage(this.state.campaign.id, messageData);

      this.setState({ sending: false, file: null });
    } else {
      await ConversationController.sendMessage(messageData, this.state.campaign.id);
      await CampaignController.updateLastMessage(this.state.campaign.id, messageData);
      this.setState({ sending: false, file: null });
    }
  };

  handleFileUpload = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      this.setState({ file: files[0] });
    }
  };

  scrollToBottom = () => {
    const messageContainer = document.getElementById('messageContainer');
    if (messageContainer) {
      messageContainer.scrollTo({
        behavior: 'smooth',
        top: messageContainer.scrollHeight,
      });
    }
  };

  handleClearFile = () => {
    this.setState({
      file: null,
    });
  };

  handleActions = (index, close) => (e) => {
    close();
    switch (index) {
      case 0:
        this.imageFileInput.click();
        break;
      case 1:
        this.videoFileInput.click();
        break;
      case 2: {
        let url = prompt('Please enter Youtube URL.');
        if (url) {
          if (isYoutubeLink(url)) {
            this.setState({
              file: {
                url,
                type: 'youtube',
              },
            });
          } else {
            alert('Youtube URL is not valid.');
          }
        }
        break;
      }
      default:
        break;
    }
  };

  onKeyPressInput = (event) => {
    if (event.charCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.setState(
        {
          message: event.target.value,
        },
        () => {
          this.handleSend(event);
        },
      );
    }
  };

  render() {
    const { campaign, users, sending, spin } = this.state;
    return spin ? (
      <div className={styles.wrapperSpin}>
        <Spin />
      </div>
    ) : (
      <div className={styles.wrapper}>
        {(campaign.type === CampaignType.CHAT_11 || campaign.type === CampaignType.CHAT_GROUP) && (
          <ChatDetailHeader users={users} campaignName={campaign.name} />
        )}
        {this.state.messages.length > 0 ? (
          <div className={styles.messagePane} id="messageContainer">
            {this.state.messages.map((message, index) => (
              <Message campaignType={campaign ? campaign.type : ''} key={index} message={message} />
            ))}
          </div>
        ) : (
          <div className={styles.empty}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No messages yet" />
          </div>
        )}
        <div className={styles.inputContainer}>
          <Popup
            trigger={
              <div className={styles.actionBtn}>
                <i className="fa fa-plus-circle" />
              </div>
            }
            position="top left"
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: '0px', border: 'none' }}
          >
            {(close) => (
              <div className={styles.menu}>
                <div className={styles['menu-item']} onClick={this.handleActions(0, close)}>
                  Add Image
                </div>
                <div className={styles['menu-item']} onClick={this.handleActions(1, close)}>
                  Add Video
                </div>
                <div className={styles['menu-item']} onClick={this.handleActions(2, close)}>
                  Add Youtube
                </div>
              </div>
            )}
          </Popup>
          <textarea
            placeholder="Type message here"
            value={this.state.message}
            onKeyPress={this.onKeyPressInput}
            onChange={this.handleMessageChange}
            onInput={(element) => {
              element.target.style.height = '5px';
              element.target.style.height = element.target.scrollHeight + 'px';
            }}
            rows="1"
            style={{
              resize: 'none',
              minHeight: '30px',
              maxHeight: '100px',
            }}
          />
          <Button
            disabled={sending}
            type="primary"
            loading={sending}
            ghost
            className={styles.sendBtn}
            onClick={this.handleSend}
          >
            SEND
          </Button>

          <input
            ref={(ref) => (this.videoFileInput = ref)}
            type="file"
            className={styles.file}
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.handleFileUpload}
          />
          <input
            ref={(ref) => (this.imageFileInput = ref)}
            type="file"
            className={styles.file}
            accept="image/*"
            onChange={this.handleFileUpload}
          />
        </div>
        {this.state.file ? (
          <>
            <div
              style={{
                display: 'flex',
                paddingLeft: 20,
              }}
            >
              <MediaLocal
                maxHeight={200}
                width={200}
                file={this.state.file}
                onRemove={this.handleClearFile}
              />
            </div>
            <div className={styles.statusbar}>
              <span>
                {this.state.file.type !== 'youtube' &&
                  this.state.sending &&
                  `File Uploaded: ${this.state.progress}% completed`}
                {!this.state.sending && this.state.file.name}
                {this.state.file.type === 'youtube' && 'Youtube'}
              </span>
            </div>
          </>
        ) : (
          <div className={styles.statusbar}>
            <span>No file is attached</span>
          </div>
        )}
      </div>
    );
  }
}

ChatDetailsContainer.contextType = AppContext;

ChatDetailsContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ChatDetailsContainer;
