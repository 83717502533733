import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './ClipResult.module.scss';
import PropTypes from 'prop-types';
import { getDownloadVideo } from '../../../utils/url';

export const ClipResult = React.memo((props) => {
  const { clipResult, clips } = props;
  const [loadingBtns, setLoadingBtns] = useState([]);
  const setLoading = (index, isLoading) => {
    const loadingBtnClone = [...loadingBtns];
    loadingBtnClone[index] = isLoading;
    setLoadingBtns(() => loadingBtnClone);
  };
  const downloadVideoClipResult = (downloadUrl, nameClip, index) => {
    setLoading(index, true);
    const stopLoadingBtn = () => {
      setLoading(index, false);
    };
    const nameDownloadVideo = `${nameClip}-${index}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, stopLoadingBtn);
  };

  return (
    <div className={styles.wrapper}>
      {clipResult.map((video, index) => {
        if (index === clipResult.length - 1) {
          return (
            <div key={index} className={styles.clipResultItem}>
              <video
                controlsList="nodownload"
                className={styles.clipVideo}
                src={video.downloadUrl}
                controls
              />
              <div className={styles.action}>
                <span>Merged Result</span>
                <Button
                  type="primary"
                  loading={loadingBtns[index]}
                  onClick={() => downloadVideoClipResult(video.downloadUrl, 'Merged Result', index)}
                >
                  Download
                </Button>
              </div>
            </div>
          );
        }
        return (
          <div key={index} className={styles.clipResultItem}>
            <video
              controlsList="nodownload"
              className={styles.clipVideo}
              src={video.downloadUrl}
              controls
            />
            <div className={styles.action}>
              <span>{`Clip ${clips[index].index + 1}`}</span>
              <Button
                type="primary"
                loading={loadingBtns[index]}
                onClick={() =>
                  downloadVideoClipResult(
                    video.downloadUrl,
                    `Clip ${clips[index].index + 1}`,
                    index,
                  )
                }
              >
                Download
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
});

ClipResult.propTypes = {
  clipResult: PropTypes.array,
  clips: PropTypes.array,
};
