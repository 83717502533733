import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './MediaItem.module.scss';
import { MediaLocal } from '../../../Media/MediaItem';

export const MediaItem = React.memo((props) => {
  const { media = null, onRemove, onDownloadVideo = () => {} } = props;
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClick = (downloadUrl) => {
    setButtonLoading(true);
    onDownloadVideo(downloadUrl, () => setButtonLoading(false));
  };

  if (!media) {
    return 'N/A';
  }
  return (
    <div className={styles.videoDownload}>
      <MediaLocal
        maxHeight={150}
        width={250}
        file={{
          url: media.downloadUrl,
          type: media.type,
        }}
        onRemove={onRemove}
      />
      <Button
        type="primary"
        loading={buttonLoading}
        onClick={(event) => {
          event.stopPropagation();
          handleClick(media.downloadUrl);
        }}
      >
        Download
      </Button>
    </div>
  );
});

MediaItem.propTypes = {
  onDownloadVideo: PropTypes.func,
  media: PropTypes.object,
  onRemove: PropTypes.func,
};
