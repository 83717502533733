import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { BackButton } from '../../../components/Button';
import { CSVLink } from 'react-csv';

import { AppContext } from '../../../components';
import { UserController, ClientController } from '../../../controllers';
import { GenderList } from '../../../constants/userType';

import styles from './UserListContainer.module.scss';

import PlaceholderIcon from '../../../assets/image/placeholder.jpg';
import { setParams, getParamsUrl } from '../../../utils/url';
import { unionBy } from 'lodash';
const headers = [
  { label: 'Avatar', key: 'avatar' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Organization', key: 'organization' },
  { label: 'Participants Group', key: 'group_name' },
  { label: 'Division / Location ', key: 'division' },
  { label: 'Gender ', key: 'gender' },
  { label: 'Birthdate ', key: 'birthdate' },
  { label: 'Zipcode ', key: 'zipcode' },
];

class UserListContainer extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      data: [],
      keyword: searchParams && searchParams.keyword ? searchParams.keyword : '',
      table: {
        page: searchParams && searchParams.page ? +searchParams.page : 1,
        size: searchParams && searchParams.size ? +searchParams.size : 10,
      },
      loading: false,
      csvData: [],
    };

    this.columns = [
      {
        title: 'Avatar',
        dataIndex: 'avatar',
        align: 'center',

        render: (text, record, index) => (
          <img alt="avatar" src={record.avatar ? record.avatar : PlaceholderIcon} />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'name'
            ? searchParams.sortOrder
            : undefined,
        sorter: (a, b) =>
          `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1,
        render: (text, record, index) => `${record.firstName} ${record.lastName}`,
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        align: 'center',
        sorter: (a, b) => (GenderList[a.gender] > GenderList[b.gender] ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'gender'
            ? searchParams.sortOrder
            : undefined,
        render: (text, record, index) => GenderList[record.gender],
      },
      {
        title: 'Birthdate',
        dataIndex: 'birthdate',
        align: 'center',
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'birthdate'
            ? searchParams.sortOrder
            : undefined,
        sorter: (a, b) => (a.birthdate > b.birthdate ? 1 : -1),
      },
      {
        title: 'Zipcode',
        align: 'center',
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'zipcode'
            ? searchParams.sortOrder
            : undefined,
        sorter: (a, b) => (a.zipcode > b.zipcode ? 1 : -1),
        dataIndex: 'zipcode',
      },
      {
        title: 'Action',
        align: 'center',
        dataIndex: 'action',
        render: (text, record, index) => (
          <div
            className={styles.actionDelete}
            onClick={this.handleActionSubmit('delete', record.id)}
          >
            <i className="fa fa-trash" />
          </div>
        ),
      },
    ];
  }

  getRowUserCsv(user, clients) {
    if (!clients || !Array.isArray(clients)) return;
    const row = {
      avatar: user.avatar || '',
      name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
      email: user.email || '',
      phone: user.phone || '',
      gender: user.gender || '',
      birthdate: user.birthdate || '',
      zipcode: user.zipcode || '',
      organization: '',
      group_name: '',
      division: '',
    };
    clients.forEach((client) => {
      if (client && Array.isArray(client.participant_groups)) {
        let isMultipleGroup = 0;
        client.participant_groups.forEach((participant) => {
          if (Array.isArray(participant.participant_list)) {
            const userIndex = participant.participant_list.findIndex((userParticipant) => {
              return (
                userParticipant.email &&
                user.email &&
                userParticipant.email.toLowerCase() === user.email.toLowerCase()
              );
            });
            if (userIndex !== -1) {
              isMultipleGroup++;
              // org
              if (isMultipleGroup === 1) {
                if (row.organization) {
                  row.organization += `\n${client.org}`;
                } else {
                  row.organization += client.org;
                }
              } else {
                row.organization += `\n${client.org}`;
              }
              // Group
              if (row.group_name) {
                row.group_name += `\n${participant.name}`;
              } else {
                row.group_name += participant.name;
              }
              // division
              if (row.division) {
                if (participant.division) {
                  row.division += `\n${participant.division}`;
                } else if (!participant.division) {
                  row.division += `\n`;
                }
              } else if (!row.division) {
                row.division += `${participant.division || ' '}`;
              }
            }
          }
        });
      }
    });

    return row;
  }

  async makeCsvData(allUser) {
    const csvData = [];
    const clients = await ClientController.getClients(true);
    if (allUser && Array.isArray(allUser)) {
      allUser.forEach((user) => {
        const row = this.getRowUserCsv(user, clients);
        csvData.push(row);
      });
    }
    this.setState({ csvData });
  }

  async componentDidMount() {
    await this.reload();
  }

  reload = async () => {
    this.setState({ loading: true });
    let userData = await UserController.getAllUsers();
    if (Array.isArray(userData)) {
      userData = unionBy(userData, (user) => user.email);
    }
    let data = userData
      .filter((user) => {
        let name = user.firstName + ' ' + user.lastName;
        return name.toLowerCase().includes(this.state.keyword.toLowerCase());
      })
      .map((user) => ({
        id: user.id,
        avatar: user.avatar,
        birthdate: user.birthdate,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        zipcode: user.zipcode,
      }));

    this.setState({ data, loading: false });
    this.makeCsvData(userData);
  };

  handleActionSubmit = (actionType, id) => async (event) => {
    switch (actionType) {
      case 'view':
        this.props.history.push(`/users/${id}`);
        break;
      case 'delete': {
        event.stopPropagation();

        if (window.confirm('Are you sure you want to remove this user?')) {
          try {
            await UserController.deleteUserById(id);
            await this.reload();
          } catch (error) {
            alert(error.message);
          }
        }
        break;
      }
      default:
        break;
    }
  };

  searchInputChanged = (e) => {
    this.setState(
      {
        keyword: e.target.value,
      },
      async () => {
        if (!this.state.keyword) {
          const newParams = setParams({ keyword: undefined });
          this.props.history.replace({
            search: `?${newParams}`,
          });
          await this.reload();
        }
      },
    );
  };

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      // enter pressed
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      await this.reload();
    }
  };

  // goBack = () => {
  //   this.props.history.goBack();
  // };

  tableChange = (pagination, sorter) => {
    const newParams = setParams({
      page: pagination.current,
      size: pagination.pageSize,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
    });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  render() {
    const { data, loading, table, csvData } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchbar}>
            <i className={`fa fa-search ${styles.iconSearch}`} />
            <input
              type="text"
              placeholder="Type user name here and press enter to get the result..."
              value={this.state.keyword}
              onChange={this.searchInputChanged}
              onKeyPress={this.searchInputKeyPressed}
            />
          </div>
          <BackButton history={this.props.history} />
        </div>
        <Table
          columns={this.columns}
          dataSource={data}
          rowKey={(record, index) => record.id}
          onChange={(pagination, filter, sorter) => {
            this.tableChange(pagination, sorter);
          }}
          bordered
          size="middle"
          pagination={{
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            defaultCurrent: table.page,
            defaultPageSize: table.size,
          }}
          onRow={(record) => ({
            onClick: this.handleActionSubmit('view', record.id),
          })}
          loading={loading}
        />

        <div className={styles.button}>
          <CSVLink
            data={csvData}
            headers={headers}
            filename="users.csv"
            style={{
              color: '#ffffff',
              padding: 8,
              backgroundColor: !(!loading && csvData && csvData.length !== 0)
                ? '#d9d9d9'
                : '#03a9f4',
              borderRadius: 4,
              textDecoration: 'none',
            }}
            disabled={!(!loading && csvData && csvData.length !== 0)}
            loading
          >
            Download data file
          </CSVLink>
        </div>
      </div>
    );
  }
}

UserListContainer.contextType = AppContext;

UserListContainer.propTypes = {
  history: PropTypes.object,
};

export default UserListContainer;
