import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rawParticipants: [],
  participants: [],
  campaign: null,
  questionSelected: null,
  wordFrequencyTable: [],
  userAnswers: [],
  feedbackSuccess: false,
  updateParticipantsSuccess: false,
};

const reportReducer = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setDrawParticipants: (state, action) => {
      state.rawParticipants = action.payload;
    },
    setQuestionSelected: (state, action) => {
      state.questionSelected = action.payload;
    },
    setWordFrequencyTable: (state, action) => {
      state.wordFrequencyTable = action.payload;
    },
    addWordSelected: (state, action) => {
      const foundIndex = state.wordFrequencyTable.findIndex((f) => f.index === action.payload);
      state.wordFrequencyTable[foundIndex].checked = true;
    },
    setUserAnswers: (state, action) => {
      state.userAnswers = action.payload;
    },
    removeWordSelected: (state, action) => {
      const foundIndex = state.wordFrequencyTable.findIndex((f) => f.index === action.payload);
      state.wordFrequencyTable[foundIndex].checked = false;
    },
    setFeedbackSuccess: (state, action) => {
      state.feedbackSuccess = action.payload;
    },
    setUpdateParticipantsSuccess: (state, action) => {
      state.updateParticipantsSuccess = action.payload;
    },
  },
});

export const reportAction = reportReducer.actions;

export default reportReducer.reducer;
