import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Router from './router';
import LoginContainer from './containers/Login';
import AnswerOpenCampaign from './containers/Answer/OpenCampaign';

import { AppContext, LoadingView } from './components';
import { Auth } from './lib/firebase';

import './App.scss';
import { ManagerController } from './controllers';

class App extends Component {
  constructor(props) {
    super(props);
    this.showLoading = () => {
      this.setState({
        loading: true,
      });
    };
    this.hideLoading = () => {
      this.setState({
        loading: false,
      });
    };
    this.state = {
      loading: false,
      showLoading: this.showLoading,
      hideLoading: this.hideLoading,
      user: undefined,
    };
  }

  async componentDidMount() {
    this.state.showLoading();
    Auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (user.isAnonymous) {
          this.setState({ user });
        } else {
          const manager = await ManagerController.getManager(user.uid);
          if (manager) {
            window.localStorage.setItem('login_manager', JSON.stringify(manager));
          } else {
            await Auth.signOut();
            alert('The user does not exist.');
            return;
          }
          this.setState({ user, manager });
        }
      } else {
        this.setState({ user: null });
      }
      this.state.hideLoading();
    });
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.onRouteChanged(this.props.location);
    }
    return true;
  }

  onRouteChanged(prevProps) {
    if (prevProps.pathname === '/login') {
      localStorage.setItem('prevPage', 'login');
    } else {
      localStorage.removeItem('prevPage');
    }
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <div className="App">
          <Switch>
            {this.state.user !== undefined && (
              <Route path="/answer/open-campaign/:id" component={AnswerOpenCampaign} />
            )}
            {this.state.user && <Router />}
            {(this.state.user === null || (this.state.user && this.state.user.isAnonymous)) && (
              <Route path="/login" component={LoginContainer} />
            )}
            {this.state.user !== undefined && (
              <Redirect to={this.state.user ? '/clients' : '/login'} />
            )}
          </Switch>
        </div>
        <LoadingView />
      </AppContext.Provider>
    );
  }
}
App.propTypes = {
  location: PropTypes.object,
};
export default App;
