import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { forEach, orderBy, unionBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import uuid from 'uuid/v4';
import { AppContext, ReportContext } from '../../../components';
import {
  ageList,
  childrenList,
  educationList,
  ethnicityList,
  genderList,
  relationshipList,
  ReportUserList,
  SummaryReportFillInTheBlank,
  SummaryReportFilter,
  SummaryReportMultipleChoice,
  SummaryReportOneChoice,
  SummaryReportOpenText,
  SummaryReportRankingChoice,
} from '../../../components/report';
import { SummaryReportInstructionChoice } from '../../../components/report/SummaryReportInstructionChoice/SummaryReportInstructionChoice';
import { AddUserDetailSummary } from '../../../components/ReportAddUserDetail/AddSummaryReport';
import CampaignType from '../../../constants/campaignType';
import QuestionType from '../../../constants/questionType';
import {
  AnswerController,
  CampaignController,
  TranscriptionController,
  UserController,
} from '../../../controllers';
import { fileSize } from '../../../controllers/file';
import { reportAction } from '../../../store/report';
import { downloadMultipleVideo } from '../../../utils/download';
import { getDownloadUrlWithFilename, getTypeFile } from '../../../utils/url';
import styles from './ReportDetailsContainer.module.scss';

const viewByType = [
  {
    value: 'USER',
    label: 'User List',
  },
  {
    value: 'SUMMARY',
    label: 'Summary Report',
  },
];

const sizeGB = 2e9; // 2GB

class ReportDetailsContainer extends React.Component {
  numberRanking = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

  constructor(props) {
    super(props);

    this.state = {
      campaignId: props.match.params.id,
      campaign: null,
      participants: [],
      dataUsers: [],
      csvData: [],
      headers: [
        { label: 'Date Submitted', key: 'sumittedAt' },
        { label: 'Client', key: 'client' },
        { label: 'Campaign', key: 'campaign' },
        { label: 'User Name', key: 'user' },
        { label: 'Email', key: 'email' },
      ],
      loading: false,
      zipping: null,
      viewBySelected: viewByType[0],
      viewBy: viewByType,
      questionList: [],
      questionSelected: null,
      userAnswers: [],
      downloadZipPart: [],
    };
  }

  giveFeedback = (userId) => {
    let { campaignId } = this.state;
    window.open(`/report/${campaignId}/${userId}`, '_blank');
  };

  initialData = async () => {
    const { campaignId } = this.state;

    this.setState({ loading: true });
    let campaign = await CampaignController.getCampaignById(campaignId);
    let userData = await UserController.getAllUsers();
    if (Array.isArray(userData)) {
      userData = unionBy(userData, (user) => user.email);
    }

    let participants = [];
    if (campaign.type === CampaignType.OPEN) {
      const answers = await AnswerController.getAnswerOpenCampaign(campaignId);
      participants = answers.map((answer) => {
        return {
          answer,
          email: answer.email || 'No Email',
          name: answer.name || 'No Name',
          status: true,
          userId: answer.user_id,
        };
      });
    } else {
      const tasks = campaign.participant_group.participant_list.map((participant) =>
        AnswerController.getAnswer(participant.email, campaignId),
      );
      let answers = await Promise.all(tasks);
      participants = campaign.participant_group.participant_list.map((participant, index) => ({
        ...participant,
        user: userData.find((f) => f.id === participant.userId),
        answer: answers[index],
      }));
    }

    if (campaign.type === CampaignType.OPEN) {
      const dataUsersFilter = participants.map((item) => {
        let dataUser = {
          userId: item.userId,
          email: item.email,
          birthdate: item.birthdate,
          zipcode: item.zipcode,
          summary: item.summary,
          gender: item.gender,
          employment: item.employment,
          education: item.education,
          relationship: item.relationship,
          children: item.children,
          ethnicity: item.ethnicity,
        };
        return dataUser;
      });
      this.setState({ dataUsers: dataUsersFilter });
    } else if (campaign.type === CampaignType.STANDARD) {
      const dataUsersFilter = participants.map((item) => {
        let dataUser = userData
          .filter((user) => {
            let email = user.email;
            return email !== null ? email.toLowerCase() === item.email.toLowerCase() : null;
          })
          .map((user) => ({
            userId: item.userId,
            email: user.email,
            birthdate: user.birthdate,
            zipcode: user.zipcode,
            summary: user.summary,
            gender: user.gender,
            employment: user.employment,
            education: user.education,
            relationship: user.relationship,
            children: user.children,
            ethnicity: user.ethnicity,
          }));
        return dataUser;
      });
      const dataUsers = dataUsersFilter.filter((item) => {
        return item.length !== 0;
      });
      this.setState({ dataUsers });
    }

    participants = await TranscriptionController.getTranscriptionBySummaryReport(participants);

    this.setState({
      campaign,
      participants,

      loading: false,
    });
    this.props.dispatch(reportAction.setDrawParticipants([...participants]));
    this.props.dispatch(reportAction.setParticipants(participants));
    this.props.dispatch(reportAction.setCampaign(campaign));
    this.chunkDownloadZip(campaign, [...participants]);
    this.manageCSV(campaign, participants);
  };

  componentDidMount() {
    this.initialData();
  }

  componentDidUpdate() {
    // eslint-disable-next-line react/prop-types
    if (this.props.feedbackSuccess) {
      this.initialData();
      this.props.dispatch(reportAction.setFeedbackSuccess(false));
      this.props.dispatch(reportAction.setUpdateParticipantsSuccess(true));
    }
  }

  chunkDownloadZip = async (campaign, rawDataParticipants) => {
    const { zipping } = this.state;
    if (zipping) return;
    const usersMedia = [];
    const appPromise = [];

    rawDataParticipants.forEach((participant) => {
      if (participant.answer) {
        let username = participant.name;
        username = username.replace(/ /g, '');

        for (const key in participant.answer.medias) {
          participant.answer.medias[key].forEach((url, index) => {
            if (url && url.downloadUrl) {
              const itemPromise = fileSize(url.downloadUrl).then((fileMetadata) => {
                usersMedia.push({
                  downloadUrl: url.downloadUrl,
                  filename: `${campaign.name}${username}${key}_${index + 1}.mov`,
                  size: fileMetadata.size,
                  username,
                });
              });
              appPromise.push(itemPromise);
            }
          });
        }
      }
    });
    await Promise.all(appPromise);
    const downloadZipPart = [];
    const items = orderBy(usersMedia, (v) => v.username.toLowerCase(), 'asc').reduce(
      (prev, current) => {
        if (prev.size + current.size >= sizeGB) {
          downloadZipPart.push(prev);
          return {
            size: current.size,
            medias: [current],
          };
        } else {
          prev.size += current.size;
          prev.medias.push(current);
          return prev;
        }
      },
      { size: 0, medias: [] },
    );
    downloadZipPart.push(items);
    this.setState({ downloadZipPart });
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  selectParticipant = (record, index) => {
    let { campaignId } = this.state;
    let userId = record.userId !== 'NaN' ? record.userId : `NaN${index}`;
    window.open(`/report/${campaignId}/${userId}`, '_blank');
  };

  getNiceFormatDateTimeString = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
      date = new Date(date);
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const realhour = hour % 12;

    var result = `${monthNames[month]} ${day} ${year} at ${realhour === 0 ? 12 : realhour}:${
      minute < 10 ? '0' + minute : minute
    } ${hour < 12 ? 'AM' : 'PM'}`;
    return result;
  };

  manageCSV = (campaign, participants) => {
    let csvData = [];
    if (campaign && participants.length > 0) {
      participants.forEach((participant) => {
        var username = participant.name;
        username = username.replace(/ /g, '');
        if (participant.answer && participant.answer.updatedAt) {
          let data = {
            sumittedAt: this.getNiceFormatDateTimeString(
              new Date(participant.answer.updatedAt * 1000),
            ),
            client: campaign.client.org,
            campaign: campaign.name,
            user: participant.name,
            email: participant.email,
          };
          Object.keys(participant.answer.answers)
            .map((m) => (m ? m : []))
            .forEach((key, i) => {
              const answer = participant.answer.answers[key];
              if (typeof answer === 'string') {
                data[`answer${i + 1}`] = answer;
              } else if (Array.isArray(answer)) {
                data[`answer${i + 1}`] = '';
                answer.forEach((an, k) => {
                  if (typeof an === 'string') {
                    data[`answer${i + 1}`] = data[`answer${i + 1}`] + an + '\n';
                  } else {
                    data[`answer${i + 1}`] =
                      data[`answer${i + 1}`] +
                      ((campaign.questions[key] &&
                        campaign.questions[key] &&
                        campaign.questions[key].answers &&
                        campaign.questions[key].answers[an]) ||
                        '') +
                      '\n';
                  }
                });
              } else {
                data[`answer${i + 1}`] =
                  (campaign.questions[key] &&
                    campaign.questions[key] &&
                    campaign.questions[key].answers &&
                    campaign.questions[key].answers[answer]) ||
                  '\n';
              }
            });
          Object.keys(participant.answer.medias)
            .map((m) => (m ? m : []))
            .forEach((key, i) => {
              let links = '';
              participant.answer.medias[key]
                .map((m) => (m ? m : []))
                .filter((m) => m.downloadUrl)
                .forEach((m, ind) => {
                  links =
                    links +
                    (m.type && typeof m.type === 'string' && m.type.indexOf('video') !== -1
                      ? getDownloadUrlWithFilename(
                          m.downloadUrl,
                          `${campaign.name}${username}${key}_${ind}.${getTypeFile(m.type)}`,
                        )
                      : m.downloadUrl) +
                    '\n';
                });
              data[`answerMedia${i + 1}`] = links;
            });
          csvData.push(data);
        }
      });
    }
    if (csvData.length > 0) {
      let { headers } = this.state;
      campaign.questions.forEach((question, i) => {
        headers.push({
          label: `Q${i + 1}: ${question.question.replaceAll('"', '""')}`,
          key: `answer${i + 1}`,
        });
        headers.push({
          label: `Q${i + 1}: media`,
          key: `answerMedia${i + 1}`,
        });
      });
      this.setState({ headers });
    }
    this.setState({ csvData });
  };

  downloadZip = async () => {
    const { campaign, rawDataParticipants } = this.props;
    const { zipping } = this.state;
    if (zipping) return;
    this.setState({ zipping: '0%' });
    const promise = [];
    try {
      var zip = new JSZip();
      var img = zip.folder('All Videos');

      rawDataParticipants.forEach((participant) => {
        if (participant.answer) {
          var username = participant.name;
          username = username.replace(/ /g, '');
          const keys = Object.keys(participant.answer.medias);
          for (var j = 0; j < keys.length; j++) {
            const key = keys[j];
            for (var k = 0; k < participant.answer.medias[key].length; k++) {
              const url = participant.answer.medias[key][k];
              if (url && url.downloadUrl) {
                promise.push({
                  downloadUrl: url.downloadUrl,
                  filename: `${campaign.name}${username}${key}_${k + 1}.mov`,
                });
              }
            }
          }
        }
      });

      await downloadMultipleVideo(img, promise, (v) => this.setState({ zipping: `${v}%` }));
      zip
        .generateAsync({ type: 'blob' })
        .then((content) => {
          saveAs(content, `${campaign.name}.zip`);
          this.setState({ zipping: null });
        })
        .catch((error) => {
          alert(error.toString());
          this.setState({ zipping: null });
        });
    } catch (error) {
      this.setState({ zipping: null });
      alert(error.toString());
    }
  };

  handleViewByChange = (checked) => {
    const questions = this.state.campaign.questions.map((question, qIndex) => {
      return {
        ...question,
        label: question.question,
        questionIndex: qIndex,
        value: uuid(),
      };
    });
    const questionsFilter = questions.filter(
      (q) => !!q && q.type !== QuestionType.SIGNATURE_QUESTION,
    );
    if (!this.state.questionSelected && questionsFilter.length > 0) {
      this.handleSelectedQuestion(questionsFilter[0]);
    }
    this.setState({
      viewBySelected: checked,
      questionList: questionsFilter,
    });
  };

  handleSelectedQuestion = (question) => {
    const userAnswers = this.state.participants.filter(
      (p) =>
        p.answer &&
        p.answer.answers &&
        (p.answer.answers[question.questionIndex] !== null ||
          p.answer.answers[question.questionIndex] !== undefined),
    );

    this.setState({
      questionSelected: question,
      userAnswers,
    });
    this.props.dispatch(reportAction.setQuestionSelected(question));
    this.props.dispatch(reportAction.setUserAnswers(userAnswers));
  };

  validateBirthDateFilter = (user, ageFilter) => {
    if (ageFilter.length === 0) return true;
    if (!user.birthdate) return false;
    let isUserValid = false;
    const ageUser = moment().diff(user.birthdate, 'years');
    const ageFilterData = ageFilter.map((f) => ageList[f]);
    forEach(ageFilterData, (ageObj) => {
      if (
        (!ageObj.from && ageUser <= ageObj.to) ||
        (ageObj.from <= ageUser && ageUser <= ageObj.to) ||
        (ageObj.from <= ageUser && !ageObj.to)
      ) {
        isUserValid = true;
        return false;
      }
    });

    return isUserValid;
  };

  validateFieldFilter = (valueCompare, dataFilter, rawFilter) => {
    if (dataFilter.length === 0) return true;
    const filterValues = dataFilter.map((f) => rawFilter[f].value);
    return filterValues.includes(valueCompare);
  };

  validateEthnicity = (values, dataFilter) => {
    if (dataFilter.length === 0) return true;
    let isUserValid = false;
    const filterValues = dataFilter.map((f) => ethnicityList[f].value);
    forEach(filterValues, (f) => {
      if (values.includes(f)) {
        isUserValid = true;
        return false;
      }
    });
    return isUserValid;
  };

  onFilterSummary = (filterData) => {
    const { rawDataParticipants } = this.props;
    const { questionSelected } = this.state;
    const {
      ageFilter,
      educationFilter,
      ethnicityFilter,
      genderFilter,
      relationshipFilter,
      childrenFilter,
    } = filterData;
    const newParticipants = [];
    forEach(rawDataParticipants, (participant) => {
      // not filter
      let isUserValid = true;
      const { user } = participant;
      if (user) {
        if (
          !this.validateBirthDateFilter(user, ageFilter) ||
          !this.validateFieldFilter(user.children, childrenFilter, childrenList) ||
          !this.validateFieldFilter(user.education, educationFilter, educationList) ||
          !this.validateFieldFilter(user.gender, genderFilter, genderList) ||
          !this.validateFieldFilter(user.relationship, relationshipFilter, relationshipList) ||
          !this.validateEthnicity(user.ethnicity, ethnicityFilter)
        ) {
          isUserValid = false;
        }
        if (isUserValid) {
          newParticipants.push(participant);
        }
      }
    });
    this.setState({ participants: newParticipants }, () => {
      this.handleSelectedQuestion(questionSelected);
    });
  };

  render() {
    const {
      viewBySelected,
      viewBy,
      questionList,
      questionSelected,
      loading,
      userAnswers,
      campaignId,
      campaign,
      csvData,
      headers,
      zipping,
      downloadZipPart,
    } = this.state;
    const { rawDataParticipants } = this.props;
    return (
      <ReportContext.Provider value={this.state}>
        <div className={styles.wrapper}>
          <div
            className={styles.selector}
            style={{
              zIndex: 3,
            }}
          >
            <span>View By:</span>
            <div className={styles.select}>
              <Select value={viewBySelected} onChange={this.handleViewByChange} options={viewBy} />
            </div>
            {this.state.csvData.length > 0 && viewBySelected.label === 'Summary Report' ? (
              <AddUserDetailSummary
                data={this.state.dataUsers}
                participants={rawDataParticipants}
                campaign={this.state.campaign}
                userAnswers={userAnswers}
                questionList={questionList}
                questionSelected={questionSelected}
              />
            ) : null}
          </div>
          {viewBySelected.value !== viewBy[1].value ? null : (
            <div
              className={styles.selector}
              style={{
                zIndex: 2,
              }}
            >
              <span>Question:</span>
              <div className={styles.select}>
                <Select
                  value={questionSelected}
                  onChange={this.handleSelectedQuestion}
                  options={questionList}
                />
              </div>
              {campaign.type !== CampaignType.OPEN && (
                <SummaryReportFilter onFilter={this.onFilterSummary} />
              )}
            </div>
          )}
          {this.state.viewBySelected.value !== viewBy[0].value ? null : (
            <ReportUserList
              participants={rawDataParticipants}
              campaignId={campaignId}
              campaign={campaign}
              loading={loading}
              styles={styles}
              csvData={csvData}
              headers={headers}
              zipping={zipping}
              downloadZip={this.downloadZip}
              cancelClicked={this.cancelClicked}
              downloadZipPart={downloadZipPart}
            />
          )}
          {questionSelected && viewBySelected.value === viewBy[1].value ? (
            <>
              {questionSelected.type === QuestionType.ONE_CHOICE_QUESTION && (
                <SummaryReportOneChoice loading={loading} />
              )}
              {questionSelected.type === QuestionType.MULTIPLE_CHOICE_QUESTION && (
                <SummaryReportMultipleChoice loading={loading} />
              )}
              {questionSelected.type === QuestionType.RANKING_CHOICE_QUESTION && (
                <SummaryReportRankingChoice loading={loading} />
              )}
              {questionSelected.type === QuestionType.INSTRUCTION_QUESTION && (
                <SummaryReportInstructionChoice />
              )}
              {questionSelected.type === QuestionType.OPEN_TEXT_QUESTION && (
                <SummaryReportOpenText />
              )}
              {questionSelected.type === QuestionType.BLANK_QUESTION && (
                <SummaryReportFillInTheBlank loading={loading} />
              )}
            </>
          ) : null}
        </div>
      </ReportContext.Provider>
    );
  }
}

ReportDetailsContainer.contextType = AppContext;

ReportDetailsContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  campaign: PropTypes.object,
  rawDataParticipants: PropTypes.array,
};

const mapStateToProps = (state) => ({
  participants: state.report.participants || [],
  rawDataParticipants: state.report.rawParticipants || [],
  campaign: state.report.campaign,
  feedbackSuccess: state.report.feedbackSuccess,
});

export default connect(mapStateToProps)(ReportDetailsContainer);
