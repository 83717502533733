import { reduce, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Media, WordFrequency } from '..';
import EditorCustom from '../../common/Editor';
import { SummaryTable } from '../SummaryTable';
import styles from './SummaryReportInstructionChoice.module.scss';

import { reportAction } from '../../../store/report';

export const SummaryReportInstructionChoice = () => {
  const dispatch = useDispatch();
  const wordFrequencyTable = useSelector((state) => state.report.wordFrequencyTable);
  const questionSelected = useSelector((state) => state.report.questionSelected);
  const userAnswers = useSelector((state) => state.report.userAnswers);
  const updateParticipantsSuccess = useSelector((state) => state.report.updateParticipantsSuccess);
  const [userSummary, setUserSummary] = useState([]);

  const totalNumberOfRespondents = React.useMemo(() => {
    if (!Array.isArray(userSummary)) return 0;
    return userSummary.reduce((prev, current) => {
      if (
        current &&
        current.answer &&
        current.answer &&
        current.answer.answers[questionSelected.questionIndex]
      ) {
        prev += 1;
      }
      return prev;
    }, 0);
  }, [questionSelected, userSummary]);

  useEffect(() => {
    const wordSelected = wordFrequencyTable.filter((f) => f.checked);
    const users = uniq(
      reduce(
        wordSelected,
        (prev, current) => {
          return prev.concat(current.users.map((f) => f.userId));
        },
        [],
      ),
    );

    if (users && users.length > 0) {
      const userFilter = userAnswers.filter((f) => users.includes(f.userId));
      setUserSummary(userFilter);
    } else {
      setUserSummary(userAnswers);
    }

    if (updateParticipantsSuccess) {
      dispatch(reportAction.setUpdateParticipantsSuccess(false));
    }
  }, [wordFrequencyTable, userAnswers]);

  return (
    <>
      <section className={styles.wrapper}>
        <div>
          {questionSelected.questionHtml ? (
            <EditorCustom value={questionSelected.questionHtml} isView={true} />
          ) : (
            <h3>{questionSelected.question}</h3>
          )}
          <Media questionSelected={questionSelected} />
        </div>
        <WordFrequency questionSelected={questionSelected} userAnswers={userAnswers} />
        <div className={styles.columns}>
          <div className={styles.typeReport}>
            Instruction
            {questionSelected.questionTitle ? (
              <span className={styles.questionTitle}>&nbsp;- {questionSelected.questionTitle}</span>
            ) : null}
          </div>

          <div className={styles.subTypeReport}>
            {totalNumberOfRespondents} Total Number of Respondents
          </div>
        </div>

        <SummaryTable data={userSummary} />
      </section>
    </>
  );
};

SummaryReportInstructionChoice.propTypes = {};
