import { Button, Checkbox, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportAction } from '../../../store/report';
import styles from './WordFrequencyTable.module.scss';
export const WordFrequencyTable = React.memo(() => {
  const wordFrequencyTable = useSelector((state) => state.report.wordFrequencyTable);
  const dispatch = useDispatch();
  const [wordFrequency, setWordFrequency] = React.useState([]);
  const [keyWord, setKeyword] = React.useState('');

  const columns = [
    {
      title: '',
      dataIndex: 'checked',
      align: 'center',
      width: 40,
      render: (_, record) => {
        return (
          <Checkbox
            checked={record.checked}
            onChange={(e) => {
              const checked = e.target.checked;
              if (checked) {
                dispatch(reportAction.addWordSelected(record.index));
              } else {
                dispatch(reportAction.removeWordSelected(record.index));
              }
            }}
          />
        );
      },
    },
    {
      title: '#',
      dataIndex: 'index',
      align: 'center',
    },
    {
      title: 'Word',
      dataIndex: 'word',
    },
    {
      title: 'Mentions',
      dataIndex: 'users',
      render: (users, record) => {
        return (
          <Button
            htmlType="button"
            type="link"
            onClick={() => {
              if (record.checked) {
                dispatch(reportAction.removeWordSelected(record.index));
              } else {
                dispatch(reportAction.addWordSelected(record.index));
              }
            }}
          >
            {users.length}
          </Button>
        );
      },
    },
  ];

  const handleData = (keyword) => {
    const trunkKey = keyword.split(' ');
    const filterData = wordFrequencyTable.filter(
      (word) =>
        trunkKey.findIndex((trunk) => trunk.toLowerCase() === word.word.toLowerCase()) !== -1,
    );
    setWordFrequency([...filterData]);
  };

  const searchInputChanged = (e) => {
    const keyword = e.target.value;
    setKeyword(() => keyword);
    if (!keyword) {
      setWordFrequency([...wordFrequencyTable]);
    }
  };

  const searchInputKeyPressed = (e) => {
    const keyword = e.target.value;
    if (e.charCode === 13) {
      handleData(keyword);
    }
  };

  React.useEffect(() => {
    if (!keyWord) {
      setWordFrequency(wordFrequencyTable);
    } else {
      handleData(keyWord);
    }
  }, [wordFrequencyTable, keyWord]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.searchbar}>
        <i className={`fa fa-search ${styles.iconSearch}`} />
        <input
          type="text"
          placeholder="Type here and press enter to get the result..."
          value={keyWord}
          onChange={searchInputChanged}
          onKeyPress={searchInputKeyPressed}
        />
      </div>
      <Table
        columns={columns}
        dataSource={wordFrequency}
        bordered
        size="middle"
        pagination={{
          pageSizeOptions: ['10', '25', '50'],
          showSizeChanger: true,
        }}
      />
    </section>
  );
});
