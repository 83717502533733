import _, { union } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import CampaignType from '../../constants/campaignType';
import QuestionType from '../../constants/questionType';
import {
  ChildrenList,
  EducationList,
  EthnicityList,
  GenderList,
  RelationshipList,
} from '../../constants/userType';
import { getDownloadUrlWithFilename, getTypeFile } from '../../utils/url';
import { words } from '../../utils/words';

export const AddUserDetailSummary = (props) => {
  const { data, participants, campaign, userAnswers, questionList, questionSelected } = props;
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([
    { label: 'Date Submitted', key: 'sumittedAt' },
    { label: 'Client', key: 'client' },
    { label: 'Campaign', key: 'campaign' },
    { label: 'User Name', key: 'user' },
    { label: 'Email', key: 'email' },
    { label: 'Birthdate', key: 'birthdate' },
    { label: 'Zipcode', key: 'zipcode' },
    { label: 'Summary', key: 'summary' },
    { label: 'Gender', key: 'gender' },
    { label: 'Employment', key: 'employment' },
    { label: 'Education', key: 'education' },
    { label: 'Relationship', key: 'relationship' },
    { label: 'Children', key: 'children' },
    { label: 'Ethnicity', key: 'ethnicity' },
  ]);
  const [headerSummaryReport, setHeaderSummaryReport] = useState([]);
  const [csvDataSummary, setCsvDataSummary] = useState([]);
  const [headerWordCloud, setHeaderWordCloud] = useState([]);
  const [csvDataWordCloud, setCsvDataWordCloud] = useState([]);
  useEffect(() => {
    reload();
  }, [questionSelected]);

  const reload = async () => {
    if (campaign.type === CampaignType.STANDARD) {
      const dataUser = data.reduce((totalUser, item) => {
        return [...totalUser, ...item];
      }, []);

      const dataFilter = dataUser.map((item) => {
        const dataUser = participants.filter((user) => {
          const email = user.email;

          return email !== 'No Email' ? email.toLowerCase() === item.email.toLowerCase() : null;
        });
        return dataUser;
      });

      const dataParticipants = dataFilter.reduce((totalParticipants, item) => {
        return [...totalParticipants, ...item];
      }, []);

      manageCSV(dataParticipants, campaign, dataUser);
    } else if (campaign.type === CampaignType.OPEN) {
      manageCSV(participants, campaign, data);
    }

    manageCSVSummaryReport(campaign);
    manageCSVWordCloudReport(userAnswers, questionList);
  };

  const getNiceFormatDateTimeString = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
      date = new Date(date);
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const realhour = hour % 12;

    var result = `${monthNames[month]} ${day} ${year} at ${realhour === 0 ? 12 : realhour}:${
      minute < 10 ? '0' + minute : minute
    } ${hour < 12 ? 'AM' : 'PM'}`;
    return result;
  };

  const manageCSV = (dataParticipants, campaign, dataUser) => {
    let csvData = [];
    dataUser.forEach((user) => {
      if (campaign && dataParticipants.length > 0) {
        dataParticipants.forEach((participant) => {
          if (
            participant.email.toLowerCase() === user.email.toLowerCase() &&
            participant.userId === user.userId &&
            participant.answer &&
            participant.answer.updatedAt
          ) {
            var username = participant.name;
            username = username.replace(/ /g, '');
            const userEthnicity = (user.ethnicity || []).map((f) => EthnicityList[f]).join(', ');
            let data = {
              sumittedAt: getNiceFormatDateTimeString(
                new Date(participant.answer.updatedAt * 1000),
              ),
              client: campaign.client.org,
              user: username,
              campaign: campaign.name,
              email:
                campaign.type === CampaignType.OPEN && campaign.required_info === false
                  ? null
                  : user.email,
              birthdate: user.birthdate,
              zipcode: user.zipcode,
              summary: user.summary,
              gender: GenderList[user.gender],
              employment: user.employment,
              education: EducationList[user.education],
              relationship: RelationshipList[user.relationship],
              children: ChildrenList[user.children],
              ethnicity: userEthnicity,
            };

            if (participant.answer !== null && participant.answer.updatedAt) {
              Object.keys(participant.answer.answers)
                .map((m) => (m ? m : []))
                .forEach((key, i) => {
                  data[`question${i + 1}`] = campaign.questions[key].question;
                });

              Object.keys(participant.answer.answers)
                .map((m) => (m ? m : []))
                .forEach((key, i) => {
                  const answer = participant.answer.answers[key];
                  if (typeof answer === 'string') {
                    data[`answer${i + 1}`] = answer;
                  } else if (Array.isArray(answer)) {
                    data[`answer${i + 1}`] = '';
                    answer.forEach((an, k) => {
                      if (typeof an === 'string') {
                        data[`answer${i + 1}`] = data[`answer${i + 1}`] + an + '\n';
                      } else {
                        data[`answer${i + 1}`] =
                          data[`answer${i + 1}`] +
                          ((campaign.questions[key] &&
                            campaign.questions[key].answers &&
                            campaign.questions[key].answers[an]) ||
                            '') +
                          '\n';
                      }
                    });
                  } else {
                    data[`answer${i + 1}`] =
                      campaign.questions[key] &&
                      campaign.questions[key].answers &&
                      campaign.questions[key].answers[answer];
                  }
                });

              Object.keys(participant.answer.medias)
                .map((m) => (m ? m : []))
                .forEach((key, i) => {
                  const media = participant.answer.medias[key];
                  if (Array.isArray(media)) {
                    data[`media${i + 1}`] = '';
                    media.forEach((an, k) => {
                      data[`question${i + 1}`] = campaign.questions[key].question.replaceAll(
                        '"',
                        '""',
                      );
                      if (typeof an.wordsStr === 'string' && an.wordsStr) {
                        data[`media${i + 1}`] = an.wordsStr + '\n';
                      } else {
                        data[`media${i + 1}`] = '';
                      }
                    });
                  } else {
                    return null;
                  }
                });
              Object.keys(participant.answer.medias)
                .map((m) => (m ? m : []))
                .forEach((key, i) => {
                  let links = '';
                  participant.answer.medias[key]
                    .map((m) => (m ? m : []))
                    .filter((m) => m.downloadUrl)
                    .forEach((m, ind) => {
                      links =
                        links +
                        (m.type && typeof m.type === 'string' && m.type.indexOf('video') !== -1
                          ? getDownloadUrlWithFilename(
                              m.downloadUrl,
                              `${campaign.name}${participant.name}${key}_${ind}.${getTypeFile(
                                m.type,
                              )}`,
                            )
                          : m.downloadUrl) +
                        '\n';
                    });
                  data[`answerMedia${i + 1}`] = links;
                });
            }
            csvData.push(data);
          }
        });
      }
    });
    if (csvData.length > 0) {
      campaign.questions.forEach((question, i) => {
        headers.push({
          label: `Q${i + 1}: Question`,
          key: `question${i + 1}`,
        });
        headers.push({
          label: `Q${i + 1}: Answer`,
          key: `answer${i + 1}`,
        });
        headers.push({
          label: `Q${i + 1}: Transcription`,
          key: `media${i + 1}`,
        });
        headers.push({
          label: `Q${i + 1}: Media`,
          key: `answerMedia${i + 1}`,
        });
      });
      setHeaders(headers);
    }
    setCsvData(csvData);
  };
  const manageCSVSummaryReport = (campaign) => {
    let questionAnser = [];
    let headerSummary = [];
    questionList.map((item, idx) => {
      const total = item.answers.reduce((arr, cur, index) => {
        const count = _.filter(userAnswers, (userAnswers) => {
          const array = userAnswers.answer.answers[item.questionIndex];
          if (Array.isArray(array)) {
            return array && array.indexOf(index) !== -1;
          } else {
            return array === index;
          }
        });
        arr.push(count.length);
        return arr;
      }, []);

      const dataSummary = item.answers.map((value, ind) => {
        let data = {};
        data[`option${item.question}`] = value;
        data[`numberOfRespondents${item.question}`] = total[ind];

        if (idx === 0) {
          questionAnser.push(data);
        } else {
          questionAnser[ind] = Object.assign({}, questionAnser[ind], data);
        }
        return data;
      });

      headerSummary.push({
        label: `Q${idx + 1}: ${item.question}`,
        key: `option${item.question}`,
      });
      headerSummary.push({
        label: `Q${idx + 1}: Number of Respondents`,
        key: `numberOfRespondents${item.question}`,
      });

      return dataSummary;
    });

    setCsvDataSummary(questionAnser);
    setHeaderSummaryReport(headerSummary);
  };
  const manageCSVWordCloudReport = (userAnswers, questionList) => {
    let dataWordCloud = [];
    let headerWordCloud = [];
    questionList.map((item, idx) => {
      let userDatas = [];
      let wordData = [];
      const mergeTextMedia = (medias, answer) => {
        const wordsArr = [];
        if (medias && Array.isArray(medias)) {
          medias.forEach((media) => {
            if (media.words && Array.isArray(media.words)) {
              media.words.forEach((word) => {
                if (word.words && Array.isArray(word.words)) {
                  word.words.forEach((w) => {
                    const parse = JSON.parse(w);
                    wordsArr.push(parse.word);
                  });
                }
              });
            }
          });
          return union(wordsArr);
        }
        if (!answer) {
          return '';
        } else {
          let text = '';
          if (item.type === QuestionType.BLANK_QUESTION) {
            text = answer[1];
          } else if (item.type === QuestionType.OPEN_TEXT_QUESTION) {
            text = answer;
          }
          text = text.split(' ').filter((f) => f.trim());
          return union(text);
        }
      };

      if (userAnswers && Array.isArray(userAnswers) && item) {
        userAnswers.forEach((userAnswer) => {
          let words = [];
          if (userAnswer && userAnswer.answer && userAnswer.answer.medias) {
            if (
              item.type === QuestionType.BLANK_QUESTION ||
              item.type === QuestionType.OPEN_TEXT_QUESTION
            ) {
              if (userAnswer && userAnswer.answer && userAnswer.answer.medias) {
                words = mergeTextMedia(userAnswer.answer.medias[item.questionIndex], '');
              }
              if (userAnswer && userAnswer.answer && userAnswer.answer.answers) {
                const textInput = mergeTextMedia('', userAnswer.answer.answers[item.questionIndex]);
                words = union(words, textInput);
              }
              if (words.length > 0) {
                userDatas.push({
                  email: userAnswer.email,
                  name: userAnswer.name,
                  userId: userAnswer.userId,
                  words: words,
                });
              }
            } else {
              const words = mergeTextMedia(userAnswer.answer.medias[item.questionIndex], '');
              if (words.length > 0) {
                userDatas.push({
                  email: userAnswer.email,
                  name: userAnswer.name,
                  userId: userAnswer.userId,
                  words: words,
                });
              }
            }
          }
        });
      }

      userDatas.forEach((result) => {
        const user = {
          email: result.email,
          name: result.name,
          userId: result.userId,
        };
        result.words.forEach((word) => {
          const foundIndex = wordData.findIndex((f) => f.word.toLowerCase() === word.toLowerCase());

          if (foundIndex !== -1) {
            wordData[foundIndex].users.push({
              ...user,
              word: word,
            });
          } else {
            wordData.push({
              word: word,
              users: [
                {
                  ...user,
                  word: word,
                },
              ],
            });
          }
        });
      });
      const result = wordData
        .filter((f) => f.users.length > 1 && !words.includes(f.word.toLowerCase()))
        .sort((prev, next) => next.users.length - prev.users.length)
        .map((w, index) => ({ ...w, index: index + 1 }));

      result.forEach((value, ind) => {
        let data = {};
        data[`word${item.question}`] = value.word;
        data[`users${item.question}`] = value.users.length;
        if (idx === 0) {
          dataWordCloud.push(data);
        } else {
          dataWordCloud[ind] = Object.assign({}, dataWordCloud[ind], data);
        }

        return data;
      });

      headerWordCloud.push({
        label: `Q${idx + 1}: ${item.question}`,
        key: `word${item.question}`,
      });
      headerWordCloud.push({
        label: `Q${idx + 1}: Mentions`,
        key: `users${item.question}`,
      });

      return result;
    });
    setCsvDataWordCloud(dataWordCloud);
    setHeaderWordCloud(headerWordCloud);
  };

  return (
    <>
      <CSVLink
        data={csvData}
        headers={headers}
        filename={'UserData'}
        style={{
          color: '#ffffff',
          padding: '6px 8px 0 8px',
          backgroundColor: '#03a9f4',
          borderRadius: 4,
          textDecoration: 'none',
          marginRight: 10,
        }}
      >
        Download User Data
      </CSVLink>{' '}
      <CSVLink
        data={csvDataSummary}
        headers={headerSummaryReport}
        filename={'SummaryReport'}
        style={{
          color: '#ffffff',
          padding: '6px 8px 0 8px',
          backgroundColor: '#03a9f4',
          borderRadius: 4,
          textDecoration: 'none',
          marginRight: 10,
        }}
      >
        Download Summary Report
      </CSVLink>
      <CSVLink
        data={csvDataWordCloud}
        headers={headerWordCloud}
        filename={'WordFrequencyReport'}
        style={{
          color: '#ffffff',
          padding: '6px 8px 0 8px',
          backgroundColor: '#03a9f4',
          borderRadius: 4,
          textDecoration: 'none',
          marginRight: 10,
        }}
      >
        Download Word Frequency Report
      </CSVLink>{' '}
      &nbsp;&nbsp;
    </>
  );
};

AddUserDetailSummary.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  participants: PropTypes.any,
  data: PropTypes.any,
  campaign: PropTypes.any,
  userAnswers: PropTypes.any,
  questionList: PropTypes.any,
  questionSelected: PropTypes.any,
};
