import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import PlaceholderIcon from '../../../assets/image/placeholder.jpg';
import { AppContext } from '../../../components';
import {
  ChildrenList,
  EducationList,
  EthnicityList,
  GenderList,
  RelationshipList,
} from '../../../constants/userType';
import { UserController } from '../../../controllers';
import styles from './UserViewContainer.module.scss';
import { BackButton } from '../../../components/Button';
import UserHistory from '../../../components/UserHistory';
class UserViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  async componentDidMount() {
    await this.reload();
  }

  reload = async () => {
    this.context.showLoading();
    let data = await UserController.getUserById(this.props.match.params.id);
    this.setState({
      data,
    });
    this.context.hideLoading();
  };

  render() {
    let { data } = this.state;
    if (!data) {
      return <div className={styles.wrapper}>This user was deleted</div>;
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <img alt="avatar" src={data.avatar ? data.avatar : PlaceholderIcon} />
        <div className={styles.name}>
          {data.firstName} {data.lastName}
        </div>
        <div className={styles.email}> {data.email} </div>
        <div className={styles.socialContainer}>
          <h2>Social Media</h2>
          <div className={styles.socialItem}>
            <i className="fa fa-facebook" />
            {data.facebookUrl ? (
              <a href={data.facebookUrl} target={'_blank'} rel="noreferrer">
                {data.facebookUrl}
              </a>
            ) : (
              <span>N/A</span>
            )}
          </div>
          <div className={styles.socialItem}>
            <i className="fa fa-twitter" />
            {data.twitterUrl ? (
              <a href={data.twitterUrl} target={'_blank'} rel="noreferrer">
                {data.twitterUrl}
              </a>
            ) : (
              <span>N/A</span>
            )}
          </div>
          <div className={styles.socialItem}>
            <i className="fa fa-youtube" />
            {data.youtubeUrl ? (
              <a href={data.youtubeUrl} target={'_blank'} rel="noreferrer">
                {data.youtubeUrl}
              </a>
            ) : (
              <span>N/A</span>
            )}
          </div>
          <div className={styles.socialItem}>
            <i className="fa fa-instagram" />
            {data.instagramUrl ? (
              <a href={data.instagramUrl} target={'_blank'} rel="noreferrer">
                {data.instagramUrl}
              </a>
            ) : (
              <span>N/A</span>
            )}
          </div>
          <div className={styles.socialItem}>
            <i className="fa fa-linkedin" />
            {data.linkedinUrl ? (
              <a href={data.linkedinUrl} target={'_blank'} rel="noreferrer">
                {data.linkedinUrl}
              </a>
            ) : (
              <span>N/A</span>
            )}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <h2> User Details </h2>
          <div className={styles.detailsItem}>
            <span>Birthdate: </span>
            <div className={styles.detailsInfo}>{data.birthdate}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Zipcode: </span>
            <div className={styles.detailsInfo}>{data.zipcode}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Summary: </span>
            <div className={styles.detailsInfo}>{data.summary}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Gender: </span>
            <div className={styles.detailsInfo}>{GenderList[data.gender]}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Employment: </span>
            <div className={styles.detailsInfo}>{data.employment}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Education: </span>
            <div className={styles.detailsInfo}>{EducationList[data.education]}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Relationship: </span>
            <div className={styles.detailsInfo}>{RelationshipList[data.relationship]}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Children: </span>
            <div className={styles.detailsInfo}>{ChildrenList[data.children]}</div>
          </div>
          <div className={styles.detailsItem}>
            <span>Ethnicity: </span>
            <div className={styles.detailsInfo}>
              {data &&
                data.ethnicity &&
                data.ethnicity.map((item) => EthnicityList[item]).join(', ')}
            </div>
          </div>
        </div>
        <div className={styles.featuredVideoContainer}>
          <h3> Featured Video </h3>
          {data.featuredVideo ? (
            <video controlsList="nodownload" controls src={data.featuredVideo} />
          ) : (
            <span> No Featured Video </span>
          )}
        </div>
        <UserHistory userId={this.props.match.params.id} />
        <Button type="danger" className={styles.btnBack} onClick={this.goBack}>
          Cancel
        </Button>
      </div>
    );
  }
}

UserViewContainer.contextType = AppContext;

UserViewContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default UserViewContainer;
