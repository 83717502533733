const CampaignColor = [
  '#c8b900',
  '#eb5286',
  '#0077c2',
  '#0039cb',
  '#ffbd33',
  '#453411',
  '#ff7f00',
  '#a779e9',
  '#4dc0b5',
  '#1f9d55',
];

export default CampaignColor;
