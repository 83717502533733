import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';
import { BackButton } from '../../../components/Button';

import { AppContext } from '../../../components';
import { SessionController } from '../../../controllers';

import styles from './SessionListContainer.module.scss';
import { setParams, getParamsUrl } from '../../../utils/url';

const filterBy = {
  name: 'name',
  org: 'org',
};

class SessionListContainer extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      data: [],
      filter: searchParams && searchParams.filter ? searchParams.filter : filterBy.name,
      keyword: searchParams && searchParams.keyword ? searchParams.keyword : '',
      loading: false,
      table: {
        page: searchParams && searchParams.page ? +searchParams.page : 1,
        size: searchParams && searchParams.size ? +searchParams.size : 10,
      },
    };

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'name'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        align: 'center',
        sorter: (a, b) => (a.client.org > b.client.org ? 1 : -1),
        render: (text, record, index) => record.client.org,
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'organization'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Participants',
        dataIndex: 'participants',
        align: 'center',
        render: (text, record, index) => record.participant_group.participant_list.length,
      },
      {
        title: 'Division / Location',
        dataIndex: 'division',
        align: 'center',
        sorter: (a, b) => (a.participant_group.division > b.participant_group.division ? 1 : -1),
        render: (text, record, index) => record.participant_group.division,
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'division'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        align: 'center',
        sorter: (a, b) => (a.participant_group.name > b.participant_group.name ? 1 : -1),
        render: (text, record, index) => record.participant_group.name,
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'group'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (text, record, index) => (
          <Switch onChange={this.statusChanged(record)} checked={record.status} />
        ),
      },
    ];
  }

  async componentDidMount() {
    await this.reload();
  }

  reload = async () => {
    this.setState({ loading: true });

    let data = await SessionController.getSessions();
    if (this.state.filter === 'name') {
      data = data.filter((session) =>
        session.name.toLowerCase().includes(this.state.keyword.toLowerCase()),
      );
    } else {
      data = data.filter((session) =>
        session.client.org.toLowerCase().includes(this.state.keyword.toLowerCase()),
      );
    }
    this.setState({
      data,
      loading: false,
    });
  };

  searchfilterChanged = (filter) => () => {
    const newParams = setParams({ filter });
    this.props.history.replace({
      search: `?${newParams}`,
    });
    this.setState({
      filter,
    });
  };

  addClicked = () => {
    this.props.history.push('/sessions/add');
  };

  editClicked = (sessionId) => {
    this.props.history.push(`/sessions/edit/${sessionId}`);
  };

  statusChanged = (session) => async (checked, event) => {
    event.stopPropagation();

    let text = session.status
      ? 'Do you want to deactivate this session?'
      : 'Do you want to activate this session?';

    if (window.confirm(text)) {
      await SessionController.setSessionStatus(session.id, !session.status);
      await this.reload();
    }
  };

  searchInputChanged = (e) => {
    this.setState(
      {
        keyword: e.target.value,
      },
      async () => {
        if (!this.state.keyword) {
          const newParams = setParams({ keyword: undefined });
          this.props.history.replace({
            search: `?${newParams}`,
          });
          await this.reload();
        }
      },
    );
  };

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      // enter pressed
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      await this.reload();
    }
  };

  tableChange = (pagination, sorter) => {
    const newParams = setParams({
      page: pagination.current,
      size: pagination.pageSize,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
    });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  render() {
    const { data, loading, table } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchContainer}>
            <div className={styles.searchbar}>
              <i className={`fa fa-search ${styles.iconSearch}`} />
              <input
                type="text"
                placeholder="Type here and press enter to get the result..."
                value={this.state.keyword}
                onChange={this.searchInputChanged}
                onKeyPress={this.searchInputKeyPressed}
              />
            </div>
            <div className={styles.searchfilters}>
              <div className={styles.filter} onClick={this.searchfilterChanged(filterBy.name)}>
                <input
                  type="checkbox"
                  value="name"
                  checked={this.state.filter === filterBy.name}
                  onChange={this.searchfilterChanged(filterBy.name)}
                />
                Search by name
              </div>
              <div className={styles.filter} onClick={this.searchfilterChanged(filterBy.org)}>
                <input
                  type="checkbox"
                  value="org"
                  checked={this.state.filter === filterBy.org}
                  onChange={this.searchfilterChanged(filterBy.org)}
                />
                Search by organization
              </div>
            </div>
          </div>
          <div className={styles.btnAdd} onClick={this.addClicked}>
            <i className={`fa fa-plus ${styles.icon}`} />
            Add
          </div>
          <BackButton history={this.props.history} />
        </div>
        <Table
          onChange={(pagination, filter, sorter) => {
            this.tableChange(pagination, sorter);
          }}
          columns={this.columns}
          dataSource={data}
          rowKey={(item) => item.id}
          bordered
          size="middle"
          pagination={{
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            defaultCurrent: table.page,
            defaultPageSize: table.size,
          }}
          onRow={(record) => ({
            onClick: (event) => this.editClicked(record.id),
          })}
          loading={loading}
        />
      </div>
    );
  }
}

SessionListContainer.contextType = AppContext;

SessionListContainer.propTypes = {
  history: PropTypes.object,
};
export default SessionListContainer;
