import { Storage } from '../lib/firebase';
import moment from 'moment';
import { floor } from 'lodash';

const getStringBase64 = (base) => {
  return base.substring(base.indexOf(',') + 1);
};

export const uploadFile = (file, progress) => {
  return new Promise((resolve, reject) => {
    let ref = Storage.ref(`media/${moment().valueOf()}`);
    let task =
      typeof file === 'string' ? ref.putString(getStringBase64(file), 'base64') : ref.put(file);
    task.on(
      'state_changed',
      (snapshot) => {
        if (typeof progress === 'function') {
          progress(floor((snapshot.bytesTransferred / file.size) * 100));
        }
      },
      (error) => {
        reject(error);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          const response = {
            name: file.name,
            size: file.size,
            url: downloadUrl,
            gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
            type: typeof file === 'string' ? 'image' : file.type,
          };
          resolve(response);
        });
      },
    );
  });
};

export const fileSize = (fileURL) => {
  return Storage.refFromURL(fileURL).getMetadata();
};
