import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { AppContext } from '../../../components';
import { ClientController, SessionController, TranscriptionController } from '../../../controllers';

import styles from './SessionAnalyticsContainer.module.scss';
import selectStyles from '../../../theme/select.styles';
import { Button } from 'antd';
import { getDownloadVideo } from './../../../utils/url';
import { setParams, getParamsUrl } from '../../../utils/url';
import { BackButton } from '../../../components/Button';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const searchType = {
  clientValue: 'clientValue',
  sessionValue: 'sessionValue',
};
const selectType = {
  client: 'client',
  session: 'session',
};

class SessionAnalyticsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: null,
      session: null,
      clientData: [],
      sessionData: [],
      clientList: [],
      sessionList: [],
      videos: [],
      transcriptions: [],
      selectedWords: [],
      clips: [],
      clipResult: [],

      loadingDownloadBtn: {},
      loadingDownloadBtnClipResult: {},
    };
  }

  async componentDidMount() {
    try {
      this.context.showLoading();
      let clientData = await ClientController.getClients(
        (this.context.manager && this.context.manager.admin) || false,
      );

      let clientList = clientData.map((client) => ({
        value: client.id,
        label: client.org,
        disabled: !client.status,
      }));
      this.setState({ clientData, clientList });
      const searchParams = getParamsUrl();
      if (searchParams) {
        if (searchParams.clientValue) {
          const foundClient = clientList.find((f) => f.value === searchParams.clientValue);
          if (foundClient) {
            await this.setState({ client: foundClient });
            await this.handleSelectChange(selectType.client)(foundClient);
            if (searchParams[searchType.sessionValue]) {
              const foundSession = this.state.sessionList.find(
                (f) => f.value === searchParams[searchType.sessionValue],
              );
              if (foundSession) {
                await this.setState({ session: foundSession });
                await this.handleSelectChange(selectType.session)(foundSession);
              }
            }
          }
        }
      }
    } catch (error) {
      alert(error.message);
    } finally {
      this.context.hideLoading();
    }
  }

  wordComparator = (a, b) => {
    if (a.iIndex < b.iIndex) return -1;
    else if (a.iIndex === b.iIndex && a.wIndex < b.wIndex) return -1;
    return 1;
  };

  updateParams = (type, value) => {
    switch (type) {
      case selectType.client:
        const sessionParams = setParams({ [searchType.clientValue]: value.value });
        this.props.history.replace({
          search: `?${sessionParams}`,
        });
        break;
      case selectType.session:
        const campaignParams = setParams({ [searchType.sessionValue]: value.value });
        this.props.history.replace({
          search: `?${campaignParams}`,
        });
        break;
      default:
        break;
    }
  };

  handleSelectChange = (type) => async (value) => {
    this.setState({
      [type]: value,
    });
    this.updateParams(type, value);
    if (type === selectType.client) {
      let sessionData = await SessionController.getSessionsByClient(value.value);
      let sessionList = sessionData.map((session) => ({
        value: session.id,
        label: session.name,
        disabled: !session.status,
      }));
      await this.setState({
        client: value,
        sessionData,
        sessionList,
      });
    } else if (type === selectType.session) {
      let index = this.state.sessionData.findIndex((item) => item.id === value.value);
      let videos = [];
      if (index !== -1) {
        videos = this.state.sessionData[index].sessions.map((item) => ({
          ...item,
          selected: false,
        }));
        // await SessionController.transcribe(videos[0].media, value.value, 0);
      }

      await this.setState({
        session: value,
        videos,
        transcriptions: [],
        clips: [],
        clipResult: [],
        loadingDownloadBtn: {},
        loadingDownloadBtnClipResult: {},
      });
    }
  };

  handleSelectVideo = (index) => async () => {
    let { videos, selectedWords } = this.state;
    videos[index].selected = !videos[index].selected;
    // getting video transcription for the selected videos
    let tasks = videos.map((video) => {
      if (video.selected === false) {
        return new Promise((resolve, reject) => resolve(null));
      } else {
        return TranscriptionController.getTranscriptionById(video.transcriptionId);
      }
    });
    let transcriptions = await Promise.all(tasks);

    // updating selected words list from state
    if (!videos[index].selected) {
      selectedWords = selectedWords.filter((item) => item.tIndex !== index);
    }

    let clips = [];
    videos.forEach((video, index) => {
      if (video.selected) {
        let words = selectedWords.filter((word) => word.tIndex === index);
        if (words.length) {
          words = words.sort(this.wordComparator);
          clips.push({
            index,
            words,
          });
        }
      }
    });

    this.setState({ videos, transcriptions, selectedWords, clips });
  };

  handleWordClick = (tIndex, iIndex, wIndex) => () => {
    let { videos, transcriptions, selectedWords } = this.state;
    let word = JSON.parse(transcriptions[tIndex][iIndex].words[wIndex]);

    let index = selectedWords.findIndex(
      (item) => item.tIndex === tIndex && item.iIndex === iIndex && item.wIndex === wIndex,
    );
    if (index === -1) {
      selectedWords.push({
        tIndex,
        iIndex,
        wIndex,
        word,
      });
    } else {
      selectedWords.splice(index, 1);
    }

    let clips = [];
    videos.forEach((video, index) => {
      if (video.selected) {
        let words = selectedWords.filter((word) => word.tIndex === index);
        if (words.length) {
          words = words.sort(this.wordComparator);
          clips.push({
            index,
            words,
          });
        }
      }
    });

    this.setState({ selectedWords, clips });
  };

  isWordSelected = (tIndex, iIndex, wIndex) => {
    let { selectedWords } = this.state;
    let index = selectedWords.findIndex(
      (item) => item.tIndex === tIndex && item.iIndex === iIndex && item.wIndex === wIndex,
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const clips = reorder(this.state.clips, result.source.index, result.destination.index);

    this.setState({
      clips,
    });
  };

  handleSearch = () => {
    let { transcriptions, videos } = this.state;
    let keyword = window.prompt('Type a keyword here...');
    if (keyword) {
      let selectedWords = [],
        clips = [];
      keyword = keyword.toLowerCase();
      transcriptions.forEach((transcription, tIndex) => {
        if (transcription === null) return;
        transcription.forEach((item, iIndex) => {
          item.words.forEach((wordStr, wIndex) => {
            let word = JSON.parse(wordStr);
            if (word.word.toLowerCase().includes(keyword)) {
              selectedWords.push({
                tIndex,
                iIndex,
                wIndex,
                word,
              });
            }
          });
        });
      });
      // updating clips
      videos.forEach((video, index) => {
        if (video.selected) {
          let words = selectedWords.filter((word) => word.tIndex === index);
          if (words.length) {
            words = words.sort(this.wordComparator);
            clips.push({
              index,
              words,
            });
          }
        }
      });

      this.setState({ selectedWords, clips });
    }
  };

  handleClear = () => {
    this.setState({
      selectedWords: [],
      clips: [],
      clipResult: [],
    });
  };

  handleSave = async () => {
    let { videos, clips } = this.state;

    let selection = [];
    clips.forEach(({ index, words }) => {
      let clipSelection = [];
      for (var i = 0; i < words.length; i++) {
        if (i === 0) {
          clipSelection.push({
            startTime: words[i].word.startTime,
            endTime: words[i].word.endTime,
          });
        } else {
          let isContinuous = false;
          if (
            words[i - 1].iIndex === words[i].iIndex &&
            words[i - 1].wIndex === words[i].wIndex - 1
          ) {
            isContinuous = true;
          }
          if (isContinuous) {
            clipSelection[clipSelection.length - 1].endTime = words[i].word.endTime;
          } else {
            clipSelection.push({
              startTime: words[i].word.startTime,
              endTime: words[i].word.endTime,
            });
          }
        }
      }
      selection.push({
        index,
        clips: clipSelection,
      });
    });

    if (!selection.length) {
      alert('Please select at least one word.');
      return;
    }

    this.context.showLoading();
    try {
      let videoGsUrls = selection.map(({ index }) => videos[index].media.gsUrl);

      // let videoGsUrl = 'gs://social-lens-3a3d5.appspot.com/media/1553271370-ec0df78d-67af-4882-99b0-23fce60b2beb';
      let { data } = await TranscriptionController.clip(videoGsUrls, selection);
      this.setState({
        clipResult: data,
      });
      this.context.hideLoading();
    } catch (error) {
      this.context.hideLoading();
      alert(error.message);
    }
  };

  downloadVideo = (downloadUrl, title, index) => {
    const { loadingDownloadBtn, session } = this.state;
    loadingDownloadBtn[index] = true;
    this.setState({ loadingDownloadBtn });

    const stopLoadingBtn = () => {
      loadingDownloadBtn[index] = false;
      this.setState({ loadingDownloadBtn });
    };
    const nameDownloadVideo = `${session.label}-${title}-${index + 1}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, stopLoadingBtn);
  };

  downloadVideoClipResult = (downloadUrl, nameVideo, index) => {
    const { loadingDownloadBtnClipResult, session } = this.state;
    loadingDownloadBtnClipResult[index] = true;
    this.setState({ loadingDownloadBtnClipResult });

    const stopLoadingBtn = () => {
      loadingDownloadBtnClipResult[index] = false;
      this.setState({ loadingDownloadBtnClipResult });
    };
    const nameDownloadVideo = `${session.label}-${nameVideo}-${index + 1}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, stopLoadingBtn);
  };

  render() {
    let {
      transcriptions,
      videos,
      selectedWords,
      clips,
      clipResult,
      loadingDownloadBtn,
      loadingDownloadBtnClipResult,
    } = this.state;
    let table = [];
    let colNum = 10;

    transcriptions.forEach((transcription, tIndex) => {
      table[tIndex] = [];
      transcription &&
        transcription.forEach((item, iIndex) => {
          const words = item.words;
          const len = words.length;
          const rows = Math.ceil(len / colNum);

          for (let y = 0; y < rows; y++) {
            let row = [];
            for (let x = 0; x < colNum; x++) {
              const index = x + y * colNum;

              if (index < len) {
                let word = JSON.parse(words[index]);
                const start_time = (
                  (word.startTime.seconds ? parseInt(word.startTime.seconds) : 0) +
                  (word.startTime.nanos ? word.startTime.nanos : 0) * 1e-9
                ).toFixed(2);
                const end_time = (
                  (word.endTime.seconds ? parseInt(word.endTime.seconds) : 0) +
                  (word.endTime.nanos ? word.endTime.nanos : 0) * 1e-9
                ).toFixed(2);

                row.push(
                  this.isWordSelected(tIndex, iIndex, index) ? (
                    <td
                      key={`${tIndex}-${iIndex}-${index}`}
                      onClick={this.handleWordClick(tIndex, iIndex, index)}
                      className={styles.selectedword}
                    >
                      {word.word}
                      <div className={styles.time}>
                        <span>{start_time}</span>
                        <span>{end_time}</span>
                      </div>
                    </td>
                  ) : (
                    <td
                      key={`${tIndex}-${iIndex}-${index}`}
                      onClick={this.handleWordClick(tIndex, iIndex, index)}
                    >
                      {word.word}
                      <div className={styles.time}>
                        <span>{start_time}</span>
                        <span>{end_time}</span>
                      </div>
                    </td>
                  ),
                );
              } else {
                row.push(<td key={`${tIndex}-${iIndex}-${index}`} />);
              }
            }
            table[tIndex].push(<tr key={`${tIndex}-${iIndex}-${y}`}>{row}</tr>);
          }
        });
    });

    let isVideoSelected = videos.filter((video) => video.selected).length > 0;
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <div className={styles.basicTableContainer}>
          <table className={styles.basicTable}>
            <tbody>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Client</span>
                    <div className={styles.select}>
                      <Select
                        styles={selectStyles}
                        value={this.state.client}
                        onChange={this.handleSelectChange(selectType.client)}
                        options={this.state.clientList}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Session</span>
                    <div className={styles.select}>
                      <Select
                        styles={selectStyles}
                        value={this.state.session}
                        onChange={this.handleSelectChange(selectType.session)}
                        options={this.state.sessionList}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {videos.length > 0 ? (
          <div className={styles.videoTableContainer}>
            <h2>Videos</h2>
            <table>
              <thead>
                <tr>
                  <th />
                  <th> Preview </th>
                  <th> Transcription Status </th>
                  <th> Name </th>
                </tr>
              </thead>
              <tbody>
                {this.state.videos.map((item, index) => (
                  <tr key={index}>
                    <td className={styles.select}>
                      <input
                        type="checkbox"
                        name="select"
                        disabled={
                          item.transcriptionStatus === 'progress' ||
                          (item.type && item.type.includes('image'))
                        }
                        value={`select${index}`}
                        checked={item.selected}
                        onChange={this.handleSelectVideo(index)}
                      />
                    </td>
                    <td>
                      {item.type && item.type.includes('image') ? (
                        <img
                          className={styles.previewImage}
                          alt="user_image"
                          src={item.media.downloadUrl}
                        />
                      ) : (
                        <video
                          controlsList="nodownload"
                          width="300"
                          className={styles.preview}
                          src={item.media.downloadUrl}
                          controls
                        />
                      )}
                    </td>

                    {item.type && item.type.includes('image') ? (
                      <td>Image</td>
                    ) : item.transcriptionStatus === 'progress' ? (
                      <td className={styles.progress}>
                        <div className={styles.action}>
                          Progress
                          <Button
                            type="primary"
                            loading={loadingDownloadBtn[index]}
                            onClick={() =>
                              this.downloadVideo(item.media.downloadUrl, item.title, index)
                            }
                          >
                            Download
                          </Button>
                        </div>
                      </td>
                    ) : (
                      <td className={styles.completed}>
                        <div className={styles.action}>
                          Completed
                          <Button
                            type="primary"
                            loading={loadingDownloadBtn[index]}
                            onClick={() =>
                              this.downloadVideo(item.media.downloadUrl, item.title, index)
                            }
                          >
                            Download
                          </Button>
                        </div>
                      </td>
                    )}
                    <td>{item.title}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.videoTableContainer}>
            <h2>No Videos</h2>
          </div>
        )}
        {isVideoSelected && (
          <div className={styles.transcriptionContainer}>
            <h2>Transcriptions</h2>
            {table.map((item, index) => {
              if (item.length) {
                return (
                  <div key={index} className={styles.transcriptionTable}>
                    <h3>{videos[index].title}</h3>
                    <table>
                      <tbody>{item}</tbody>
                    </table>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        <div className={styles.btnGroup}>
          {isVideoSelected && (
            <div className={styles.btnSearch} onClick={this.handleSearch}>
              Search
            </div>
          )}
          {selectedWords.length > 0 && (
            <div className={styles.btnClear} onClick={this.handleClear}>
              Clear
            </div>
          )}
        </div>
        {selectedWords.length > 0 && (
          <div className={styles.clipListContainer}>
            <h2>Clips</h2>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.clipList}
                  >
                    {clips.map((item, index) => {
                      return (
                        <Draggable
                          key={`${item.index}`}
                          draggableId={`${item.index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={cn(
                                styles.clipListItem,
                                snapshot.isDragging && styles.clipListItemDragging,
                              )}
                            >
                              {`${item.index + 1}. ${item.words
                                .map((word) => word.word.word)
                                .join(' ')}`}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {selectedWords.length > 0 && (
          <div className={styles.btnGroup}>
            <div className={styles.btnSave} onClick={this.handleSave}>
              Save
            </div>
          </div>
        )}
        {clipResult.length > 0 && (
          <div className={styles.clipResultContainer}>
            {clipResult.map((video, index) => {
              if (index === clipResult.length - 1) {
                return (
                  <div key={index} className={styles.clipResultItem}>
                    <video
                      controlsList="nodownload"
                      className={styles.clipVideo}
                      src={video.downloadUrl}
                      controls
                    />
                    <div className={styles.action}>
                      <span>Merged Result</span>
                      <Button
                        type="primary"
                        loading={loadingDownloadBtnClipResult[index]}
                        onClick={() =>
                          this.downloadVideoClipResult(video.downloadUrl, 'Merged Result', index)
                        }
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                );
              }
              return (
                <div key={index} className={styles.clipResultItem}>
                  <video
                    controlsList="nodownload"
                    className={styles.clipVideo}
                    src={video.downloadUrl}
                    controls
                  />
                  <div className={styles.action}>
                    <span>{`Clip ${clips[index].index + 1}`}</span>
                    <Button
                      type="primary"
                      loading={loadingDownloadBtnClipResult[index]}
                      onClick={() =>
                        this.downloadVideoClipResult(
                          video.downloadUrl,
                          `Clip ${clips[index].index + 1}`,
                          index,
                        )
                      }
                    >
                      Download
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

SessionAnalyticsContainer.contextType = AppContext;

SessionAnalyticsContainer.propTypes = {
  history: PropTypes.object,
};

export default SessionAnalyticsContainer;
