import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Switch from 'react-switch';
import { Button } from 'antd';
import uuid from 'uuid/v4';
import moment from 'moment';
import { range } from 'lodash/fp';

import { AppContext } from '../../../components';
import { ClientController, CampaignController } from '../../../controllers';
import EditorCustom from './../../../components/common/Editor';

import CampaignType from '../../../constants/campaignType';
import QuestionType from '../../../constants/questionType';
import CampaignColor from '../../../constants/campaignColor';

import styles from './CampaignAddContainer.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import selectStyles from '../../../theme/select.styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { BackButton } from '../../../components/Button';
import { emailRegEx } from '../../../utils/validation';

import { MediaLocal } from './../../../components/Media/MediaItem';
import { isYoutubeLink } from '../../../utils/url';

class CampaignAddContainer extends React.Component {
  colors = [];
  constructor(props) {
    super(props);
    this.colors = CampaignColor;
    let today = moment();
    let end = moment().add(7, 'days');
    let campaignTypeList = [
      { value: CampaignType.STANDARD, label: 'Standard' },
      { value: CampaignType.CONVERSATION, label: 'Conversation' },
      { value: CampaignType.APPLICATION, label: 'Application' },
      { value: CampaignType.CHAT_GROUP, label: 'Chat Group' },
      { value: CampaignType.CHAT_11, label: 'Chat 1-1' },
    ];

    this.state = {
      clients: [],
      participant_groups: [],
      orgList: [],
      groupList: [],
      typeList: campaignTypeList,
      selectedOrg: {},
      selectedGroup: {},
      selectedType: campaignTypeList[0],
      // campaign data
      basic: {
        name: '',
        marketing_name: '',
        org: '',
        contact: '',
        from: today.toDate(),
        to: end.toDate(),
        participant_group: '',
        location: '',
        total_points: 0,
        type: CampaignType.STANDARD,
        description: '',
        descriptionHtml: '',
        logo: null,
        email: '',
      },
      questions: [this.generateNewQuestion()],
      activities: [],
      selectedOrder: {},
    };

    this.fileInputs = {};
    this.logoInput = {};
  }

  async componentDidMount() {
    let { basic, selectedOrder } = this.state;

    let clients = await ClientController.getClients(
      (this.context.manager && this.context.manager.admin) || false,
    );
    let participant_groups = [],
      orgList = [],
      groupList = [];
    if (clients.length > 0) {
      participant_groups = await ClientController.getParticipantGroupsByClientId(clients[0].id);
      basic.org = clients[0].id;
      basic.contact = clients[0].contact;

      orgList = clients.map((client) => ({
        value: client.id,
        label: client.org,
      }));
    }

    if (participant_groups.length) {
      basic.participant_group = participant_groups[0].id;
      basic.location = participant_groups[0].division;

      groupList = participant_groups.map((group) => ({
        value: group.id,
        label: group.name,
      }));
    }

    const campaigns = await CampaignController.getCampaigns();

    const activities = range(1, campaigns.length + 2).map((item) => {
      return {
        label: item,
        value: item,
      };
    });

    selectedOrder = {
      label: activities.length,
      value: activities.length,
    };

    this.setState({
      clients,
      participant_groups,
      basic,
      orgList,
      groupList,
      selectedOrg: orgList.length ? orgList[0] : {},
      selectedGroup: groupList.length ? groupList[0] : {},
      activities,
      selectedOrder,
    });
  }

  getIsTypeChat = () => {
    return (
      this.state.basic.type === CampaignType.CHAT_11 ||
      this.state.basic.type === CampaignType.CHAT_GROUP
    );
  };

  generateNewQuestion = () => {
    return {
      id: uuid(),
      type: QuestionType.INSTRUCTION_QUESTION,
      question: '',
      questionHtml: '',
      answers: [],
      media: null,
      userMediaEnable: false,
      userMediaShow: true,
    };
  };

  saveClicked = async () => {
    // validation for basic info
    let { basic, questions } = this.state;
    const isTypeChat = this.getIsTypeChat();
    if (!basic.name) {
      alert('Name is empty or invalid.');
      return;
    }
    if (!basic.marketing_name) {
      alert('Marketing name is empty or invalid.');
      return;
    }
    if (!basic.org) {
      alert('Please select an organization.');
      return;
    }
    if (basic.type !== CampaignType.CHAT_11 && !basic.participant_group) {
      alert('Please select a participant group.');
      return;
    }
    if (!isTypeChat && parseInt(basic.total_points) <= 0) {
      alert('Total point is not valid.');
      return;
    }
    if (!isTypeChat && !CampaignController.getPlanTextByEditor(basic.descriptionHtml)) {
      alert('Description is empty or invalid.');
      return;
    }

    if (
      basic.type === CampaignType.CHAT_11 &&
      (!basic.email || !basic.email.trim() || !emailRegEx.test(basic.email.trim()))
    ) {
      alert('Email is empty or invalid.');
      return;
    }
    // validation for questions
    if (!isTypeChat) {
      for (var i = 0; i < questions.length; i++) {
        if (!CampaignController.getPlanTextByEditor(questions[i].questionHtml)) {
          alert(`Question ${i + 1}'s text can't be empty or invalid.`);
          return;
        }
        if (
          questions[i].type !== QuestionType.INSTRUCTION_QUESTION &&
          questions[i].type !== QuestionType.OPEN_TEXT_QUESTION
        ) {
          for (let j = 0; j < questions[i].answers.length; j++) {
            if (!questions[i].answers[j]) {
              alert(`Question ${i + 1}'s answers are not completed.`);
              return;
            }
          }
        }
      }
    }
    this.context.showLoading();
    // adding a campaign
    const params = this.state.basic;
    if (!isTypeChat) {
      params.order = this.state.selectedOrder.value;
    }
    await CampaignController.addCampaign({
      basic: params,
      questions: isTypeChat ? [] : this.state.questions,
    });
    this.context.hideLoading();
    this.props.history.goBack();
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  basicInfoChanged = (key) => async (e) => {
    let { basic } = this.state;

    if (key === 'org') {
      let selectedOrg = e;
      basic[key] = e.value;
      let index = this.state.clients.findIndex((client) => client.id === e.value);
      basic['contact'] = this.state.clients[index].contact;

      let participant_groups = await ClientController.getParticipantGroupsByClientId(e.value);
      let groupList = [];
      if (participant_groups.length) {
        basic.participant_group = participant_groups[0].id;
        basic.location = participant_groups[0].division;

        groupList = participant_groups.map((group) => ({
          value: group.id,
          label: group.name,
        }));
      } else {
        basic.participant_group = '';
        basic.location = '';
      }

      this.setState({
        selectedOrg,
        basic,
        participant_groups,
        groupList,
        selectedGroup: groupList.length ? groupList[0] : {},
      });
    } else if (key === 'participant_group') {
      let selectedGroup = e;
      basic[key] = e.value;
      let index = this.state.participant_groups.findIndex((group) => group.id === e.value);
      basic['location'] = this.state.participant_groups[index].division;
      this.setState({
        selectedGroup,
        basic,
      });
    } else if (key === 'type') {
      let selectedType = e;
      basic[key] = e.value;
      this.setState({
        selectedType,
        basic,
        questions: [this.generateNewQuestion()], // resetting questions
      });
    } else if (key === 'from' || key === 'to') {
      basic[key] = e;
      this.setState({ basic });
    } else {
      basic[key] = e.target.value;
      this.setState({ basic });
    }
  };

  basicInfoChangeEditor = (value) => {
    const { basic } = this.state;
    basic.descriptionHtml = value;
    this.setState({ basic });
  };

  uploadLogoClicked = () => {
    this.logoInput.click();
  };

  logoFileUploadChange = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let { basic } = this.state;
      basic.logo = files[0];
      this.setState({ basic });
    }
  };

  uploadClicked = (id, source) => () => {
    if (source === 'local') {
      this.fileInputs[id].click();
    } else {
      let url = prompt('Please enter Youtube URL.');
      if (url) {
        if (isYoutubeLink(url)) {
          let index = id;
          let { questions } = this.state;
          questions[index].media = url;
          questions[index].media_type = 'youtube';
          this.setState({ questions });
        } else {
          alert('Youtube URL is not valid.');
        }
      }
    }
  };

  fileUploadChange = (index) => (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let { questions } = this.state;
      questions[index].media = files[0];
      questions[index].media_type = '';
      this.setState({ questions });
      e.target.value = null;
    }
  };

  selectQuestonType = (qtype, index) => () => {
    let { questions } = this.state;
    questions[index].type = qtype;

    switch (qtype) {
      case QuestionType.INSTRUCTION_QUESTION:
        questions[index].answers = [];
        questions[index].userMediaEnable = false;
        questions[index].userMediaShow = true;
        break;
      case QuestionType.OPEN_TEXT_QUESTION:
        questions[index].answers = [];
        questions[index].userMediaEnable = true;
        questions[index].userMediaShow = true;
        break;
      case QuestionType.SIGNATURE_QUESTION:
        questions[index].answers = [];
        questions[index].userMediaEnable = false;
        questions[index].userMediaShow = false;
        break;
      default:
        questions[index].answers = [''];
        questions[index].userMediaEnable = true;
        questions[index].userMediaShow = true;
        break;
    }
    this.setState({ questions });
  };

  questionChanged = (key, index) => (e) => {
    let { questions } = this.state;
    if (key === 'userMediaEnable' || key === 'userMediaShow') {
      questions[index][key] = e;
    } else {
      questions[index][key] = e.target.value;
    }
    this.setState({ questions });
  };

  questionHtmlChanged = (index, value) => {
    let { questions } = this.state;
    questions[index].questionHtml = value;
    this.setState({ questions });
  };

  questionTitleChanged = (index, e) => {
    let { questions } = this.state;
    questions[index].questionTitle = e.target.value;
    this.setState({ questions });
  };

  addAnswer = (index) => () => {
    // multiple option
    let { questions } = this.state;
    questions[index].answers.push('');
    this.setState({ questions });
  };

  removeAnswer = (questionIndex, answerIndex) => () => {
    let { questions } = this.state;
    questions[questionIndex].answers.splice(answerIndex, 1);
    this.setState({ questions });
  };

  answerChanged = (questionIndex, answerIndex) => (e) => {
    let { questions } = this.state;
    questions[questionIndex].answers[answerIndex] = e.target.value;
    this.setState({ questions });
  };

  addQuestion = () => {
    let { questions } = this.state;
    // question validation
    const last = questions[questions.length - 1];
    if (!CampaignController.getPlanTextByEditor(last.questionHtml)) {
      alert('Question text is empty or invalid.');
      return;
    }
    if (
      last.type !== QuestionType.INSTRUCTION_QUESTION &&
      last.type !== QuestionType.OPEN_TEXT_QUESTION
    ) {
      for (let i = 0; i < last.answers.length; i++) {
        if (!last.answers[i]) {
          alert("Question answer can't be empty.");
          return;
        }
      }
    }
    questions.push(this.generateNewQuestion());
    this.setState({ questions }, () => this.checkElementScroll(questions[questions.length - 1]));
  };

  checkElementScroll = (question) => {
    const el = document.getElementById(`${question.id}-id`);
    if (!el) return;
    el.scrollIntoView();
  };
  removeQuestion = (index) => () => {
    if (window.confirm('Are you sure you want to remove?')) {
      let { questions } = this.state;
      questions.splice(index, 1);
      this.setState({ questions });
    }
  };

  removeLogo = () => {
    let { basic } = this.state;
    basic['logo'] = null;
    this.setState({ basic });
  };

  removeQuestionMedia = (index) => () => {
    let { questions } = this.state;
    questions[index].media = null;
    this.setState({ questions });
  };

  handleBricks = (text) => {
    const arr = text.split('[**]');
    if (arr.length > 1) {
      const newText = arr.reduce((data, current, index) => {
        data += current;
        if (index !== arr.length - 1) {
          data += ' _____';
        }
        return data;
      }, '');
      return newText;
    }
    return text;
  };

  renderAnswerPreview = (indexQuestion) => {
    const { answers, question, questionHtml } = this.state.questions[indexQuestion];
    if (!question && !questionHtml && answers.length === 1 && answers[0].length === 0) {
      return (
        <span>I [Care / Don&apos;t care / Don&apos;t even know] what happened because _____</span>
      );
    }
    const answerText = answers.reduce((data, current, index) => {
      if (current.trim()) {
        if (index !== 0) {
          data.push(<span key={uuid()}>&nbsp;/&nbsp;</span>);
        }
        data.push(
          <span key={uuid()} style={{ color: this.colors[index % this.colors.length] }}>
            {current}{' '}
          </span>,
        );
      }
      return data;
    }, []);

    const answerDataHandle = questionHtml
      ? CampaignController.getPlanTextByEditor(questionHtml)
      : question;
    const arrReplaceAnswer = answerDataHandle.split('[*]');
    const newArrReplace = arrReplaceAnswer.reduce((arr, current, index) => {
      const newCurrent = this.handleBricks(current);

      arr.push(<span key={uuid()}>{newCurrent}</span>);
      if (index !== arrReplaceAnswer.length - 1) {
        arr.push(<span key={uuid()}>&nbsp;[&nbsp;</span>);
        arr.push(answerText);
        arr.push(<span key={uuid()}>&nbsp;]&nbsp;</span>);
      }
      return arr;
    }, []);

    return newArrReplace;
  };

  renderStandardQuestion = (question, index) => {
    return (
      <Draggable key={question.id} draggableId={`${question.id}-id`} index={index}>
        {(provided, snapshot) => (
          <div
            key={`${index}`}
            id={`${question.id}-id`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn(
              styles.questionContainer,
              snapshot.isDragging && styles.clipListItemDragging,
            )}
          >
            <div className={styles.title}>
              <div className={styles.questionTitle}>
                <h2> Question {index + 1} </h2>
                <span onClick={() => this.handleReplicateQuestion(question)}>
                  <i className="fa fa-files-o" aria-hidden="true"></i>
                </span>
              </div>
              {this.state.questions.length > 1 && (
                <span onClick={this.removeQuestion(index)}>
                  <i className="fa fa-minus-circle" />
                </span>
              )}
            </div>

            <div className={styles.qtypeContainer}>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.INSTRUCTION_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`open${index}`}
                  checked={question.type === QuestionType.INSTRUCTION_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.INSTRUCTION_QUESTION, index)}
                />
                Instruction
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.OPEN_TEXT_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`open${index}`}
                  checked={question.type === QuestionType.OPEN_TEXT_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.OPEN_TEXT_QUESTION, index)}
                />
                Open Text
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.ONE_CHOICE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.ONE_CHOICE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.ONE_CHOICE_QUESTION, index)}
                />
                One Choice
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.MULTIPLE_CHOICE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`multiple${index}`}
                  checked={question.type === QuestionType.MULTIPLE_CHOICE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.MULTIPLE_CHOICE_QUESTION, index)}
                />
                Multiple Choice
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.RANKING_CHOICE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.RANKING_CHOICE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.RANKING_CHOICE_QUESTION, index)}
                />
                Ranking Choice
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.BLANK_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.BLANK_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.BLANK_QUESTION, index)}
                />
                Fill-in-the-Blank
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.SIGNATURE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.SIGNATURE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.SIGNATURE_QUESTION, index)}
                />
                Signature
              </div>
            </div>
            <div className={styles.inputItem}>
              <span>Question Title</span>
              <input
                name="question-title"
                placeholder="Type question title here"
                value={question.questionTitle}
                onChange={(v) => this.questionTitleChanged(index, v)}
              />
            </div>
            <div className={styles.inputItem}>
              <span>
                {question.type === QuestionType.INSTRUCTION_QUESTION
                  ? 'Instruction Text'
                  : question.type === QuestionType.SIGNATURE_QUESTION
                  ? 'Legal Text'
                  : 'Question Text'}
              </span>
              <EditorCustom
                name="description-question"
                placeholder={
                  question.type === QuestionType.INSTRUCTION_QUESTION
                    ? 'Type Instruction here'
                    : question.type === QuestionType.BLANK_QUESTION
                    ? 'I [*] what happened because [**]'
                    : 'Type question here'
                }
                value={question.questionHtml}
                onChange={(v) => this.questionHtmlChanged(index, v)}
              />
            </div>
            {question.type === QuestionType.BLANK_QUESTION ? (
              <>
                <div className={styles.textReview}>
                  <span className={styles.note}>*</span>[*] represents the answer options. Please
                  input the answers below
                </div>
                <div className={styles.textReview}>
                  <span className={styles.note}>*</span>[**] represents the blank
                </div>
                <div className={styles.textReview}>
                  <span className={styles.title}>Preview:</span> {this.renderAnswerPreview(index)}
                </div>
              </>
            ) : null}
            {question.type !== QuestionType.SIGNATURE_QUESTION && (
              <div className={styles.mediaContainer}>
                <span>Media</span>
                <div className={styles.localMedia}>
                  <div className={styles.upload}>
                    <div
                      className={styles.btnUpload}
                      onClick={this.uploadClicked(question.id, 'local')}
                    >
                      Upload Local Image/Video
                    </div>
                  </div>
                  {question.media ? (
                    question.media_type !== 'youtube' && (
                      <div className={styles.mediaView}>
                        <MediaLocal
                          file={question.media}
                          onRemove={this.removeQuestionMedia(index)}
                        />
                      </div>
                    )
                  ) : (
                    <div className={styles.nomedia}>No image or video is uploaded</div>
                  )}
                  <input
                    ref={(ref) => (this.fileInputs[question.id] = ref)}
                    type="file"
                    className={styles.file}
                    accept="video/mp4,video/x-m4v,video/*,image/*"
                    onChange={this.fileUploadChange(index)}
                  />
                </div>
                <div className={styles.or}>OR</div>
                <div className={styles.youtubeMedia}>
                  <div className={styles.btnUpload} onClick={this.uploadClicked(index, 'youtube')}>
                    Set Youtube Video Url
                  </div>
                  {question.media && question.media_type === 'youtube' && (
                    <div className={styles.youtubeView}>
                      <MediaLocal
                        file={{
                          type: 'youtube',
                          url: question.media,
                        }}
                        onRemove={this.removeQuestionMedia(index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {question.type !== QuestionType.SIGNATURE_QUESTION && (
              <div className={styles.userMediaOptionsContainer}>
                <div className={styles.userMediaShowHide}>
                  <span>Show/Hide video or image</span>
                  <Switch
                    checked={question.userMediaShow}
                    onChange={this.questionChanged('userMediaShow', index)}
                  />
                </div>
                {question.userMediaShow && (
                  <div className={styles.userMediaRequired}>
                    <span>Require video or image</span>
                    <Switch
                      checked={question.userMediaEnable}
                      onChange={this.questionChanged('userMediaEnable', index)}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={styles.answers}>
              {question.type !== QuestionType.SIGNATURE_QUESTION &&
                question.type !== QuestionType.INSTRUCTION_QUESTION &&
                question.type !== QuestionType.OPEN_TEXT_QUESTION && (
                  <div className={styles.answerContainer}>
                    <span>Answers</span>
                    <div>
                      {question.answers.map((answer, answerIndex) => (
                        <div key={`${answerIndex}`} className={styles.answerItem}>
                          <input
                            placeholder="Type answer here"
                            value={answer}
                            onChange={this.answerChanged(index, answerIndex)}
                          />
                          {question.answers.length > 1 && (
                            <span onClick={this.removeAnswer(index, answerIndex)}>
                              <i className="fa fa-minus-circle" />
                            </span>
                          )}
                        </div>
                      ))}
                      <div className={styles.btnAdd} onClick={this.addAnswer(index)}>
                        <i className="fa fa-plus" />
                        Add
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {index === this.state.questions.length - 1 && (
              <div className={styles.btnAddMore} onClick={this.addQuestion}>
                Add More Question
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  };

  renderConversationQuestion = (question, index) => {
    return (
      <Draggable key={question.id} draggableId={`${question.id}-id`} index={index}>
        {(provided, snapshot) => (
          <div
            key={`${index}`}
            ref={provided.innerRef}
            id={`${question.id}-id`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn(
              styles.questionContainer,
              snapshot.isDragging && styles.clipListItemDragging,
            )}
          >
            <div className={styles.title}>
              <div className={styles.questionTitle}>
                <h2> Question {index + 1} </h2>
                <span onClick={() => this.handleReplicateQuestion(question)}>
                  <i className="fa fa-files-o" aria-hidden="true"></i>
                </span>
              </div>
              {this.state.questions.length > 1 && (
                <span onClick={this.removeQuestion(index)}>
                  <i className="fa fa-minus-circle" />
                </span>
              )}
            </div>

            <div className={styles.qtypeContainer}>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.INSTRUCTION_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`open${index}`}
                  checked={question.type === QuestionType.INSTRUCTION_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.INSTRUCTION_QUESTION, index)}
                />
                Instruction
              </div>
            </div>
            <div className={styles.inputItem}>
              <span>
                {question.type === QuestionType.INSTRUCTION_QUESTION
                  ? 'Instruction Text'
                  : 'Question Text'}
              </span>
              <input
                placeholder="Type question here"
                value={question.question}
                onChange={this.questionChanged('question', index)}
              />
            </div>
            <div className={styles.mediaContainer}>
              <span>Media</span>
              <div className={styles.localMedia}>
                <div className={styles.upload}>
                  <div
                    className={styles.btnUpload}
                    onClick={this.uploadClicked(question.id, 'local')}
                  >
                    Upload Local Image/Video
                  </div>
                </div>
                {question.media ? (
                  question.media_type !== 'youtube' && (
                    <div className={styles.mediaView}>
                      <MediaLocal
                        file={question.media}
                        onRemove={this.removeQuestionMedia(index)}
                      />
                    </div>
                  )
                ) : (
                  <div className={styles.nomedia}>No image or video is uploaded</div>
                )}
                <input
                  ref={(ref) => (this.fileInputs[question.id] = ref)}
                  type="file"
                  className={styles.file}
                  accept="video/mp4,video/x-m4v,video/*,image/*"
                  onChange={this.fileUploadChange(index)}
                />
              </div>
              <div className={styles.or}>OR</div>
              <div className={styles.youtubeMedia}>
                <div className={styles.btnUpload} onClick={this.uploadClicked(index, 'youtube')}>
                  Set Youtube Video Url
                </div>
                {question.media && question.media_type === 'youtube' && (
                  <div className={styles.youtubeView}>
                    <MediaLocal
                      file={{
                        type: 'youtube',
                        url: question.media,
                      }}
                      onRemove={this.removeQuestionMedia(index)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.userMediaOptionsContainer}>
              <div className={styles.userMediaShowHide}>
                <span>Show/Hide video or image</span>
                <Switch
                  checked={question.userMediaShow}
                  onChange={this.questionChanged('userMediaShow', index)}
                />
              </div>
              {question.userMediaShow && (
                <div className={styles.userMediaRequired}>
                  <span>Require video or image</span>
                  <Switch
                    checked={question.userMediaEnable}
                    onChange={this.questionChanged('userMediaEnable', index)}
                  />
                </div>
              )}
            </div>
            <div className={styles.answers}>
              {question.type !== QuestionType.INSTRUCTION_QUESTION &&
                question.type !== QuestionType.OPEN_TEXT_QUESTION && (
                  <div className={styles.answerContainer}>
                    <span>Answers</span>
                    <div>
                      {question.answers.map((answer, answerIndex) => (
                        <div key={`${answerIndex}`} className={styles.answerItem}>
                          <input
                            placeholder="Type answer here"
                            value={answer}
                            onChange={this.answerChanged(index, answerIndex)}
                          />
                          {question.answers.length > 1 && (
                            <span onClick={this.removeAnswer(index, answerIndex)}>
                              <i className="fa fa-minus-circle" />
                            </span>
                          )}
                        </div>
                      ))}
                      <div className={styles.btnAdd} onClick={this.addAnswer(index)}>
                        <i className="fa fa-plus" />
                        Add
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {index === this.state.questions.length - 1 && (
              <div className={styles.btnAddMore} onClick={this.addQuestion}>
                Add More Question
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  };

  onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const questions = CampaignController.reorder(
      this.state.questions,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      questions,
    });
  };

  handleReplicateQuestion = (question) => {
    let { questions } = this.state;
    // question validation
    const last = questions[questions.length - 1];
    if (!last.question && !CampaignController.getPlanTextByEditor(last.questionHtml)) {
      alert('Question text is empty or invalid.');
      return;
    }
    if (
      last.type !== QuestionType.INSTRUCTION_QUESTION &&
      last.type !== QuestionType.OPEN_TEXT_QUESTION
    ) {
      for (let i = 0; i < last.answers.length; i++) {
        if (!last.answers[i]) {
          alert("Question answer can't be empty.");
          return;
        }
      }
    }
    questions.push({ ...question, id: uuid() });
    this.setState({ questions }, () => this.checkElementScroll(questions[questions.length - 1]));
  };

  render() {
    const {
      basic: { type },
    } = this.state;
    const isTypeChat = this.getIsTypeChat();
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <h1> Add a new campaign </h1>
        <section className={styles.info}>
          <h2 className={styles.title}>Basic Information</h2>
          <div className={styles.inputItem}>
            <span>Name</span>
            <input value={this.state.basic.name} onChange={this.basicInfoChanged('name')} />
          </div>
          <div className={styles.inputItem}>
            <span>Marketing Name</span>
            <input
              value={this.state.basic.marketing_name}
              onChange={this.basicInfoChanged('marketing_name')}
            />
          </div>
          <div className={styles.inputItem}>
            <span>Organization</span>
            <div className={styles.select}>
              <Select
                styles={selectStyles}
                value={this.state.selectedOrg}
                onChange={this.basicInfoChanged('org')}
                options={this.state.orgList}
              />
            </div>
          </div>
          <div className={styles.inputItem}>
            <span>Contact</span>
            <input disabled value={this.state.basic['contact']} />
          </div>
          <div className={styles.inputItem}>
            <span>Starting Date</span>
            <DatePicker
              className={styles.datepicker}
              selected={this.state.basic.from}
              onChange={this.basicInfoChanged('from')}
            />
          </div>
          <div className={styles.inputItem}>
            <span>Ending Date</span>
            <DatePicker
              className={styles.datepicker}
              selected={this.state.basic.to}
              onChange={this.basicInfoChanged('to')}
            />
          </div>
          {type !== CampaignType.CHAT_11 && (
            <>
              <div className={styles.inputItem}>
                <span>Participant Group</span>
                <div className={styles.select}>
                  <Select
                    styles={selectStyles}
                    value={this.state.selectedGroup}
                    onChange={this.basicInfoChanged('participant_group')}
                    options={this.state.groupList}
                  />
                </div>
              </div>
              <div className={styles.inputItem}>
                <span>Division / Location</span>
                <input disabled value={this.state.basic['location']} />
              </div>
            </>
          )}
          {!isTypeChat && (
            <div className={styles.inputItem}>
              <span>Total Points</span>
              <input
                value={this.state.basic['total_points']}
                onChange={this.basicInfoChanged('total_points')}
              />
            </div>
          )}
          {type === CampaignType.CHAT_11 && (
            <div className={styles.inputItem}>
              <span>Email</span>
              <input value={this.state.basic['email']} onChange={this.basicInfoChanged('email')} />
            </div>
          )}

          <div className={`${styles.inputItem} ${isTypeChat ? styles.typeIsChat : ''}`}>
            <span>Type</span>
            <div className={styles.select}>
              <Select
                styles={selectStyles}
                value={this.state.selectedType}
                onChange={this.basicInfoChanged('type')}
                options={this.state.typeList}
              />
            </div>
          </div>
          {!isTypeChat && (
            <>
              <div className={styles.inputItem}>
                <span>Activity #</span>
                <div className={styles.select}>
                  <Select
                    styles={selectStyles}
                    value={this.state.selectedOrder}
                    onChange={(item) => this.setState({ selectedOrder: item })}
                    options={this.state.activities}
                  />
                </div>
              </div>
              <div className={styles.textareaItem}>
                <span>Description</span>
                <EditorCustom
                  name="description-basic"
                  placeholder=""
                  value={this.state.basic['descriptionHtml']}
                  onChange={(v) => this.basicInfoChangeEditor(v)}
                />
              </div>
              <div className={styles.logoItem}>
                <span>Logo</span>
                <div className={styles.logoContainer}>
                  <div className={styles.upload}>
                    <div className={styles.btnUpload} onClick={this.uploadLogoClicked}>
                      Upload Logo
                    </div>
                  </div>
                  {this.state.basic.logo ? (
                    <MediaLocal
                      width="100%"
                      file={this.state.basic.logo}
                      onRemove={this.removeLogo}
                    />
                  ) : (
                    <span>No logo is uploaded</span>
                  )}
                  <input
                    ref={(ref) => (this.logoInput = ref)}
                    type="file"
                    className={styles.file}
                    accept="image/*"
                    onChange={this.logoFileUploadChange}
                  />
                </div>
              </div>
            </>
          )}
        </section>
        {!isTypeChat && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {type === CampaignType.STANDARD &&
                    this.state.questions.map((question, index) =>
                      this.renderStandardQuestion(question, index),
                    )}
                  {type === CampaignType.APPLICATION &&
                    this.state.questions.map((question, index) =>
                      this.renderStandardQuestion(question, index),
                    )}
                  {type === CampaignType.CONVERSATION &&
                    this.state.questions.map((question, index) =>
                      this.renderConversationQuestion(question, index),
                    )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div className={styles.btnGroup}>
          <Button type="primary" className={styles.btn} onClick={this.saveClicked}>
            Save
          </Button>
          <Button type="danger" className={styles.btn} onClick={this.cancelClicked}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

CampaignAddContainer.contextType = AppContext;

CampaignAddContainer.propTypes = {
  history: PropTypes.object,
};

export default CampaignAddContainer;
