import React from 'react';

const ReportContext = React.createContext({
  status: {
    done: {
      value: 0,
      isNoEmail: true,
    },
    started: {
      value: 0,
      isNoEmail: true,
    },
    noActivity: {
      value: 0,
      isNoEmail: true,
    },
    total: 0,
    setStatus: (status) => {},
  },
});

export default ReportContext;
