import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { union, unionBy } from 'lodash';
import { WordFrequencyTable } from '..';
import styles from './WordFrequency.module.scss';
import { useDispatch } from 'react-redux';
import { reportAction } from '../../../store/report';
import questionType from '../../../constants/questionType';
import { words } from '../../../utils/words';

export const WordFrequency = React.memo((props) => {
  const { userAnswers, questionSelected } = props;
  const dispatch = useDispatch();
  const mergeTextMedia = (medias) => {
    const wordsArr = [];
    if (medias && Array.isArray(medias)) {
      medias.forEach((media) => {
        if (media.words && Array.isArray(media.words)) {
          media.words.forEach((word) => {
            if (word.words && Array.isArray(word.words)) {
              word.words.forEach((w) => {
                const parse = JSON.parse(w);
                wordsArr.push(parse.word);
              });
            }
          });
        }
      });
    }
    return union(wordsArr);
  };

  const mergeTextInput = (answer) => {
    if (!answer) return '';
    let text = '';
    switch (questionSelected.type) {
      // key: <string>
      case questionType.OPEN_TEXT_QUESTION:
        text = answer ? answer.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '') : '';
        break;
      case questionType.BLANK_QUESTION:
        text = answer[1] ? answer[1].replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '') : '';
        break;
      default:
        break;
    }
    text = text.split(' ').filter((f) => f.trim());
    return union(text);
  };

  useEffect(() => {
    let userData = [];
    let wordData = [];
    if (userAnswers && Array.isArray(userAnswers) && questionSelected) {
      userAnswers.forEach((userAnswer) => {
        let words = [];
        if (userAnswer && userAnswer.answer && userAnswer.answer.medias) {
          words = mergeTextMedia(userAnswer.answer.medias[questionSelected.questionIndex]);
        }
        if (userAnswer && userAnswer.answer && userAnswer.answer.answers) {
          const textInput = mergeTextInput(
            userAnswer.answer.answers[questionSelected.questionIndex],
          );
          words = unionBy(words, textInput, (f) => f.toLowerCase());
        }
        if (words.length > 0) {
          userData.push({
            email: userAnswer.email,
            name: userAnswer.name,
            userId: userAnswer.userId,
            words: words,
          });
        }
      });
    }
    userData.forEach((result) => {
      const user = {
        email: result.email,
        name: result.name,
        userId: result.userId,
      };
      result.words.forEach((word) => {
        const foundIndex = wordData.findIndex((f) => f.word.toLowerCase() === word.toLowerCase());
        if (foundIndex !== -1) {
          wordData[foundIndex].users.push({
            ...user,
            word: word,
          });
        } else {
          wordData.push({
            word: word,
            users: [
              {
                ...user,
                word: word,
              },
            ],
          });
        }
      });
    });
    const result = wordData
      .filter((f) => f.users.length > 1 && !words.includes(f.word.toLowerCase()))
      .sort((prev, next) => next.users.length - prev.users.length)
      .map((w, index) => ({ ...w, index: index + 1 }));

    dispatch(reportAction.setWordFrequencyTable(result));
    return () => {
      dispatch(reportAction.setWordFrequencyTable([]));
    };
  }, [userAnswers, questionSelected]);

  return (
    <section className={styles.wrapper}>
      <WordFrequencyTable />
    </section>
  );
});

WordFrequency.propTypes = {
  userAnswers: PropTypes.any,
  questionSelected: PropTypes.object,
};
