import { Firestore, firestore } from '../lib/firebase';

let collection = Firestore.collection('conversation');

export const sendMessage = async (message, campaignId) => {
  let conversationRef = collection.doc(campaignId);

  await conversationRef.update({
    messages: firestore.FieldValue.arrayUnion(message),
  });
};

export const addConversation = async (campaignId) => {
  let conversationDoc = collection.doc(campaignId);
  await conversationDoc.set({
    messages: [],
  });
};
