import { Button, notification } from 'antd';
import { filter, forEach, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import styles from './SummaryTranscriptions.module.scss';
import { Transcriptions } from './Transcriptions';

const getClipSelection = (data) => {
  let clipSelection = [];
  let firstSelection = null;
  let curentSelection = null;

  const newSortData = orderBy(data, (record) => record.index, 'asc');

  forEach(newSortData, (data, index) => {
    const newData = { ...data };
    if (!firstSelection) {
      firstSelection = newData;
      curentSelection = newData;
    } else {
      // check current data and next data is close together
      if (curentSelection.index + 1 === newData.index) {
        firstSelection = {
          ...firstSelection,
          data: {
            ...firstSelection.data,
            endTime: newData.data.endTime,
          },
        };
        curentSelection = newData;
      } else {
        const newFirstSelection = { ...firstSelection };
        const importSelection = {
          startTime: newFirstSelection.data.startTime,
          endTime: newFirstSelection.data.endTime,
        };
        clipSelection.push(importSelection);
        firstSelection = newData;
        curentSelection = newData;
      }
    }
    if (newSortData.length - 1 === index) {
      const newFirstSelection = { ...firstSelection };
      const importSelection = {
        startTime: newFirstSelection.data.startTime,
        endTime: newFirstSelection.data.endTime,
      };
      clipSelection.push(importSelection);
    }
  });

  return clipSelection;
};

const isSelectedWords = (transcriptionClips) => {
  return transcriptionClips.length >= 1;
};

export const SummaryTranscriptions = React.memo(
  ({ gsUrls = [], transcriptions = null, onCreateClip = () => {} }) => {
    const [transcriptionClips, setTranscriptionClips] = useState([]);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const transcriptionRef = useRef(null);

    const getClipboardText = useCallback(() => {
      const sortedTranscription = orderBy(transcriptionClips, (record) => record.index, 'asc');
      const dataReduce = sortedTranscription.reduce(
        (accumulator, currentValue) => {
          if (currentValue.data && currentValue.data.word && currentValue.gsUrl) {
            let newText = `${accumulator.text} ${currentValue.data.word}`;
            if (accumulator.lastClipUrl !== '' && accumulator.lastClipUrl !== currentValue.gsUrl)
              newText = `${accumulator.text}\n${currentValue.data.word}`;

            return {
              text: newText,
              lastClipUrl: currentValue.gsUrl,
            };
          }
          return accumulator;
        },
        {
          text: '',
          lastClipUrl: '',
        },
      );
      return dataReduce.text.trim();
    }, [transcriptionClips]);

    const handleMouseDown = useCallback(() => {
      const handleComplete = () => {
        setIsMouseDown(false);
        window.removeEventListener('mouseup', handleComplete);
        window.removeEventListener('mouseleave', handleComplete);
      };

      setIsMouseDown(true);
      window.addEventListener('mouseup', handleComplete);
      window.addEventListener('mouseleave', handleComplete);
    }, []);

    if (!transcriptions) {
      return <p className={styles.noTranscriptWrapper}>N/A</p>;
    }

    const handleCreateClip = async () => {
      let videoGsUrls = [];
      let selection = [];
      let index = 0;

      forEach(gsUrls, (gsUrl) => {
        const gsUrlFilter = filter(transcriptionClips, { gsUrl: gsUrl });
        if (gsUrlFilter.length >= 1) {
          videoGsUrls.push(gsUrl);
          const clipSelection = getClipSelection(gsUrlFilter);
          selection.push({
            index: index,
            clips: clipSelection,
          });
          index = index + 1;
        }
      });

      await onCreateClip(videoGsUrls, selection);
    };

    const onClear = () => {
      if (
        transcriptionRef &&
        transcriptionRef.current &&
        typeof transcriptionRef.current.removeSelectedWords === 'function'
      ) {
        transcriptionRef.current.removeSelectedWords();
        setTranscriptionClips([]);
      }
    };

    return (
      <div className={styles.wrapper}>
        <div
          className={styles.userSummaryTranscription}
          onMouseDown={(event) => {
            if (event.button === 0) {
              handleMouseDown();
            }
          }}
        >
          <Transcriptions
            ref={transcriptionRef}
            dataTranscriptions={transcriptions}
            isMouseDown={isMouseDown}
            onChange={(selectedWords) => setTranscriptionClips(selectedWords)}
          />
        </div>
        <div className={styles.actions}>
          <Button
            disabled={!isSelectedWords(transcriptionClips)}
            className={styles.createClipBtn}
            type="primary"
            onClick={handleCreateClip}
          >
            Create Clip
          </Button>

          <Button
            disabled={!isSelectedWords(transcriptionClips)}
            className={`${styles.createClipBtn} ${styles.clearButton}`}
            danger
            onClick={onClear}
          >
            Clear
          </Button>

          <Button
            disabled={!isSelectedWords(transcriptionClips)}
            className={`${styles.createClipBtn} ${styles.clearButton}`}
            type="default"
            onClick={() => {
              navigator.clipboard.writeText(getClipboardText());
              notification.info({
                message: 'Text copied!',
              });
            }}
          >
            Copy Text
          </Button>
        </div>
      </div>
    );
  },
);

SummaryTranscriptions.propTypes = {
  gsUrls: PropTypes.array,
  transcriptions: PropTypes.array,
  onCreateClip: PropTypes.func,
};
