import React from 'react';
import PropTypes from 'prop-types';
import styles from './MediaPreviewItem.module.scss';

const MediaPreviewItem = (props) => {
  const refVideo = React.useRef(null);
  const { media, removeQuestionMedia } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.mediaView}>
        {typeof media === 'string' ? (
          <img className={styles.media} width="300" alt="media" src={media} />
        ) : media.type.includes('image/') ? (
          <img className={styles.media} width="300" alt="media" src={URL.createObjectURL(media)} />
        ) : (
          <video
            width="300"
            controls
            id="video"
            autoPlay
            controlsList="nodownload"
            ref={refVideo}
            onLoadedData={() =>
              setTimeout(() => {
                refVideo.current.pause();
                refVideo.current.muted = false;
              }, 10)
            }
            className={styles.media}
            src={URL.createObjectURL(media)}
          />
        )}
        <div className={styles.iconRemove} onClick={() => removeQuestionMedia()}>
          <i className="fa fa-minus-circle" />
        </div>
      </div>
    </div>
  );
};

MediaPreviewItem.propTypes = {
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  removeQuestionMedia: PropTypes.func,
};

export default React.memo(MediaPreviewItem);
