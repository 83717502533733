import { CheckCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Divider, Input, Layout, Progress, Modal } from 'antd';
import { css, cx } from 'emotion';
import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid/v1';
import { AppContext } from '../../../components';
import { Auth } from '../../../lib/firebase';
import QuestionType from './../../../constants/questionType';
import {
  checkExistAnswer,
  getOpenCampaign,
  submitAnswers,
  subTotal,
  transcribeAnswer,
} from './../../../controllers/OpenCampaign';
import styles from './AnswerOpenCampaignContainer.module.scss';
import uploadIcon from './../../../assets/image/upload_icon.png';
import VideoCapture from './VideoCapture';
import UploadingProgress from './UploadingProgress';
import ShowMediaHeader from './ShowMediaHeader';
import * as loadImage from 'blueimp-load-image';
import { getAnswerOpenCampaign } from '../../../controllers/Answers';
import CampaignCompleted from '../../../constants/campaignCompleted';
import EditorCustom from './../../../components/common/Editor';

const styleText = (props) => css`
  .ant-progress-outer {
    padding-right: 0px;
  }
  .ant-progress-inner {
    background: #bfc5ca;
  }
  .ant-progress-text {
    position: absolute;
    top: 25%;
    left: calc(50% - 14px);
    color: white;
    margin: auto;
  }
  .ant-progress-bg {
    background: ${props && props.bg};
  }
`;

const inputItem = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  &.mb-10 {
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    line-height: 25px;
    white-space: normal;
  }
  label {
    font-weight: 500;
    font-size: 16px;
    color: inherit;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 0;
  }
`;

const descriptionStyle = (props) => css`
  display: block;
  font-weight: 400;
  font-size: 16px;
  margin-top: ${props && props.mt && `${props.mt}px`};
  margin-bottom: ${props && props.mb && `${props.mb}px`};
`;

const mediaRow = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

const btnOption = css`
  width: 100%;
  max-width: 300px;
  min-height: 40px;
  height: auto;
  flex: 1;
  border-radius: 2px;
  line-height: 25px;
  font-size: 14px;
  text-align: start;
  margin-top: 10px;

  .rowRanking {
    display: flex;
    justify-content: space-between;
  }
  span {
    white-space: normal;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: start;
  }
  .index {
    margin-left: 10px;
  }
`;

const modalSelectWebcam = css`
  width: auto !important;
  .ant-modal-content {
    .ant-modal-close {
      right: -10px;
      top: -10px;
    }
  }
  button:not(:first-of-type) {
    margin: 10px;
  }
`;

const blankOptionStyle = css`
  color: #bfbfbf;
`;

const isRotateImage = () => {
  if (/SamsungBrowser/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};
class MediaItem extends React.Component {
  refVideo;
  shouldComponentUpdate(nextProps) {
    if (_.isEqual(nextProps.media, this.props.media)) {
      return false;
    }
    return true;
  }

  getMediaPreview = () => {
    const { media } = this.props;
    if (media.downloadUrl) {
      if (media.type.includes('image/')) {
        return <img className={styles.media} width="300" alt="media" src={media.downloadUrl} />;
      }
      if (media.type.includes('video/')) {
        return (
          <video
            controlsList="nodownload"
            preload="metadata"
            width="300"
            controls
            id="video"
            playsInline
            autoPlay
            ref={(ref) => (this.refVideo = ref)}
            onLoadedData={() => setTimeout(() => this.refVideo.pause(), 10)}
            className={styles.media}
            src={media.downloadUrl}
          />
        );
      }
    }
    if (typeof media === 'string') {
      return <img className={styles.media} width="300" alt="media" src={media} />;
    }
    if (media.type.includes('image/')) {
      return (
        <img className={styles.media} width="300" alt="media" src={URL.createObjectURL(media)} />
      );
    }
    return (
      <video
        controlsList="nodownload"
        preload="metadata"
        width="300"
        controls
        id="video"
        playsInline
        autoPlay
        ref={(ref) => (this.refVideo = ref)}
        onLoadedData={() => setTimeout(() => this.refVideo.pause(), 10)}
        className={styles.media}
        src={URL.createObjectURL(media)}
      />
    );
  };

  render() {
    return (
      <div className={styles.mediaView}>
        {this.getMediaPreview()}
        <div className={styles.iconRemove} onClick={() => this.props.removeQuestionMedia()}>
          <i className="fa fa-minus-circle" />
        </div>
      </div>
    );
  }
}

MediaItem.propTypes = {
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  removeQuestionMedia: PropTypes.func,
};

class ShowMediaUpload extends React.Component {
  fileInputs;

  constructor(props) {
    super(props);
    this.state = {
      isShowVideoCapture: false,
      isShowModalUploadOrCapture: false,
    };
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  componentDidUpdate() {
    this.fileInputs.value = null;
  }

  openCaptureWebcam = () => {
    this.setState({ isShowVideoCapture: true, isShowModalUploadOrCapture: false });
  };

  openUploadFile = () => {
    this.setState({ isShowModalUploadOrCapture: false }, () => {
      this.fileInputs.click();
    });
  };

  fileAction = () => {
    if (this.isMobile) {
      this.fileInputs.click();
    } else {
      this.setState({ isShowModalUploadOrCapture: true });
    }
  };

  closeCaptureVideo = (data = null) => {
    this.setState({ isShowVideoCapture: false });
    if (data) {
      this.props.fileUploadChange(data);
    }
  };

  render() {
    const { data } = this.props;
    return (
      <>
        {data.medias && data.medias.length ? null : (
          <div className={styles.nomedia}>Add your video below</div>
        )}
        <div className={styles.upload}>
          <div className={styles.btnUpload} onClick={() => this.fileAction()}>
            <img className={styles.img} src={uploadIcon} alt="" />
          </div>
          <input
            ref={(ref) => (this.fileInputs = ref)}
            type="file"
            hidden
            multiple
            className={styles.file}
            accept="video/mp4,video/x-m4v,video/*"
            // ,image/*
            onChange={this.props.fileUploadChange}
          />
        </div>
        {data.medias && data.medias.length ? (
          <div className={mediaRow}>
            {data.medias.map((media, index) => (
              <MediaItem
                key={index}
                media={media}
                removeQuestionMedia={() => this.props.removeQuestionMedia(index)}
              />
            ))}
          </div>
        ) : null}
        <VideoCapture onClose={this.closeCaptureVideo} isShow={this.state.isShowVideoCapture} />
        <Modal
          className={cx(modalSelectWebcam)}
          centered
          visible={this.state.isShowModalUploadOrCapture}
          footer={null}
          onCancel={() => this.setState({ isShowModalUploadOrCapture: false })}
        >
          <Button onClick={() => this.openUploadFile()}>Upload File</Button>
          <Button type="primary" onClick={() => this.openCaptureWebcam()}>
            Use Webcam
          </Button>
        </Modal>
      </>
    );
  }
}

ShowMediaUpload.propTypes = {
  data: PropTypes.object,
  removeQuestionMedia: PropTypes.func,
  fileUploadChange: PropTypes.func,
};

class AnswerOpenCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.match.params.id,
      processPercent: 0,
      campaignData: null,
      activeQuestion: -1,
      dataQuestion: null,
      showAlertError: false,
      alertErrorMessage: '',
      basic: {
        fullName: '',
        email: '',
      },
      answers: {},
      medias: [],
      lockCampaign: false,
      completeCampaign: false,
      currentUser: {},
      percentUploading: 0,
      isShowProgress: false,
      checkboxChecked: {},
      userId: null,
    };
    this.oldDataAnswer = null;
    this.listStepSetOldDataDone = [];
    this.fileInputs = {};
    this.refResizeInput = {};
  }

  setPercentUploading = (value) => {
    this.setState({ percentUploading: value });
  };

  setUserId = async () => {
    const { search } = window.location;
    const query = search.split('=');
    if (query.length > 1) await this.setState({ userId: query[1] });
    else {
      const currentUser = Auth.currentUser;
      await this.setState({ userId: currentUser.uid });
    }
  };

  getOldDataSubmit = async () => {
    const answers = await getAnswerOpenCampaign(this.state.campaignId);
    const oldDataAnswer = answers.find((f) => f.user_id === this.state.userId);
    if (oldDataAnswer && oldDataAnswer.completed === CampaignCompleted.rejected) {
      this.oldDataAnswer = oldDataAnswer;
    } else {
      this.oldDataAnswer = null;
    }
  };

  loginAnonymously = () => {
    return new Promise(async (resolve, reject) => {
      const currentUser = Auth.currentUser;
      await this.setState({ currentUser });
      if (!currentUser) {
        Auth.signInAnonymously()
          .then((data) => {
            this.setState({ currentUser: Auth.currentUser }, () => {
              resolve();
            });
          })
          .catch((error) => {
            reject();
          });
      } else resolve();
    });
  };

  updateWidthTitle = () => {
    Object.keys(this.refResizeInput).forEach((k) => {
      if (this.refResizeInput[k] && this.refResizeInput[k].parentElement) {
        if (this.refResizeInput[k].parentElement.clientWidth < 300)
          this.refResizeInput[k].style.minWidth = '100%';
        else this.refResizeInput[k].style.minWidth = '300px';
      }
    });
  };

  componentDidUpdate() {
    this.updateWidthTitle();
  }

  async componentDidMount() {
    this.context.showLoading();
    try {
      await this.loginAnonymously();
      await this.setUserId();
      if (this.state.userId) {
        await this.getOldDataSubmit();
      }
      const { campaignId, currentUser, activeQuestion, userId } = this.state;
      let checkIsExistAnswer = false;
      if (currentUser && currentUser.uid) {
        checkIsExistAnswer = await checkExistAnswer(campaignId, userId || currentUser.uid);
      }
      const campaignData = await getOpenCampaign(campaignId, this.updateRealtime);
      if (campaignData && campaignData.questions) {
        campaignData.questions.map((question) => {
          if (!question.hasOwnProperty('userMediaShow')) {
            question.userMediaShow = true;
          }
          return question;
        });
      }
      this.checkLockCampaign(
        campaignData.count_userAnswer,
        campaignData.limit_users,
        campaignData.to,
      );

      this.setState(
        {
          campaignData,
          completeCampaign:
            checkIsExistAnswer &&
            (!this.oldDataAnswer || this.oldDataAnswer.completed !== CampaignCompleted.rejected),
        },
        () => {
          if (!campaignData.required_info && activeQuestion === -1) {
            this.onNext();
          } else if (this.state.userId && this.oldDataAnswer) {
            this.setState({
              basic: {
                fullName: this.oldDataAnswer.name || '',
                email: this.oldDataAnswer.email || '',
              },
            });
          }
          window.addEventListener('resize', this.updateWidthTitle);
        },
      );

      this.context.hideLoading();
    } catch (error) {
      this.context.hideLoading();
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {
      return;
    };
    window.removeEventListener('resize', this.updateWidthTitle);
  }

  updateRealtime = (campaignDataRealtime) => {
    if (!campaignDataRealtime) return;
    this.checkLockCampaign(
      campaignDataRealtime.count_userAnswer,
      campaignDataRealtime.limit_users,
      campaignDataRealtime.to,
    );
  };

  checkLockCampaign = async (count_userAnswer, limit_users, time) => {
    if (time) {
      const timeTo = moment(time);
      const timeNow = moment(new Date());

      if (timeNow.isAfter(timeTo)) {
        await this.setState({
          lockCampaign: true,
        });
        return;
      }
    }

    if (this.oldDataAnswer && this.oldDataAnswer.completed === CampaignCompleted.rejected) {
      await this.setState({
        lockCampaign: false,
      });
      return;
    }

    if (count_userAnswer && limit_users) {
      const intLimit = +limit_users;
      if (count_userAnswer >= intLimit) {
        await this.setState({
          lockCampaign: true,
        });
      } else {
        await this.setState({
          lockCampaign: false,
        });
      }
    }
  };

  userInvalid = () => {
    const { campaignData, basic } = this.state;
    if (campaignData.required_info && !basic.fullName.trim()) {
      this.setState({
        alertErrorMessage: `Your name can't be empty.`,
        showAlertError: true,
      });
      return true;
    }

    if (campaignData.required_info && !basic.email.trim()) {
      this.setState({
        alertErrorMessage: `Your email can't be empty.`,
        showAlertError: true,
      });
      return true;
    }
    const emailRegEx =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (basic.email.trim() && !emailRegEx.test(basic.email.trim())) {
      this.setState({
        alertErrorMessage: `Email is not valid.`,
        showAlertError: true,
      });
      return true;
    }
    return false;
  };

  answerInvalid = () => {
    const { dataQuestion, activeQuestion, medias, answers, checkboxChecked } = this.state;

    if (
      dataQuestion.type === QuestionType.INSTRUCTION_QUESTION &&
      dataQuestion.checkboxSections &&
      dataQuestion.checkboxSections.length > 0
    ) {
      const listCheck = checkboxChecked[activeQuestion];
      let isValid = true;
      for (let index = 0; index < dataQuestion.checkboxSections.length; index++) {
        if (!listCheck.includes(index)) {
          isValid = false;
          break;
        }
      }
      if (!isValid) {
        this.setState({
          alertErrorMessage: `You must choose all.`,
          showAlertError: true,
        });
        return true;
      }
    }

    // check value
    if (dataQuestion.type === QuestionType.OPEN_TEXT_QUESTION && !answers[`${activeQuestion}`]) {
      this.setState({
        alertErrorMessage: `The text box can't be empty.`,
        showAlertError: true,
      });
      return true;
    }
    if (
      dataQuestion.type === QuestionType.ONE_CHOICE_QUESTION &&
      typeof answers[`${activeQuestion}`] !== 'number'
    ) {
      this.setState({
        alertErrorMessage: `You must choose one.`,
        showAlertError: true,
      });
      return true;
    }

    if (
      dataQuestion.type === QuestionType.MULTIPLE_CHOICE_QUESTION &&
      (!answers[`${activeQuestion}`] || answers[`${activeQuestion}`].length === 0)
    ) {
      this.setState({
        alertErrorMessage: `You must choose at least one.`,
        showAlertError: true,
      });
      return true;
    }
    if (
      dataQuestion.type === QuestionType.RANKING_CHOICE_QUESTION &&
      (!answers[`${activeQuestion}`] ||
        answers[`${activeQuestion}`].length < dataQuestion.answers.length)
    ) {
      this.setState({
        alertErrorMessage: `You must choose all.`,
        showAlertError: true,
      });
      return true;
    }
    if (
      dataQuestion.type === QuestionType.BLANK_QUESTION &&
      (!answers[`${activeQuestion}`] || typeof answers[`${activeQuestion}`][0] !== 'number')
    ) {
      this.setState({
        alertErrorMessage: `You must choose one.`,
        showAlertError: true,
      });
      return true;
    }
    if (
      dataQuestion.type === QuestionType.BLANK_QUESTION &&
      (!answers[`${activeQuestion}`] || !answers[`${activeQuestion}`][1])
    ) {
      this.setState({
        alertErrorMessage: `The text box can’t be empty.`,
        showAlertError: true,
      });
      return true;
    }

    // check image/video
    if (
      dataQuestion.userMediaShow &&
      dataQuestion.userMediaEnable &&
      (!medias[activeQuestion].medias || medias[activeQuestion].medias.length === 0)
    ) {
      this.setState({
        alertErrorMessage: `Video or image required.`,
        showAlertError: true,
      });
      return true;
    }
    return false;
  };

  initOldDataStep = (step) => {
    if (this.listStepSetOldDataDone.includes(step) || !this.oldDataAnswer) return;
    this.listStepSetOldDataDone.push(step);
    // set data
    const { answers, medias } = this.state;
    answers[`${step}`] = this.oldDataAnswer.answers[step];
    if (!medias[step].medias) {
      medias[step].medias = [];
    }
    medias[step].medias = this.oldDataAnswer.medias[step];
    this.setState({ answers, medias });
  };

  // activeQuestion = -1 user info
  onNext = () => {
    const { activeQuestion, campaignData, medias, answers } = this.state;
    if (activeQuestion === -1 && this.userInvalid()) {
      return;
    } else if (activeQuestion !== -1 && this.answerInvalid()) {
      return;
    }

    if (
      activeQuestion !== -1 &&
      campaignData.questions[activeQuestion].type === QuestionType.INSTRUCTION_QUESTION
    ) {
      answers[`${activeQuestion}`] = true;
    }
    if (activeQuestion + 1 === campaignData.questions.length) {
      this.submitData();
      return;
    }
    if (!medias[activeQuestion + 1]) {
      medias.push({});
    }
    if (
      !answers[`${activeQuestion}`] &&
      typeof answers[`${activeQuestion}`] !== 'number' &&
      activeQuestion !== -1
    ) {
      answers[`${activeQuestion}`] = '';
    }

    if (this.state.userId) {
      this.initOldDataStep(activeQuestion + 1);
    }

    this.setState({
      activeQuestion: activeQuestion + 1,
      processPercent: ((activeQuestion + 1) / campaignData.questions.length) * 100,
      dataQuestion: campaignData.questions[activeQuestion + 1],
      medias,
      showAlertError: false,
    });
  };

  onBack = () => {
    const { activeQuestion, campaignData } = this.state;
    if (activeQuestion === -1) return;
    this.setState({
      activeQuestion: activeQuestion - 1,
      processPercent: ((activeQuestion + 1) / campaignData.questions.length) * 100,
      dataQuestion: campaignData.questions[activeQuestion - 1],
      showAlertError: false,
    });
  };

  setValueBasic = (event) => {
    const { name, value } = event.target;
    this.setState({
      basic: {
        ...this.state.basic,
        [name]: value,
      },
    });
  };

  removeQuestionMedia = (index) => {
    const { medias, activeQuestion } = this.state;
    medias[activeQuestion].medias.splice(index, 1);
    this.setState({ medias });
  };

  fileUploadChange = async (e) => {
    const { activeQuestion, medias } = this.state;
    let isValid = true;
    if (_.isArray(e)) {
      if (!medias[activeQuestion].medias) {
        medias[activeQuestion].medias = [];
      }
      medias[activeQuestion].medias = [...medias[activeQuestion].medias, ...e];
      medias[activeQuestion].media_type = '';
      this.setState({ medias, showAlertError: false, alertErrorMessage: '' });
    } else {
      let files = e.target.files;
      if (files.length > 0) {
        this.context.showLoading();
        const mediasEmpty = [];
        for (let index = 0; index < files.length; index++) {
          if (!files[index].type.includes('image') && !files[index].type.includes('video')) {
            isValid = false;
            break;
          }
          // Rotate image
          if (isRotateImage() && files[index].type.includes('image')) {
            const data = await new Promise((resolve) => {
              loadImage(
                files[index],
                (img, data) => {
                  const dataURL = img.toDataURL();
                  resolve(dataURL);
                },
                { orientation: true, canvas: true },
              );
            });
            mediasEmpty.push(data);
          } else {
            mediasEmpty.push(files[index]);
          }
        }
        this.context.hideLoading();
        if (!isValid) {
          this.setState({
            showAlertError: true,
            alertErrorMessage: 'File unsupported.',
          });
          return;
        }
        if (!medias[activeQuestion].medias) {
          medias[activeQuestion].medias = [];
        }
        medias[activeQuestion].medias = [...medias[activeQuestion].medias, ...mediasEmpty];
        medias[activeQuestion].media_type = '';
        this.setState({ medias, showAlertError: false, alertErrorMessage: '' });
      }
    }
  };

  submitData = async () => {
    this.setState({ isShowProgress: true });
    const { campaignId, basic, medias, answers, currentUser, campaignData } = this.state;
    if (currentUser) {
      const { success, ref: refId } = await submitAnswers(
        campaignId,
        basic,
        medias,
        answers,
        this.state.userId ? this.state.userId : currentUser.uid,
        this.setPercentUploading,
      );
      await transcribeAnswer(campaignData.questions.length, refId);
      this.setPercentUploading(97);
      if (success) {
        if (!this.oldDataAnswer) {
          const { updateSuccess } = await subTotal(campaignId);
          this.setPercentUploading(100);
          if (updateSuccess) {
            this.setState({ completeCampaign: true });
          }
        } else {
          this.setPercentUploading(100);
          this.setState({ completeCampaign: true });
        }
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({ isShowProgress: false });
    }, 1000);
  };

  calculatorPercentProgress = () => {
    const { activeQuestion, campaignData } = this.state;
    const numberQuestion = campaignData.required_info ? activeQuestion + 1 : activeQuestion;
    const length = campaignData.required_info
      ? campaignData.questions.length
      : campaignData.questions.length - 1;
    return Math.floor((numberQuestion / length) * 100);
  };

  uploadClicked = (id) => () => {
    this.fileInputs[id].click();
  };

  // DOM
  answerUserStep = () => {
    const {
      basic: { fullName, email },
      campaignData: {
        logo,
        description,
        descriptionHtml,
        instruction,
        instructionEmail,
        instructionName,
      },
    } = this.state;
    return (
      <>
        <div className={styles.logo}>
          {!logo ? null : logo.type ? (
            <img src={URL.createObjectURL(logo)} alt="logo" />
          ) : (
            <img src={logo} alt="logo" />
          )}
        </div>
        <span className={styles.title}>
          <EditorCustom value={descriptionHtml || description || ''} isView={true} />
        </span>
        <span className={styles.shareYour}>
          {instruction ? instruction : 'Share your contact information below'}
        </span>
        <Form.Item
          label={instructionName ? instructionName : 'Your Name'}
          className={cx([inputItem, 'mb-10'])}
          labelAlign="left"
        >
          <Input
            size="large"
            name="fullName"
            autoComplete="off"
            value={fullName}
            disabled={!!this.oldDataAnswer}
            onChange={this.setValueBasic}
          />
        </Form.Item>
        <Form.Item
          label={instructionEmail ? instructionEmail : 'Your Email'}
          className={cx(inputItem)}
          labelAlign="left"
        >
          <Input
            size="large"
            autoComplete="off"
            name="email"
            value={email}
            disabled={!!this.oldDataAnswer}
            onChange={this.setValueBasic}
          />
        </Form.Item>
      </>
    );
  };

  renderCheckboxSection = (checkboxSections) => {
    const { checkboxChecked, activeQuestion } = this.state;
    if (!checkboxChecked[activeQuestion]) {
      checkboxChecked[activeQuestion] = [];
    }
    let currentListChecked = checkboxChecked[activeQuestion];

    const onChecked = (index) => {
      const indexValue = currentListChecked.indexOf(index);

      if (indexValue !== -1) {
        currentListChecked.splice(indexValue, 1);
      } else {
        currentListChecked.push(index);
      }
      checkboxChecked[activeQuestion] = currentListChecked;

      this.setState({ checkboxChecked });
    };

    return checkboxSections.map((section, index) => (
      <div key={index} className={styles.select} onClick={() => onChecked(index)}>
        <input
          type="checkbox"
          checked={currentListChecked.includes(index)}
          name="select"
          value={`section${index}`}
          defaultChecked={false}
          readOnly
        />
        <span>{section}</span>
      </div>
    ));
  };

  renderFeedbacks = () => {
    const { activeQuestion } = this.state;
    return (
      this.oldDataAnswer &&
      this.oldDataAnswer.feedbacks &&
      this.oldDataAnswer.feedbacks[activeQuestion] && (
        <div className={styles.feedback}>
          <div className={styles.feedbackTitle}>Feedback about your response</div>
          <span>{this.oldDataAnswer.feedbacks[activeQuestion]}</span>
        </div>
      )
    );
  };

  uploadClicked = (id) => () => {
    this.fileInputs[id].click();
  };

  renderInstructionType = () => {
    const { dataQuestion, medias, activeQuestion } = this.state;

    return (
      <div className={styles.question}>
        {dataQuestion.media ? <ShowMediaHeader data={dataQuestion} /> : null}
        <div className={styles.typeQuestion}>
          <div
            className={styles.questionTitle}
            ref={(ref) => (this.refResizeInput[`refResizeInput_${activeQuestion}`] = ref)}
          >
            <div className={styles.questionTitle}>
              <EditorCustom
                value={dataQuestion.questionHtml || dataQuestion.question || ''}
                isView={true}
              />
              {/* {dataQuestion.question} */}
            </div>

            <span className={cx(descriptionStyle({ mb: 10, mt: 10 }))}>
              <EditorCustom
                value={dataQuestion.descriptionHtml || dataQuestion.description || ''}
                isView={true}
              />
            </span>

            {this.renderCheckboxSection(dataQuestion.checkboxSections)}
          </div>
        </div>
        {!dataQuestion.userMediaShow ? null : (
          <ShowMediaUpload
            data={medias[activeQuestion]}
            removeQuestionMedia={this.removeQuestionMedia}
            fileUploadChange={this.fileUploadChange}
          />
        )}
        {this.renderFeedbacks()}
      </div>
    );
  };

  renderOpenTextType = () => {
    const { dataQuestion, medias, activeQuestion, answers } = this.state;

    const onUpdateValueOpenTextType = (value) => {
      answers[`${activeQuestion}`] = value;
      this.setState({ answers });
    };
    return (
      <div className={styles.question}>
        {dataQuestion.media ? <ShowMediaHeader data={dataQuestion} /> : null}
        <div className={styles.typeQuestion}>
          <div
            className={styles.questionTitle}
            ref={(ref) => (this.refResizeInput[`refResizeInput_${activeQuestion}`] = ref)}
          >
            <EditorCustom
              value={dataQuestion.questionHtml || dataQuestion.question || ''}
              isView={true}
            />
            {/* {dataQuestion.question} */}
          </div>
          <span
            className={styles.type}
            ref={(ref) => (this.refResizeInput[`refResizeInput_type_${activeQuestion}`] = ref)}
          >
            <span className={cx(descriptionStyle())}>
              <EditorCustom
                value={
                  dataQuestion.descriptionHtml || dataQuestion.description || 'Share your answer.'
                }
                isView={true}
              />
            </span>
          </span>
        </div>
        <textarea
          value={answers[`${activeQuestion}`] || ''}
          onChange={(e) => onUpdateValueOpenTextType(e.target.value)}
          className={styles.textarea}
          placeholder="Enter text"
          rows={4}
        />
        {!dataQuestion.userMediaShow ? null : (
          <ShowMediaUpload
            data={medias[activeQuestion]}
            removeQuestionMedia={this.removeQuestionMedia}
            fileUploadChange={this.fileUploadChange}
          />
        )}
        {this.renderFeedbacks()}
      </div>
    );
  };

  renderOneChoiceType = () => {
    const { dataQuestion, medias, activeQuestion, answers } = this.state;
    const onSelectValueOpenTextType = (value) => {
      answers[`${activeQuestion}`] = value;
      this.setState({ answers });
    };
    return (
      <div className={styles.question}>
        {dataQuestion.media ? <ShowMediaHeader data={dataQuestion} /> : null}
        <div className={styles.typeQuestion}>
          <div
            className={styles.questionTitle}
            ref={(ref) => (this.refResizeInput[`refResizeInput_${activeQuestion}`] = ref)}
          >
            <EditorCustom
              value={dataQuestion.questionHtml || dataQuestion.question || ''}
              isView={true}
            />
            {/* {dataQuestion.question} */}
          </div>
          <span
            className={styles.type}
            ref={(ref) => (this.refResizeInput[`refResizeInput_type_${activeQuestion}`] = ref)}
          >
            <span className={cx(descriptionStyle())}>
              <EditorCustom
                value={dataQuestion.descriptionHtml || dataQuestion.description || 'Select one.'}
                isView={true}
              />
            </span>
          </span>
        </div>
        <div className={styles.buttonSelected}>
          {dataQuestion.answers.map((value, index) => (
            <Button
              key={index}
              className={cx(btnOption)}
              type={index === answers[`${activeQuestion}`] ? 'primary active' : 'default'}
              onClick={() => onSelectValueOpenTextType(index)}
            >
              {value}
            </Button>
          ))}
        </div>
        {!dataQuestion.userMediaShow ? null : (
          <ShowMediaUpload
            data={medias[activeQuestion]}
            removeQuestionMedia={this.removeQuestionMedia}
            fileUploadChange={this.fileUploadChange}
          />
        )}
        {this.renderFeedbacks()}
      </div>
    );
  };

  renderMultipleChoice = () => {
    const { dataQuestion, medias, activeQuestion, answers } = this.state;
    const onSelectValueOpenTextType = (value) => {
      let answer = answers[`${activeQuestion}`];
      if (!answer) answer = [];
      const foundIndex = answer.findIndex((f) => f === value);
      if (foundIndex === -1) {
        answer.push(value);
      } else {
        answer.splice(foundIndex, 1);
      }
      answer = _.sortBy(answer);
      answers[`${activeQuestion}`] = answer;
      this.setState({ answers });
    };
    return (
      <div className={styles.question}>
        {dataQuestion.media ? <ShowMediaHeader data={dataQuestion} /> : null}
        <div className={styles.typeQuestion}>
          <div
            className={styles.questionTitle}
            ref={(ref) => (this.refResizeInput[`refResizeInput_${activeQuestion}`] = ref)}
          >
            <EditorCustom
              value={dataQuestion.questionHtml || dataQuestion.question || ''}
              isView={true}
            />
            {/* {dataQuestion.question} */}
          </div>
          <span
            className={styles.type}
            ref={(ref) => (this.refResizeInput[`refResizeInput_type_${activeQuestion}`] = ref)}
          >
            <span className={cx(descriptionStyle())}>
              <EditorCustom
                value={
                  dataQuestion.descriptionHtml ||
                  dataQuestion.description ||
                  'Select as many as apply.'
                }
                isView={true}
              />
            </span>
          </span>
        </div>
        <div className={styles.buttonSelected}>
          {dataQuestion.answers.map((value, index) => (
            <Button
              key={index}
              className={cx(btnOption)}
              type={
                answers[`${activeQuestion}`] && answers[`${activeQuestion}`].includes(index)
                  ? 'primary active'
                  : 'default'
              }
              onClick={() => onSelectValueOpenTextType(index)}
            >
              {value}
            </Button>
          ))}
        </div>
        {!dataQuestion.userMediaShow ? null : (
          <ShowMediaUpload
            data={medias[activeQuestion]}
            removeQuestionMedia={this.removeQuestionMedia}
            fileUploadChange={this.fileUploadChange}
          />
        )}
        {this.renderFeedbacks()}
      </div>
    );
  };

  renderRankingChoice = () => {
    const { dataQuestion, medias, activeQuestion, answers } = this.state;
    const onSelectValueOpenTextType = (value) => {
      let answer = answers[`${activeQuestion}`];
      if (!answer) answer = [];
      const foundIndex = answer.findIndex((f) => f === value);
      if (foundIndex === -1) {
        answer.push(value);
      } else {
        answer.splice(foundIndex, 1);
      }
      answers[`${activeQuestion}`] = answer;
      this.setState({ answers });
    };
    const getOrder = (index) => {
      let answer = answers[`${activeQuestion}`];
      if (answer) {
        const findIndex = answer.findIndex((f) => f === index);
        if (findIndex !== -1) return findIndex;
      }
      return 1000;
    };

    const getIndex = (value, indexData) => {
      let answer = answers[`${activeQuestion}`];
      if (answer) {
        const index = answer.findIndex((f) => f === indexData);
        if (index !== -1) {
          return (
            <div className="rowRanking">
              <span>{`${String.fromCharCode(65 + indexData)}. ${value}`}</span>
              <span className="index">{index + 1}</span>
            </div>
          );
        }
      }
      return <span>{`${String.fromCharCode(65 + indexData)}. ${value}`}</span>;
    };

    return (
      <div className={styles.question}>
        {dataQuestion.media ? <ShowMediaHeader data={dataQuestion} /> : null}
        <div className={styles.typeQuestion}>
          <div
            className={styles.questionTitle}
            ref={(ref) => (this.refResizeInput[`refResizeInput_${activeQuestion}`] = ref)}
          >
            <EditorCustom
              value={dataQuestion.questionHtml || dataQuestion.question || ''}
              isView={true}
            />
            {/* {dataQuestion.question} */}
          </div>
          <span
            className={styles.type}
            ref={(ref) => (this.refResizeInput[`refResizeInput_type_${activeQuestion}`] = ref)}
          >
            <span className={cx(descriptionStyle())}>
              <EditorCustom
                value={dataQuestion.descriptionHtml || dataQuestion.description || 'Rank choices.'}
                isView={true}
              />
            </span>
          </span>
        </div>
        <div className={styles.buttonSelected}>
          {dataQuestion.answers.map((value, index) => (
            <Button
              key={index}
              className={cx(btnOption)}
              style={{
                order: getOrder(index),
              }}
              type={
                answers[`${activeQuestion}`] && answers[`${activeQuestion}`].includes(index)
                  ? 'primary active'
                  : 'default'
              }
              onClick={() => onSelectValueOpenTextType(index)}
            >
              {getIndex(value, index)}
            </Button>
          ))}
        </div>

        {!dataQuestion.userMediaShow ? null : (
          <ShowMediaUpload
            data={medias[activeQuestion]}
            removeQuestionMedia={this.removeQuestionMedia}
            fileUploadChange={this.fileUploadChange}
          />
        )}
        {this.renderFeedbacks()}
      </div>
    );
  };

  renderBlankType = () => {
    const { dataQuestion, medias, activeQuestion, answers } = this.state;

    const onSelectValueOpenTextType = (value) => {
      let answer = answers[`${activeQuestion}`];
      if (!_.isArray(answer)) {
        answer = [];
      }
      answer[0] = value;
      answers[`${activeQuestion}`] = answer;
      this.setState({ answers });
    };

    const onUpdateValueOpenTextType = (value) => {
      let answer = answers[`${activeQuestion}`];
      if (!_.isArray(answer)) {
        answer = [null];
      }
      answer[1] = value;
      answers[`${activeQuestion}`] = answer;
      this.setState({ answers });
    };

    const handleBricks = (text) => {
      const arr = text.split('[**]');
      if (arr.length > 1) {
        const newText = arr.reduce((data, current, index) => {
          data += current;
          if (index !== arr.length - 1) {
            data += ' _____';
          }
          return data;
        }, '');
        return newText;
      }
      return text;
    };

    const renderTitle = () => {
      if (dataQuestion.questionHtml) {
        const answerText = dataQuestion.answers.reduce((data, current, index) => {
          if (current.trim()) {
            if (index !== 0) {
              data.push('&nbsp;/&nbsp;');
            }
            data.push(current);
          }
          return data;
        }, []);
        let arrReplaceAnswer = dataQuestion.questionHtml.split('[*]');
        const newArrReplace = arrReplaceAnswer.reduce((arr, current, index) => {
          const newCurrent = handleBricks(current);
          arr += newCurrent;
          if (index !== arrReplaceAnswer.length - 1) {
            arr += '&nbsp;[&nbsp;';
            arr += '<span style="color: rgb(191, 191, 191);">' + answerText.join('') + '</span>';
            arr += '&nbsp;]&nbsp;';
          }
          return arr;
        }, '');
        return newArrReplace;
      } else {
        const answerText = dataQuestion.answers.reduce((data, current, index) => {
          if (current.trim()) {
            if (index !== 0) {
              data.push(<span key={uuid()}>&nbsp;/&nbsp;</span>);
            }
            data.push(<span key={uuid()}>{current}</span>);
          }
          return data;
        }, []);
        const arrReplaceAnswer = dataQuestion.question.split('[*]');
        const newArrReplace = arrReplaceAnswer.reduce((arr, current, index) => {
          const newCurrent = handleBricks(current);

          arr.push(<span key={uuid()}>{newCurrent}</span>);
          if (index !== arrReplaceAnswer.length - 1) {
            arr.push(<span key={uuid()}>&nbsp;[&nbsp;</span>);
            arr.push(
              <span key={uuid()} className={cx(blankOptionStyle)}>
                {answerText}
              </span>,
            );
            arr.push(<span key={uuid()}>&nbsp;]&nbsp;</span>);
          }
          return arr;
        }, []);

        return newArrReplace;
      }
    };

    return (
      <div className={styles.question}>
        {dataQuestion.media ? <ShowMediaHeader data={dataQuestion} /> : null}
        <div className={styles.typeQuestion}>
          <div
            className={styles.questionTitle}
            ref={(ref) => (this.refResizeInput[`refResizeInput_${activeQuestion}`] = ref)}
          >
            {dataQuestion.questionHtml ? (
              <EditorCustom value={renderTitle()} isView={true} />
            ) : (
              renderTitle()
            )}
          </div>
          <span
            className={styles.type}
            ref={(ref) => (this.refResizeInput[`refResizeInput_type_${activeQuestion}`] = ref)}
          >
            <span className={cx(descriptionStyle())}>
              <EditorCustom
                value={dataQuestion.descriptionHtml || dataQuestion.description || 'Select one.'}
                isView={true}
              />
            </span>
          </span>
        </div>
        <div className={styles.buttonSelected}>
          {dataQuestion.answers.map((value, index) => (
            <Button
              key={index}
              className={cx(btnOption)}
              type={
                index === (answers[`${activeQuestion}`] && answers[`${activeQuestion}`][0])
                  ? 'primary active'
                  : 'default'
              }
              onClick={() => onSelectValueOpenTextType(index)}
            >
              {value}
            </Button>
          ))}
        </div>

        <div className={styles.typeQuestion}>
          <span
            className={styles.type}
            ref={(ref) => (this.refResizeInput[`refResizeInput_type_${activeQuestion}`] = ref)}
          >
            Share your answer.
          </span>
        </div>
        <textarea
          value={(answers[`${activeQuestion}`] && answers[`${activeQuestion}`][1]) || ''}
          onChange={(e) => onUpdateValueOpenTextType(e.target.value)}
          className={styles.textarea}
          placeholder="Enter text"
          rows={4}
        />

        {!dataQuestion.userMediaShow ? null : (
          <ShowMediaUpload
            data={medias[activeQuestion]}
            removeQuestionMedia={this.removeQuestionMedia}
            fileUploadChange={this.fileUploadChange}
          />
        )}
        {this.renderFeedbacks()}
      </div>
    );
  };

  answerStep = () => {
    const { dataQuestion } = this.state;
    if (dataQuestion && dataQuestion.type) {
      switch (dataQuestion.type) {
        case QuestionType.INSTRUCTION_QUESTION:
          return this.renderInstructionType();
        case QuestionType.OPEN_TEXT_QUESTION:
          return this.renderOpenTextType();
        case QuestionType.ONE_CHOICE_QUESTION:
          return this.renderOneChoiceType();
        case QuestionType.MULTIPLE_CHOICE_QUESTION:
          return this.renderMultipleChoice();
        case QuestionType.RANKING_CHOICE_QUESTION:
          return this.renderRankingChoice();
        case QuestionType.BLANK_QUESTION:
          return this.renderBlankType();
        default:
          return null;
      }
    }
  };

  showMediaPreview = (data) => {
    const renderVideoYoutube = (dataVideo) => {
      return (
        <div className={styles.youtubeView}>
          <iframe
            title="youtube_video"
            className={styles.media}
            width="300"
            src={
              dataVideo.media.includes('www.youtube.com/watch?v=')
                ? dataVideo.media.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')
                : dataVideo.media.replace('youtu.be/', 'www.youtube.com/embed/')
            }
            frameBorder="0"
            allowFullScreen
          />
        </div>
      );
    };
    return data.media
      ? data.media_type === 'youtube'
        ? renderVideoYoutube(data)
        : data.media_type !== 'youtube' &&
          (data.media.type ? (
            <div className={styles.mediaView}>
              {data.media.type.includes('image/') ? (
                <img
                  className={styles.media}
                  width="300"
                  alt="media"
                  src={URL.createObjectURL(data.media)}
                />
              ) : (
                <video
                  controlsList="nodownload"
                  width="300"
                  controls
                  className={styles.media}
                  src={URL.createObjectURL(data.media)}
                />
              )}
            </div>
          ) : (
            <div className={styles.mediaView}>
              {data.media_type.includes('image/') ? (
                <img className={styles.media} alt="media" src={data.media.downloadUrl} />
              ) : (
                <video
                  controlsList="nodownload"
                  width="200"
                  controls
                  className={styles.media}
                  src={data.media.downloadUrl}
                />
              )}
            </div>
          ))
      : null;
  };

  showMediaUpload = (data) => {
    return data.medias && data.medias.length ? (
      <div className={mediaRow}>
        {data.medias.map((media, index) => (
          <div key={index} className={styles.mediaView}>
            {media.type.includes('image/') ? (
              <img
                className={styles.media}
                width="300"
                alt="media"
                src={URL.createObjectURL(media)}
              />
            ) : (
              <video
                width="300"
                controls
                controlsList="nodownload"
                className={styles.media}
                src={URL.createObjectURL(media)}
              />
            )}
            <div className={styles.iconRemove} onClick={() => this.removeQuestionMedia(index)}>
              <i className="fa fa-minus-circle" />
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className={styles.nomedia}>No image or video is uploaded</div>
    );
  };

  render() {
    const {
      campaignData,
      activeQuestion,
      dataQuestion,
      showAlertError,
      alertErrorMessage,
      lockCampaign,
      completeCampaign,
      isShowProgress,
      percentUploading,
    } = this.state;
    return (
      campaignData && (
        <>
          <div className={styles.wrapper}>
            <div className={styles.layout}>
              <Layout.Header
                className={styles.header}
                style={{ background: campaignData.colorSchema }}
              >
                <span className={styles.text}>{campaignData.marketing_name}</span>
              </Layout.Header>
              <Layout.Content className={styles.content}>
                {completeCampaign ? (
                  <div className={styles.completeCampaign}>
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    <div>Thank You!</div>
                    <div>Your submission has been received!</div>
                  </div>
                ) : lockCampaign ? (
                  <div className={styles.expireTime}>The link has expired!</div>
                ) : (
                  <>
                    {((campaignData.required_info && activeQuestion === -1) ||
                      (!campaignData.required_info && activeQuestion === 0)) &&
                      this.oldDataAnswer && (
                        <div className={styles.errInfoReject}>
                          <span>Additional information requested</span>
                        </div>
                      )}
                    <div className={styles.answerUser}>
                      {activeQuestion === -1 && this.answerUserStep()}
                    </div>
                    <div className={styles.answerStep}>
                      {activeQuestion !== -1 && dataQuestion && this.answerStep()}
                    </div>
                    <Divider />
                    <div className={styles.alert}>
                      {showAlertError && (
                        <Alert message={alertErrorMessage} type="error" showIcon />
                      )}
                    </div>
                    <div className={styles.action}>
                      {((campaignData.required_info && activeQuestion !== -1) ||
                        (!campaignData.required_info && activeQuestion !== 0)) && (
                        <Button
                          type="default"
                          size="large"
                          className={styles.backBtn}
                          onClick={() => this.onBack()}
                        >
                          {(campaignData.defaultButtons && campaignData.defaultButtons.back) ||
                            'Back'}
                        </Button>
                      )}

                      <Button type="primary" size="large" onClick={() => this.onNext()}>
                        {activeQuestion === campaignData.questions.length - 1
                          ? (campaignData.defaultButtons && campaignData.defaultButtons.submit) ||
                            'Submit'
                          : (campaignData.defaultButtons && campaignData.defaultButtons.next) ||
                            'Next'}
                      </Button>
                    </div>
                    <div className={styles.progress}>
                      <div className={styles.inner}>
                        <Progress
                          strokeWidth={20}
                          className={cx(styleText())}
                          percent={this.calculatorPercentProgress()}
                          status="active"
                        />
                      </div>
                    </div>
                  </>
                )}
              </Layout.Content>
            </div>
          </div>
          <UploadingProgress percent={percentUploading} isShow={isShowProgress} />
        </>
      )
    );
  }
}

AnswerOpenCampaign.contextType = AppContext;
AnswerOpenCampaign.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default AnswerOpenCampaign;
