export const getCreateAtFromFeedbackHistory = (gsUrl, feedbackHistoryObject) => {
  let createAt = null;

  const feedbackHistoryArray = Object.values(feedbackHistoryObject);
  feedbackHistoryArray.forEach((listFeedback) => {
    const feedbackFound = listFeedback.find((feedback) => {
      if (feedback.medias) {
        return feedback.medias.some((media) => media.gsUrl === gsUrl);
      }
      return false;
    });

    if (feedbackFound && feedbackFound.createAt) {
      createAt = feedbackFound.createAt;
    }
  });

  return createAt;
};
