import { Col, Row, Modal, Button, Checkbox, Input, Avatar, Empty, notification } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import uuid from 'uuid/v4';
import { AnswerController, TranscriptionController, UserController } from '../../../controllers';
import { getDownloadVideo } from '../../../utils/url';
import AppContext from '../../AppContext';
import { SummaryClips } from './SummaryClips/SummaryClips';
import { SummaryTableItem } from './SummaryTableItem/SummaryTableItem';
import QuestionType from '../../../constants/questionType';
import styles from './SummaryTable.module.scss';
import { reportAction } from '../../../store/report';
import placeholderIcon from '../../../assets/image/placeholder.jpg';

const { TextArea } = Input;

export const SummaryTable = React.memo(({ data }) => {
  const dispatch = useDispatch();
  const participants = useSelector((state) => state.report.participants);
  const questionSelected = useSelector((state) => state.report.questionSelected);
  const updateParticipantsSuccess = useSelector((state) => state.report.updateParticipantsSuccess);
  const campaign = useSelector((state) => state.report.campaign);
  const appContext = useContext(AppContext);
  const [createdClips, setCreatedClips] = useState([]);
  const [answerLoadingList, setAnswerLoadingList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  const [answerRefId, setAnswerRefId] = useState('');
  const [answer, setAnswer] = useState();
  const [answerIndex, setAnswerIndex] = useState(-1);
  const [requiredText, setRequiredText] = useState(false);
  const [requiredVideo, setRequiredVideo] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleDownloadVideo = (downloadUrl, name, funcDone = () => {}) => {
    const nameDownloadVideo = `${campaign.name}-${name}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, funcDone);
  };

  const handleFeedback = (record, index) => {
    setAnswer(record.answer);
    setAnswerIndex(index);
    setVisible(true);
    setAnswerRefId(`${campaign.id}-${record.userId}`);
  };

  const handleCreateClip = async (videoGsUrls, selection) => {
    try {
      appContext.showLoading();
      let { data } = await TranscriptionController.createClip(videoGsUrls, selection);
      setCreatedClips((state) => [...state, ...data]);
    } catch (error) {
      alert(error.message);
    } finally {
      appContext.hideLoading();
    }
  };

  const onRemoveVideo = (index) => {
    const clipClone = [...createdClips];
    clipClone.splice(index, 1);
    setCreatedClips(clipClone);
  };

  const getTitleByType = () => {
    if (questionSelected.type === QuestionType.OPEN_TEXT_QUESTION) {
      return 'Open Text';
    }

    if (questionSelected.type === QuestionType.ONE_CHOICE_QUESTION) {
      return 'One Choice';
    }

    if (questionSelected.type === QuestionType.MULTIPLE_CHOICE_QUESTION) {
      return 'Multiple Choice';
    }

    if (questionSelected.type === QuestionType.INSTRUCTION_QUESTION) {
      return 'Instruction';
    }

    if (questionSelected.type === QuestionType.BLANK_QUESTION) {
      return 'Fill in the Blank';
    }

    return 'Ranking Choice';
  };

  const isRequiredByType = () => {
    if (
      questionSelected.type === QuestionType.OPEN_TEXT_QUESTION ||
      questionSelected.type === QuestionType.BLANK_QUESTION
    ) {
      return {
        isText: true,
        isVideo: true,
      };
    }

    return {
      isText: false,
      isVideo: true,
    };
  };

  const onCloseModal = () => {
    setText('');
    setAnswer();
    setAnswerIndex(-1);
    setRequiredText(false);
    setRequiredVideo(false);
  };

  const onClickReject = async () => {
    if (!text.trim()) {
      notification.error({
        message: "Feedback can't be empty.",
      });
      return;
    }

    if (isRequiredByType().isText && !requiredText && !requiredVideo) {
      notification.error({
        message: 'Please check "Require Video" and/or "Require Text" to request feedback.',
      });
      return;
    }

    if (!isRequiredByType().isText && !requiredVideo) {
      notification.error({
        message: 'Please check "Require Video" to request feedback.',
      });
      return;
    }

    if (answerIndex > -1) {
      setAnswerLoadingList([
        ...answerLoadingList,
        {
          index: answerIndex,
          questionIndex: questionSelected.questionIndex,
        },
      ]);
    }

    const userManager = JSON.parse(localStorage.getItem('login_manager'));
    delete userManager.admin;

    const newQuestions = [...campaign.questions];
    let feedbackHistory;
    let comments;
    let feedbacks;
    const user = await UserController.getUserById(userManager.userId);

    const feedback = {
      text,
      required_video: requiredVideo,
      required_text: requiredText,
      createAt: moment().unix(),
      avatar: user ? user.avatar : '',
      ...userManager,
    };

    const comment = {
      createAt: moment().unix(),
      feedback: text,
      ...userManager,
    };

    if (answer.feedbackHistory) {
      const histories = { ...answer.feedbackHistory };
      if (histories[questionSelected.questionIndex].length) {
        histories[questionSelected.questionIndex] =
          histories[questionSelected.questionIndex].concat(feedback);
      } else {
        histories[questionSelected.questionIndex] = [feedback];
      }
      feedbackHistory = histories;
    } else {
      feedbackHistory = newQuestions.map((_, index) => {
        if (index === questionSelected.questionIndex) {
          return [feedback];
        }
        return [];
      });
    }

    if (answer.comments) {
      const newComments = { ...answer.comments };
      if (newComments[questionSelected.questionIndex].length) {
        newComments[questionSelected.questionIndex] =
          newComments[questionSelected.questionIndex].concat(comment);
      } else {
        newComments[questionSelected.questionIndex] = [comment];
      }
      comments = newComments;
    } else {
      comments = newQuestions.map((_, index) => {
        if (index === questionSelected.questionIndex) {
          return [comment];
        }
        return [];
      });
    }

    if (answer.feedbacks && answer.feedbacks.length) {
      const newFeedbacks = [...answer.feedbacks];
      newFeedbacks[questionSelected.questionIndex] = text;
      feedbacks = newFeedbacks;
    } else {
      feedbacks = newQuestions.map((_, index) => {
        if (index === questionSelected.questionIndex) {
          return text;
        }
        return '';
      });
    }

    await AnswerController.updateAnswer(answerRefId, {
      ...answer,
      feedbacks,
      completed: 'rejected',
      comments: { ...comments },
      feedbackHistory: { ...feedbackHistory },
    });

    dispatch(reportAction.setFeedbackSuccess(true));
  };
  const isRejected = React.useCallback(() => {
    if (answer && answer.completed === 'completed') {
      return true;
    }
    if (
      answer &&
      answer.completed === 'rejected' &&
      answer.feedbacks &&
      answer.feedbacks[questionSelected.questionIndex] &&
      answer.feedbacks[questionSelected.questionIndex].length
    ) {
      return true;
    }

    return false;
  }, [answer, questionSelected]);

  const getTranscriptionById = (transcriptionId) => {
    let text = '';
    if (answer && answer.medias) {
      answer.medias[questionSelected.questionIndex].forEach((item) => {
        if (item.wordsStr && item.transcriptionId === transcriptionId) {
          if (text) {
            text += `\n \n` + item.wordsStr;
          } else {
            text += item.wordsStr;
          }
        }
      });
    }

    return text;
  };

  const isLoading = () => {
    const newAnswerLoadingList = [...answerLoadingList];
    const itemIndex = newAnswerLoadingList.findIndex(
      (item) => item.index === answerIndex && questionSelected.questionIndex === item.questionIndex,
    );
    return itemIndex > -1;
  };

  React.useEffect(() => {
    if (!answer) {
      setVisible(false);
    }
  }, [answer]);

  React.useEffect(() => {
    data.forEach(async (item) => {
      if (item.answer.completed === 'rejected' && !item.answer.feedbackHistory) {
        const feedbackHistory = {};
        for (const key in item.answer.comments) {
          const feedbackHistoryItems = [];
          if (item.answer.comments[key].length) {
            for (const innerKey in item.answer.comments[key]) {
              if (Object.hasOwnProperty.call(item.answer.comments[key], innerKey)) {
                const { createAt, email, fullName, feedback, userId } =
                  item.answer.comments[key][innerKey];
                feedbackHistoryItems.push({
                  createAt,
                  email,
                  fullName,
                  required_text: false,
                  required_video: false,
                  text: feedback,
                  userId,
                });
              }
            }
          }

          feedbackHistory[key] = feedbackHistoryItems.length ? feedbackHistoryItems : [];
        }

        await AnswerController.updateAnswer(`${campaign.id}-${item.userId}`, {
          ...item.answer,
          feedbackHistory,
        });
      }
    });
  }, [data]);

  React.useEffect(() => {
    if (updateParticipantsSuccess) {
      const userAnswers = participants.filter(
        (p) =>
          p.answer &&
          p.answer.answers &&
          (p.answer.answers[questionSelected.questionIndex] !== null ||
            p.answer.answers[questionSelected.questionIndex] !== undefined),
      );

      dispatch(reportAction.setUserAnswers(userAnswers));
    }
  }, [participants]);

  React.useEffect(() => {
    if (!updateParticipantsSuccess) {
      if (isLoading()) {
        onCloseModal();
      }
      const newAnswerLoadingList = [...answerLoadingList];
      newAnswerLoadingList.shift();
      setAnswerLoadingList(newAnswerLoadingList);
    }
  }, [updateParticipantsSuccess]);

  return (
    <>
      {data.length > 0 && (
        <Row gutter={16}>
          <Col xs={24} lg={10} xl={8} xxl={6}>
            <SummaryClips
              createdClips={createdClips}
              onRemoveVideo={onRemoveVideo}
              onDownloadVideo={handleDownloadVideo}
            />
          </Col>
          <Col xs={24} lg={14} xl={16} xxl={18}>
            {data.map((record, index) => {
              return (
                <SummaryTableItem
                  key={record.userId}
                  record={record}
                  questionSelected={questionSelected}
                  onFeedback={() => handleFeedback(record, index)}
                  onCreateClip={handleCreateClip}
                  onDownloadVideo={(downloadUrl, funcDone) => {
                    handleDownloadVideo(downloadUrl, record.name, funcDone);
                  }}
                />
              );
            })}
          </Col>
        </Row>
      )}

      <Modal
        visible={visible}
        centered
        width={800}
        zIndex={6000}
        className={styles.modal}
        onCancel={onCloseModal}
        footer={
          <div className={styles.modalFooter}>
            <TextArea
              autoSize={{ minRows: 3 }}
              placeholder="Add your feedback..."
              value={text}
              onChange={(evt) => setText(evt.target.value)}
              disabled={isRejected()}
            />
            <div className={styles.wrapperCheckbox}>
              {isRequiredByType().isVideo && (
                <Checkbox
                  className={styles.requireVideoCheckbox}
                  checked={requiredVideo}
                  onChange={(evt) => setRequiredVideo(evt.target.checked)}
                  disabled={isRejected()}
                >
                  Require Video
                </Checkbox>
              )}
              {isRequiredByType().isText && (
                <Checkbox
                  checked={requiredText}
                  onChange={(evt) => setRequiredText(evt.target.checked)}
                  disabled={isRejected()}
                >
                  Require Text
                </Checkbox>
              )}
            </div>
            <div>
              <Button type="primary" onClick={onCloseModal}>
                Cancel
              </Button>
              <Button
                type="danger"
                loading={isLoading()}
                className={isRejected() ? styles.markRejected : ''}
                onClick={!isRejected() && onClickReject}
                disabled={isRejected()}
              >
                {isRejected() ? 'Rejected' : 'Reject'}
              </Button>
            </div>
          </div>
        }
      >
        <span className={styles.modalTitle}>
          [{getTitleByType()}] {questionSelected.question}
        </span>
        <div className={styles.modalWrapperContent}>
          {answer &&
          answer.feedbackHistory &&
          answer.feedbackHistory[questionSelected.questionIndex] &&
          answer.feedbackHistory[questionSelected.questionIndex].length ? (
            answer.feedbackHistory[questionSelected.questionIndex].map((item, index) => (
              <div className={styles.modalContent} key={index}>
                <div className={styles.avatar}>
                  <Avatar src={item.avatar ? item.avatar : placeholderIcon} size="large" />
                </div>
                <div>
                  <span className={styles.username}>{item.fullName}</span>
                  {'. '}
                  {item.createAt && (
                    <span className={styles.dateTime}>
                      {moment.unix(item.createAt).format('lll')}
                    </span>
                  )}

                  <p>{item.text}</p>

                  <div className={styles.messageGroup}>
                    {item.medias &&
                      item.medias.map((media, index) => (
                        <div key={index} className={styles.wrapperMessage}>
                          <div className={styles.wrapperVideo}>
                            <video
                              controlsList="nodownload"
                              id={uuid()}
                              height="150"
                              width="250"
                              controls
                              src={media.downloadUrl}
                              crossOrigin="anonymous"
                            />
                            <Button
                              type="primary"
                              loading={downloading}
                              onClick={() => {
                                setDownloading(true);
                                handleDownloadVideo(media.downloadUrl, `Clip ${index + 1}`, () => {
                                  setDownloading(false);
                                });
                              }}
                            >
                              Download
                            </Button>
                          </div>
                          <p
                            style={{
                              whiteSpace: 'break-spaces',
                            }}
                          >
                            {getTranscriptionById(media.transcriptionId)}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </div>
      </Modal>
    </>
  );
});

SummaryTable.propTypes = {
  data: PropTypes.array,
};
