import { Firestore, Storage } from '../lib/firebase';
import moment from 'moment';
import axios from 'axios';
import * as _ from 'lodash';

const collectionCampaigns = Firestore.collection('campaigns');
const collectionAnswers = Firestore.collection('answers');

const getStringBase64 = (base) => {
  return base.substring(base.indexOf(',') + 1);
};

const getFileSizeBase64 = (file) => {
  const base64 = getStringBase64(file);
  const sizeInBytes = 4 * Math.ceil(base64.length / 3) * 0.5624896334383812;
  return sizeInBytes;
};

const calculateProgressBar = (summaryFileSize) => {
  const total = summaryFileSize.reduce(
    (currentValue, currentItem) => {
      return currentItem.reduce((v, currentV) => {
        v.size += currentV.size;
        v.uploading += currentV.uploading;
        return v;
      }, currentValue);
    },
    { size: 0, uploading: 0 },
  );
  return (total.uploading / total.size) * 100;
};

export const getOpenCampaign = async (campaignId, callBack) => {
  let campaignDoc = collectionCampaigns.doc(campaignId);
  // listen realtime
  campaignDoc.onSnapshot(async (snapshot) => {
    let campaignData = snapshot.data();
    callBack(campaignData);
  });
  const getData = await campaignDoc.get();
  const data = getData.data();
  let clientDoc = Firestore.collection('clients').doc(data.client_id);
  const getDataClientDoc = await clientDoc.get();
  const clientDocData = getDataClientDoc.data();
  data.client = clientDocData;
  return data;
};

export const submitAnswers = async (
  campaignId,
  answer,
  medias,
  answers,
  userId,
  progressUploading,
) => {
  const answerRefId = `${campaignId}-${userId}`;
  let answerRef = collectionAnswers.doc(answerRefId);
  let answerDoc = await answerRef.get();
  try {
    // upload media file
    let mediasData = medias.map((m) => {
      if (m.medias) {
        const m2 = m.medias.reduce((current) => {
          current.push({});
          return current;
        }, []);
        return m2;
      }
      return [];
    });
    const summaryFileSize = medias.map((m) => {
      if (m.medias) {
        const m2 = m.medias.reduce((arr, current) => {
          if (typeof current === 'string') {
            const size = getFileSizeBase64(current);
            arr.push({
              size,
              uploading: 0,
            });
          } else {
            arr.push({
              size: current.size || 0,
              uploading: 0,
            });
          }
          return arr;
        }, []);
        return m2;
      }
      return [];
    });

    for (let index = 0; index < medias.length; index++) {
      if (medias[index] && medias[index].medias) {
        const filters = medias[index].medias.filter((m) => (m && m.type) || typeof m === 'string');
        for (let indexMedia = 0; indexMedia < filters.length; indexMedia++) {
          const media = filters[indexMedia];
          if (media.downloadUrl) {
            mediasData[index][indexMedia] = media;
            continue;
          }
          const promise = new Promise((resolve) => {
            let ref = Storage.ref(`media/${moment().valueOf()}`);
            let task =
              typeof media === 'string'
                ? ref.putString(getStringBase64(media), 'base64')
                : ref.put(media);
            task.on(
              'state_changed',
              (snapshot) => {
                summaryFileSize[index][indexMedia].uploading = snapshot.bytesTransferred;
                let progress = calculateProgressBar(summaryFileSize);
                if (progress > 95) {
                  progress = 95;
                }
                progressUploading(_.floor(progress));
              },
              (error) => {},
              () => {
                task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                  mediasData[index][indexMedia] = {
                    downloadUrl,
                    gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                    type: typeof media === 'string' ? 'image' : media.type,
                  };
                  if (media.type && media.type.includes('video')) {
                    mediasData[index][indexMedia].transcriptionId = null;
                    mediasData[index][indexMedia].transcriptionStatus = 'progress';
                  }
                  resolve(downloadUrl);
                });
              },
            );
          });
          await promise;
        }
      }
    }
    const mediaObj = mediasData.reduce((data, current, index) => {
      data[`${index}`] = current;
      return data;
    }, {});

    const answerData = {
      campaign_id: campaignId,
      name: answer.fullName,
      email: answer.email,
      medias: mediaObj,
      updatedAt: moment().unix(),
      answers: answers || {},
      user_id: userId,
      completed: '',
    };
    if (answerDoc.exists) {
      await answerRef.update(answerData);
    } else {
      await answerRef.set(answerData);
    }
    return { success: true, ref: answerRefId };
  } catch (error) {
    return { success: false, ref: answerRefId };
  }
};

export const checkExistAnswer = async (campaignId, userId) => {
  const answerRefId = `${campaignId}-${userId}`;
  let answerRef = collectionAnswers.doc(answerRefId);
  let answerDoc = await answerRef.get();
  const data = answerDoc.data();
  return !!data;
};

export const subTotal = async (campaignId) => {
  try {
    let campaignDoc = collectionCampaigns.doc(campaignId);
    const getCollection = await campaignDoc.get();
    const data = getCollection.data();
    if (!data.count_userAnswer) {
      data.count_userAnswer = 1;
    } else {
      data.count_userAnswer += 1;
    }
    await campaignDoc.update(data);
    return { updateSuccess: true };
  } catch (error) {
    return { updateSuccess: false };
  }
};

export const transcribeAnswer = async (numOfQuestions, answerRefId) => {
  try {
    let url = process.env.REACT_APP_API_URL + 'analytics/autotranscribe';
    const answerRef = collectionAnswers.doc(answerRefId);
    const snapshot = await answerRef.get();
    let answerData = snapshot.data();

    let tasks = [];
    for (let index = 0; index < numOfQuestions; index++) {
      answerData.medias[index].forEach((item, vIndex) => {
        if (item && item.type && item.type.includes('video')) {
          tasks.push(
            axios.post(
              url,
              {
                type: 'answer',
                url: item.gsUrl,
                id: answerRefId,
                index,
                vIndex,
              },
              {
                headers: { 'content-type': 'application/json' },
              },
            ),
          );
        }
      });
    }
    await Promise.all(tasks);
  } catch (error) {
    alert(error.message);
  }
};
