import axios from 'axios';
import { Firestore } from '../lib/firebase';

let collection = Firestore.collection('users');

export const getAllUsers = async () => {
  let snapshot = await collection.get();
  let userList = [];
  snapshot.forEach((doc) => {
    userList.push(doc.data());
  });
  return userList;
};

export const getUserByEmail = async (email) => {
  const snapshot = await collection.get();
  let result = null;
  snapshot.forEach((user) => {
    const userData = user.data();
    if (userData && userData.email && userData.email.toLowerCase() === email.toLowerCase()) {
      result = userData;
      return;
    }
  });
  return result;
};

export const getUserById = async (id) => {
  let snapshot = await collection.doc(id).get();
  let user = snapshot.data();
  return user;
};

export const deleteUserById = async (id) => {
  try {
    let ref = collection.doc(id);
    await ref.delete();

    let url = `${process.env.REACT_APP_API_URL}user/${id}`;
    await axios.delete(url, {
      headers: { 'content-type': 'application/json' },
    });
  } catch (error) {
    throw error;
  }
};
