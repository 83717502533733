import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid/v4';
import styles from './Media.module.scss';

export const Media = ({ questionSelected }) => {
  return (
    <section className={styles.wrapper}>
      {questionSelected.media &&
        (questionSelected.media_type === 'youtube' ? (
          <iframe
            title="youtube_video"
            id={uuid()}
            className={styles.media}
            width="400"
            src={questionSelected.media.replace(
              'https://youtu.be',
              'https://www.youtube.com/embed',
            )}
            frameBorder="0"
            allowFullScreen
          />
        ) : questionSelected.media_type.includes('image/') ? (
          <img className={styles.media} alt="media" src={questionSelected.media.downloadUrl} />
        ) : (
          <video
            controlsList="nodownload"
            id={uuid()}
            width="400"
            controls
            className={styles.media}
            src={questionSelected.media.downloadUrl}
            crossOrigin="*"
          />
        ))}
    </section>
  );
};

Media.propTypes = {
  questionSelected: PropTypes.any,
};
