import { Button, Col, List, Modal, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './FeedbackConversation.module.scss';
const FeedbackConversation = React.memo((props) => {
  const { feedbackConversation = [] } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <section className={styles.wrapper}>
      <Button size="small" type="primary" onClick={() => setShowModal(true)}>
        View History
      </Button>
      <Modal
        title="Feedback History"
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        wrapClassName={styles.wrapper}
      >
        <List
          dataSource={feedbackConversation}
          renderItem={(item) => (
            <Row wrap={false} gutter={[12, 12]}>
              <Col flex="none">
                <Tooltip title={item.email}>
                  <Typography.Title level={5}>{item.fullName}:</Typography.Title>
                </Tooltip>
              </Col>
              <Col flex="auto">
                <Typography.Paragraph className={styles.paragraph}>
                  {item.feedback}
                </Typography.Paragraph>
              </Col>
              <Col flex="none">
                <Typography.Text className={styles.createAt}>
                  {moment.unix(item.createAt).format('lll')}
                </Typography.Text>
              </Col>
            </Row>
          )}
        />
      </Modal>
    </section>
  );
});

FeedbackConversation.propTypes = {
  feedbackConversation: PropTypes.array,
};
export default FeedbackConversation;
