import { Firestore } from '../lib/firebase';
import Axios from 'axios';
let collection = Firestore.collection('managers');
let clientCollection = Firestore.collection('clients');

export const getManager = async (id) => {
  let snapshot = await collection.doc(id).get();
  let manager = snapshot.data();
  return manager;
};

export const getAllManagers = async () => {
  let snapshot = await collection.get();
  let managerList = [];
  snapshot.forEach((doc) => {
    managerList.push(doc.data());
  });
  return managerList;
};

export const addManager = ({ id, fullName, email, password, clientIds, forceChange = true }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/onAddManager`, {
      id: id,
      fullName,
      email,
      password,
      clientIds,
      forceChange,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editManager = ({ id, fullName, email, password, clientIds, managerId }) => {
  return new Promise((resolve, reject) => {
    Axios.put(`${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/onUpdateManager`, {
      id,
      fullName,
      email,
      password,
      clientIds,
      managerId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteManager = (id, targetId) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/onDeleteManager`, {
      id,
      userId: targetId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllClients = async () => {
  const snapshot = await clientCollection.get();
  let clientList = [];
  snapshot.forEach((doc) => {
    clientList.push(doc.data());
  });
  return clientList;
};

export const getManagerForUserId = async (userId) => {
  const managers = collection.where('userId', '==', userId);
  const snapshot = await managers.get();
  let managerList = [];
  snapshot.forEach((doc) => {
    managerList.push(doc.data());
  });
  return managerList;
};

export const getClientIds = async () => {
  const login_manager = window.localStorage.getItem('login_manager');
  if (!login_manager) return [];
  const { userId } = JSON.parse(login_manager);
  const managerList = await getManagerForUserId(userId);
  return managerList.reduce((arr, managerItem) => {
    return [...arr, ...(managerItem.clientIds || [])];
  }, []);
};

export const isAdmin = () => {
  const login_manager = window.localStorage.getItem('login_manager');
  if (!login_manager) return [];
  const { admin } = JSON.parse(login_manager);
  return admin;
};
