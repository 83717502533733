import { css, cx } from 'emotion';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { uploadFile } from '../../controllers/file';
import './Editor.scss';
import { CampaignController } from '../../controllers';

const styleEditorView = css`
  .se-dialog-tabs {
    display: none;
  }
  .sun-editor {
    border-width: 0px;
    .sun-editor-editable {
      padding: 0px;
      min-height: 1px;
      p {
        margin-bottom: 0px;
      }
    }
  }

  .sun-editor-editable li {
    text-align: left;
  }

  .se-resizing-container,
  .se-line-breaker-component,
  .se-line-breaker-component,
  .se-controller-resizing {
    display: 'none !important';
  }
  strong {
    letter-spacing: normal;
  }
`;

const styleEditor = css`
  .sun-editor {
    height: 100%;
    .se-input-control {
      padding: 0 !important;
    }
  }
`;

const EditorCustom = (props) => {
  const { isView, value, onChange, placeholder, name } = props;
  const ref = useRef(null);
  let flag = true;

  const beforeUploadImage = async (files, info) => {
    const { core } = ref.current;
    if (files.length > 0) {
      const response = await uploadFile(files[0]);
      const result = {
        result: [response],
      };
      core.plugins.image.register.call(core, info, result);
    }
    core.closeLoading.call(core);
  };

  const beforeUploadVideo = async (files, info) => {
    const { core } = ref.current;
    if (files.length > 0) {
      const response = await uploadFile(files[0]);
      const result = {
        result: [response],
      };
      core.plugins.video.register.call(core, info, result);
    }
    core.closeLoading.call(core);
  };

  const onPaste = (e) => {
    return CampaignController.removeStyleInline(e);
  };

  const getSunEditorInstance = (sunEditor) => {
    ref.current = sunEditor;
  };

  const defaultButtonList = {
    buttonList: [
      [
        // 'font',
        // 'fontSize',
        'bold',
        'underline',
        'italic',
        'link',
        'align',
        'list',
        'image',
        'video',
      ],
    ],
    videoFileInput: true,
    resizingBar: false,
    videoAccept: 'video/mp4,video/x-m4v,video/*',
    videoTagAttrs: {
      controlsList: 'nodownload',
    },
    defaultTag: 'div',
  };

  useEffect(() => {
    if (isView) return;
    const { core } = ref.current;
    const { image, video } = core.plugins;
    image.upload = () => {
      // disable function upload of library
    };
    video.upload = () => {
      // disable function upload of library
    };
  }, []);

  useEffect(() => {
    if (isView) return;

    const { core } = ref.current;
    core.options.placeholder = placeholder;
    core.functions.setOptions({
      ...defaultButtonList,
      placeholder: placeholder,
    });
  }, [placeholder]);

  const showController = (name, controllers) => {
    if (controllers && controllers.length > 0 && name === 'image' && flag) {
      window.scrollTo(0, 0);
      flag = false;
    }
  };
  return isView ? (
    <div className={cx(styleEditorView)} dangerouslySetInnerHTML={{ __html: value }}></div>
  ) : (
    <div className={cx(styleEditor)} style={{ width: '100%' }}>
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        name={name}
        hideToolbar={isView}
        disable={isView}
        autoFocus={isView ? false : undefined}
        onPaste={onPaste}
        onChange={onChange}
        onBlur={(_, editorContents) => {
          if (onChange) {
            onChange(editorContents);
          }
        }}
        height="auto"
        setDefaultStyle="font-size: 16px; color: rgba(0,0,0,.65); background: rgba(0, 0, 0, 0);"
        setContents={value}
        showController={showController}
        onImageUploadBefore={beforeUploadImage}
        onVideoUploadBefore={beforeUploadVideo}
      />
    </div>
  );
};

EditorCustom.propTypes = {
  isView: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default EditorCustom;
