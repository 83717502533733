import React, { useEffect } from 'react';
import styles from './UserHistory.module.scss';
import PropTypes from 'prop-types';
import { getUserHistory } from '../../controllers/UserHistory';
import { useState } from 'react';
import { useMemo } from 'react';
import { Table } from 'antd';
import { v4 } from 'uuid';

const UserHistory = ({ userId }) => {
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleChange = (pagination) => {
    setPaginationInfo(pagination);
  };
  const columns = useMemo(() => {
    return [
      {
        title: 'Client',
        dataIndex: 'client_name',
        onCell: (record, index) => {
          const trueIndex = index + paginationInfo.pageSize * (paginationInfo.current - 1);
          let rowSpan = record.rowSpan;
          if (index >= 1 && record.client_id === dataHistory[trueIndex - 1].client_id) {
            rowSpan = 0;
          } else {
            for (
              let i = 0;
              trueIndex + i !== dataHistory.length &&
              record.client_id === dataHistory[trueIndex + i].client_id;
              i += 1
            ) {
              rowSpan = i + 1;
            }
          }
          return { rowSpan };
        },
      },
      {
        title: 'Participant Group(s)',
        dataIndex: 'participant_group_name',
      },
    ];
  }, [dataHistory, paginationInfo]);

  const getDataHistories = async () => {
    setLoading(true);
    const response = await getUserHistory(userId);
    let mapData = [];
    response.clients.forEach((item) => {
      const result = [];
      item.participant_groups.forEach((p, index) => {
        if (index === 0) {
          result.push({
            client_id: item.client_id,
            client_name: item.client_name,
            participant_group_id: p.participant_group_id,
            participant_group_name: p.participant_group_name,
            rowSpan: item.participant_groups.length,
          });
        } else {
          result.push({
            ...p,
            client_id: item.client_id,
            client_name: item.client_name,
            rowSpan: 0,
          });
        }
      });
      mapData = mapData.concat(result);
    });
    setDataHistory(mapData);
    setLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getDataHistories();
    }
  }, []);

  return (
    <section className={styles.wrapper}>
      <h3>User history</h3>
      <Table
        dataSource={dataHistory || []}
        rowKey={() => v4()}
        columns={columns}
        bordered
        size="middle"
        onChange={handleChange}
        pagination={{
          pageSizeOptions: ['10', '25', '50'],
          showSizeChanger: true,
        }}
        loading={loading}
      />
    </section>
  );
};

UserHistory.propTypes = {
  userId: PropTypes.string,
};
export default React.memo(UserHistory);
