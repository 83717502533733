import React from 'react';
import PropTypes from 'prop-types';
import styles from './ShowMediaHeader.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const ShowMediaHeader = ({ data }) => {
  const [isLoad, setIsLoad] = React.useState(false);

  const renderVideoYoutube = React.useCallback((dataVideo) => {
    return (
      <div className={styles.youtubeView}>
        <iframe
          title="youtube_video"
          className={styles.media}
          width="300"
          src={
            dataVideo.media.includes('www.youtube.com/watch?v=')
              ? dataVideo.media.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')
              : dataVideo.media.replace('youtu.be/', 'www.youtube.com/embed/')
          }
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }, []);

  React.useEffect(() => {
    setIsLoad(false);
  }, [data]);

  return data.media
    ? data.media_type === 'youtube'
      ? renderVideoYoutube(data)
      : data.media_type !== 'youtube' &&
        (data.media.type ? (
          <div className={styles.mediaView}>
            {data.media.type.includes('image/') ? (
              <>
                {!isLoad ? (
                  <div className={styles.media}>
                    <Spin
                      size="small"
                      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    />
                  </div>
                ) : null}
                <img
                  className={styles.media}
                  onLoad={() => setIsLoad(true)}
                  style={isLoad ? {} : { display: 'none' }}
                  width="300"
                  alt="media"
                  src={URL.createObjectURL(data.media)}
                />
              </>
            ) : (
              <video
                width="300"
                controls
                controlsList="nodownload"
                className={styles.media}
                src={URL.createObjectURL(data.media)}
              />
            )}
          </div>
        ) : (
          <div className={styles.mediaView}>
            {data.media_type.includes('image/') ? (
              <>
                {!isLoad ? (
                  <div className={styles.media}>
                    <Spin
                      size="small"
                      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    />
                  </div>
                ) : null}
                <img
                  className={styles.media}
                  onLoad={() => setIsLoad(true)}
                  style={isLoad ? {} : { display: 'none' }}
                  alt="media"
                  src={data.media.downloadUrl}
                />
              </>
            ) : (
              <video
                controlsList="nodownload"
                width="200"
                controls
                className={styles.media}
                src={data.media.downloadUrl}
              />
            )}
          </div>
        ))
    : null;
};

ShowMediaHeader.propTypes = {
  data: PropTypes.any,
};
export default React.memo(ShowMediaHeader);
