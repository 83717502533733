import { Button, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Media, WordFrequency } from '..';
import EditorCustom from '../../common/Editor';
import { SummaryTable } from '../SummaryTable';
import styles from './SummaryReportFillInTheBlank.module.scss';
import { useSummaryReportFillInTheBlank } from './useSummaryReportFillInTheBlank';

export const SummaryReportFillInTheBlank = React.memo((props) => {
  const { loading } = props;
  const {
    detailSummaryUserOneChoice,
    questionSelected,
    userAnswers,
    userSummary,
    userAnswered,
    totalNumberOfRespondents,
    userOptionSummary,
    showSummaryDetail,
  } = useSummaryReportFillInTheBlank();

  const columnsSummaryOneChoice = [
    {
      title: 'Option',
      dataIndex: 'option',
      align: 'center',
      sorter: (a, b) => (a.option > b.option ? 1 : -1),
    },
    {
      title: 'Number of Respondents',
      dataIndex: 'numberOfRespondents',
      align: 'center',
      width: 300,
      sorter: (a, b) => (a.numberOfRespondents > b.numberOfRespondents ? 1 : -1),
      render: (text, record) => (
        <Button type="link" onClick={detailSummaryUserOneChoice(record.defaultIndex)}>
          {text}
        </Button>
      ),
    },
  ];

  return (
    <>
      <section className={styles.wrapper}>
        <div>
          {questionSelected.questionHtml ? (
            <EditorCustom value={questionSelected.questionHtml} isView={true} />
          ) : (
            <h3>{questionSelected.question}</h3>
          )}
          <Media questionSelected={questionSelected} />
        </div>
        <WordFrequency questionSelected={questionSelected} userAnswers={userAnswers} />
        <div className={styles.columns}>
          <div className={styles.typeReport}>
            Fill in the Blank
            {questionSelected.questionTitle ? (
              <span className={styles.questionTitle}>&nbsp;- {questionSelected.questionTitle}</span>
            ) : null}
          </div>
          <div className={styles.subTypeReport}>
            {totalNumberOfRespondents} Total Number of Respondents
          </div>
          <section className={styles.tableSummary}>
            <Table
              columns={columnsSummaryOneChoice}
              dataSource={userOptionSummary}
              bordered
              size="middle"
              pagination={{
                pageSizeOptions: ['10', '25', '50'],
                showSizeChanger: true,
              }}
              loading={loading}
            />
          </section>

          <SummaryTable data={showSummaryDetail ? userAnswered : userSummary} />
        </div>
      </section>
    </>
  );
});

SummaryReportFillInTheBlank.propTypes = {
  loading: PropTypes.bool,
};
