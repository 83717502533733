import { Button, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Media, WordFrequency } from '..';
import EditorCustom from '../../common/Editor';
import { SummaryTable } from '../SummaryTable';
import styles from './SummaryReportRankingChoice.module.scss';
import { useSummaryReportRankingChoice } from './useSummaryReportRankingChoice';

export const SummaryReportRankingChoice = React.memo(({ loading }) => {
  const {
    detailSummaryRankingChoice,
    userAnswered,
    userSummary,
    userAnswers,
    userOptionSummary,
    totalNumberOfRespondents,
    questionSelected,
    showSummaryDetail,
  } = useSummaryReportRankingChoice();

  const header = ['', ...questionSelected.answers];
  const columns = header.map((v, index) => {
    if (index === 0) {
      return {
        title: v,
        dataIndex: `value_${index}`,
        align: 'center',
        width: 150,
      };
    }
    return {
      title: v,
      dataIndex: `value_${index}`,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Button type="link" onClick={detailSummaryRankingChoice(record, index)}>
          {text}
        </Button>
      ),
    };
  });

  const dataSource = userOptionSummary.map((v, index) => {
    const array = [`Rank ${index + 1}`, ...v];
    return array.reduce((obj, current, indexData) => {
      obj[`value_${indexData}`] = current;
      obj.defaultIndex = index;
      return obj;
    }, {});
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          {questionSelected.questionHtml ? (
            <EditorCustom value={questionSelected.questionHtml} isView={true} />
          ) : (
            <h3>{questionSelected.question}</h3>
          )}
          <Media questionSelected={questionSelected} />
        </div>
        <WordFrequency questionSelected={questionSelected} userAnswers={userAnswers} />

        <div className={styles.columns}>
          <div className={styles.typeReport}>
            Ranking Choice
            {questionSelected.questionTitle ? (
              <span className={styles.questionTitle}>&nbsp;- {questionSelected.questionTitle}</span>
            ) : null}
          </div>
          <div className={styles.subTypeReport}>
            {totalNumberOfRespondents} Total Number of Respondents
          </div>
          <section className={styles.tableSummary}>
            <Table
              columns={columns}
              dataSource={dataSource}
              bordered
              size="middle"
              pagination={{
                pageSizeOptions: ['10', '25', '50'],
                showSizeChanger: true,
              }}
              loading={loading}
            />
          </section>

          <SummaryTable data={showSummaryDetail ? userAnswered : userSummary} />
        </div>
      </div>
    </>
  );
});

SummaryReportRankingChoice.propTypes = {
  loading: PropTypes.bool,
};
