import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { Button, Typography } from 'antd';
import { getDownloadVideo } from './../../../utils/url';
import { AppContext } from '../../../components';
import {
  ClientController,
  CampaignController,
  AnswerController,
  ParticipantsController,
  TranscriptionController,
  ManagerController,
} from '../../../controllers';

import styles from './CampaignAnalyticsContainer.module.scss';
import selectStyles from '../../../theme/select.styles';
import QuestionType from '../../../constants/questionType';
import CampaignType from '../../../constants/campaignType';
import { setParams, getParamsUrl } from '../../../utils/url';
import { BackButton } from '../../../components/Button';

const { Paragraph } = Typography;

const selectType = {
  client: 'client',
  campaign: 'campaign',
  analysis: 'analysis',
  participant: 'participant',
  question: 'question',
};

const searchType = {
  analysisValue: 'analysisValue',
  campaignValue: 'campaignValue',
  clientValue: 'clientValue',
  participantValue: 'participantValue',
  questionValue: 'questionValue',
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class CampaignAnalyticsContainer extends React.Component {
  constructor(props) {
    super(props);

    let analysisList = [
      { value: 0, label: 'Participant' },
      { value: 1, label: 'Question' },
    ];
    const searchParams = getParamsUrl();
    this.state = {
      client: null,
      campaign: null,
      analysis: searchParams[searchType.analysisValue]
        ? analysisList[searchParams[searchType.analysisValue]]
        : analysisList[0],
      question: null,
      participant: null,

      clientData: [],
      campaignData: [],
      questionData: [],
      participantData: [],

      clientList: [],
      campaignList: [],
      analysisList,
      questionList: [],
      participantList: [],

      videos: [],
      transcriptions: [],
      selectedWords: [],
      clips: [],
      clipResult: [],

      cacheIndexVideoOnSave: [],
      loadingDownloadBtn: {},
      loadingDownloadBtnClipResult: {},
    };
  }

  async componentDidMount() {
    try {
      this.context.showLoading();
      let clientData = await ClientController.getClients(ManagerController.isAdmin());
      let clientList = clientData.map((client) => ({
        value: client.id,
        label: client.org,
        disabled: !client.status,
      }));
      await this.setState({ clientData, clientList });
      const searchParams = getParamsUrl();
      if (searchParams) {
        if (searchParams.clientValue) {
          const foundClient = clientList.find((f) => f.value === searchParams.clientValue);
          if (foundClient) {
            await this.setState({ client: foundClient });
            await this.handleSelectChange(selectType.client)(foundClient);
            if (searchParams[searchType.campaignValue]) {
              const foundCampaign = this.state.campaignList.find(
                (f) => f.value === searchParams[searchType.campaignValue],
              );
              if (foundCampaign) {
                await this.setState({ campaign: foundCampaign });
                await this.handleSelectChange(selectType.campaign)(foundCampaign);
                if (searchParams[searchType.questionValue]) {
                  const foundQuestion = this.state.questionList.find(
                    (f) => f.value === +searchParams[searchType.questionValue],
                  );
                  if (foundQuestion) {
                    await this.setState({ question: foundQuestion });
                    await this.handleSelectChange(selectType.question)(foundQuestion);
                  }
                } else if (searchParams[searchType.participantValue]) {
                  const foundParticipant = this.state.participantList.find(
                    (f) => f.value === searchParams[searchType.participantValue],
                  );
                  if (foundParticipant) {
                    await this.setState({ participant: foundParticipant });
                    await this.handleSelectChange(selectType.participant)(foundParticipant);
                  }
                }
              }
            }
          }
        }
      }
    } finally {
      this.context.hideLoading();
    }
  }

  wordComparator = (a, b) => {
    if (a.iIndex < b.iIndex) return -1;
    else if (a.iIndex === b.iIndex && a.wIndex < b.wIndex) return -1;
    return 1;
  };

  handleBricks = (str) => {
    const arr = str.split('[**]');
    const result = arr.reduce((text, current, index) => {
      text += ` ${current}`;
      if (index !== arr.length - 1) {
        text += ' ___';
      }
      return text;
    }, '');
    return result.trim();
  };

  updateTextQuestion = (question) => {
    if (question.answers && question.type === QuestionType.BLANK_QUESTION) {
      const answerStr = question.answers.reduce((str, current, index) => {
        str += current;
        if (index !== question.answers.length - 1) {
          str += ' / ';
        }
        return str;
      }, '');
      const questionSplit = question.question.split('[*]');
      const newQuestionTitle = questionSplit.reduce((str, current, index) => {
        const newStr = this.handleBricks(current);
        str += `${newStr} `;
        if (index !== questionSplit.length - 1) {
          str += `[ ${answerStr} ] `;
        }
        return str;
      }, '');
      question.question = newQuestionTitle.trim();
    }
    return question;
  };

  updateParams = (type, value) => {
    switch (type) {
      case selectType.client:
        const clientParams = setParams({ [searchType.clientValue]: value.value });
        this.props.history.replace({
          search: `?${clientParams}`,
        });
        break;
      case selectType.campaign:
        const campaignParams = setParams({ [searchType.campaignValue]: value.value });
        this.props.history.replace({
          search: `?${campaignParams}`,
        });
        break;
      case selectType.analysis:
        const analysisParams = setParams({ [searchType.analysisValue]: value.value });
        this.props.history.replace({
          search: `?${analysisParams}`,
        });
        break;
      case selectType.participant:
        const participantParams = setParams({
          [searchType.participantValue]: value.value,
          [searchType.questionValue]: undefined,
        });
        this.props.history.replace({
          search: `?${participantParams}`,
        });
        break;
      case selectType.question:
        const questionParams = setParams({
          [searchType.questionValue]: value.value,
          [searchType.participantValue]: undefined,
        });
        this.props.history.replace({
          search: `?${questionParams}`,
        });
        break;
      default:
        break;
    }
  };

  handleSelectChange = (type) => async (value) => {
    this.setState({
      [type]: value,
    });
    this.updateParams(type, value);
    if (type === 'client') {
      let campaignData = await CampaignController.getCampaignsByClient(value.value);
      let campaignList = campaignData.map((campaign) => ({
        value: campaign.id,
        label: campaign.name,
        disabled: !campaign.status,
      }));
      await this.setState({
        campaignData,
        campaignList,
      });
    } else if (
      (type === 'campaign' && this.state.analysis.value === 0) ||
      (type === 'analysis' && value.value === 0 && this.state.campaign)
    ) {
      // participant
      let campaignId = type === 'campaign' ? value.value : this.state.campaign.value;
      const { participant_group_id } = this.state.campaignData.filter(
        (item) => item.id === campaignId,
      )[0];
      let participantList = [];
      let participantData;
      if (participant_group_id) {
        participantData = await ParticipantsController.getParticipantById(participant_group_id);
        participantList = participantData.participant_list.map((item) => ({
          value: item.email,
          label: item.name,
          disabled: !item.status,
        }));
      }

      this.setState({
        participantData,
        participantList,
        // clearing the old data
        videos: [],
        transcriptions: [],
        selectedWords: [],
        clips: [],
        clipResult: [],
      });
    } else if (
      (type === 'campaign' && this.state.analysis.value === 1) ||
      (type === 'analysis' && value.value === 1 && this.state.campaign)
    ) {
      // question
      let campaignId = type === 'campaign' ? value.value : this.state.campaign.value;
      let campaign = this.state.campaignData.filter((item) => item.id === campaignId)[0];
      let questionData = campaign.questions;
      let participantData = [];
      let questionList = questionData.map((item, index) => {
        const changeQuestion = this.updateTextQuestion(item);
        return {
          value: index,
          label: (
            <Paragraph ellipsis={{ rows: 3 }} className={styles.questionSelect}>
              {changeQuestion.question}
            </Paragraph>
          ),
          questionType: item.type,
        };
      });

      if (campaign.participant_group_id) {
        participantData = await ParticipantsController.getParticipantById(
          campaign.participant_group_id,
        );
      }
      this.setState({
        questionData,
        participantData,
        questionList,
        // clearing the old data
        videos: [],
        transcriptions: [],
        selectedWords: [],
        clips: [],
        clipResult: [],
      });
    } else if (type === 'participant') {
      this.context.showLoading();
      // get video list
      let videos = [];
      let campaign = this.state.campaignData.filter(
        (item) => item.id === this.state.campaign.value,
      )[0];
      let participant = this.state.participantData.participant_list.filter(
        (item) => item.email === value.value,
      )[0];
      let answer = await AnswerController.getAnswer(value.value, campaign.id);

      answer &&
        campaign.questions.forEach((question, index) => {
          this.updateTextQuestion(question);
          if (answer && answer.medias && answer.medias[index]) {
            answer.medias[index].forEach((media) => {
              if (media.type === 'video') {
                videos.push({
                  ...media,
                  title: `${question.question} - ${participant.name}`,
                  selected: false,
                  questionType: question.type,
                });
              }
            });
          }
        });
      this.context.hideLoading();
      this.setState({
        videos,
        transcriptions: [],
        selectedWords: [],
        clips: [],
        clipResult: [],
        loadingDownloadBtn: {},
        loadingDownloadBtnClipResult: {},
      });
    } else if (type === 'question') {
      this.context.showLoading();
      // get video list
      let campaign = this.state.campaignData.filter(
        (item) => item.id === this.state.campaign.value,
      )[0];
      let question = campaign.questions[value.value];
      let videos = [];

      let tasks = [],
        answers = [];
      if (campaign.type === CampaignType.OPEN) {
        answers = await AnswerController.getAnswerOpenCampaign(campaign.id);
        answers.forEach((answer, index) => {
          if (answers[index] && answers[index].medias && answers[index].medias[value.value]) {
            answers[index].medias[value.value].forEach((video) => {
              this.updateTextQuestion(question);
              videos.push({
                ...video,
                title: `${question.question} - ${answer.name || 'No Name'}`,
                userName: answer.name || 'No Name',
                selected: false,
                questionType: question.type,
              });
            });
          }
        });
      } else {
        let participant_list = this.state.participantData.participant_list;
        if (participant_list) {
          tasks = participant_list
            .filter((participant) => participant.status)
            .map((participant) => {
              return AnswerController.getAnswer(participant.email, campaign.id);
            });
          answers = await Promise.all(tasks);
        }
        if (participant_list) {
          participant_list
            .filter((participant) => participant.status)
            .forEach((participant, index) => {
              if (answers[index] && answers[index].medias && answers[index].medias[value.value]) {
                answers[index].medias[value.value].forEach((video) => {
                  this.updateTextQuestion(question);
                  videos.push({
                    ...video,
                    title: `${question.question} - ${participant.name}`,
                    userName: participant.name,
                    selected: false,
                    questionType: question.type,
                  });
                });
              }
            });
        }
      }
      this.context.hideLoading();
      this.setState({
        videos,
        transcriptions: [],
        selectedWords: [],
        clips: [],
        clipResult: [],
        loadingDownloadBtn: {},
        loadingDownloadBtnClipResult: {},
      });
    }
  };

  handleSelectVideo = (index) => async () => {
    let { videos, selectedWords, cacheIndexVideoOnSave } = this.state;
    videos[index].selected = !videos[index].selected;
    // getting video transcription for the selected videos
    let tasks = videos.map((video) => {
      if (video.selected === false) {
        return new Promise((resolve, reject) => resolve(null));
      } else {
        return TranscriptionController.getTranscriptionById(video.transcriptionId);
      }
    });
    let transcriptions = await Promise.all(tasks);

    // updating selected words list from state
    if (!videos[index].selected) {
      selectedWords = selectedWords.filter((item) => item.tIndex !== index);
    }

    let clips = [];
    videos.forEach((video, index) => {
      if (video.selected) {
        let words = selectedWords.filter((word) => word.tIndex === index);
        if (words.length) {
          words = words.sort(this.wordComparator);
          clips.push({
            index,
            words,
          });
        }
      }
    });

    if (!videos[index].selected && cacheIndexVideoOnSave.includes(index)) {
      this.setState({ videos, transcriptions, selectedWords, clips, clipResult: [] });
    } else {
      this.setState({ videos, transcriptions, selectedWords, clips });
    }
  };

  downloadVideo = (downloadUrl, userName, index) => {
    const { loadingDownloadBtn, campaign } = this.state;
    loadingDownloadBtn[index] = true;
    this.setState({ loadingDownloadBtn });

    const stopLoadingBtn = () => {
      loadingDownloadBtn[index] = false;
      this.setState({ loadingDownloadBtn });
    };
    const nameDownloadVideo = `${campaign.label}-${userName}-${index}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, stopLoadingBtn);
  };

  downloadVideoClipResult = (downloadUrl, nameClip, index) => {
    const { loadingDownloadBtnClipResult, campaign } = this.state;
    loadingDownloadBtnClipResult[index] = true;
    this.setState({ loadingDownloadBtnClipResult });

    const stopLoadingBtn = () => {
      loadingDownloadBtnClipResult[index] = false;
      this.setState({ loadingDownloadBtnClipResult });
    };
    const nameDownloadVideo = `${campaign.label}-${nameClip}-${index}`;
    getDownloadVideo(downloadUrl, nameDownloadVideo, stopLoadingBtn);
  };

  handleWordClick = (tIndex, iIndex, wIndex) => () => {
    let { videos, transcriptions, selectedWords, cacheIndexVideoOnSave } = this.state;
    let word = JSON.parse(transcriptions[tIndex][iIndex].words[wIndex]);

    let index = selectedWords.findIndex(
      (item) => item.tIndex === tIndex && item.iIndex === iIndex && item.wIndex === wIndex,
    );
    if (index === -1) {
      selectedWords.push({
        tIndex,
        iIndex,
        wIndex,
        word,
      });
    } else {
      selectedWords.splice(index, 1);
    }

    let clips = [];
    videos.forEach((video, index) => {
      if (video.selected) {
        let words = selectedWords.filter((word) => word.tIndex === index);
        if (words.length) {
          words = words.sort(this.wordComparator);
          clips.push({
            index,
            words,
          });
        }
      }
    });
    if (
      cacheIndexVideoOnSave.includes(tIndex) &&
      (!selectedWords.length || !selectedWords.includes((s) => s.tIndex !== tIndex))
    ) {
      this.setState({ selectedWords, clips, clipResult: [] });
    } else {
      this.setState({ selectedWords, clips });
    }
  };

  isWordSelected = (tIndex, iIndex, wIndex) => {
    let { selectedWords } = this.state;
    let index = selectedWords.findIndex(
      (item) => item.tIndex === tIndex && item.iIndex === iIndex && item.wIndex === wIndex,
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const clips = reorder(this.state.clips, result.source.index, result.destination.index);

    this.setState({
      clips,
    });
  };

  handleSearch = () => {
    let { transcriptions, videos } = this.state;
    let keyword = window.prompt('Type a keyword here...');
    if (keyword) {
      let selectedWords = [],
        clips = [];
      keyword = keyword.toLowerCase();
      transcriptions.forEach((transcription, tIndex) => {
        if (transcription === null) return;
        transcription.forEach((item, iIndex) => {
          item.words.forEach((wordStr, wIndex) => {
            let word = JSON.parse(wordStr);
            if (word.word.toLowerCase().includes(keyword)) {
              selectedWords.push({
                tIndex,
                iIndex,
                wIndex,
                word,
              });
            }
          });
        });
      });
      // updating clips
      videos.forEach((video, index) => {
        if (video.selected) {
          let words = selectedWords.filter((word) => word.tIndex === index);
          if (words.length) {
            words = words.sort(this.wordComparator);
            clips.push({
              index,
              words,
            });
          }
        }
      });

      this.setState({ selectedWords, clips });
    }
  };

  handleClear = () => {
    this.setState({
      selectedWords: [],
      clips: [],
      clipResult: [],
    });
  };

  handleSave = async () => {
    let { videos, clips } = this.state;

    let selection = [];
    clips.forEach(({ index, words }) => {
      let clipSelection = [];
      for (var i = 0; i < words.length; i++) {
        if (i === 0) {
          clipSelection.push({
            startTime: words[i].word.startTime,
            endTime: words[i].word.endTime,
          });
        } else {
          let isContinuous = false;
          if (
            words[i - 1].iIndex === words[i].iIndex &&
            words[i - 1].wIndex === words[i].wIndex - 1
          ) {
            isContinuous = true;
          }
          if (isContinuous) {
            clipSelection[clipSelection.length - 1].endTime = words[i].word.endTime;
          } else {
            clipSelection.push({
              startTime: words[i].word.startTime,
              endTime: words[i].word.endTime,
            });
          }
        }
      }
      selection.push({
        index,
        clips: clipSelection,
      });
    });

    if (!selection.length) {
      alert('Please select at least one word.');
      return;
    }

    this.context.showLoading();
    try {
      let videoGsUrls = selection.map(({ index }) => videos[index].gsUrl);

      // let videoGsUrl = 'gs://social-lens-3a3d5.appspot.com/media/1553271370-ec0df78d-67af-4882-99b0-23fce60b2beb';
      let { data } = await TranscriptionController.clip(videoGsUrls, selection);
      const cacheIndexVideoOnSave = selection.map((s) => s.index);
      this.setState({
        clipResult: data,
        cacheIndexVideoOnSave,
      });
      this.context.hideLoading();
    } catch (error) {
      this.context.hideLoading();
      alert(error.message);
    }
  };

  renderTableTranscription = (table) => {
    const { videos } = this.state;
    return table.map((item, index) => {
      if (!item || !item.length) {
        return null;
      }
      return (
        <div key={index} className={styles.transcriptionTable}>
          <h3>{videos[index].title}</h3>
          <table>
            <tbody>{item}</tbody>
          </table>
        </div>
      );
    });
  };

  render() {
    let {
      transcriptions,
      videos,
      selectedWords,
      clips,
      clipResult,
      loadingDownloadBtn,
      loadingDownloadBtnClipResult,
    } = this.state;
    let table = [];
    let colNum = 10;
    transcriptions.forEach((transcription, tIndex) => {
      table[tIndex] = [];
      transcription &&
        transcription.forEach((item, iIndex) => {
          const words = item.words;
          const len = words.length;
          const rows = Math.ceil(len / colNum);

          for (let y = 0; y < rows; y++) {
            let row = [];
            for (let x = 0; x < colNum; x++) {
              const index = x + y * colNum;

              if (index < len) {
                let word = JSON.parse(words[index]);
                const start_time = (
                  (word.startTime.seconds ? parseInt(word.startTime.seconds) : 0) +
                  (word.startTime.nanos ? word.startTime.nanos : 0) * 1e-9
                ).toFixed(2);
                const end_time = (
                  (word.endTime.seconds ? parseInt(word.endTime.seconds) : 0) +
                  (word.endTime.nanos ? word.endTime.nanos : 0) * 1e-9
                ).toFixed(2);

                row.push(
                  this.isWordSelected(tIndex, iIndex, index) ? (
                    <td
                      key={`${tIndex}-${iIndex}-${index}`}
                      onClick={this.handleWordClick(tIndex, iIndex, index)}
                      className={styles.selectedword}
                    >
                      {word.word}
                      <div className={styles.time}>
                        <span>{start_time}</span>
                        <span>{end_time}</span>
                      </div>
                    </td>
                  ) : (
                    <td
                      key={`${tIndex}-${iIndex}-${index}`}
                      onClick={this.handleWordClick(tIndex, iIndex, index)}
                    >
                      {word.word}
                      <div className={styles.time}>
                        <span>{start_time}</span>
                        <span>{end_time}</span>
                      </div>
                    </td>
                  ),
                );
              } else {
                row.push(<td key={`${tIndex}-${iIndex}-${index}`} />);
              }
            }
            table[tIndex].push(<tr key={`${tIndex}-${iIndex}-${y}`}>{row}</tr>);
          }
        });
    });

    let isVideoSelected = videos.filter((video) => video.selected).length > 0;
    const isSelectedSignatureQuestion =
      this.state.analysis.value === 1 &&
      this.state.question &&
      this.state.question.questionType === QuestionType.SIGNATURE_QUESTION;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <div className={styles.basicTableContainer}>
          <table className={styles.basicTable}>
            <tbody>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Client</span>
                    <div className={styles.select}>
                      <Select
                        styles={selectStyles}
                        value={this.state.client}
                        onChange={this.handleSelectChange('client')}
                        options={this.state.clientList}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Campaign</span>
                    <div className={styles.select}>
                      <Select
                        styles={selectStyles}
                        value={this.state.campaign}
                        onChange={this.handleSelectChange('campaign')}
                        options={this.state.campaignList}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Analysis</span>
                    <div className={styles.select}>
                      <Select
                        styles={selectStyles}
                        value={this.state.analysis}
                        onChange={this.handleSelectChange('analysis')}
                        options={this.state.analysisList}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="100">
                  {this.state.analysis.value === 0 ? ( // participant
                    <div className={styles.inputItem}>
                      <span>Participant</span>
                      <div className={styles.select}>
                        <Select
                          styles={selectStyles}
                          value={this.state.participant}
                          onChange={this.handleSelectChange('participant')}
                          options={this.state.participantList}
                        />
                      </div>
                    </div>
                  ) : (
                    // question
                    <div className={styles.inputItem}>
                      <span>Question</span>
                      <div className={styles.select}>
                        <Select
                          styles={selectStyles}
                          value={this.state.question}
                          onChange={this.handleSelectChange('question')}
                          options={this.state.questionList}
                        />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {videos.length > 0 ? (
          <div className={styles.videoTableContainer}>
            {isSelectedSignatureQuestion ? (
              videos.length === 1 ? (
                <h2>Signature</h2>
              ) : (
                <h2>Signatures</h2>
              )
            ) : (
              <h2>Videos</h2>
            )}
            <table>
              <thead>
                <tr>
                  <th />
                  <th> Preview </th>
                  <th> Transcription Status </th>
                  <th> Name </th>
                </tr>
              </thead>
              <tbody>
                {this.state.videos.map(
                  (item, index) =>
                    item &&
                    item.downloadUrl && (
                      <tr key={index}>
                        <td className={styles.select}>
                          <input
                            type="checkbox"
                            name="select"
                            disabled={
                              item.transcriptionStatus === 'progress' ||
                              (item.type && item.type.includes('image'))
                            }
                            value={`select${index}`}
                            checked={item.selected}
                            onChange={this.handleSelectVideo(index)}
                          />
                        </td>
                        <td>
                          {item.type && item.type.includes('image') ? (
                            <img
                              className={styles.previewImage}
                              alt="user_image"
                              src={item.downloadUrl}
                            />
                          ) : (
                            <video
                              controlsList="nodownload"
                              width="300"
                              className={styles.preview}
                              src={item.downloadUrl}
                              controls
                            />
                          )}
                        </td>
                        {item.questionType === QuestionType.SIGNATURE_QUESTION ? (
                          <td>Signature</td>
                        ) : item.type && item.type.includes('image') ? (
                          <td>Image</td>
                        ) : item.transcriptionStatus === 'progress' ? (
                          <td className={styles.progress}>
                            <div className={styles.action}>
                              Progress
                              <Button
                                type="primary"
                                loading={loadingDownloadBtn[index + 1]}
                                onClick={() =>
                                  this.downloadVideo(item.downloadUrl, item.userName, index + 1)
                                }
                              >
                                Download
                              </Button>
                            </div>
                          </td>
                        ) : (
                          <td className={styles.completed}>
                            <div className={styles.action}>
                              Completed
                              <Button
                                type="primary"
                                loading={loadingDownloadBtn[index + 1]}
                                onClick={() =>
                                  this.downloadVideo(item.downloadUrl, item.userName, index + 1)
                                }
                              >
                                Download
                              </Button>
                            </div>
                          </td>
                        )}
                        <td>
                          <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                            {item.title}
                          </Paragraph>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.videoTableContainer}>
            {isSelectedSignatureQuestion ? <h2>No Signatures</h2> : <h2>No Videos</h2>}
          </div>
        )}
        {isVideoSelected && (
          <div className={styles.transcriptionContainer}>
            <h2>Transcriptions</h2>
            {this.renderTableTranscription(table)}
          </div>
        )}
        <div className={styles.btnGroup}>
          {isVideoSelected > 0 && (
            <div className={styles.btnSearch} onClick={this.handleSearch}>
              Search
            </div>
          )}
          {selectedWords.length > 0 && (
            <div className={styles.btnClear} onClick={this.handleClear}>
              Clear
            </div>
          )}
        </div>
        {selectedWords.length > 0 && (
          <div className={styles.clipListContainer}>
            <h2>Clips</h2>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.clipList}
                  >
                    {clips.map((item, index) => {
                      return (
                        <Draggable
                          key={`${item.index}`}
                          draggableId={`${item.index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={cn(
                                styles.clipListItem,
                                snapshot.isDragging && styles.clipListItemDragging,
                              )}
                            >
                              {`${item.index + 1}. ${item.words
                                .map((word) => word.word.word)
                                .join(' ')}`}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {selectedWords.length > 0 && (
          <div className={styles.btnGroup}>
            <div className={styles.btnSave} onClick={this.handleSave}>
              Save
            </div>
          </div>
        )}
        {clipResult.length > 0 && (
          <div className={styles.clipResultContainer}>
            {clipResult.map((video, index) => {
              if (index === clipResult.length - 1) {
                return (
                  <div key={index} className={styles.clipResultItem}>
                    <video
                      controlsList="nodownload"
                      className={styles.clipVideo}
                      src={video.downloadUrl}
                      controls
                    />
                    <div className={styles.action}>
                      <span>Merged Result</span>
                      <Button
                        type="primary"
                        loading={loadingDownloadBtnClipResult[index]}
                        onClick={() =>
                          this.downloadVideoClipResult(video.downloadUrl, 'Merged Result', index)
                        }
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                );
              }
              return (
                <div key={index} className={styles.clipResultItem}>
                  <video
                    controlsList="nodownload"
                    className={styles.clipVideo}
                    src={video.downloadUrl}
                    controls
                  />
                  <div className={styles.action}>
                    <span>{`Clip ${clips[index].index + 1}`}</span>
                    <Button
                      type="primary"
                      loading={loadingDownloadBtnClipResult[index]}
                      onClick={() =>
                        this.downloadVideoClipResult(
                          video.downloadUrl,
                          `Clip ${clips[index].index + 1}`,
                          index,
                        )
                      }
                    >
                      Download
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

CampaignAnalyticsContainer.contextType = AppContext;

CampaignAnalyticsContainer.propTypes = {
  history: PropTypes.object,
};

export default CampaignAnalyticsContainer;
