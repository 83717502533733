import axios from 'axios';
import { forEach, clone, map } from 'lodash';
export const transcribe = async (gs_url) => {
  let API_URL = `${process.env.REACT_APP_API_URL}analytics/transcribe`;

  let { data } = await axios.post(API_URL, {
    url: gs_url,
  });
  return data;
};

export const clip = async (videos, selections) => {
  let API_URL = `${process.env.REACT_APP_API_URL}analytics/clip`;
  const timeout = process.env.REACT_APP_MAX_TIME_AWAIT || 10 * 60 * 1000; // 10m

  let { data } = await axios.post(
    API_URL,
    {
      videos,
      selections,
    },
    { timeout },
  );
  return data;
};

export const createClip = async (videos, selections) => {
  let API_URL = `${process.env.REACT_APP_API_URL}analytics/clip/create`;
  const timeout = process.env.REACT_APP_MAX_TIME_AWAIT || 10 * 60 * 1000; // 10m

  let { data } = await axios.post(
    API_URL,
    {
      videos,
      selections,
    },
    { timeout },
  );
  return data;
};

export const mergeClip = async (videos) => {
  let API_URL = `${process.env.REACT_APP_API_URL}analytics/clip/merge`;
  const timeout = process.env.REACT_APP_MAX_TIME_AWAIT || 10 * 60 * 1000; // 10m

  let { data } = await axios.post(
    API_URL,
    {
      videos,
    },
    { timeout },
  );
  return data;
};

export const getTranscriptionById = async (transcriptionId) => {
  let API_URL = `${process.env.REACT_APP_API_URL}analytics/transcription/${transcriptionId}`;
  let { data } = await axios.get(API_URL);
  return data ? data.data : null;
};

export const getTranscriptionByIds = async (ids) => {
  let API_URL = `${process.env.REACT_APP_API_URL}analytics/transcription`;
  let { data } = await axios.post(API_URL, {
    ids,
  });
  if (typeof data === 'object') {
    return map(data, (value, key) => {
      return {
        transcriptionId: key,
        words: (value && value.data) || [],
      };
    });
  }
  return [];
};

export const getWordPlanText = (listData) => {
  if (!listData) return '';
  let text = '';
  forEach(listData, (data) => {
    if (data && data.words) {
      forEach(data.words, (word) => {
        const obj = JSON.parse(word);
        text += `${obj.word || ''} `;
      });
    }
  });
  return text;
};

export const getTranscriptionBySummaryReport = async (userSummary) => {
  const transcriptionIds = [];
  const userSummaryClone = clone(userSummary);
  if (Array.isArray(userSummaryClone)) {
    userSummaryClone.forEach((item) => {
      if (item.answer && item.answer.medias) {
        forEach(item.answer.medias, (media) => {
          media.forEach((mediaItem) => {
            if (
              mediaItem &&
              mediaItem.transcriptionStatus === 'complete' &&
              mediaItem.transcriptionId
            ) {
              transcriptionIds.push(mediaItem.transcriptionId);
            }
          });
        });
      }
    });
  }

  const allTranscription = await getTranscriptionByIds(transcriptionIds);

  if (Array.isArray(userSummaryClone)) {
    userSummaryClone.forEach((item) => {
      if (item.answer && item.answer.medias) {
        forEach(item.answer.medias, (media) => {
          media.forEach((mediaItem) => {
            if (
              mediaItem &&
              mediaItem.transcriptionStatus === 'complete' &&
              mediaItem.transcriptionId
            ) {
              const found = allTranscription.find(
                (f) => f.transcriptionId === mediaItem.transcriptionId,
              );
              if (found) {
                mediaItem.words = found.words;
                mediaItem.wordsStr = getWordPlanText(found.words);
              }
            }
          });
        });
      }
    });
  }
  return userSummaryClone;
};
