import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './SummaryAnswer.module.scss';
import { useSelector } from 'react-redux';
import CampaignType from '../../../../constants/campaignType';
export const SummaryAnswer = React.memo(({ answer = '', onFeedback = () => {} }) => {
  const campaign = useSelector((state) => state.report.campaign);

  return (
    <div className={styles.userSummaryAnswer}>
      <p>{answer}</p>
      {campaign && campaign.type !== CampaignType.OPEN && (
        <Button
          style={{
            width: '150px',
          }}
          type="primary"
          onClick={onFeedback}
        >
          Give Feedback
        </Button>
      )}
    </div>
  );
});

SummaryAnswer.propTypes = {
  answer: PropTypes.string,
  onFeedback: PropTypes.func,
};
