import PropTypes from 'prop-types';
import React from 'react';
import { MediaItem } from './MediaItem';
import styles from './SummaryMedia.module.scss';

export const SummaryMedia = React.memo(({ medias = null, onDownloadVideo = () => {} }) => {
  if (!medias) {
    return 'N/A';
  }
  return (
    <div className={styles.userSummaryMedia}>
      {medias.map((media, index) => {
        if (media.type && media.type.includes('image')) {
          return (
            <img
              key={index}
              className={styles.userMedia}
              alt="user_image"
              src={media.downloadUrl}
            />
          );
        } else {
          return (
            <div key={index} className={styles.mediaWraper}>
              {media.createAt && <p className={styles.dateTime}>{media.createAt}</p>}
              <MediaItem media={media} onDownloadVideo={onDownloadVideo} />
            </div>
          );
        }
      })}
    </div>
  );
});

SummaryMedia.propTypes = {
  medias: PropTypes.array,
  onDownloadVideo: PropTypes.func,
};
