import React, { useState, useEffect, useMemo } from 'react';
import Webcam from 'react-webcam';
import { Button, Modal } from 'antd';
import styles from './VideoCapture.module.scss';
import PropTypes from 'prop-types';
import MediaPreviewItem from './MediaPreviewItem';
import * as _ from 'lodash';
import { css, cx } from 'emotion';

const modalSelectWebcam = css`
  .ant-modal-content {
    .ant-modal-close {
      right: -12px;
      top: -12px;
    }
  }
`;

const videoConstraints = {
  width: 750,
  height: 500,
  facingMode: 'user',
};
let interval;
const TimeInterval = (props) => {
  const { isStart } = props;
  const [time, setTime] = useState(0);
  const intervalTime = 1000;

  const timeStr = useMemo(() => {
    let cache = _.clone(time);
    const house = _.floor(time / (60 * 60 * 1000));
    cache = cache - house * (60 * 60 * 1000);
    const min = _.floor(cache / (60 * 1000));
    cache = cache - min * (60 * 1000);
    const sec = _.floor(cache / 1000);
    return `${_.padStart(house, 2, '0')}:${_.padStart(min, 2, '0')}:${_.padStart(sec, 2, '0')}`;
  }, [time]);

  useEffect(() => {
    if (interval) clearTimeout(interval);
    if (isStart) {
      interval = setTimeout(() => {
        setTime(time + intervalTime);
      }, 1000);
    } else {
      setTime(0);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [time, isStart]);
  return <span>{timeStr}</span>;
};

TimeInterval.propTypes = {
  isStart: PropTypes.bool,
};

const VideoCapture = (props) => {
  const { isShow, onClose } = props;
  const [isPermission, setPermission] = React.useState(null);
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [browserNotSupport, setBrowserNotSupport] = useState(null);
  const [isBrowser, setBrowser] = useState({
    is_safari: false,
    is_ie: false,
  });
  // const capturePhoto = () => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setRecordedChunks([...recordedChunks, imageSrc]);
  // };

  const handleStartCaptureClick = () => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm;codecs=vp8,opus',
    });
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current.start();
  };

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => {
          return prev.concat(data);
        });
      }
    },
    [setRecordedChunks],
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, []);

  const captureDone = async () => {
    let data = [];
    if (recordedChunks.length) {
      data = data.concat(recordedChunks);
    }
    onClose(data);
    setRecordedChunks([]);
  };

  const mediaError = (error) => {
    setPermission(false);
  };

  const mediaSuccess = () => {
    setPermission(true);
  };

  const removeMedia = (index) => {
    const record = [...recordedChunks];
    record.splice(index, 1);
    setRecordedChunks(record);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const is_chrome = /Chrome/i.test(userAgent);
    const is_safari = /Safari/i.test(userAgent);
    const is_ie = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
    if ((is_safari && !is_chrome) || is_ie) {
      setBrowserNotSupport(true);
      setBrowser({ is_safari, is_ie });
    } else {
      setBrowserNotSupport(false);
    }
  }, []);

  return (
    <Modal
      visible={isShow}
      okButtonProps={{
        disabled: capturing,
      }}
      cancelButtonProps={{
        disabled: capturing,
      }}
      onCancel={() => onClose(null)}
      onOk={() => captureDone()}
      centered
      className={cx(modalSelectWebcam)}
    >
      <div className={styles.wrapper}>
        <div className={styles.webcam}>
          {isShow && (
            <Webcam
              onUserMedia={mediaSuccess}
              onUserMediaError={mediaError}
              ref={webcamRef}
              videoConstraints={videoConstraints}
            />
          )}
          <span>
            Time: <TimeInterval isStart={capturing} />
          </span>
          {isPermission === null ? (
            <div>Await permission</div>
          ) : !isPermission ? (
            <div>Permission denied</div>
          ) : null}
        </div>

        {!isPermission ? null : (
          <div className={styles.capture}>
            <div className={styles.actionCapture}>
              {capturing ? (
                <Button onClick={handleStopCaptureClick}>Stop Capture</Button>
              ) : (
                <Button onClick={handleStartCaptureClick} disabled={browserNotSupport}>
                  Capture Video
                </Button>
              )}
              {/* <Button className={styles.capturePhoto} onClick={() => capturePhoto()}>
                Capture Photo
              </Button> */}
            </div>
            {browserNotSupport ? (
              <>
                {isBrowser.is_safari ? (
                  <span>
                    <b>
                      Warning: Safari does not currently support webcams. Use the study link on your
                      cell phone or use an up-to-date version of Chrome for the best experience.
                    </b>
                  </span>
                ) : null}
                {isBrowser.is_ie ? (
                  <span>
                    <b>
                      Warning: Internet Explorer does not currently support webcams. Use the study
                      link on your cell phone or use an up-to-date version of Chrome for the best
                      experience.
                    </b>
                  </span>
                ) : null}
              </>
            ) : null}
          </div>
        )}
        {recordedChunks.map((m, index) => (
          <MediaPreviewItem key={index} media={m} removeQuestionMedia={() => removeMedia(index)} />
        ))}
      </div>
    </Modal>
  );
};

VideoCapture.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
};
export default VideoCapture;
