import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

import { AppContext } from '../../../components';
import { ReportController, ClientController } from '../../../controllers';
import CampaignType, { getCampaignType } from '../../../constants/campaignType';
import { BackButton } from '../../../components/Button';

import 'rc-checkbox/assets/index.css';
import styles from './ReportContainer.module.scss';
import { setParams, getParamsUrl } from '../../../utils/url';
import QuestionType from '../../../constants/questionType';

const headers = [
  { label: 'Campaign', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Completions', key: 'completion' },
  { label: 'Organization', key: 'company_name' },
  { label: 'Participants Group', key: 'group_name' },
  { label: 'Division / Location ', key: 'division' },
];

class ReportContainer extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      'Select',
      'Campaign',
      'Type',
      'Completions',
      'Organization',
      'Participants Group',
      'Division / Location',
      'Has Signature',
    ];
    const searchParams = getParamsUrl();
    this.state = {
      orgSelected: {
        label: 'all',
        value: 0,
      },
      loading: true,
      keyword: searchParams && searchParams.keyword ? searchParams.keyword : '',
    };
  }

  handleOrgChange = async (orgSelected) => {
    // Set Org by url params
    const newParams = setParams({ orgSelectedValue: orgSelected.value });
    this.props.history.replace({
      search: `?${newParams}`,
    });

    await this.setState({ orgSelected });
    await this.getCampaigns();
    const filterCampaigns = this.filterByOrg(this.state.campaigns);
    this.setState({ campaigns: filterCampaigns });
    await this.makeCsvData();
  };

  filterByOrg = (campaigns) => {
    const { orgSelected } = this.state;
    if (orgSelected.value !== 0) {
      const filterCampaigns = [];
      campaigns &&
        campaigns.length !== 0 &&
        campaigns.map(async (campaign) => {
          if (campaign.client_id === orgSelected.value) {
            filterCampaigns.push(campaign);
          }
        });
      return filterCampaigns;
    } else {
      return campaigns;
    }
  };

  async componentDidMount() {
    await this.setState({ loading: true });
    this.context.showLoading();
    let orgs = await ClientController.getClients(
      (this.context.manager && this.context.manager.admin) || false,
    );
    let orgList = [
      {
        label: 'all',
        value: 0,
      },
    ];

    if (orgs && orgs.length > 0) {
      await orgs.forEach((item) => {
        let org = {
          label: item.org,
          value: item.id,
        };
        orgList.push(org);
      });
    }

    // Add filter org
    const searchParams = getParamsUrl();
    if (searchParams && searchParams.orgSelectedValue) {
      const found = orgList.find((f) => f.value === searchParams.orgSelectedValue);
      if (found) {
        await this.setState({ orgSelected: found });
      }
    }
    await this.setState({ orgList });

    await this.getCampaigns();
    this.context.hideLoading();
    this.setState({ loading: false });
  }

  sortedCampaignsByDate(myArray) {
    return _.orderBy(myArray, [(obj) => new Date(obj.updatedAt)], ['desc']);
  }

  async getCampaigns() {
    const { keyword } = this.state;
    this.context.showLoading();
    await this.setState({ loading: true });
    let campaigns = await ReportController.getCampaigns(
      (this.context.manager && this.context.manager.admin) || false,
    );
    campaigns = campaigns.filter(
      (campaign) => campaign.type !== CampaignType.CONVERSATION && campaign.status,
    );

    if (keyword.length > 0) {
      campaigns = campaigns.filter((campaign) =>
        campaign.name.toLowerCase().includes(this.state.keyword.toLowerCase()),
      );
    }
    campaigns = this.filterByOrg(campaigns);
    await this.setState({ campaigns: this.sortedCampaignsByDate(campaigns) });
    await this.makeCsvData();
    this.context.hideLoading();
    await this.setState({ loading: false });
  }

  async select(selectedData) {
    let { campaigns } = this.state;

    await campaigns.forEach((item) => {
      if (item.id === selectedData.id) {
        item.checked = !selectedData.checked;
      }
    });

    await this.setState({ campaigns });

    this.makeCsvData();
  }

  async makeCsvData() {
    let csvData = [];

    this.state.campaigns &&
      this.state.campaigns.length !== 0 &&
      this.state.campaigns.forEach((campaign) => {
        if (campaign.checked) {
          const totalAnswerComplete =
            (campaign.answers && campaign.answers.filter((f) => !!f).length) || 0;
          const campaignCompletion =
            campaign.completion || (campaign.answers && campaign.answers.length) || 0;

          let data = {
            name: campaign.name,
            completion: `${totalAnswerComplete} out of ${campaignCompletion}`,
            company_name: campaign.company_name,
            group_name: campaign.participant_group_name,
            division: campaign.division,
          };
          csvData.push(data);
        }
      });

    this.setState({ csvData });
  }

  hasSignatureQuestion(campaignQuestions = []) {
    return campaignQuestions.some((item) => item.type === QuestionType.SIGNATURE_QUESTION);
  }

  createRow() {
    const { campaigns } = this.state;

    let children = [];
    for (var i = 0; i < campaigns.length; i++) {
      let item = campaigns[i];
      let count = 0;
      campaigns[i].answers.forEach((answerData) => {
        if (answerData) {
          count++;
        }
      });

      const hasSignatureQuestion = this.hasSignatureQuestion(item.questions);

      children.push(
        _.isEmpty(item) ? null : (
          <tr key={item.id}>
            <td>
              <Checkbox checked={item.checked} onChange={() => this.select(item)} />
            </td>
            <td
              onClick={() => {
                this.goCampaignReport(item.id);
              }}
            >
              <span className={styles.campaign}>{item.name}</span>
            </td>
            <td>{getCampaignType(item.type)}</td>
            <td>
              {item.participant_group && item.participant_group.number_of_participants
                ? `${count} out of ${item.participant_group.number_of_participants}`
                : `${count}`}
            </td>
            <td>{item.company_name}</td>
            <td>{item.participant_group.name}</td>
            <td>{item.division}</td>
            <td>{hasSignatureQuestion ? 'Yes' : 'No'}</td>
          </tr>
        ),
      );
    }

    return children;
  }

  goCampaignReport(id) {
    window.open(`/report/${id}`, '_blank');
  }

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      // enter pressed
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      await this.getCampaigns();
    }
  };

  searchInputChanged = (e) => {
    this.setState(
      {
        keyword: e.target.value,
      },
      async () => {
        if (!this.state.keyword) {
          const newParams = setParams({ keyword: undefined });
          this.props.history.replace({
            search: `?${newParams}`,
          });
          await this.getCampaigns();
        }
      },
    );
  };
  render() {
    const { orgSelected, orgList, campaigns, loading, csvData } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchbar}>
            <i className={`fa fa-search ${styles.iconSearch}`} />
            <input
              type="text"
              placeholder="Type here and press enter to get the result..."
              value={this.state.keyword}
              onChange={this.searchInputChanged}
              onKeyPress={this.searchInputKeyPressed}
            />
          </div>
          <BackButton history={this.props.history} />
        </div>
        <div className={styles.selector}>
          <span>Organization:</span>
          <div className={styles.select}>
            <Select value={orgSelected} onChange={this.handleOrgChange} options={orgList} />
          </div>
        </div>
        {!loading && campaigns.length ? (
          <table>
            <thead>
              <tr className={styles.header}>
                {this.columns.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>{this.createRow()}</tbody>
          </table>
        ) : (
          <h3>No Result</h3>
        )}

        <div>
          {!loading && csvData && csvData.length !== 0 && (
            <div className={styles.button}>
              <CSVLink
                data={csvData}
                headers={headers}
                style={{
                  color: '#ffffff',
                  padding: 8,
                  backgroundColor: '#03a9f4',
                  borderRadius: 4,
                  textDecoration: 'none',
                }}
              >
                Download data file
              </CSVLink>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReportContainer.contextType = AppContext;

ReportContainer.propTypes = {
  history: PropTypes.object,
};

export default ReportContainer;
