import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, Checkbox, Divider } from 'antd';
import { useState } from 'react';
import {
  ageList,
  genderList,
  educationList,
  relationshipList,
  ethnicityList,
  childrenList,
} from './filterConst';
import styles from './SummaryReportFilter.module.scss';
import { remove } from 'lodash';

export const SummaryReportFilter = React.memo((props) => {
  const { onFilter } = props;
  const [showModal, setShowModal] = useState(false);
  const [filterData, setFilterData] = useState({
    ageFilter: [],
    genderFilter: [],
    educationFilter: [],
    relationshipFilter: [],
    ethnicityFilter: [],
    childrenFilter: [],
  });
  const onSetFilterAge = (index, key) => (event) => {
    const dataByKey = filterData[key];
    if (event.target.checked) {
      dataByKey.push(index);
    } else {
      remove(dataByKey, (v) => v === index);
    }
    setFilterData((e) => ({
      ...e,
      [key]: dataByKey,
    }));
  };

  const onSave = () => {
    onFilter(filterData);
    setShowModal(false);
  };
  return (
    <section className={styles.wrapper}>
      <Button
        type="primary"
        size="large"
        className={styles.button}
        onClick={() => setShowModal(true)}
      >
        <i className="fa fa-filter" aria-hidden="true"></i>
        Filter
      </Button>
      <Modal
        className={styles.wrapperModal}
        visible={showModal}
        footer={null}
        onCancel={() => onSave()}
        width={700}
      >
        <section className={styles.wrapperLayout}>
          <section className={styles.checkboxSection}>
            <h3>Age</h3>
            {ageList.map((f, index) => (
              <Checkbox
                className={styles.checkbox}
                onChange={onSetFilterAge(index, 'ageFilter')}
                key={index}
              >
                <span className={styles.name}>{f.name}</span>
              </Checkbox>
            ))}
          </section>
          <section className={styles.checkboxSection}>
            <h3>Gender</h3>
            {genderList.map((f, index) => (
              <Checkbox
                className={styles.checkbox}
                onChange={onSetFilterAge(index, 'genderFilter')}
                key={index}
              >
                <span className={styles.name}>{f.label}</span>
              </Checkbox>
            ))}
          </section>

          <section className={styles.checkboxSection}>
            <h3>Education</h3>
            {educationList.map((f, index) => (
              <Checkbox
                className={styles.checkbox}
                onChange={onSetFilterAge(index, 'educationFilter')}
                key={index}
              >
                <span className={styles.name}>{f.label}</span>
              </Checkbox>
            ))}
          </section>

          <section className={styles.checkboxSection}>
            <h3>Relationship</h3>
            {relationshipList.map((f, index) => (
              <Checkbox
                className={styles.checkbox}
                onChange={onSetFilterAge(index, 'relationshipFilter')}
                key={index}
              >
                <span className={styles.name}>{f.label}</span>
              </Checkbox>
            ))}
          </section>

          <section className={styles.checkboxSection}>
            <h3>Ethnicity</h3>
            {ethnicityList.map((f, index) => (
              <Checkbox
                className={styles.checkbox}
                onChange={onSetFilterAge(index, 'ethnicityFilter')}
                key={index}
              >
                <span className={styles.name}>{f.label}</span>
              </Checkbox>
            ))}
          </section>

          <section className={styles.checkboxSection}>
            <h3>Children</h3>
            {childrenList.map((f, index) => (
              <Checkbox
                className={styles.checkbox}
                onChange={onSetFilterAge(index, 'childrenFilter')}
                key={index}
              >
                <span className={styles.name}>{f.label}</span>
              </Checkbox>
            ))}
          </section>
        </section>
        <Divider />
        <section className={styles.footer}>
          <Button type="primary" ghost onClick={() => onSave()}>
            Save
          </Button>
        </section>
      </Modal>
    </section>
  );
});

SummaryReportFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};
