import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';
import { BackButton } from '../../../components/Button';

import { AppContext } from '../../../components';
import { CampaignController } from '../../../controllers';
import CampaignType from '../../../constants/campaignType';

import styles from './ChatListContainer.module.scss';
import { setParams, getParamsUrl } from '../../../utils/url';
import { get } from 'lodash';

const filterBy = {
  name: 'name',
  org: 'org',
};

class ChatListContainer extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      data: [],
      filter: searchParams && searchParams.filter ? searchParams.filter : filterBy.name,
      keyword: searchParams && searchParams.keyword ? searchParams.keyword : '',
      loading: false,
      table: {
        page: searchParams && searchParams.page ? +searchParams.page : 1,
        size: searchParams && searchParams.size ? +searchParams.size : 10,
      },
    };

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'name'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        align: 'center',
        sorter: (a, b) => (a.client.org > b.client.org ? 1 : -1),
        render: (text, record, index) => record.client.org,
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'organization'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Participants',
        dataIndex: 'participants',
        align: 'center',
        render: (text, record, index) =>
          get(record, 'participant_group.participant_list.length', ''),
      },
      {
        title: 'Division / Location',
        dataIndex: 'division',
        align: 'center',
        sorter: (a, b) => {
          const aDivision = get(a, 'participant_group.division', '');
          const bDivision = get(b, 'participant_group.division', '');
          return aDivision.toLowerCase() > bDivision.toLowerCase() ? 1 : -1;
        },
        render: (text, record, index) => get(record, 'participant_group.division', ''),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'division'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        align: 'center',
        sorter: (a, b) => {
          const aGroup = get(a, 'participant_group.name', '');
          const bGroup = get(b, 'participant_group.name', '');
          return aGroup.toLowerCase() > bGroup.toLowerCase() ? 1 : -1;
        },
        render: (text, record, index) => get(record, 'participant_group.name', ''),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'group'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (text, record, index) => (
          <Switch onChange={this.statusChanged(record)} checked={record.status} />
        ),
      },
    ];
  }

  async componentDidMount() {
    await this.reload();
  }

  reload = async () => {
    this.setState({ loading: true });

    let data = await CampaignController.getCampaigns(
      (this.context.manager && this.context.manager.admin) || false,
    );
    data = data.filter(
      (campaign) =>
        campaign.type === CampaignType.CONVERSATION ||
        campaign.type === CampaignType.CHAT_GROUP ||
        campaign.type === CampaignType.CHAT_11,
    );
    if (this.state.filter === 'name') {
      data = data.filter((campaign) =>
        campaign.name.toLowerCase().includes(this.state.keyword.toLowerCase()),
      );
    } else {
      data = data.filter((campaign) =>
        campaign.client.org.toLowerCase().includes(this.state.keyword.toLowerCase()),
      );
    }
    this.setState({
      data,
      loading: false,
    });
  };

  searchfilterChanged = (filter) => () => {
    const newParams = setParams({ filter });
    this.props.history.replace({
      search: `?${newParams}`,
    });
    this.setState({
      filter,
    });
  };

  campaignClicked = (campaignId) => {
    this.props.history.push(`/chat/${campaignId}`);
  };

  statusChanged = (campaign) => async (checked, event) => {
    event.stopPropagation();
    if (campaign.status) {
      if (window.confirm('Do you want to deactivate this campaign?')) {
        await CampaignController.deactivateCampaign(campaign.id);
        await this.reload();
      }
    } else {
      if (window.confirm('Do you want to activate this campaign?')) {
        await CampaignController.activateCampaign(campaign.id);
        await this.reload();
      }
    }
  };

  searchInputChanged = (e) => {
    this.setState(
      {
        keyword: e.target.value,
      },
      async () => {
        if (!this.state.keyword) {
          const newParams = setParams({ keyword: undefined });
          this.props.history.replace({
            search: `?${newParams}`,
          });
          await this.reload();
        }
      },
    );
  };

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      // enter pressed
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      await this.reload();
    }
  };

  // goBack = () => {
  //   this.props.history.goBack();
  // };

  tableChange = (pagination, sorter) => {
    const newParams = setParams({
      page: pagination.current,
      size: pagination.pageSize,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
    });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  render() {
    const { data, loading, table } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchContainer}>
            <div className={styles.searchbar}>
              <i className={`fa fa-search ${styles.iconSearch}`} />
              <input
                type="text"
                placeholder="Type here and press enter to get the result..."
                value={this.state.keyword}
                onChange={this.searchInputChanged}
                onKeyPress={this.searchInputKeyPressed}
              />
            </div>
            <BackButton history={this.props.history} />
          </div>
          <div className={styles.searchfilters}>
            <div className={styles.filter} onClick={this.searchfilterChanged('name')}>
              <input
                type="checkbox"
                value="name"
                checked={this.state.filter === 'name'}
                onChange={this.searchfilterChanged('name')}
              />
              Search by name
            </div>
            <div className={styles.filter} onClick={this.searchfilterChanged('org')}>
              <input
                type="checkbox"
                value="org"
                checked={this.state.filter === 'org'}
                onChange={this.searchfilterChanged('org')}
              />
              Search by organization
            </div>
            <div className={styles.moreSpace}></div>
          </div>
        </div>
        <Table
          columns={this.columns}
          dataSource={data}
          rowKey={(item) => item.id}
          bordered
          size="middle"
          onChange={(pagination, filter, sorter) => {
            this.tableChange(pagination, sorter);
          }}
          pagination={{
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            defaultCurrent: table.page,
            defaultPageSize: table.size,
          }}
          onRow={(record) => ({
            onClick: (event) => this.campaignClicked(record.id),
          })}
          loading={loading}
        />
      </div>
    );
  }
}

ChatListContainer.contextType = AppContext;

ChatListContainer.propTypes = {
  history: PropTypes.object,
};
export default ChatListContainer;
