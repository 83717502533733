export const ageList = [
  {
    to: 13,
    name: 'Under 13',
  },
  {
    name: '14 to 18',
    from: 14,
    to: 18,
  },
  {
    name: '19 to 24',
    from: 19,
    to: 24,
  },
  {
    name: '25 to 35',
    from: 25,
    to: 35,
  },
  {
    name: '36 to 40',
    from: 36,
    to: 40,
  },
  {
    name: '41 to 45',
    from: 41,
    to: 45,
  },
  {
    name: '45 to 55',
    from: 45,
    to: 55,
  },
  {
    name: '55+',
    from: 56,
  },
];

export const genderList = [
  {
    label: 'Male',
    value: 0,
  },
  {
    label: 'Female',
    value: 1,
  },
  {
    label: 'Non-binary',
    value: 2,
  },
  {
    label: 'Prefer not to answer',
    value: 3,
  },
];

export const educationList = [
  {
    label: 'No schooling completed',
    value: 0,
  },
  {
    label: 'Nursery school to 8th grade',
    value: 1,
  },
  {
    label: 'Some high school, no diploma',
    value: 2,
  },
  {
    label: 'High school graduate / GED',
    value: 3,
  },
  {
    label: 'Some college credit, no degree',
    value: 4,
  },
  {
    label: 'Trade/technical/vocational training',
    value: 5,
  },
  {
    label: 'Associate degree',
    value: 6,
  },
  {
    label: `Bachelor's degree`,
    value: 7,
  },
  {
    label: `Master's degree`,
    value: 8,
  },
  {
    label: `Professional degree`,
    value: 9,
  },
  {
    label: `Doctorate degree`,
    value: 10,
  },
];

export const childrenList = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: 'More',
    value: 6,
  },
];

export const ethnicityList = [
  {
    label: 'American Indian',
    value: 0,
  },
  {
    label: 'Black',
    value: 1,
  },
  {
    label: 'Asian',
    value: 2,
  },
  {
    label: 'White',
    value: 3,
  },
  {
    label: 'Latino',
    value: 4,
  },
  {
    label: 'Other',
    value: 5,
  },
  {
    label: 'Prefer not to answer',
    value: 6,
  },
];

export const relationshipList = [
  {
    label: 'Single (never married)',
    value: 0,
  },
  {
    label: 'Committed relationship',
    value: 1,
  },
  {
    label: 'Married, or in a domestic partnership',
    value: 2,
  },
  {
    label: 'Widowed',
    value: 3,
  },
  {
    label: 'Divorced',
    value: 4,
  },
  {
    label: 'Separated',
    value: 5,
  },
];
