const CampaignType = {
  STANDARD: 'STANDARD',
  CONVERSATION: 'CONVERSATION',
  APPLICATION: 'APPLICATION',
  OPEN: 'OPEN',
  CHAT_GROUP: 'CHAT_GROUP',
  CHAT_11: 'CHAT_11',
};

export const getCampaignType = (type) => {
  switch (type) {
    case CampaignType.STANDARD:
      return 'STANDARD';
    case CampaignType.CONVERSATION:
      return 'CONVERSATION';
    case CampaignType.APPLICATION:
      return 'APPLICATION';
    case CampaignType.OPEN:
      return 'OPEN';
    case CampaignType.CHAT_GROUP:
      return 'CHAT GROUP';
    case CampaignType.CHAT_11:
      return 'CHAT 1-1';
    default:
      break;
  }
};

export default CampaignType;
