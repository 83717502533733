export const GenderList = ['Male', 'Female', 'Non-binary', 'Prefer not to answer'];

export const EducationList = [
  'No schooling completed',
  'Nursery school to 8th grade',
  'Some high school, no diploma',
  'High school graduate / GED',
  'Some college credit, no degree',
  'Trade/technical/vocational training',
  'Associate degree',
  'Bachelor’s degree',
  'Master’s degree',
  'Professional degree',
  'Doctorate degree',
];

export const RelationshipList = [
  'Single (never married)',
  'Committed relationship',
  'Married, or in a domestic partnership',
  'Widowed',
  'Divorced',
  'Separated',
];

export const ChildrenList = ['0', '1', '2', '3', '4', '5', 'More'];

export const EthnicityList = [
  'American Indian',
  'Black',
  'Asian',
  'White',
  'Latino',
  'Other',
  'Prefer not to answer',
];
