import React from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import uuid from 'uuid/v4';
import { CSVLink } from 'react-csv';
import * as _ from 'lodash';
import { Button, notification } from 'antd';
import { BackButton } from '../../../components/Button';

import { AppContext } from '../../../components';
import { ClientController } from '../../../controllers';

import styles from './ClientEditContainer.module.scss';

class ClientEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: props.match.params.id,
      basic: {
        org: '',
        contact: '',
      },
      groups: [],
      inputs: [],
    };

    this.fileInputs = {};
  }

  async componentDidMount() {
    this.context.showLoading();
    let data = await ClientController.getClientById(this.state.clientId);
    let groups = data.participant_groups.map((group) => {
      let item = { ...group };
      item.participant_list = group.participant_list.map((participant) => [
        participant.name,
        participant.email,
      ]);
      return item;
    });
    let inputs = groups.map((group) => ({ name: '', email: '' }));
    this.setState({
      basic: {
        org: data.org,
        contact: data.contact,
        status: data.status,
      },
      groups,
      inputs,
    });
    this.context.hideLoading();
  }

  randomCode = () => {
    return Math.random().toString(36).substring(2);
  };

  generateNewGroup = () => {
    return {
      id: uuid(),
      name: '',
      division: '',
      participant_code: this.randomCode(),
      number_of_participants: 0,
      participant_list: [],
      newlyAdded: true,
    };
  };

  updateClicked = async () => {
    let isValid = true;
    if (!this.state.basic.org.trim()) {
      isValid = false;
      alert("Organization can't be empty.");
      return;
    }
    if (this.state.groups.length > 1) {
      const isDuplicateParticipantCodeLocal = _(this.state.groups)
        .filter((f) => f.participant_code)
        .groupBy((f) => f.participant_code)
        .pickBy((p) => p.length > 1)
        .keys()
        .value();
      if (isDuplicateParticipantCodeLocal.length > 0) {
        alert('One of your group codes is already taken. Please choose another one.');
        isValid = false;
        return;
      }
    }

    for (let index = 0; index < this.state.groups.length; index++) {
      const group = this.state.groups[index];
      if (!group.name.trim()) {
        alert("The participant group name can't be empty.");
        isValid = false;
        return false;
      }
      if (group.participant_code) {
        if (group.participant_code.length < 5) {
          alert('The participant code must be greater than 5 characters.');
          isValid = false;
          return false;
        }

        const codeIsExist = await ClientController.participantCodeExist(
          group.participant_code,
          group.id,
        );
        if (codeIsExist) {
          alert('The participant code already exists.');
          isValid = false;
          return false;
        }
      }

      if (group.limit_users && typeof group.limit_users === 'number') {
        if (group.limit_users < group.participant_list.length) {
          alert('The user limit must be greater or equal to the participant list.');
          isValid = false;
          return false;
        }
      }
      if (group.limit_users && typeof group.limit_users === 'string') {
        const valueStrValid = group.limit_users.trim();
        const valueNumValid = +valueStrValid;
        if (typeof valueNumValid !== 'number') {
          alert('User Limit must be numbers.');
          isValid = false;
          return false;
        }
        if (valueNumValid < group.participant_list.length) {
          alert('The user limit must be greater or equal to the participant list.');
          isValid = false;
          return false;
        }
      }
    }

    if (!isValid) return;
    this.context.showLoading();
    try {
      await ClientController.updateClient({
        clientId: this.state.clientId,
        basic: this.state.basic,
        groups: this.state.groups,
      });
      this.props.history.goBack();
    } catch (error) {
      alert(error.message);
    }
    this.context.hideLoading();
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  addmoreClicked = () => {
    let { groups, inputs } = this.state;

    let last = groups[groups.length - 1];
    if (!last.name || !last.division || !last.number_of_participants) {
      alert('Please complete the current participant group to add more.');
      return;
    }
    groups.push(this.generateNewGroup());
    inputs.push({ name: '', email: '' });
    this.setState({ groups, inputs });
  };

  handleUpload = (id) => () => {
    this.fileInputs[id].click();
  };

  handleClearAll = (index) => () => {
    let { groups } = this.state;
    groups[index]['participant_list'] = [];
    groups[index]['number_of_participants'] = 0;
    this.setState({ groups });
  };

  handleAdd = (index) => () => {
    let { groups, inputs } = this.state;

    if (
      groups[index].limit_users &&
      +groups[index].limit_users === groups[index].participant_list.length
    ) {
      alert('The number of users has reached the limit.');
      return;
    }

    if (!inputs[index].name.trim()) {
      alert("Name can't be empty.");
      return;
    }
    if (!inputs[index].email.trim()) {
      alert("Email can't be empty.");
      return;
    }
    // eslint-disable-next-line max-len
    const emailRegEx =
      // eslint-disable-next-line max-len
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegEx.test(inputs[index].email.trim())) {
      alert('Email is not valid.');
      return;
    }

    const email = inputs[index].email.trim();
    const found = groups[index].participant_list.find((p) => p[1] === email);
    if (found) {
      alert('Email already exists.');
      return;
    }

    groups[index]['participant_list'].push([inputs[index].name.trim(), inputs[index].email.trim()]);
    groups[index]['number_of_participants'] = groups[index]['participant_list'].length;
    inputs[index] = { name: '', email: '' };

    this.setState({ groups, inputs });
  };

  handleDelete = (participantIndex, groupIndex) => () => {
    let { groups } = this.state;
    let participantList = groups[groupIndex]['participant_list'];
    for (let i = 0; i < participantList.length; i++) {
      if (participantIndex === i) {
        participantList.splice(i, 1);
      }
    }
    groups[groupIndex].number_of_participants = participantList.length;
    this.setState({ groups });
  };

  fileUploadChange = (index) => (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      Papa.parse(files[0], {
        error: (error) => {
          alert(error.message);
        },
        complete: (results) => {
          let { groups } = this.state;
          groups[index]['participant_list'] = results.data;
          groups[index]['number_of_participants'] = results.data.length;
          this.setState({ groups });
        },
      });
    }
  };

  handleGroupInputChange = (key, index) => (e) => {
    let { inputs } = this.state;
    inputs[index][key] = e.target.value;
    this.setState({ inputs });
  };

  basicInfoChanged = (type) => (e) => {
    let { basic } = this.state;
    basic[type] = e.target.value;
    this.setState({ basic });
  };

  groupChange = (type, index) => (e) => {
    let { groups } = this.state;
    groups[index][type] = e.target.value;
    this.setState({ groups });
  };

  groupRemove = (index) => () => {
    let { groups } = this.state;
    groups.splice(index, 1);
    this.setState({ groups });
  };

  newCode = (indexGroup) => {
    const { groups } = this.state;
    groups[indexGroup].participant_code = this.randomCode();
    this.setState({ groups });
  };

  onCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    this.openNotification();
  };

  openNotification = () => {
    notification.info({
      message: 'Code copied!',
    });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <h1> Edit client </h1>
        <div className={styles.container}>
          <h2>Basic Info</h2>
          <div className={styles.inputItem}>
            <span>Organization</span>
            <input value={this.state.basic.org} onChange={this.basicInfoChanged('org')} />
          </div>
          <div className={styles.inputItem}>
            <span>Contact</span>
            <input value={this.state.basic.contact} onChange={this.basicInfoChanged('contact')} />
          </div>
        </div>
        {this.state.groups.map((group, index) => {
          return (
            <div key={group.id} className={styles.container}>
              <div className={styles.title}>
                <h2>Participant Group</h2>
                {this.state.groups.length > 1 && (
                  <span onClick={this.groupRemove(index)}>
                    <i className={`fa fa-times-circle-o ${styles.iconRemove}`} />
                  </span>
                )}
              </div>
              <div className={styles.inputItem}>
                <span>Participant Group Name</span>
                <input value={group.name} onChange={this.groupChange('name', index)} />
              </div>
              <div className={styles.inputItem}>
                <span>Participant Code</span>
                <input
                  value={group.participant_code || ''}
                  onChange={this.groupChange('participant_code', index)}
                />
                <div className={styles.codeAction}>
                  <Button
                    size="large"
                    type="primary"
                    ghost
                    className={styles.button}
                    onClick={() => this.newCode(index)}
                  >
                    New Code
                  </Button>
                  {group.participant_code && (
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      className={styles.button}
                      onClick={() => this.onCopyCode(group.participant_code)}
                    >
                      Copy Code
                    </Button>
                  )}
                </div>
              </div>
              <div className={styles.inputItem}>
                <span>Division / Location</span>
                <input value={group.division} onChange={this.groupChange('division', index)} />
              </div>
              <div className={styles.inputItem}>
                <span>Limit Users</span>
                <input
                  value={group.limit_users || ''}
                  onChange={this.groupChange('limit_users', index)}
                />
              </div>
              <div className={styles.inputItem}>
                <span>Number of Participants</span>
                <input disabled value={group.number_of_participants} />
              </div>
              <div className={styles.inputItem}>
                <span>Participant List</span>
                <div className={styles.participantBtnGroup}>
                  <div className={styles.btnPrimary} onClick={this.handleUpload(group.id)}>
                    Upload CSV
                  </div>
                  {group['number_of_participants'] === 0 ? (
                    <div className={styles.btnPrimaryDisabled}>Download CSV</div>
                  ) : (
                    <CSVLink
                      data={group['participant_list']}
                      className={styles.btnPrimary}
                      filename={group.name}
                    >
                      Download CSV
                    </CSVLink>
                  )}
                  {group['number_of_participants'] === 0 ? (
                    <div className={styles.btnDangerDisabled}>Clear All</div>
                  ) : (
                    <div className={styles.btnDanger} onClick={this.handleClearAll(index)}>
                      Clear All
                    </div>
                  )}
                </div>
                <input
                  ref={(ref) => (this.fileInputs[group.id] = ref)}
                  type="file"
                  className={styles.file}
                  accept=".csv"
                  onChange={this.fileUploadChange(index)}
                />
              </div>
              <div className={styles.inputItem}>
                <input
                  className={styles.nameInput}
                  placeholder="Type Name"
                  value={this.state.inputs[index].name}
                  onChange={this.handleGroupInputChange('name', index)}
                />
                <input
                  className={styles.emailInput}
                  placeholder="Type Email"
                  value={this.state.inputs[index].email}
                  onChange={this.handleGroupInputChange('email', index)}
                />
                <div className={styles.btnAdd} onClick={this.handleAdd(index)}>
                  Add
                </div>
              </div>
              {group.participant_list.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Feature</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.participant_list.map((participant, participantIndex) => (
                      <tr key={`${participantIndex}`}>
                        <td>{participant[0]}</td>
                        <td>{participant[1]}</td>
                        <td>
                          <div className={styles.participantBtnGroup}>
                            <button
                              className={styles.btnDanger}
                              onClick={this.handleDelete(participantIndex, index)}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {index === this.state.groups.length - 1 && (
                <div className={styles.btnAddMore} onClick={this.addmoreClicked}>
                  Add More Participant Group
                </div>
              )}
            </div>
          );
        })}
        <div className={styles.btnGroup}>
          <div className={styles.btnSave} onClick={this.updateClicked}>
            Update
          </div>
          <div className={styles.btnCancel} onClick={this.cancelClicked}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}

ClientEditContainer.contextType = AppContext;

ClientEditContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ClientEditContainer;
