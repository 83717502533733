import { Firestore } from '../lib/firebase';

import { getParticipantById } from './Participants';
import { getClientIds as getClientsManager } from './Managers';
import { uniq } from 'lodash';
import { syncHistoryByEmail } from './UserHistory';

const handleTrimValueLimitUser = (value) => {
  switch (typeof value) {
    case 'number':
      return value;
    case 'string':
      return +value.trim();
    default:
      return null;
  }
};

export const addClient = async (payload) => {
  try {
    let groupIds = [];
    const allEmailSync = [];
    let tasks = payload.groups.map((group) => {
      let groupDoc = Firestore.collection('participant_groups').doc();
      let participant_list = group.participant_list.map((participant) => {
        allEmailSync.push(participant[1]);
        return {
          name: participant[0],
          email: participant[1],
          status: true,
        };
      });
      groupIds.push(groupDoc.id);
      return groupDoc.set({
        id: groupDoc.id,
        name: group.name.trim(),
        division: group.division.trim(),
        participant_code: group.participant_code,
        limit_users: handleTrimValueLimitUser(group.limit_users),
        number_of_participants: group.number_of_participants,
        participant_list,
      });
    });
    await Promise.all(tasks);

    let clientDoc = Firestore.collection('clients').doc();
    await clientDoc.set({
      id: clientDoc.id,
      org: payload.basic.org.trim(),
      contact: payload.basic.contact.trim(),
      status: true,
      participant_group_ids: groupIds,
    });
    const listUnion = uniq(allEmailSync);
    const listEmailSync = listUnion.map((email) => syncHistoryByEmail(email));
    await Promise.all(listEmailSync);
    return clientDoc.id;
  } catch (error) {
    throw error;
  }
};

export const updateClient = async (payload) => {
  try {
    let groupIds = [];
    const allEmailSync = [];
    let tasks = payload.groups.map((group) => {
      let groupDoc;
      if (group.newlyAdded) {
        //newly added
        groupDoc = Firestore.collection('participant_groups').doc();
      } else {
        groupDoc = Firestore.collection('participant_groups').doc(group.id);
      }

      let participant_list = group.participant_list.map((participant) => {
        allEmailSync.push(participant[1]);
        return {
          name: participant[0],
          email: participant[1],
          status: true,
        };
      });
      groupIds.push(groupDoc.id);
      if (group.newlyAdded) {
        return groupDoc.set({
          id: groupDoc.id,
          name: group.name.trim(),
          division: group.division.trim(),
          participant_code: group.participant_code || null,
          limit_users: handleTrimValueLimitUser(group.limit_users),
          number_of_participants: group.number_of_participants,
          participant_list,
        });
      } else {
        return groupDoc.update({
          id: groupDoc.id,
          name: group.name.trim(),
          division: group.division.trim(),
          participant_code: group.participant_code || null,
          limit_users: handleTrimValueLimitUser(group.limit_users),
          number_of_participants: group.number_of_participants,
          participant_list,
        });
      }
    });
    await Promise.all(tasks);

    let clientDoc = Firestore.collection('clients').doc(payload.clientId);
    await clientDoc.update({
      id: payload.clientId,
      org: payload.basic.org.trim(),
      contact: payload.basic.contact.trim(),
      status: payload.basic.status,
      participant_group_ids: groupIds,
    });
    const listUnion = uniq(allEmailSync);

    const listEmailSync = listUnion.map((email) => syncHistoryByEmail(email));
    await Promise.all(listEmailSync);
  } catch (error) {
    throw error;
  }
};

export const deactivateClient = async (clientId) => {
  try {
    let clientCollection = Firestore.collection('clients');
    await clientCollection.doc(clientId).update({
      status: false,
    });
  } catch (error) {
    throw error;
  }
};

export const activateClient = async (clientId) => {
  try {
    let clientCollection = Firestore.collection('clients');
    await clientCollection.doc(clientId).update({
      status: true,
    });
  } catch (error) {
    throw error;
  }
};

export const getClientById = (clientId) => {
  return new Promise((resolve, reject) => {
    let clientDoc = Firestore.collection('clients').doc(clientId);
    clientDoc.onSnapshot(async (snapshot) => {
      let clientData = snapshot.data();

      let tasks = clientData.participant_group_ids.map(
        (groupId) =>
          new Promise((resolve, reject) => {
            let participant_group_doc = Firestore.collection('participant_groups').doc(groupId);
            participant_group_doc.onSnapshot((group_snapshot) => {
              let participant_group_data = group_snapshot.data();
              resolve(participant_group_data);
            });
          }),
      );
      clientData.participant_groups = await Promise.all(tasks);
      let query = Firestore.collection('campaigns').where('client_id', '==', clientId);
      let docSnapshots = await query.get();
      clientData.campaign = docSnapshots.docs.length;
      resolve(clientData);
    });
  });
};

// search clients with the criteria
export const getClients = async (isAdmin) => {
  try {
    let tasks;
    if (isAdmin) {
      let clientCollection = Firestore.collection('clients');
      let snapshot = await clientCollection.get();
      tasks = snapshot.docs.map((clientDoc) => getClientById(clientDoc.id));
    } else {
      let clientIds = await getClientsManager();
      tasks = clientIds.map((clientDoc) => getClientById(clientDoc));
    }
    let clients = Promise.all(tasks);
    return clients;
  } catch (error) {
    throw error;
  }
};

export const getParticipantGroupsByClientId = async (clientId) => {
  try {
    let client = await getClientById(clientId);
    let tasks = client.participant_group_ids.map((groupId) => {
      return getParticipantById(groupId);
    });
    let participant_groups = await Promise.all(tasks);
    return participant_groups;
  } catch (error) {
    throw error;
  }
};

export const participantCodeExist = async (code, id = '') => {
  let participantsCollection = await Firestore.collection('participant_groups');
  const dataQuery = participantsCollection.where('participant_code', '==', code);
  const dataResult = await dataQuery.get();
  let isExist = true;
  if (id) {
    dataResult.forEach((doc) => {
      const data = doc.data();
      if (data.id === id) isExist = false;
    });
  }
  if (dataResult.size !== 0 && isExist) return true;
  else return false;
};
