import { Firestore } from '../lib/firebase';
import { getUserByEmail } from './Users';
export const getAnswer = async (participantEmail, campaignId) => {
  let users = await getUserByEmail(participantEmail);
  if (users) {
    let answerId = `${campaignId}-${users.id}`;
    let answerRef = await Firestore.collection('answers').doc(answerId);
    let doc = await answerRef.get();
    if (doc.exists) {
      return doc.data();
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getAnswerOpenCampaign = async (campaignId) => {
  let userCollection = Firestore.collection('answers');
  let snapshot = await userCollection.where('campaign_id', '==', campaignId).get();
  let answers = snapshot.docs.map((doc) => doc.data());
  return answers;
};

export const updateAnswer = async (answerRefId, answers) => {
  let ref = Firestore.collection('answers').doc(answerRefId);
  await ref.update(answers);
};
