import PropTypes from 'prop-types';
import React from 'react';
import styles from './MediaItem.module.scss';
import { getYoutubeLink } from '../../utils/url';
export const MediaItem = React.memo((props) => {
  const refVideo = React.useRef(null);
  const { media, onRemove, defaultType = 'video' } = props;
  const videoTag = () => {
    return (
      <video
        controlsList="nodownload"
        preload="metadata"
        width="300"
        controls
        id="video"
        playsInline
        autoPlay
        ref={refVideo}
        onLoadedData={() =>
          setTimeout(() => {
            refVideo.current.pause();
            refVideo.current.muted = false;
          }, 10)
        }
        className={styles.media}
        src={media.downloadUrl ? media.downloadUrl : URL.createObjectURL(media)}
      />
    );
  };

  const imgTag = (url) => {
    return <img className={styles.media} width="300" alt="media" src={url} />;
  };

  const getMediaPreview = () => {
    if (media.downloadUrl) {
      if (!media.type) {
        if (defaultType === 'video') {
          return videoTag();
        }
      }
      if (media.type.includes('image/')) {
        return imgTag(media.downloadUrl);
      }
      if (media.type.includes('video')) {
        return videoTag();
      }
    }
    if (typeof media === 'string') {
      return imgTag(media);
    }
    if (media.type.includes('image/')) {
      return imgTag(URL.createObjectURL(media));
    }

    return videoTag();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mediaView}>
        {getMediaPreview()}
        {typeof onRemove === 'function' && (
          <div className={styles.iconRemove} onClick={() => onRemove()}>
            <i className="fa fa-minus-circle" />
          </div>
        )}
      </div>
    </div>
  );
});

MediaItem.propTypes = {
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onRemove: PropTypes.func,
  defaultType: PropTypes.string,
};

export const MediaLocal = React.memo((props) => {
  const refVideo = React.useRef(null);
  const { file, onRemove, defaultType = 'video', width = 300, maxHeight = 'unset' } = props;

  const videoTag = () => {
    return (
      <video
        controlsList="nodownload"
        preload="metadata"
        width={width}
        style={{
          maxHeight: maxHeight,
        }}
        controls
        id="video"
        playsInline
        autoPlay
        muted
        ref={refVideo}
        onLoadedData={() =>
          setTimeout(() => {
            refVideo.current.pause();
            refVideo.current.muted = false;
          }, 10)
        }
        className={styles.media}
        src={file.url ? file.url : URL.createObjectURL(file)}
      />
    );
  };

  const videoYoutube = () => {
    return (
      <iframe
        width={width}
        title="youtube"
        className={styles.media}
        src={getYoutubeLink(file.url)}
        frameBorder="0"
        allowFullScreen
      />
    );
  };

  const imgTag = (url) => {
    return (
      <img
        className={styles.media}
        style={{
          maxHeight: maxHeight,
        }}
        width={width}
        alt="media"
        src={url}
      />
    );
  };

  const getMediaPreview = () => {
    if (file.url) {
      if (!file.type) {
        if (defaultType === 'video') {
          return videoTag();
        } else if (defaultType === 'image') {
          return imgTag();
        }
      }
      if (file.type.includes('image/')) {
        return imgTag(file.url);
      }
      if (file.type.includes('video')) {
        return videoTag();
      }
      if (file.type === 'youtube') {
        return videoYoutube();
      }
    }
    if (typeof file === 'string') {
      return imgTag(file);
    }
    if (file.type.includes('image/')) {
      return imgTag(URL.createObjectURL(file));
    }

    return videoTag();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mediaView}>
        {getMediaPreview()}
        {typeof onRemove === 'function' && (
          <div className={styles.iconRemove} onClick={() => onRemove()}>
            <i className="fa fa-minus-circle" />
          </div>
        )}
      </div>
    </div>
  );
});

MediaLocal.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onRemove: PropTypes.func,
  defaultType: PropTypes.string,
  width: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
