import { forEach, some } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './Transcriptions.module.scss';
import { Word } from './Word';

const compare2Word = (fstWord, secWord) => {
  return fstWord.index === secWord.index ? 0 : fstWord.index < secWord.index ? -1 : 1;
};

const checkAllSelectedWords = (selectedWords, hoveredWords) => {
  let isAllSelected = true;
  forEach(hoveredWords, (word) => {
    if (!isWordExist(selectedWords, word)) {
      isAllSelected = false;
      return false;
    }
  });
  return isAllSelected;
};

const addSelectedWords = (selectedWords, hoveredWords) => {
  let newSelectedWords = [...selectedWords];
  forEach(hoveredWords, (word) => {
    if (!isWordExist(selectedWords, word)) {
      newSelectedWords = [...newSelectedWords, word];
    }
  });
  return newSelectedWords;
};

const removeSelectedWords = (selectedWords, hoveredWords) => {
  return selectedWords.filter((selectedWord) => !isWordExist(hoveredWords, selectedWord));
};

const isWordExist = (arrayWords, word) => {
  return some(arrayWords, { index: word.index, gsUrl: word.gsUrl });
};

export const Transcriptions = React.forwardRef((props, ref) => {
  const { dataTranscriptions, isMouseDown, onChange = () => {} } = props;
  const [selectedWords, setSelectedWords] = useState([]);
  const [hoveredWords, setHoveredWords] = useState([]);
  const [hoverInfo, setHoverInfo] = useState({
    hoverStart: null,
    hoverEnd: null,
    hoverBegin: null,
  });

  // Handle user selectedWords
  useEffect(() => {
    if (isMouseDown === true || hoveredWords.length <= 0) return;

    if (checkAllSelectedWords(selectedWords, hoveredWords)) {
      const newSelectedWords = removeSelectedWords(selectedWords, hoveredWords);
      setSelectedWords(newSelectedWords);
      onChange(newSelectedWords);
    } else {
      const newSelectedWords = addSelectedWords(selectedWords, hoveredWords);
      setSelectedWords(newSelectedWords);
      onChange(newSelectedWords);
    }
    setHoverInfo({ hoverStart: null, hoverEnd: null, hoverBegin: null });
    setHoveredWords([]);
  }, [isMouseDown]);

  // Handle user hoveredWords
  useEffect(() => {
    if (!hoverInfo.hoverStart || !hoverInfo.hoverEnd) return;
    // loop all words
    let newHoverWords = [];
    forEach(dataTranscriptions, (word) => {
      if (
        word.type === 'text' &&
        compare2Word(word, hoverInfo.hoverStart) >= 0 &&
        compare2Word(word, hoverInfo.hoverEnd) <= 0
      ) {
        newHoverWords = [...newHoverWords, word];
      }
    });
    setHoveredWords(newHoverWords);
  }, [hoverInfo]);

  const handleMouseEnter = (word) => {
    if (isMouseDown) {
      if (!hoverInfo.hoverStart || !hoverInfo.hoverEnd || !hoverInfo.hoverBegin) {
        setHoverInfo({ hoverStart: word, hoverEnd: word, hoverBegin: word });
        return;
      }
      if (compare2Word(word, hoverInfo.hoverBegin) < 0) {
        setHoverInfo((state) => ({ ...state, hoverStart: word, hoverEnd: state.hoverBegin }));
        return;
      }
      if (compare2Word(word, hoverInfo.hoverBegin) > 0) {
        setHoverInfo((state) => ({ ...state, hoverEnd: word, hoverStart: state.hoverBegin }));
        return;
      }
    }
  };

  const handleMouseDown = (word) => {
    if (!hoverInfo.hoverStart || !hoverInfo.hoverEnd || !hoverInfo.hoverBegin) {
      setHoverInfo({ hoverStart: word, hoverEnd: word, hoverBegin: word });
      return;
    }
  };

  React.useImperativeHandle(ref, () => ({
    removeSelectedWords: () => {
      setSelectedWords([]);
    },
  }));

  if (dataTranscriptions.length === 0) {
    return (
      <p className={styles.noSelect}>
        <span className={styles.textDanger}>N/A</span>
      </p>
    );
  }

  return (
    <p className={styles.noSelect}>
      {dataTranscriptions.map((item, index) => {
        switch (item.type) {
          case 'progress':
            return (
              <span key={index} className={styles.textDanger}>
                {item.data}
              </span>
            );

          case 'N/A':
            return (
              <span key={index} className={styles.textDanger}>
                N/A
              </span>
            );

          case 'break':
            return <br key={index} />;

          case 'enter':
            return (
              <span key={index}>
                <br />
                <br />
              </span>
            );

          case 'text':
            return (
              <Word
                key={`${item.gsUrl}-${item.index}`}
                isSelect={isWordExist(selectedWords, item)}
                isHover={isWordExist(hoveredWords, item)}
                onMouseDown={(event) => {
                  if (event.button === 0) {
                    handleMouseDown(item);
                  }
                }}
                onMouseEnter={() => handleMouseEnter(item)}
              >
                {item.data.word}{' '}
              </Word>
            );

          default:
            return null;
        }
      })}
      <br />
    </p>
  );
});
// Transcriptions.displayName = 'Transcriptions';
Transcriptions.propTypes = {
  dataTranscriptions: PropTypes.array,
  isMouseDown: PropTypes.bool,
  onChange: PropTypes.func,
};
