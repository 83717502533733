import { Dropdown, Menu, Modal, notification, Switch, Table } from 'antd';
import { orderBy, remove } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { AppContext } from '../../../components';
import CampaignType, { getCampaignType } from '../../../constants/campaignType';
import { CampaignController, ClientController } from '../../../controllers';
import styles from './CampaignListContainer.module.scss';
import { setParams, getParamsUrl } from '../../../utils/url';
import moment from 'moment';
import { BackButton } from '../../../components/Button';

const ALL = 'all';

const ALL_ORGANIZATION = {
  label: 'Organization',
  value: ALL,
};

const ALL_GROUP = {
  label: 'ALL',
  value: ALL,
};

class CampaignListContainer extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      data: [],
      keyword: searchParams && searchParams.keyword ? searchParams.keyword : '',
      loading: false,
      visibleModal: false,
      campaignIdSelected: null,
      organizations: [ALL_ORGANIZATION],
      groups: [],
      campaigns: [],
      campaignsByGroup: [],
      campaignsByOrganization: [],
      groupValue: ALL_GROUP,
      organizationValue: ALL_ORGANIZATION,
      isAddOrderColumn: false,
      isAddOrganizationColumn: true,
      table: {
        page: searchParams && searchParams.page ? +searchParams.page : 1,
        size: searchParams && searchParams.size ? +searchParams.size : 10,
        sortOrder: (searchParams && searchParams.sortOrder) || 'descend',
        sortField: (searchParams && searchParams.sortField) || null,
      },
    };
  }
  handleAction = ({ key }) => {
    const name = key.split('-')[0];
    const id = key.split('-')[1];
    if (name === 'edit') {
      this.editClicked(id);
    } else if (name === 'duplicate') {
      this.showModal(id);
    } else {
      this.handleDeleteCampaign(id);
    }
  };

  renderActionMenu = (record) => {
    return (
      <Menu onClick={this.handleAction}>
        <Menu.Item key={`edit-${record.id}`}>Edit</Menu.Item>
        <Menu.Item key={`duplicate-${record.id}`} id={record.id}>
          Duplicate
        </Menu.Item>
        <Menu.Divider style={{ display: record.status === false ? 'block' : 'none' }} />
        {record.status === false && (
          <Menu.Item style={{ color: 'red' }} key={`delete-${record.id}`}>
            Delete
          </Menu.Item>
        )}
      </Menu>
    );
  };

  async componentDidMount() {
    await this.reload();
  }

  getOrganizations = (data) => {
    let organizations = data.map((campaign) => {
      return {
        label: campaign.client.org,
        value: campaign.client.id,
      };
    });

    organizations = [
      ...new Map(organizations.map((organization) => [organization.label, organization])).values(),
    ];
    organizations = orderBy(organizations, ['label'], 'asc');
    organizations.unshift(ALL_ORGANIZATION);
    // Set Org by url params
    const searchParams = getParamsUrl();
    let organizationValue = this.state.organizationValue;
    if (searchParams && searchParams.organizationValue) {
      const found = organizations.find((f) => f.value === searchParams.organizationValue);
      if (found) {
        organizationValue = found;
      }
    }

    this.setState({
      organizations,
      organizationValue,
    });
  };

  getGroups = async (clientId) => {
    let groups = [ALL_GROUP];
    if (clientId !== ALL) {
      try {
        const result = await ClientController.getParticipantGroupsByClientId(clientId);
        const resultMap = result.map((m) => ({ label: m.name, value: m.id }));
        const resultOrder = orderBy(resultMap, ['label'], 'asc');
        groups = [ALL_GROUP, ...resultOrder];
      } catch (error) {
        alert(error.message);
      }
    }
    const searchParams = getParamsUrl();
    let groupValue = ALL_GROUP;
    if (searchParams && searchParams.groupValue) {
      const found = groups.find((f) => f.value === searchParams.groupValue);
      if (found) {
        groupValue = found;
      }
    }
    await this.setState({
      groups,
      groupValue,
    });
  };

  reload = async () => {
    try {
      this.setState({ loading: true });

      const data = await CampaignController.getCampaigns();
      await this.setState({
        campaigns: data,
      });

      this.getOrganizations(data);

      await this.getGroups(this.state.organizationValue.value);

      await this.filterByOrganization(this.state.organizationValue);

      if (this.state.groupValue) {
        this.filterByGroup(this.state.groupValue);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  filterByName = (data, value) => {
    this.setState({
      loading: true,
    });

    data = data.filter((campaign) => campaign.name.toLowerCase().includes(value.toLowerCase()));

    this.setState({
      data,
      loading: false,
    });
  };

  filterByOrganization = async (organization) => {
    this.setState({ loading: true });
    let newData = [...this.state.campaigns];
    const { value } = organization;

    if (value !== ALL) {
      newData = newData.filter((campaign) => campaign.client_id === organization.value);
      await this.getGroups(value);
    } else {
      this.setState({
        groups: [],
        groupValue: null,
      });
    }

    this.filterByName(newData, this.state.keyword);

    this.setState({
      campaignsByOrganization: newData,
      organizationValue: organization,
      loading: false,
    });
    // Set params url
    const newParams = setParams({ organizationValue: value });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  filterByGroup = (group) => {
    this.setState({ loading: true });
    let newData = [...this.state.campaignsByOrganization];
    const { value } = group;
    if (value !== ALL) {
      newData = newData.filter((campaign) => campaign.participant_group_id === value);

      const getCampaignsOrder = newData
        .filter((item) => item.order)
        .sort((pre, next) => (pre.order > next.order ? 1 : -1))
        .reverse();
      newData = newData.filter((item) => !item.order);
      getCampaignsOrder.forEach((campaign) => newData.unshift(campaign));
    }

    this.filterByName(newData, this.state.keyword);

    this.setState({
      campaignsByGroup: newData,
      groupValue: group,
      loading: false,
    });
    // Set params url
    const newParams = setParams({ groupValue: value });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  updateCampaigns = (idCampaign) => {
    const newData = [...this.state.data];

    const index = newData.findIndex((campaign) => campaign.id === idCampaign);

    newData[index].status = !newData[index].status;

    this.setState({
      data: newData,
    });
  };

  addClicked = () => {
    this.props.history.push('/campaigns/add');
  };

  addOpenClicked = () => {
    this.props.history.push('/campaigns/addOpen');
  };

  editClicked = (campaignId) => {
    const { data } = this.state;
    const found = data.find((f) => f.id === campaignId);
    if (found.type === CampaignType.OPEN) {
      this.props.history.push(`/campaigns/editOpen/${campaignId}`);
    } else {
      this.props.history.push(`/campaigns/edit/${campaignId}`);
    }
  };

  handleDuplicateCampaigns = (type) => {
    const { campaignIdSelected } = this.state;
    if (type === CampaignType.OPEN) {
      this.props.history.push(`/campaigns/editOpen/${campaignIdSelected}?action=duplicate`);
    } else {
      this.props.history.push(`/campaigns/edit/${campaignIdSelected}?action=duplicate`);
    }
  };

  statusChanged = (campaign) => async (checked, event) => {
    event.stopPropagation();
    if (campaign.status) {
      if (window.confirm('Do you want to deactivate this campaign?')) {
        this.setState({ loading: true });
        await CampaignController.deactivateCampaign(campaign.id);
        this.updateCampaigns(campaign.id);
        this.setState({ loading: false });
      }
    } else {
      if (window.confirm('Do you want to activate this campaign?')) {
        this.setState({ loading: true });
        await CampaignController.activateCampaign(campaign.id);
        this.updateCampaigns(campaign.id);
        this.setState({ loading: false });
      }
    }
  };

  handleFilterByName = (value) => {
    if (!this.state.groupValue || this.state.groupValue.value === ALL) {
      this.filterByName([...this.state.campaignsByOrganization], value);
    } else {
      this.filterByName([...this.state.campaignsByGroup], value);
    }
  };

  searchInputChanged = (e) => {
    this.setState({
      keyword: e.target.value,
    });

    if (!e.target.value) {
      const newParams = setParams({ keyword: undefined });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      this.handleFilterByName('');
    }
  };

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      // enter pressed
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      this.handleFilterByName(e.target.value);
    }
  };

  showModal = (campaignID) => {
    this.setState({
      visibleModal: true,
      campaignIdSelected: campaignID,
    });
  };

  handleDeleteCampaign = (campaignID) => {
    if (window.confirm('Are you sure you want to remove this campaign?')) {
      this.setState({ loading: true });
      CampaignController.deleteCampaign(campaignID)
        .then(async () => {
          await this.reload();

          notification.success({
            message: 'Your campaign has been removed',
          });
        })
        .catch((error) => {
          alert(error.toString());
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  tableChange = async (pagination, sorter) => {
    const { table } = this.state;
    const newParams = setParams({
      page: pagination.current,
      size: pagination.pageSize,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
    });
    this.props.history.replace({
      search: `?${newParams}`,
    });
    await this.setState({
      table: {
        ...table,
        sortOrder: sorter.order,
        sortField: sorter.columnKey,
      },
    });
  };
  render() {
    const { data, loading, table, groupValue } = this.state;
    const columns = [
      {
        title: '#',
        dataIndex: 'order',
        key: 'order',
        align: 'center',
        width: 100,
        sorter: (a, b) => (a.order > b.order ? 1 : -1),
        render: (value) => value,
        sortOrder: this.state.table.sortField === 'order' && this.state.table.sortOrder,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        sortOrder: this.state.table.sortField === 'name' && this.state.table.sortOrder,
        render: (text, record) => {
          if (
            record.type === CampaignType.CHAT_11 ||
            record.type === CampaignType.CONVERSATION ||
            record.type === CampaignType.CHAT_GROUP
          ) {
            return (
              <a href={`/chat/${record.id}`} target="_blank" rel="noreferrer">
                {text}
              </a>
            );
          } else {
            return (
              <a href={`/report/${record.id}`} target="_blank" rel="noreferrer">
                {text}
              </a>
            );
          }
        },
      },

      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        sorter: (a, b) => (a.type > b.type ? 1 : -1),
        sortOrder: this.state.table.sortField === 'type' && this.state.table.sortOrder,
        render: (text) => getCampaignType(text),
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        key: 'organization',
        align: 'center',
        sorter: (a, b) => (a.client.org > b.client.org ? 1 : -1),
        sortOrder: this.state.table.sortField === 'organization' && this.state.table.sortOrder,
        render: (text, record, index) => record.client.org,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        align: 'center',
        sorter: (a, b) => {
          const alpha = this.state.table.sortOrder === 'descend' ? '-1' : 'z';
          const sortA =
            (a.participant_group &&
              a.participant_group.name &&
              a.participant_group.name.toLowerCase()) ||
            alpha;
          const sortB =
            (b.participant_group &&
              b.participant_group.name &&
              b.participant_group.name.toLowerCase()) ||
            alpha;
          if (sortA < sortB) return -1;
          if (sortA > sortB) return 1;
          return 0;
        },
        sortOrder: this.state.table.sortField === 'group' && this.state.table.sortOrder,
        render: (text, record, index) =>
          (record.participant_group && record.participant_group.name) || '',
      },
      {
        title: 'Study Code',
        dataIndex: 'participants',
        key: 'participants',
        align: 'center',
        render: (text, record, index) =>
          (record.participant_group && record.participant_group.participant_code) || '',
      },
      {
        title: 'Last Activity',
        key: 'lastActivity',
        dataIndex: 'lastActivity',
        align: 'center',
        sorter: (a, b) => {
          const distantFuture = this.state.table.sortOrder === 'descend' ? 0 : 8640000000000000;
          const sortA = a.lastActivity || distantFuture;
          const sortB = b.lastActivity || distantFuture;
          return sortA - sortB;
        },
        render: (value) => {
          if (!value) return null;
          return moment.unix(value).format('lll');
        },

        sortOrder: this.state.table.sortOrder,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (text, record, index) => (
          <Switch onChange={this.statusChanged(record)} checked={record.status} />
        ),
      },
      {
        title: 'Actions',
        align: 'center',
        key: 'Actions',
        render: (text, record, index) => (
          <div className={styles.action}>
            <Dropdown
              placement="bottomRight"
              overlayStyle={{
                boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.16)',
              }}
              overlay={() => this.renderActionMenu(record)}
              trigger={['click']}
            >
              <i
                className="fa fa-ellipsis-v ic-action"
                aria-hidden="true"
                onClick={(e) => e.preventDefault()}
              ></i>
            </Dropdown>
          </div>
        ),
      },
    ];
    if (groupValue && groupValue.value !== ALL_GROUP.value) {
      remove(columns, (f) => f.dataIndex === 'organization');
    } else {
      remove(columns, (f) => f.dataIndex === 'order');
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchContainer}>
            <div className={styles.searchbar}>
              <i className={`fa fa-search ${styles.iconSearch}`} />
              <input
                type="text"
                placeholder="Type here and press enter to get the result..."
                value={this.state.keyword}
                onChange={this.searchInputChanged}
                onKeyPress={this.searchInputKeyPressed}
              />
            </div>
            <div className={styles.searchfilters}>
              <div className={styles.filter}>
                <Select
                  className={styles.select}
                  value={this.state.organizationValue}
                  onChange={this.filterByOrganization}
                  options={this.state.organizations}
                />
              </div>
              <div className={styles.filter}>
                <Select
                  placeholder="Group"
                  value={this.state.groupValue}
                  className={styles.select}
                  onChange={this.filterByGroup}
                  options={this.state.groups}
                />
              </div>
            </div>
          </div>
          <div className={styles.btnAddOpen} onClick={this.addOpenClicked}>
            <i className={`fa fa-plus ${styles.icon}`} />
            Add Open
          </div>
          <div className={styles.btnAdd} onClick={this.addClicked}>
            <i className={`fa fa-plus ${styles.icon}`} />
            Add
          </div>
          <BackButton history={this.props.history} />
        </div>
        <Table
          onChange={(pagination, filter, sorter) => {
            this.tableChange(pagination, sorter);
          }}
          columns={columns}
          dataSource={data}
          rowKey={(item) => item.id}
          bordered
          size="middle"
          pagination={{
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            defaultCurrent: table.page,
            defaultPageSize: table.size,
          }}
          loading={loading}
        />
        <Modal
          title={null}
          visible={this.state.visibleModal}
          className={styles.modal}
          footer={null}
          onCancel={() => {
            this.setState({ visibleModal: false });
          }}
        >
          <div className={styles.modalHeader}>
            <p className={styles.modalTitle}>
              Select the actions below to duplicate your campaign to the open campaign or standard
              campaign
            </p>
          </div>
          <div className={styles.modalContent}>
            <div
              className={styles.btnAddOpen}
              onClick={() => this.handleDuplicateCampaigns('OPEN')}
            >
              <i className={`fa fa-plus ${styles.icon}`} />
              Add Open
            </div>
            <div className={styles.btnAdd} onClick={() => this.handleDuplicateCampaigns()}>
              <i className={`fa fa-plus ${styles.icon}`} />
              Add
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

CampaignListContainer.contextType = AppContext;

CampaignListContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default CampaignListContainer;
