import moment from 'moment';

import { Firestore, Storage } from '../lib/firebase';
import { getClientIds } from './Managers';
import CampaignType from '../constants/campaignType';
import { AnswerController, ConversationController } from '../controllers';
import QuestionType from './../constants/questionType';
let collection = Firestore.collection('campaigns');

export const addCampaign = async (payload) => {
  try {
    let questions = [];
    let tasks = [];
    const isTypeChat =
      payload.basic.type === CampaignType.CHAT_11 || payload.basic.type === CampaignType.CHAT_GROUP;
    if (!isTypeChat) {
      tasks = payload.questions.map(
        (question, index) =>
          new Promise((resolve, reject) => {
            questions.push({
              type: question.type,
              questionHtml: question.questionHtml,
              question: getPlanTextByEditor(question.questionHtml),
              questionTitle: question.questionTitle || '',
              answers: question.answers,
              userMediaEnable: question.userMediaShow ? question.userMediaEnable : false,
              userMediaShow: question.userMediaShow,
            });
            if (question.type !== QuestionType.SIGNATURE_QUESTION) {
              if (question.media && question.media.type) {
                let ref = Storage.ref(`media/${moment().valueOf()}`);
                let task = ref.put(question.media);
                task.on(
                  'state_changed',
                  (snapshot) => {},
                  (error) => {},
                  () => {
                    task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                      questions[index].media = {
                        downloadUrl,
                        gsUrl:
                          'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                      };
                      questions[index].media_type = question.media.type;
                      resolve(downloadUrl);
                    });
                  },
                );
              } else if (question.media && question.media_type) {
                if (
                  question.media_type === 'youtube' &&
                  question.media.includes('www.youtube.com/watch?v=')
                ) {
                  question.media = question.media.replace('www.youtube.com/watch?v=', 'youtu.be/');
                }
                questions[index].media = question.media;
                questions[index].media_type = question.media_type;

                resolve();
              } else {
                resolve();
              }
            } else {
              resolve();
            }
          }),
      );
    }

    let data = {
      name: payload.basic.name,
      marketing_name: payload.basic.marketing_name,
      from: payload.basic.from.toISOString(),
      to: payload.basic.to.toISOString(),
      type: payload.basic.type,
      client_id: payload.basic.org,
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      status: true,
    };
    if (payload.basic.type === CampaignType.CHAT_11) {
      data.email = payload.basic.email;
    }
    if (
      payload.basic.type !== CampaignType.CHAT_11 &&
      payload.basic.type !== CampaignType.CHAT_GROUP
    ) {
      data = {
        ...data,
        participant_group_id: payload.basic.participant_group,
      };
    }
    if (payload.basic.type === CampaignType.CHAT_GROUP) {
      data.chat_group_id = payload.basic.participant_group;
    }
    if (
      payload.basic.type !== CampaignType.CHAT_11 &&
      payload.basic.type !== CampaignType.CHAT_GROUP
    ) {
      data = {
        ...data,
        total_points: parseInt(payload.basic.total_points),
        descriptionHtml: payload.basic.descriptionHtml,
        description: getPlanTextByEditor(payload.basic.descriptionHtml),
        order: payload.basic.order,
      };
    }

    if (payload.basic.logo && payload.basic.logo.type) {
      tasks.push(
        new Promise((resolve, reject) => {
          let ref = Storage.ref(`media/${moment().valueOf()}`);
          let task = ref.put(payload.basic.logo);
          task.on(
            'state_changed',
            (snapshot) => {},
            (error) => {},
            () => {
              task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                data.logo = downloadUrl;
                resolve(downloadUrl);
              });
            },
          );
        }),
      );
    } else {
      data.logo = payload.basic.logo || null;
    }
    await Promise.all(tasks);
    let campaignDoc = collection.doc();
    await campaignDoc.set({
      id: campaignDoc.id,
      ...data,
      questions,
    });
    if (isTypeChat) {
      await ConversationController.addConversation(campaignDoc.id);
    }
  } catch (error) {
    throw error;
  }
};

export const addOpenCampaign = async (payload) => {
  try {
    let questions = [];
    let tasks = payload.questions.map(
      (question, index) =>
        new Promise((resolve, reject) => {
          questions.push({
            type: question.type,
            questionHtml: question.questionHtml || '',
            question: getPlanTextByEditor(question.questionHtml),
            descriptionHtml: question.descriptionHtml || '',
            description: getPlanTextByEditor(question.descriptionHtml),
            answers: question.answers,
            userMediaEnable: question.userMediaShow ? question.userMediaEnable : false,
            userMediaShow: question.userMediaShow,
            checkboxSections: question.checkboxSections || [],
          });
          if (question.media && question.media.type) {
            let ref = Storage.ref(`media/${moment().valueOf()}`);
            let task = ref.put(question.media);
            task.on(
              'state_changed',
              (snapshot) => {},
              (error) => {},
              () => {
                task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                  questions[index].media = {
                    downloadUrl,
                    gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                  };
                  questions[index].media_type = question.media.type;
                  resolve(downloadUrl);
                });
              },
            );
          } else if (question.media && question.media_type) {
            if (
              question.media_type === 'youtube' &&
              question.media.includes('www.youtube.com/watch?v=')
            ) {
              question.media = question.media.replace('www.youtube.com/watch?v=', 'youtu.be/');
            }
            questions[index].media = question.media;
            questions[index].media_type = question.media_type;

            resolve();
          } else {
            resolve();
          }
        }),
    );

    let data = {
      name: payload.basic.name,
      marketing_name: payload.basic.marketing_name,
      client_id: payload.basic.org,
      from: payload.basic.from.toISOString(),
      to: payload.basic.to.toISOString(),

      type: payload.basic.type,
      descriptionHtml: payload.basic.descriptionHtml,
      description: getPlanTextByEditor(payload.basic.descriptionHtml),
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      status: true,
      required_info: payload.basic.required_info,
      limit_users: payload.basic.limit_users,

      instructionName: payload.basic.instructionName,
      instruction: payload.basic.instruction,
      instructionEmail: payload.basic.instructionEmail,
      colorSchema: payload.basic.colorSchema,
      defaultButtons: payload.defaultButtons,
      shortLink: [payload.basic.short_link],
    };

    if (payload.basic.logo && payload.basic.logo.type) {
      tasks.push(
        new Promise((resolve, reject) => {
          let ref = Storage.ref(`media/${moment().valueOf()}`);
          let task = ref.put(payload.basic.logo);
          task.on(
            'state_changed',
            (snapshot) => {},
            (error) => {},
            () => {
              task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                data.logo = downloadUrl;
                resolve(downloadUrl);
              });
            },
          );
        }),
      );
    } else {
      data.logo = payload.basic.logo || null;
    }
    await Promise.all(tasks);

    let campaignDoc = collection.doc();
    await campaignDoc.set({
      id: campaignDoc.id,
      ...data,
      questions,
    });
    return campaignDoc.id;
  } catch (error) {
    throw error;
  }
};

export const updateCampaign = async (payload) => {
  try {
    let questions = [];
    let tasks = [];
    const isTypeChat =
      payload.basic.type === CampaignType.CHAT_11 && payload.basic.type === CampaignType.CHAT_GROUP;
    if (!isTypeChat) {
      tasks = payload.questions.map(
        (question, index) =>
          new Promise((resolve, reject) => {
            questions.push({
              type: question.type,
              questionHtml: question.questionHtml,
              question: getPlanTextByEditor(question.questionHtml),
              questionTitle: question.questionTitle || '',
              answers: question.answers,
              userMediaEnable: question.userMediaShow ? question.userMediaEnable : false,
              userMediaShow: question.userMediaShow,
            });
            if (question.media && question.media.type) {
              //local file
              let ref = Storage.ref(`media/${moment().valueOf()}`);
              let task = ref.put(question.media);
              task.on(
                'state_changed',
                (snapshot) => {},
                (error) => {},
                () => {
                  task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    questions[index].media = {
                      downloadUrl,
                      gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                    };
                    questions[index].media_type = question.media.type;
                    resolve(downloadUrl);
                  });
                },
              );
            } else if (question.media && question.media_type) {
              // remote url or youtube video
              if (
                question.media_type === 'youtube' &&
                question.media.includes('www.youtube.com/watch?v=')
              ) {
                question.media = question.media.replace('www.youtube.com/watch?v=', 'youtu.be/');
              }
              questions[index].media = question.media;
              questions[index].media_type = question.media_type;
              resolve();
            } else {
              resolve();
            }
          }),
      );
    }

    let data = {
      name: payload.basic.name,
      marketing_name: payload.basic.marketing_name,
      client_id: payload.basic.org,
      from: payload.basic.from.toISOString(),
      to: payload.basic.to.toISOString(),
      type: payload.basic.type,
      updatedAt: moment().unix(),
      status: payload.basic.status,
    };
    if (payload.basic.type === CampaignType.CHAT_11) {
      data.email = payload.basic.email;
      data.participant_group_id = null;
    }

    if (
      payload.basic.type !== CampaignType.CHAT_11 &&
      payload.basic.type !== CampaignType.CHAT_GROUP
    ) {
      data = {
        ...data,
        participant_group_id: payload.basic.participant_group,
        chat_group_id: null,
      };
    }
    if (payload.basic.type === CampaignType.CHAT_GROUP) {
      data.chat_group_id = payload.basic.participant_group;
      data.participant_group_id = null;
    }
    if (
      payload.basic.type !== CampaignType.CHAT_11 &&
      payload.basic.type !== CampaignType.CHAT_GROUP
    ) {
      data = {
        ...data,
        total_points: parseInt(payload.basic.total_points),
        descriptionHtml: payload.basic.descriptionHtml,
        description: getPlanTextByEditor(payload.basic.descriptionHtml),
        order: payload.basic.order,
      };
    }

    if (payload.basic.logo && payload.basic.logo.type) {
      tasks.push(
        new Promise((resolve, reject) => {
          let ref = Storage.ref(`media/${moment().valueOf()}`);
          let task = ref.put(payload.basic.logo);
          task.on(
            'state_changed',
            (snapshot) => {},
            (error) => {},
            () => {
              task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                data.logo = downloadUrl;
                resolve(downloadUrl);
              });
            },
          );
        }),
      );
    } else {
      data.logo = payload.basic.logo || null;
    }
    await Promise.all(tasks);

    let campaignDoc = collection.doc(payload.campaignId);
    campaignDoc.update({
      ...data,
      questions,
    });
  } catch (error) {
    throw error;
  }
};

export const updateOpenCampaign = async (payload) => {
  try {
    let questions = [];
    let tasks = payload.questions.map(
      (question, index) =>
        new Promise((resolve, reject) => {
          questions.push({
            type: question.type,
            answers: question.answers,
            questionHtml: question.questionHtml,
            question: getPlanTextByEditor(question.questionHtml),
            descriptionHtml: question.descriptionHtml || '',
            description: question.descriptionHtml
              ? getPlanTextByEditor(question.descriptionHtml)
              : '',
            userMediaEnable: question.userMediaShow ? question.userMediaEnable : false,
            checkboxSections: question.checkboxSections || [],
            userMediaShow: question.userMediaShow,
          });
          if (question.media && question.media.type) {
            //local file
            let ref = Storage.ref(`media/${moment().valueOf()}`);
            let task = ref.put(question.media);
            task.on(
              'state_changed',
              (snapshot) => {},
              (error) => {},
              () => {
                task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                  questions[index].media = {
                    downloadUrl,
                    gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                  };
                  questions[index].media_type = question.media.type;
                  resolve(downloadUrl);
                });
              },
            );
          } else if (question.media && question.media_type) {
            // remote url or youtube video
            if (
              question.media_type === 'youtube' &&
              question.media.includes('www.youtube.com/watch?v=')
            ) {
              question.media = question.media.replace('www.youtube.com/watch?v=', 'youtu.be/');
            }
            questions[index].media = question.media;
            questions[index].media_type = question.media_type;
            resolve();
          } else {
            resolve();
          }
        }),
    );

    let data = {
      name: payload.basic.name,
      marketing_name: payload.basic.marketing_name,
      client_id: payload.basic.org,
      from: payload.basic.from.toISOString(),
      to: payload.basic.to.toISOString(),

      type: payload.basic.type,
      descriptionHtml: payload.basic.descriptionHtml,
      description: getPlanTextByEditor(payload.basic.descriptionHtml),

      updatedAt: moment().unix(),
      status: payload.basic.status,
      required_info: payload.basic.required_info,
      limit_users: payload.basic.limit_users,

      instructionName: payload.basic.instructionName || '',
      instruction: payload.basic.instruction || '',
      instructionEmail: payload.basic.instructionEmail || '',
      colorSchema: payload.basic.colorSchema || '',
      defaultButtons: payload.defaultButtons,
      shortLink: payload.basic.short_link,
    };
    if (payload.basic.logo && payload.basic.logo.type) {
      tasks.push(
        new Promise((resolve, reject) => {
          let ref = Storage.ref(`media/${moment().valueOf()}`);
          let task = ref.put(payload.basic.logo);
          task.on(
            'state_changed',
            (snapshot) => {},
            (error) => {},
            () => {
              task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                data.logo = downloadUrl;
                resolve(downloadUrl);
              });
            },
          );
        }),
      );
    } else {
      data.logo = payload.basic.logo;
    }

    await Promise.all(tasks);

    let campaignDoc = collection.doc(payload.campaignId);
    await campaignDoc.update({
      ...data,
      questions,
    });
  } catch (error) {
    throw error;
  }
};

export const updateLastMessage = (id, lastMessage) => {
  let campaignDoc = collection.doc(id);
  campaignDoc.update({
    last_message: lastMessage,
  });
};

export const getCampaignById = async (campaignId) =>
  new Promise((resolve, reject) => {
    let campaignDoc = collection.doc(campaignId);
    campaignDoc.onSnapshot(async (snapshot) => {
      let campaignData = snapshot.data();
      if (!campaignData) return;
      if (campaignData.type === CampaignType.CHAT_GROUP) {
        campaignData.participant_group_id = campaignData.chat_group_id;
      }
      let clientDoc = Firestore.collection('clients').doc(campaignData.client_id);
      clientDoc.onSnapshot((client_snapshot) => {
        let client_data = client_snapshot.data();
        campaignData.client = client_data;
        if (campaignData.participant_group_id) {
          let participantDoc = Firestore.collection('participant_groups').doc(
            campaignData.participant_group_id,
          );
          participantDoc.onSnapshot((group_snapshot) => {
            let participant_group_data = group_snapshot.data();

            // getting user id for the participants
            Firestore.collection('users')
              .get()
              .then((snapshot) => {
                let users = snapshot.docs.map((userDoc) => userDoc.data());
                participant_group_data.participant_list = participant_group_data.participant_list
                  .filter((participant) => participant.status)
                  .map((participant) => {
                    let index = users.findIndex(
                      (item) =>
                        item.email &&
                        participant.email &&
                        item.email.toLowerCase() === participant.email.toLowerCase(),
                    );
                    if (index === -1) {
                      return {
                        userId: 'NaN',
                        ...participant,
                        answer: null,
                      };
                    } else {
                      return {
                        userId: users[index].id,
                        ...participant,
                      };
                    }
                  });
                campaignData.participant_group = participant_group_data;
                resolve(campaignData);
              });
          });
        } else {
          resolve(campaignData);
        }
      });
    });
  });

export const getCampaigns = async () => {
  try {
    const login_manager = window.localStorage.getItem('login_manager');
    if (!login_manager) return [];
    const { admin } = JSON.parse(login_manager);
    let snapshot;
    if (admin) {
      snapshot = await collection.get();
    } else {
      const clientIds = await getClientIds();
      if (clientIds.length === 0) return [];
      snapshot = await collection.where('client_id', 'in', clientIds).get();
    }
    let tasks = snapshot.docs.map((campaignDoc) => getCampaignById(campaignDoc.id));
    let campaigns = Promise.all(tasks);
    return campaigns;
  } catch (error) {
    throw error;
  }
};

export const getCampaignsByClient = async (clientId) => {
  let snapshot = await collection.where('client_id', '==', clientId).get();
  let campaigns = snapshot.docs.map((campaignDoc) => campaignDoc.data());
  return campaigns;
};

export const getAnsweredCampaignActive = async (campaignId) => {
  try {
    let listAnswers = await getListUserAnswered(campaignId);
    if (!listAnswers || listAnswers.length === 0) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const deactivateCampaign = async (campaignId) => {
  try {
    await collection.doc(campaignId).update({
      status: false,
    });
  } catch (error) {
    throw error;
  }
};

export const activateCampaign = async (campaignId) => {
  try {
    await collection.doc(campaignId).update({
      status: true,
    });
  } catch (error) {
    throw error;
  }
};

export const queryKeyWord = async (keywod) => {
  try {
    let queryKeyWord = await collection.where('shortLink', 'array-contains', keywod).get();
    let campaigns = queryKeyWord.docs.map((campaignDoc) => campaignDoc.data());
    return campaigns;
  } catch (error) {
    throw error;
  }
};

export const getPlanTextByEditor = (strEditor) => {
  if (!strEditor) return '';
  const textElement = document.createElement('div');
  textElement.innerHTML = strEditor;
  let textContent = textElement.textContent;

  // remove zero width space
  textContent = textContent.replace(/\u200B/g, '');

  return textContent.trim();
};

export const removeStyleInline = (e) => {
  const plainText = e.clipboardData.getData('text/plain');
  const handleNewLine = plainText.replace(/\n/g, '<br />');
  return handleNewLine;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const deleteCampaign = async (campaignId) => {
  try {
    let listAnswers = await getListUserAnswered(campaignId);
    await collection.doc(campaignId).delete();
    if (listAnswers && listAnswers.length > 0) {
      let tasks = listAnswers.map((item) => {
        return Firestore.collection('answers').doc(`${campaignId}-${item.userId}`).delete();
      });
      await Promise.all(tasks);
    }
  } catch (error) {
    throw error;
  }
};

export const getListUserAnswered = async (campaignId) => {
  try {
    let campaign = await getCampaignById(campaignId);
    let participants = [];
    if (campaign.type === CampaignType.CHAT_11) return [];
    if (campaign.type === CampaignType.OPEN) {
      const answers = await AnswerController.getAnswerOpenCampaign(campaignId);
      participants = answers.map((answer) => {
        return {
          answer,
          email: answer.email || 'No Email',
          name: answer.name || 'No Name',
          status: true,
          userId: answer.user_id,
        };
      });
    } else {
      const tasks = campaign.participant_group.participant_list.map((participant) =>
        AnswerController.getAnswer(participant.email, campaignId),
      );
      let answers = await Promise.all(tasks);

      participants = campaign.participant_group.participant_list.map((participant, index) => ({
        ...participant,
        answer: answers[index],
      }));
    }
    participants = participants.filter(function (el) {
      return el.answer;
    });
    return participants || [];
  } catch (error) {
    throw error;
  }
};
