import { Avatar, Tooltip } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Header.module.scss';

const ChatDetailHeader = React.memo((props) => {
  const { users, campaignName } = props;

  const getName = (user) => {
    const firstName = get(user, 'user.firstName', '');
    const lastName = get(user, 'user.lastName', '');
    if (!firstName && !lastName) {
      return get(user, 'email', '')[0];
    }
    return `${firstName[0]}${lastName[0]}`;
  };

  const getFullName = (user) => {
    const firstName = get(user, 'user.firstName', '');
    const lastName = get(user, 'user.lastName', '');
    if (!firstName && !lastName) {
      return get(user, 'email', '');
    }
    return `${firstName} ${lastName}`;
  };

  return (
    <section className={styles.wrapper}>
      <h2>{campaignName}</h2>
      {users && users.length > 0 && (
        <Avatar.Group maxCount={4} size="large" maxPopoverPlacement="bottom">
          {users.map((user, index) => {
            return (
              <Tooltip key={index} title={getFullName(user)} placement="bottom">
                <Avatar className={styles.avatar} src={user.user && user.user.avatar}>
                  {getName(user)}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>
      )}
    </section>
  );
});

ChatDetailHeader.propTypes = {
  users: PropTypes.array,
  campaignName: PropTypes.string,
};

export default ChatDetailHeader;
