import Axios from 'axios';

const downloadVideo = async (img, mapStack, promiseData, downloadDone) => {
  const videoBlob = await Axios({
    url: promiseData.promise.downloadUrl,
    method: 'GET',
    responseType: 'blob',
  });
  downloadDone();
  img.file(promiseData.promise.filename, new Blob([videoBlob.data]), { base64: true });
  if (mapStack.length > 0) {
    await downloadVideo(img, mapStack, mapStack.pop(), downloadDone);
  }
};
/**
 *
 * @param {img} img from jsZip: var img = zip.folder('All Videos');
 * @param {*} arrPromise list includes download url and filename
 * @param {*} maximumStack max process run async
 * @returns
 */
export const downloadMultipleVideo = async (img, arrPromise, callback, maximumStack = 4) => {
  if (Array.isArray(arrPromise)) {
    const totalItem = arrPromise.length;
    let fileDownloadDone = 0;
    const downloadDone = () => {
      fileDownloadDone++;
      const processValue = (fileDownloadDone / totalItem) * 100;
      if (typeof callback === 'function') {
        callback(Math.ceil(processValue));
      }
    };
    const mapStack = arrPromise.map((promise, index) => ({ index, promise }));
    const maxIndex = mapStack.length >= maximumStack ? maximumStack : mapStack.length;
    const promise = [];
    for (let index = 0; index < maxIndex; index++) {
      promise.push(downloadVideo(img, mapStack, mapStack.pop(), downloadDone));
    }
    await Promise.all(promise);
  }
};
