import React from 'react';
import cn from 'classnames';
import { Link, Switch, Route, Redirect } from 'react-router-dom';

import { Auth } from './../lib/firebase';

// users
import UserListContainer from '../containers/Users/List';
import UserViewController from '../containers/Users/View';

// clients
import ClientListContainer from '../containers/Clients/List';
import ClientAddContainer from '../containers/Clients/Add';
import ClientEditContainer from '../containers/Clients/Edit';

// campaigns & sessions
import CampaignListContainer from '../containers/Campaigns/List';
import CampaignAddContainer from '../containers/Campaigns/Add';
import CampaignAddOpenContainer from '../containers/Campaigns/AddOpen';
import CampaignEditContainer from '../containers/Campaigns/Edit';
import CampaignEditOpenContainer from '../containers/Campaigns/EditOpen';
import SessionListContainer from '../containers/Sessions/List';
import SessionAddContainer from '../containers/Sessions/Add';
import SessionEditContainer from '../containers/Sessions/Edit';

//report
import ReportListContainer from '../containers/Report/List';
import ReportDetailsContainer from '../containers/Report/Details';
import ReportAnswersContainer from '../containers/Report/Answers';

//participants
import ParticipantListContainer from '../containers/Participants/List';

//analytics
import CampaignAnalyticsContainer from '../containers/Analytics/Campaign';
import SessionAnalyticsContainer from '../containers/Analytics/Session';

//chat
import ChatListContainer from '../containers/Chat/List';
import ChatDetailsContainer from '../containers/Chat/Details';

import styles from './Router.module.scss';
import ManagersContainer from '../containers/Managers';

/**
 * 0 : client
 * 1 : campaign
 * 2 : session
 * 3 : report
 * 4 : participants
 * 10 : campaign analytics
 * 20: session analytics
 * 5 : group chat
 * 6 : users
 * 7 : managers
 */

class Router extends React.Component {
  constructor() {
    super();
    this.state = {
      manager: JSON.parse(window.localStorage.getItem('login_manager')),
    };
  }

  handleLogout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      alert(error.message);
    }
  };

  render() {
    let selectedMenuItem = 0;
    if (window.location.pathname.startsWith('/clients')) {
      selectedMenuItem = 0;
    } else if (window.location.pathname.startsWith('/campaigns')) {
      selectedMenuItem = 1;
    } else if (window.location.pathname.startsWith('/sessions')) {
      selectedMenuItem = 2;
    } else if (window.location.pathname.startsWith('/report')) {
      selectedMenuItem = 3;
    } else if (window.location.pathname.startsWith('/participants')) {
      selectedMenuItem = 4;
    } else if (window.location.pathname.startsWith('/analytics/campaign')) {
      selectedMenuItem = 10;
    } else if (window.location.pathname.startsWith('/analytics/session')) {
      selectedMenuItem = 20;
    } else if (window.location.pathname.startsWith('/chat')) {
      selectedMenuItem = 5;
    } else if (window.location.pathname.startsWith('/users')) {
      selectedMenuItem = 6;
    } else if (window.location.pathname.startsWith('/managers')) {
      selectedMenuItem = 7;
    } else {
      selectedMenuItem = 0; // default
    }
    return (
      <div className={styles.wrapper}>
        <header>
          Lens Engage
          <div className={styles.logoutBtn} onClick={this.handleLogout}>
            <i className="fa fa-power-off" />
            Log Out
          </div>
        </header>
        <div className={styles.container}>
          <div className={styles.sidebar}>
            <Link
              to="/clients"
              className={cn(styles.menuitem, selectedMenuItem === 0 && styles['menuitem-selected'])}
            >
              Clients
            </Link>
            <div className={cn(styles.menuitem, styles.menucategory)}>
              Campaigns
              <Link
                to="/campaigns"
                className={cn(
                  styles.submenuitem,
                  selectedMenuItem === 1 && styles['menuitem-selected'],
                )}
              >
                Campaign
              </Link>
              <Link
                to="/sessions"
                className={cn(
                  styles.submenuitem,
                  selectedMenuItem === 2 && styles['menuitem-selected'],
                )}
              >
                Session
              </Link>
            </div>
            <Link
              to="/report"
              className={cn(styles.menuitem, selectedMenuItem === 3 && styles['menuitem-selected'])}
            >
              Report
            </Link>
            <Link
              to="/participants"
              className={cn(styles.menuitem, selectedMenuItem === 4 && styles['menuitem-selected'])}
            >
              Participants
            </Link>
            <div className={cn(styles.menuitem, styles.menucategory)}>
              Analytics
              <Link
                to="/analytics/campaign"
                className={cn(
                  styles.submenuitem,
                  selectedMenuItem === 10 && styles['menuitem-selected'],
                )}
              >
                Campaign
              </Link>
              <Link
                to="/analytics/session"
                className={cn(
                  styles.submenuitem,
                  selectedMenuItem === 20 && styles['menuitem-selected'],
                )}
              >
                Session
              </Link>
            </div>
            <Link
              to="/chat"
              className={cn(styles.menuitem, selectedMenuItem === 5 && styles['menuitem-selected'])}
            >
              Chat
            </Link>
            {this.state.manager && this.state.manager.admin && (
              <Link
                to="/users"
                className={cn(
                  styles.menuitem,
                  selectedMenuItem === 6 && styles['menuitem-selected'],
                )}
              >
                Users
              </Link>
            )}

            {this.state.manager && this.state.manager.admin ? (
              <Link
                to="/managers"
                className={cn(
                  styles.menuitem,
                  selectedMenuItem === 7 && styles['menuitem-selected'],
                )}
              >
                Managers
              </Link>
            ) : null}
          </div>
          <div className={styles.content}>
            <Switch>
              <Route path="/clients/add" component={ClientAddContainer} />
              <Route path="/clients/edit/:id" component={ClientEditContainer} />
              <Route path="/clients" component={ClientListContainer} />
              <Route path="/campaigns/add" component={CampaignAddContainer} />
              <Route path="/campaigns/addOpen" component={CampaignAddOpenContainer} />
              <Route path="/campaigns/edit/:id" component={CampaignEditContainer} />
              <Route path="/campaigns/editOpen/:id" component={CampaignEditOpenContainer} />
              <Route path="/campaigns" component={CampaignListContainer} />
              <Route path="/sessions/add" component={SessionAddContainer} />
              <Route path="/sessions/edit/:id" component={SessionEditContainer} />
              <Route path="/sessions" component={SessionListContainer} />
              <Route path="/report/:id/:userId" component={ReportAnswersContainer} />
              <Route path="/report/:id" component={ReportDetailsContainer} />
              <Route path="/report" component={ReportListContainer} />
              <Route path="/participants" component={ParticipantListContainer} />
              <Route path="/analytics/campaign" component={CampaignAnalyticsContainer} />
              <Route path="/analytics/session" component={SessionAnalyticsContainer} />
              <Route path="/chat/:id" component={ChatDetailsContainer} />
              <Route path="/chat" component={ChatListContainer} />
              <Route path="/users/:id" component={UserViewController} />
              {this.state.manager && this.state.manager.admin && (
                <Route path="/users" component={UserListContainer} />
              )}
              {this.state.manager && this.state.manager.admin ? (
                <Route path="/managers" component={ManagersContainer} />
              ) : null}
              <Redirect to="/clients" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Router;
