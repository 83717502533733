import moment from 'moment';
import axios from 'axios';

import { Firestore, Storage } from '../lib/firebase';
import { isAdmin, getClientIds } from './Managers';

let collection = Firestore.collection('sessions');

export const transcribe = async ({ downloadUrl, gsUrl }, sessionId, index) => {
  let url = `${process.env.REACT_APP_API_URL}analytics/autotranscribe`;
  await axios.post(
    url,
    {
      type: 'session',
      url: gsUrl,
      id: sessionId,
      index,
    },
    {
      headers: { 'content-type': 'application/json' },
    },
  );
};

export const addSession = async (payload) => {
  try {
    let sessions = [];
    let tasks = payload.sessions.map(
      (session, index) =>
        new Promise((resolve, reject) => {
          sessions.push({
            title: session.title,
            transcriptionStatus: 'progress',
            transcriptionId: null,
          });
          if (session.media && session.media.type) {
            let ref = Storage.ref(`session/${moment().valueOf()}`);
            let task = ref.put(session.media);
            task.on(
              'state_changed',
              (snapshot) => {},
              (error) => {},
              () => {
                task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                  sessions[index].media = {
                    downloadUrl,
                    gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                  };
                  resolve(downloadUrl);
                });
              },
            );
          } else {
            resolve();
          }
        }),
    );

    let data = {
      name: payload.basic.name,
      marketing_name: payload.basic.marketing_name,
      client_id: payload.basic.org,
      from: payload.basic.from.toISOString(),
      to: payload.basic.to.toISOString(),
      participant_group_id: payload.basic.participant_group,
      description: payload.basic.description,
      status: true,
    };

    if (payload.basic.logo) {
      tasks.push(
        new Promise((resolve, reject) => {
          let ref = Storage.ref(`media/${moment().valueOf()}`);
          let task = ref.put(payload.basic.logo);
          task.on(
            'state_changed',
            (snapshot) => {},
            (error) => {},
            () => {
              task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                data.logo = downloadUrl;
                resolve(downloadUrl);
              });
            },
          );
        }),
      );
    } else {
      data.logo = null;
    }
    await Promise.all(tasks);

    let sessionDoc = collection.doc();
    await sessionDoc.set({
      id: sessionDoc.id,
      ...data,
      sessions,
    });
    // calling transcription api
    sessions.forEach((item, index) => {
      transcribe(item.media, sessionDoc.id, index);
    });
  } catch (error) {
    throw error;
  }
};

export const updateSession = async (payload) => {
  try {
    let sessions = [];
    let tasks = payload.sessions.map(
      (session, index) =>
        new Promise((resolve, reject) => {
          sessions.push({
            title: session.title,
          });
          if (session.media && session.media.type) {
            let ref = Storage.ref(`session/${moment().valueOf()}`);
            let task = ref.put(session.media);
            task.on(
              'state_changed',
              (snapshot) => {},
              (error) => {},
              () => {
                task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                  sessions[index].media = {
                    downloadUrl,
                    gsUrl: 'gs://' + task.snapshot.ref.bucket + '/' + task.snapshot.ref.fullPath,
                  };
                  sessions[index].transcriptionId = null;
                  sessions[index].transcriptionStatus = 'progress';
                  resolve(downloadUrl);
                });
              },
            );
          } else if (session.media) {
            sessions[index].media = session.media;
            sessions[index].transcriptionId = session.transcriptionId;
            sessions[index].transcriptionStatus = session.transcriptionStatus;
            resolve();
          } else {
            resolve();
          }
        }),
    );

    let data = {
      name: payload.basic.name,
      marketing_name: payload.basic.marketing_name,
      client_id: payload.basic.org,
      from: payload.basic.from.toISOString(),
      to: payload.basic.to.toISOString(),
      participant_group_id: payload.basic.participant_group,
      description: payload.basic.description,
      status: payload.basic.status,
    };

    if (payload.basic.logo && payload.basic.logo.type) {
      tasks.push(
        new Promise((resolve, reject) => {
          let ref = Storage.ref(`media/${moment().valueOf()}`);
          let task = ref.put(payload.basic.logo);
          task.on(
            'state_changed',
            (snapshot) => {},
            (error) => {},
            () => {
              task.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                data.logo = downloadUrl;
                resolve(downloadUrl);
              });
            },
          );
        }),
      );
    } else {
      data.logo = payload.basic.logo;
    }
    await Promise.all(tasks);

    let sessionDoc = collection.doc(payload.sessionId);
    sessionDoc.update({
      ...data,
      sessions,
    });

    // calling transcription api
    sessions.forEach((item, index) => {
      if (item.transcriptionStatus === 'progress') {
        transcribe(item.media, sessionDoc.id, index);
      }
    });
  } catch (error) {
    throw error;
  }
};

export const getSessionById = async (sessionId) =>
  new Promise((resolve, reject) => {
    let sessionDoc = collection.doc(sessionId);
    sessionDoc.onSnapshot(async (snapshot) => {
      let sessionData = snapshot.data();

      let clientDoc = Firestore.collection('clients').doc(sessionData.client_id);
      clientDoc.onSnapshot((client_snapshot) => {
        let client_data = client_snapshot.data();
        sessionData.client = client_data;

        let participantDoc = Firestore.collection('participant_groups').doc(
          sessionData.participant_group_id,
        );
        participantDoc.onSnapshot((group_snapshot) => {
          let participant_group_data = group_snapshot.data();

          // getting user id for the participants
          Firestore.collection('users')
            .get()
            .then((snapshot) => {
              let users = snapshot.docs.map((userDoc) => userDoc.data());
              participant_group_data.participant_list = participant_group_data.participant_list.map(
                (participant) => {
                  let index = users.findIndex((item) => item.email === participant.email);
                  if (index === -1) {
                    return {
                      userId: 'NaN',
                      ...participant,
                    };
                  } else {
                    return {
                      userId: users[index].id,
                      ...participant,
                    };
                  }
                },
              );
              sessionData.participant_group = participant_group_data;
              resolve(sessionData);
            });
        });
      });
    });
  });

export const getSessions = async () => {
  try {
    let snapshot;
    if (isAdmin()) {
      snapshot = await collection.get();
    } else {
      const clientIds = await getClientIds();
      if (clientIds.length === 0) return [];
      snapshot = await collection.where('client_id', 'in', clientIds).get();
    }
    let tasks = snapshot.docs.map((sessionDoc) => getSessionById(sessionDoc.id));
    let sessions = Promise.all(tasks);
    return sessions;
  } catch (error) {
    throw error;
  }
};

export const getSessionsByClient = async (clientId) => {
  let snapshot = await collection.where('client_id', '==', clientId).get();
  let sessions = snapshot.docs.map((sessionDoc) => sessionDoc.data());
  return sessions;
};

export const setSessionStatus = async (sessionId, status) => {
  try {
    await collection.doc(sessionId).update({
      status,
    });
  } catch (error) {
    throw error;
  }
};
