import { Button } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ClipResult } from '..';
import { TranscriptionController } from '../../../controllers';
import AppContext from '../../AppContext';
import styles from './Clips.module.scss';

export const ReportClips = React.memo((props) => {
  const { clips, getIndexWord } = props;
  const [clipsHandle, setClipHandle] = useState([]);
  const [transcriptionClips, setTranscriptionClips] = useState([]);
  const appContext = useContext(AppContext);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const clipsOrder = reorder(clipsHandle, result.source.index, result.destination.index);

    setClipHandle(clipsOrder);
  };

  const handleSave = async () => {
    let selection = [];
    clipsHandle.forEach(({ index, words, key }) => {
      let clipSelection = [];
      for (var i = 0; i < words.length; i++) {
        if (i === 0) {
          clipSelection.push({
            startTime: words[i].startTime,
            endTime: words[i].endTime,
          });
        } else {
          let isContinuous = false;
          const currentWordIndex = getIndexWord(key, words[i]);
          const prevWordIndex = getIndexWord(key, words[i - 1]);
          if (
            currentWordIndex.rIndex === prevWordIndex.rIndex &&
            currentWordIndex.wIndex === prevWordIndex.wIndex + 1
          ) {
            isContinuous = true;
          }

          if (isContinuous) {
            clipSelection[clipSelection.length - 1].endTime = words[i].endTime;
          } else {
            clipSelection.push({
              startTime: words[i].startTime,
              endTime: words[i].endTime,
            });
          }
        }
      }
      selection.push({
        index,
        clips: clipSelection,
      });
    });

    if (!selection.length) {
      alert('Please select at least one word.');
      return;
    }

    // this.context.showLoading();
    try {
      appContext.showLoading();
      let videoGsUrls = clipsHandle.map((clip) => clip.key);
      // let videoGsUrl = 'gs://social-lens-3a3d5.appspot.com/media/1553271370-ec0df78d-67af-4882-99b0-23fce60b2beb';
      let { data } = await TranscriptionController.clip(videoGsUrls, selection);
      setTranscriptionClips(data);
      // this.context.hideLoading();
    } catch (error) {
      // this.context.hideLoading();
      alert(error.message);
    } finally {
      appContext.hideLoading();
    }
  };

  useEffect(() => {
    setClipHandle(clips);
  }, [clips]);

  return (
    <>
      <div className={styles.wrapper}>
        <h2>Clips</h2>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className={styles.clipList}>
                {clipsHandle.map((item, index) => {
                  return (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided2, snapshot) => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          className={cn(
                            styles.clipListItem,
                            snapshot.isDragging && styles.clipListItemDragging,
                          )}
                        >
                          {`${item.index + 1}. ${item.words.map((word) => word.word).join(' ')}`}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={styles.btnGroup}>
          <Button type="primary" className={styles.save} ghost onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      {transcriptionClips.length > 0 && (
        <ClipResult clips={clips} clipResult={transcriptionClips} />
      )}
    </>
  );
});

ReportClips.propTypes = {
  clips: PropTypes.array,
  getIndexWord: PropTypes.func,
};
