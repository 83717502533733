import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Switch from 'react-switch';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import uuid from 'uuid/v4';
import moment from 'moment';

import { AppContext } from '../../../components';
import { ClientController, CampaignController } from '../../../controllers';

import CampaignType from '../../../constants/campaignType';
import QuestionType from '../../../constants/questionType';
import CampaignColor from '../../../constants/campaignColor';
import EditorCustom from './../../../components/common/Editor';

import styles from './CampaignAddOpenContainter.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import selectStyles from '../../../theme/select.styles';
import { queryKeyWord } from '../../../controllers/Campaign';
import { hasWhiteSpace } from '../../../utils/url';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { BackButton } from '../../../components/Button';

import { MediaLocal } from './../../../components/Media/MediaItem';
import { isYoutubeLink } from '../../../utils/url';

class CampaignAddOpenContainer extends React.Component {
  colors = [];
  constructor(props) {
    super(props);
    this.colors = CampaignColor;
    let today = moment();
    let end = moment().add(7, 'days');
    let campaignTypeList = [{ value: CampaignType.OPEN, label: 'Open' }];

    this.state = {
      clients: [],
      orgList: [],
      typeList: campaignTypeList,
      selectedOrg: {},
      selectedType: campaignTypeList[0],
      // campaign data
      basic: {
        name: '',
        marketing_name: '',
        org: '',
        contact: '',
        from: today.toDate(),
        to: end.toDate(),
        limit_users: '',
        instructionName: '',
        instruction: '',
        instructionEmail: '',
        colorSchema: '',
        type: CampaignType.OPEN,
        description: '',
        descriptionHtml: '',
        logo: null,
        required_info: false,
        short_link: '',
      },
      defaultButtons: {
        next: '',
        back: '',
        submit: '',
      },
      questions: [this.generateNewQuestion()],
      link: '',
    };

    this.fileInputs = {};
    this.logoInput = {};
  }

  async componentDidMount() {
    let { basic } = this.state;

    let clients = await ClientController.getClients(
      (this.context.manager && this.context.manager.admin) || false,
    );
    let orgList = [];
    if (clients.length > 0) {
      basic.org = clients[0].id;
      basic.contact = clients[0].contact;

      orgList = clients.map((client) => ({
        value: client.id,
        label: client.org,
      }));
    }

    this.setState({
      clients,
      basic,
      orgList,
      selectedOrg: orgList.length ? orgList[0] : {},
    });
  }

  generateNewQuestion = () => {
    return {
      id: uuid(),
      type: QuestionType.INSTRUCTION_QUESTION,
      question: '',
      questionHtml: '',
      description: '',
      descriptionHtml: '',
      answers: [],
      media: null,
      userMediaEnable: false,
      userMediaShow: true,
      checkboxSections: [],
    };
  };

  saveClicked = async () => {
    // validation for basic info
    let { basic, questions } = this.state;

    if (!basic.name || !basic.name.trim()) {
      alert('Name is empty or invalid.');
      return;
    }
    if (!basic.marketing_name || !basic.marketing_name.trim()) {
      alert('Marketing name is empty or invalid.');
      return;
    }
    if (!basic.org) {
      alert('Please select an organization.');
      return;
    }
    if (!CampaignController.getPlanTextByEditor(basic.descriptionHtml)) {
      alert('Description is empty or invalid.');
      return;
    }

    // validation for questions
    for (var i = 0; i < questions.length; i++) {
      if (!CampaignController.getPlanTextByEditor(questions[i].questionHtml)) {
        alert(`Question ${i + 1}'s text can't be empty or invalid.`);
        return;
      }
      if (
        questions[i].type !== QuestionType.INSTRUCTION_QUESTION &&
        questions[i].type !== QuestionType.OPEN_TEXT_QUESTION
      ) {
        let isValid = false;
        for (let j = 0; j < questions[i].answers.length; j++) {
          if (!questions[i].answers[j] || !questions[i].answers[j].trim()) {
            isValid = true;
            alert(`Question ${i + 1}'s answers are not completed.`);
            break;
          }
        }
        if (isValid) {
          return;
        }
      }
      if (questions[i].type === QuestionType.INSTRUCTION_QUESTION) {
        let isValid = false;
        for (let j = 0; j < questions[i].checkboxSections.length; j++) {
          if (!questions[i].checkboxSections[j] || !questions[i].checkboxSections[j].trim()) {
            isValid = true;
            alert(`Question ${i + 1}'s checkbox section can't be empty.`);
            break;
          }
        }
        if (isValid) {
          return;
        }
      }
    }

    if (this.state.basic.short_link) {
      let keyword = await queryKeyWord(this.state.basic.short_link);
      if (keyword.length !== 0) {
        alert('Link already exists');
        return;
      }
    }
    let checkHasWhiteSpace = hasWhiteSpace(this.state.basic.short_link);
    if (checkHasWhiteSpace) {
      alert('Link is invalid');
      return;
    }

    this.context.showLoading();
    // adding a campaign
    const id = await CampaignController.addOpenCampaign({
      basic: this.state.basic,
      questions: this.state.questions,
      defaultButtons: this.state.defaultButtons,
    });

    let link = '';
    if (this.state.basic.short_link !== '') {
      link = `${window.location.origin}/b/${this.state.basic.short_link}`;
    } else {
      link = `${window.location.origin}/answer/open-campaign/b/${id}`;
    }

    this.setState({ link });
    this.context.hideLoading();
    this.confirm();
  };

  openNotification = () => {
    notification.info({
      message: 'Link copied!',
    });
  };

  confirm = () => {
    Modal.confirm({
      title: 'The open campaign has been created successfully.',
      content: (
        <div className={styles.inputModal}>
          <input type="text" id="textLink" value={this.state.link} readOnly />
        </div>
      ),
      okText: 'Copy Link',
      cancelText: 'Close',
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      onOk: () => this.onCopyLink(),
      onCancel: () => this.props.history.goBack(),
    });
  };

  onCopyLink = () => {
    navigator.clipboard.writeText(this.state.link);
    this.openNotification();
    this.props.history.goBack();
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  basicInfoChanged = (key) => async (e) => {
    let { basic } = this.state;

    if (key === 'org') {
      let selectedOrg = e;
      basic[key] = e.value;
      let index = this.state.clients.findIndex((client) => client.id === e.value);
      basic['contact'] = this.state.clients[index].contact;

      this.setState({
        selectedOrg,
        basic,
      });
    } else if (key === 'type') {
      let selectedType = e;
      basic[key] = e.value;
      this.setState({
        selectedType,
        basic,
        questions: [this.generateNewQuestion()], // resetting questions
      });
    } else if (key === 'from' || key === 'to') {
      basic[key] = e;
      this.setState({ basic });
    } else {
      basic[key] = e.target.value;
      this.setState({ basic });
    }
  };

  basicInfoChangeEditor = (value) => {
    const { basic } = this.state;
    basic.descriptionHtml = value;
    this.setState({ basic });
  };

  defaultButtonsChanged = (key) => async (e) => {
    let { defaultButtons } = this.state;
    defaultButtons[key] = e.target.value;
    this.setState({ defaultButtons });
  };

  basicRequiredValue = (key) => (e) => {
    let { basic } = this.state;
    basic[key] = e.target.checked;
    this.setState({ basic });
  };

  uploadLogoClicked = () => {
    this.logoInput.click();
  };

  logoFileUploadChange = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let { basic } = this.state;
      basic.logo = files[0];
      this.setState({ basic });
    }
  };

  uploadClicked = (id, source) => () => {
    if (source === 'local') {
      this.fileInputs[id].click();
    } else {
      let url = prompt('Please enter Youtube URL.');
      if (url) {
        if (isYoutubeLink(url)) {
          let index = id;
          let { questions } = this.state;
          questions[index].media = url;
          questions[index].media_type = 'youtube';
          this.setState({ questions });
        } else {
          alert('Youtube URL is not valid.');
        }
      }
    }
  };

  fileUploadChange = (index) => (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let { questions } = this.state;
      questions[index].media = files[0];
      questions[index].media_type = '';
      this.setState({ questions });
      e.target.value = null;
    }
  };

  selectQuestonType = (qtype, index) => () => {
    let { questions } = this.state;
    questions[index].type = qtype;

    switch (qtype) {
      case QuestionType.INSTRUCTION_QUESTION:
        questions[index].answers = [];
        questions[index].userMediaEnable = false;
        questions[index].userMediaShow = true;
        questions[index].checkboxSections = [];
        break;
      case QuestionType.OPEN_TEXT_QUESTION:
        questions[index].answers = [];
        questions[index].userMediaEnable = true;
        questions[index].userMediaShow = true;
        break;
      default:
        questions[index].answers = [''];
        questions[index].userMediaEnable = true;
        questions[index].userMediaShow = true;
        break;
    }
    this.setState({ questions });
  };

  questionChanged = (key, index) => (e) => {
    let { questions } = this.state;
    if (key === 'userMediaEnable' || key === 'userMediaShow') {
      questions[index][key] = e;
    } else {
      questions[index][key] = e.target.value;
    }
    this.setState({ questions });
  };

  questionHtmlChanged = (index, value, type) => {
    let { questions } = this.state;
    if (type === 'description') {
      questions[index].descriptionHtml = value;
    } else if (type === 'question') {
      questions[index].questionHtml = value;
    }
    this.setState({ questions });
  };

  addAnswer = (index) => () => {
    // multiple option
    let { questions } = this.state;
    questions[index].answers.push('');
    this.setState({ questions });
  };

  removeAnswer = (questionIndex, answerIndex) => () => {
    let { questions } = this.state;
    questions[questionIndex].answers.splice(answerIndex, 1);
    this.setState({ questions });
  };

  answerChanged = (questionIndex, answerIndex) => (e) => {
    let { questions } = this.state;
    questions[questionIndex].answers[answerIndex] = e.target.value;
    this.setState({ questions });
  };

  checkboxSectionChange = (questionIndex, checkboxIndex) => (e) => {
    let { questions } = this.state;
    questions[questionIndex].checkboxSections[checkboxIndex] = e.target.value;
    this.setState({ questions });
  };

  removeCheckboxSection = (questionIndex, checkboxIndex) => () => {
    let { questions } = this.state;
    questions[questionIndex].checkboxSections.splice(checkboxIndex, 1);
    this.setState({ questions });
  };

  addCheckboxSection = (index) => () => {
    let { questions } = this.state;
    if (!questions[index].checkboxSections) {
      questions[index].checkboxSections = [];
    }
    questions[index].checkboxSections.push('');
    this.setState({ questions });
  };

  addQuestion = () => {
    let { questions } = this.state;
    // question validation
    const last = questions[questions.length - 1];
    if (!last.question && !CampaignController.getPlanTextByEditor(last.questionHtml)) {
      alert('Question text is empty or invalid.');
      return;
    }
    if (
      last.type !== QuestionType.INSTRUCTION_QUESTION &&
      last.type !== QuestionType.OPEN_TEXT_QUESTION
    ) {
      for (let i = 0; i < last.answers.length; i++) {
        if (!last.answers[i]) {
          alert("Question answer can't be empty.");
          return;
        }
      }
    }
    questions.push(this.generateNewQuestion());
    this.setState({ questions }, () => this.checkElementScroll(questions[questions.length - 1]));
  };

  removeQuestion = (index) => () => {
    if (window.confirm('Are you sure you want to remove?')) {
      let { questions } = this.state;
      questions.splice(index, 1);
      this.setState({ questions });
    }
  };

  removeLogo = () => {
    let { basic } = this.state;
    basic['logo'] = null;
    this.setState({ basic });
  };

  removeQuestionMedia = (index) => () => {
    let { questions } = this.state;
    questions[index].media = null;
    this.setState({ questions });
  };

  handleBricks = (text) => {
    const arr = text.split('[**]');
    if (arr.length > 1) {
      const newText = arr.reduce((data, current, index) => {
        data += current;
        if (index !== arr.length - 1) {
          data += ' _____';
        }
        return data;
      }, '');
      return newText;
    }
    return text;
  };

  renderAnswerPreview = (indexQuestion) => {
    const { answers, question, questionHtml } = this.state.questions[indexQuestion];
    if (!question && !questionHtml && answers.length === 1 && answers[0].length === 0) {
      return (
        <span>I [Care / Don&apos;t care / Don&apos;t even know] what happened because _____</span>
      );
    }
    const answerText = answers.reduce((data, current, index) => {
      if (current.trim()) {
        if (index !== 0) {
          data.push(<span key={uuid()}>&nbsp;/&nbsp;</span>);
        }
        data.push(
          <span key={uuid()} style={{ color: this.colors[index % this.colors.length] }}>
            {current}{' '}
          </span>,
        );
      }
      return data;
    }, []);

    const answerDataHandle = questionHtml
      ? CampaignController.getPlanTextByEditor(questionHtml)
      : question;

    const arrReplaceAnswer = answerDataHandle.split('[*]');
    const newArrReplace = arrReplaceAnswer.reduce((arr, current, index) => {
      const newCurrent = this.handleBricks(current);

      arr.push(<span key={uuid()}>{newCurrent}</span>);
      if (index !== arrReplaceAnswer.length - 1) {
        arr.push(<span key={uuid()}>&nbsp;[&nbsp;</span>);
        arr.push(answerText);
        arr.push(<span key={uuid()}>&nbsp;]&nbsp;</span>);
      }
      return arr;
    }, []);

    return newArrReplace;
  };

  renderStandardQuestion = (question, index) => {
    return (
      <Draggable key={question.id} draggableId={`${question.id}-id`} index={index}>
        {(provided, snapshot) => (
          <div
            key={`${index}`}
            id={`${question.id}-id`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn(
              styles.questionContainer,
              snapshot.isDragging && styles.clipListItemDragging,
            )}
          >
            <div className={styles.title}>
              <div className={styles.questionTitle}>
                <h2> Question {index + 1} </h2>
                <span onClick={() => this.handleReplicateQuestion(question)}>
                  <i className="fa fa-files-o" aria-hidden="true"></i>
                </span>
              </div>
              {this.state.questions.length > 1 && (
                <span onClick={this.removeQuestion(index)}>
                  <i className="fa fa-minus-circle" />
                </span>
              )}
            </div>

            <div className={styles.qtypeContainer}>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.INSTRUCTION_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`open${index}`}
                  checked={question.type === QuestionType.INSTRUCTION_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.INSTRUCTION_QUESTION, index)}
                />
                Instruction
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.OPEN_TEXT_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`open${index}`}
                  checked={question.type === QuestionType.OPEN_TEXT_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.OPEN_TEXT_QUESTION, index)}
                />
                Open Text
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.ONE_CHOICE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.ONE_CHOICE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.ONE_CHOICE_QUESTION, index)}
                />
                One Choice
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.MULTIPLE_CHOICE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`multiple${index}`}
                  checked={question.type === QuestionType.MULTIPLE_CHOICE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.MULTIPLE_CHOICE_QUESTION, index)}
                />
                Multiple Choice
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.RANKING_CHOICE_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.RANKING_CHOICE_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.RANKING_CHOICE_QUESTION, index)}
                />
                Ranking Choice
              </div>
              <div
                className={styles.qtypeRadio}
                onClick={this.selectQuestonType(QuestionType.BLANK_QUESTION, index)}
              >
                <input
                  type="radio"
                  value={`truefalse${index}`}
                  checked={question.type === QuestionType.BLANK_QUESTION}
                  onChange={this.selectQuestonType(QuestionType.BLANK_QUESTION, index)}
                />
                Fill-in-the-Blank
              </div>
            </div>
            <div className={styles.inputItem}>
              <span>
                {question.type === QuestionType.INSTRUCTION_QUESTION
                  ? 'Instruction Text'
                  : 'Question Text'}
              </span>
              <EditorCustom
                name={`question-${index}`}
                placeholder={
                  question.type === QuestionType.INSTRUCTION_QUESTION
                    ? 'Type Instruction here'
                    : question.type === QuestionType.BLANK_QUESTION
                    ? 'I [*] what happened because [**]'
                    : 'Type question here'
                }
                value={question.questionHtml}
                onChange={(v) => this.questionHtmlChanged(index, v, 'question')}
              />
            </div>

            {question.type === QuestionType.BLANK_QUESTION ? (
              <>
                <div className={styles.textReview}>
                  <span className={styles.note}>*</span>[*] represents the answer options. Please
                  input the answers below
                </div>
                <div className={styles.textReview}>
                  <span className={styles.note}>*</span>[**] represents the blank
                </div>
                <div className={styles.textReview} style={{ marginBottom: '10px' }}>
                  <span className={styles.title}>Preview:</span> {this.renderAnswerPreview(index)}
                </div>
              </>
            ) : null}

            <div className={styles.inputItem}>
              <span>Description</span>
              <EditorCustom
                name={`question-description-${index}`}
                placeholder="Type description here"
                value={question.descriptionHtml}
                onChange={(v) => this.questionHtmlChanged(index, v, 'description')}
              />
            </div>

            <div className={styles.mediaContainer}>
              <span>Media</span>
              <div className={styles.localMedia}>
                <div className={styles.upload}>
                  <div
                    className={styles.btnUpload}
                    onClick={this.uploadClicked(question.id, 'local')}
                  >
                    Upload Local Image/Video
                  </div>
                </div>
                {question.media ? (
                  question.media_type !== 'youtube' && (
                    <div className={styles.mediaView}>
                      <MediaLocal
                        file={question.media}
                        onRemove={this.removeQuestionMedia(index)}
                      />
                    </div>
                  )
                ) : (
                  <div className={styles.nomedia}>No image or video is uploaded</div>
                )}
                <input
                  ref={(ref) => (this.fileInputs[question.id] = ref)}
                  type="file"
                  className={styles.file}
                  accept="video/mp4,video/x-m4v,video/*,image/*"
                  onChange={this.fileUploadChange(index)}
                />
              </div>
              <div className={styles.or}>OR</div>
              <div className={styles.youtubeMedia}>
                <div className={styles.btnUpload} onClick={this.uploadClicked(index, 'youtube')}>
                  Set Youtube Video Url
                </div>
                {question.media && question.media_type === 'youtube' && (
                  <div className={styles.youtubeView}>
                    <MediaLocal
                      file={{
                        type: 'youtube',
                        url: question.media,
                      }}
                      onRemove={this.removeQuestionMedia(index)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.userMediaOptionsContainer}>
              <div className={styles.userMediaShowHide}>
                <span>Show/Hide video or image</span>
                <Switch
                  checked={question.userMediaShow}
                  onChange={this.questionChanged('userMediaShow', index)}
                />
              </div>
              {question.userMediaShow && (
                <div className={styles.userMediaRequired}>
                  <span>Require video or image</span>
                  <Switch
                    checked={question.userMediaEnable}
                    onChange={this.questionChanged('userMediaEnable', index)}
                  />
                </div>
              )}
            </div>
            <div className={styles.answers}>
              {question.type === QuestionType.INSTRUCTION_QUESTION && (
                <div className={styles.answerContainer}>
                  <span>Checkbox Section</span>
                  <div>
                    {(question.checkboxSections || []).map((checkboxSection, checkboxIndex) => (
                      <div key={`${checkboxIndex}`} className={styles.answerItem}>
                        <input
                          placeholder="Type checkbox here"
                          value={checkboxSection}
                          onChange={this.checkboxSectionChange(index, checkboxIndex)}
                        />
                        <span onClick={this.removeCheckboxSection(index, checkboxIndex)}>
                          <i className="fa fa-minus-circle" />
                        </span>
                      </div>
                    ))}
                    <div className={styles.btnAdd} onClick={this.addCheckboxSection(index)}>
                      <i className="fa fa-plus" />
                      Add
                    </div>
                  </div>
                </div>
              )}
              {question.type !== QuestionType.INSTRUCTION_QUESTION &&
                question.type !== QuestionType.OPEN_TEXT_QUESTION && (
                  <div className={styles.answerContainer}>
                    <span>Answers</span>
                    <div>
                      {question.answers.map((answer, answerIndex) => (
                        <div key={`${answerIndex}`} className={styles.answerItem}>
                          <input
                            placeholder="Type answer here"
                            value={answer}
                            onChange={this.answerChanged(index, answerIndex)}
                          />
                          {question.answers.length > 1 && (
                            <span onClick={this.removeAnswer(index, answerIndex)}>
                              <i className="fa fa-minus-circle" />
                            </span>
                          )}
                        </div>
                      ))}
                      <div className={styles.btnAdd} onClick={this.addAnswer(index)}>
                        <i className="fa fa-plus" />
                        Add
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {index === this.state.questions.length - 1 && (
              <div className={styles.btnAddMore} onClick={this.addQuestion}>
                Add More Question
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  };
  onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const questions = CampaignController.reorder(
      this.state.questions,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      questions,
    });
  };

  onKeyDownInputLink = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      return;
    }
  };
  handleReplicateQuestion = (question) => {
    let { questions } = this.state;
    // question validation
    const last = questions[questions.length - 1];
    if (!last.question && !CampaignController.getPlanTextByEditor(last.questionHtml)) {
      alert('Question text is empty or invalid.');
      return;
    }
    if (
      last.type !== QuestionType.INSTRUCTION_QUESTION &&
      last.type !== QuestionType.OPEN_TEXT_QUESTION
    ) {
      for (let i = 0; i < last.answers.length; i++) {
        if (!last.answers[i]) {
          alert("Question answer can't be empty.");
          return;
        }
      }
    }
    questions.push({ ...question, id: uuid() });
    this.setState({ questions }, () => this.checkElementScroll(questions[questions.length - 1]));
  };
  checkElementScroll = (question) => {
    const el = document.getElementById(`${question.id}-id`);
    if (!el) return;
    el.scrollIntoView();
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <div className={styles.title}>
          <h1> Add a new open campaign </h1>
          <div className={styles.copyAction}>
            <div className={styles.copyLink}>
              <div className={styles.inputItem}>
                <span>Custom URL</span>
                <input
                  type="text"
                  id="textLink"
                  name="link"
                  autoComplete="off"
                  value={this.state.basic.short_link}
                  onKeyDown={this.onKeyDownInputLink}
                  onChange={this.basicInfoChanged('short_link')}
                />
              </div>
            </div>
          </div>
        </div>

        <section className={styles.tableSpace}>
          <table className="table">
            <thead>
              <tr>
                <td colSpan={2}>
                  <h2>Basic Information</h2>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Name</span>
                    <input value={this.state.basic.name} onChange={this.basicInfoChanged('name')} />
                  </div>
                </td>
                <td>
                  <div className={styles.inputItem}>
                    <span>Marketing Name</span>
                    <input
                      value={this.state.basic.marketing_name}
                      onChange={this.basicInfoChanged('marketing_name')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Organization</span>
                    <div className={styles.select}>
                      <Select
                        styles={selectStyles}
                        value={this.state.selectedOrg}
                        onChange={this.basicInfoChanged('org')}
                        options={this.state.orgList}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className={styles.inputItem}>
                    <span>Contact</span>
                    <input disabled value={this.state.basic['contact']} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Starting Date</span>
                    <DatePicker
                      className={styles.datepicker}
                      selected={this.state.basic.from}
                      onChange={this.basicInfoChanged('from')}
                    />
                  </div>
                </td>
                <td>
                  <div className={styles.inputItem}>
                    <span>Ending Date</span>
                    <DatePicker
                      className={styles.datepicker}
                      selected={this.state.basic.to}
                      onChange={this.basicInfoChanged('to')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Limit users </span>
                    <input
                      value={this.state.basic['limit_users']}
                      onChange={this.basicInfoChanged('limit_users')}
                    />
                  </div>
                </td>
                <td>
                  <div className={styles.requiredField}>
                    <div className={styles.name}>
                      <input
                        type="checkbox"
                        value="info"
                        checked={this.state.basic['required_info']}
                        onChange={this.basicRequiredValue('required_info')}
                      />
                      <span> Required name / email</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td rowSpan={4}>
                  <div className={styles.textareaItem}>
                    <span>Description</span>
                    <EditorCustom
                      name="description-basic"
                      placeholder=""
                      value={this.state.basic['descriptionHtml']}
                      onChange={(v) => this.basicInfoChangeEditor(v)}
                    />
                  </div>
                </td>
                <td className={styles.height40p}>
                  <div className={styles.inputItem}>
                    <span>Instruction</span>
                    <input
                      value={this.state.basic['instruction']}
                      onChange={this.basicInfoChanged('instruction')}
                      placeholder="Share your contact information below"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.height40p}>
                  <div className={styles.inputItem}>
                    <span>Name</span>
                    <input
                      value={this.state.basic['instructionName']}
                      placeholder="Your Name"
                      onChange={this.basicInfoChanged('instructionName')}
                      maxLength={150}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.height40p}>
                  <div className={styles.inputItem}>
                    <span>Email</span>
                    <input
                      value={this.state.basic['instructionEmail']}
                      onChange={this.basicInfoChanged('instructionEmail')}
                      placeholder="Your Email"
                      maxLength={150}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Color Scheme</span>
                    <input
                      value={this.state.basic['colorSchema']}
                      onChange={this.basicInfoChanged('colorSchema')}
                      placeholder="#03A9F4"
                    />
                  </div>
                </td>
                <td>&ensp;</td>
              </tr>
              <tr>
                <td>
                  <div className={styles.logoItem}>
                    <span>Logo</span>
                    <div className={styles.logoContainer}>
                      <div className={styles.upload}>
                        <div className={styles.btnUpload} onClick={this.uploadLogoClicked}>
                          Upload Logo
                        </div>
                      </div>
                      {this.state.basic.logo ? (
                        <MediaLocal
                          width="100%"
                          file={this.state.basic.logo}
                          onRemove={this.removeLogo}
                        />
                      ) : (
                        <span>No logo is uploaded</span>
                      )}
                      <input
                        ref={(ref) => (this.logoInput = ref)}
                        type="file"
                        className={styles.file}
                        accept="image/*"
                        onChange={this.logoFileUploadChange}
                      />
                    </div>
                  </div>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </section>

        <section className={styles.tableSpace}>
          <table className={styles.tableSmall}>
            <thead>
              <tr>
                <td>
                  <h2>Edit Default Buttons</h2>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Next</span>
                    <input
                      value={this.state.defaultButtons['next']}
                      onChange={this.defaultButtonsChanged('next')}
                      maxLength={30}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Back</span>
                    <input
                      value={this.state.defaultButtons['back']}
                      onChange={this.defaultButtonsChanged('back')}
                      maxLength={30}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.inputItem}>
                    <span>Submit</span>
                    <input
                      value={this.state.defaultButtons['submit']}
                      onChange={this.defaultButtonsChanged('submit')}
                      maxLength={30}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.basic['type'] === CampaignType.OPEN &&
                  this.state.questions.map((question, index) =>
                    this.renderStandardQuestion(question, index),
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={styles.btnGroup}>
          <Button type="primary" className={styles.btn} onClick={this.saveClicked}>
            Save
          </Button>
          <Button type="danger" className={styles.btn} onClick={this.cancelClicked}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

CampaignAddOpenContainer.contextType = AppContext;

CampaignAddOpenContainer.propTypes = {
  history: PropTypes.object,
};

export default CampaignAddOpenContainer;
