import { Button, Modal, Table, Typography } from 'antd';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import CampaignType from '../../../constants/campaignType';
import QuestionType from '../../../constants/questionType';
import { downloadMultipleVideo } from '../../../utils/download';
const { Text } = Typography;

export const ReportUserList = React.memo((props) => {
  const {
    participants,
    campaignId,
    loading,
    campaign,
    styles,
    csvData,
    headers,
    zipping,
    downloadZip,
    cancelClicked,
    downloadZipPart,
  } = props;

  // Get all campaign's signature question index type
  const arraySignatureQuestionIndex = useMemo(() => {
    if (!campaign) return [];
    const questions = campaign.questions || [];
    return questions.reduce((array, currentItem, currentIndex) => {
      if (currentItem.type === QuestionType.SIGNATURE_QUESTION) {
        return [...array, currentIndex];
      }
      return array;
    }, []);
  }, [campaign]);

  const columns = useMemo(() => {
    if (!campaign) return [];
    const columnsData = [
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
        sorter: (a, b) => (a.email > b.email ? 1 : -1),
      },
      {
        title: 'Updated At',
        dataIndex: 'updated_at',
        align: 'center',
        render: (_, record) =>
          record.answer ? moment.unix(record.answer.updatedAt).format('lll') : 'No Update',
        sorter: (a, b) => {
          const updateAtA = (a.answer && a.answer.updatedAt) || 0;
          const updateAtB = (b.answer && b.answer.updatedAt) || 0;
          return updateAtA > updateAtB ? 1 : -1;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (_, record) => {
          if (record.answer) return 'Done';
          if (record.start) return 'Started';
          return 'No activity';
        },
      },
    ];
    // remove columns form Open campaign
    if (campaign.type !== CampaignType.OPEN) {
      columnsData.push({
        title: 'Signature',
        dataIndex: 'signature',
        align: 'center',
        width: 250,
        render: (_, record) => {
          if (arraySignatureQuestionIndex.length === 0) return '';
          if (record.answer) {
            // Check user answer if they have answered any signature question
            const isSigned = arraySignatureQuestionIndex.some((indexQuestion) =>
              record.answer.answers && record.answer.answers[indexQuestion] ? true : false,
            );
            if (isSigned) return <Text type="success">Signed</Text>;
          }
          return 'Unsigned';
        },
      });
    }

    return columnsData;
  }, [campaign]);
  const selectParticipant = (record, index) => {
    if (record.userId === 'NaN') {
      Modal.warning({
        title: 'This user does not exist.',
        centered: true,
        okButtonProps: {
          style: {
            width: '80px',
          },
        },
      });
    } else {
      window.open(`/report/${campaignId}/${record.userId}`, '_blank');
    }
  };

  const renderHeader = () => {
    if (campaign) {
      return (
        <div>
          <h2>{campaign.name}</h2>
          <div className={styles.subHeader}>
            {campaign.logo && <img src={campaign.logo} alt="logo" className={styles.logo} />}
          </div>
        </div>
      );
    }
    return <div />;
  };

  const [buttonDownload, setButtonDownload] = useState({});

  const onDownloadZipPart = async (part, partIndex) => {
    try {
      setButtonDownload((pre) => ({ ...pre, [partIndex]: '0%' }));
      var zip = new JSZip();
      var img = zip.folder('All Videos ');
      await downloadMultipleVideo(img, part.medias, (v) =>
        setButtonDownload((pre) => ({ ...pre, [partIndex]: `${v}%` })),
      );

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${campaign.name}_Part${partIndex + 1}.zip`);
        setButtonDownload((pre) => ({ ...pre, [partIndex]: false }));
      });
    } catch (error) {
      alert(error.toString());
      setButtonDownload((pre) => ({ ...pre, [partIndex]: null }));
    } finally {
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={participants}
        bordered
        size="middle"
        pagination={{
          pageSizeOptions: ['10', '25', '50'],
          showSizeChanger: true,
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => selectParticipant(record, rowIndex),
        })}
        loading={loading}
        title={renderHeader}
      />
      <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
        {csvData.length > 0 ? (
          <>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={campaign ? campaign.name + '.csv' : 'campaign.csv'}
              style={{
                color: '#ffffff',
                padding: '4px 8px 0 8px',
                backgroundColor: '#03a9f4',
                borderRadius: 4,
                textDecoration: 'none',
              }}
            >
              Download data file
            </CSVLink>{' '}
          </>
        ) : null}
        {!loading ? (
          <React.Fragment>
            <Button
              type="success"
              style={{
                minWidth: '150px',
              }}
              onClick={downloadZip}
              loading={!!zipping}
            >
              Download Zip
              {zipping && zipping !== null ? ` - ${zipping}` : ''}
            </Button>
            {downloadZipPart &&
              downloadZipPart.length > 1 &&
              downloadZipPart.map((part, index) => (
                <Button
                  loading={!!buttonDownload[index]}
                  type="default"
                  onClick={() => onDownloadZipPart(part, index)}
                  key={index}
                >
                  Download Zip (Part {index + 1})
                  {buttonDownload[index] && buttonDownload[index] !== null
                    ? ` - ${buttonDownload[index]}`
                    : ''}
                </Button>
              ))}
          </React.Fragment>
        ) : null}
        <Button type="danger" className={styles.btnCancel} onClick={cancelClicked}>
          Cancel
        </Button>
      </div>
    </>
  );
});

ReportUserList.propTypes = {
  participants: PropTypes.any,
  campaignId: PropTypes.string,
  loading: PropTypes.bool,
  campaign: PropTypes.any,
  styles: PropTypes.any,
  csvData: PropTypes.any,
  headers: PropTypes.any,
  zipping: PropTypes.any,
  downloadZip: PropTypes.func,
  cancelClicked: PropTypes.func,
  downloadZipPart: PropTypes.array,
};
