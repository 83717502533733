import React from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import uuid from 'uuid/v4';
import { CSVLink } from 'react-csv';
import * as _ from 'lodash';
import { Button } from 'antd';
import { AppContext } from '../../../components';
import { ClientController, ManagerController } from '../../../controllers';
import { BackButton } from '../../../components/Button';
import { emailRegEx } from '../../../utils/validation';

import styles from './ClientAddContainer.module.scss';

class ClientAddContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      basic: {
        org: '',
        contact: '',
      },
      groups: [this.generateNewGroup()],
      inputs: [{ name: '', email: '' }],
    };

    this.fileInputs = {};
  }

  randomCode = () => {
    return Math.random().toString(36).substring(2);
  };

  generateNewGroup = () => {
    return {
      id: uuid(),
      name: '',
      division: '',
      participant_code: this.randomCode(),
      number_of_participants: 0,
      participant_list: [],
    };
  };

  addClicked = async () => {
    let isValid = true;
    if (!this.state.basic.org.trim()) {
      isValid = false;
      alert("Organization can't be empty.");
      return;
    }
    if (this.state.groups.length > 1) {
      const isDuplicateParticipantCodeLocal = _(this.state.groups)
        .filter((f) => f.participant_code)
        .groupBy((f) => f.participant_code)
        .pickBy((p) => p.length > 1)
        .keys()
        .value();
      if (isDuplicateParticipantCodeLocal.length > 0) {
        alert('One of your group codes is already taken. Please choose another one.');
        isValid = false;
        return;
      }
    }
    for (let index = 0; index < this.state.groups.length; index++) {
      const group = this.state.groups[index];
      if (!group.name.trim()) {
        alert("The participant group name can't be empty.");
        isValid = false;
        return false;
      }
      if (group.participant_code) {
        if (group.participant_code.length < 5) {
          alert('The participant code must be greater than 5 characters.');
          isValid = false;
          return false;
        }

        const codeIsExist = await ClientController.participantCodeExist(group.participant_code);
        if (codeIsExist) {
          alert('The participant code already exists.');
          isValid = false;
          return false;
        }
      }

      if (group.limit_users && typeof group.limit_users === 'number') {
        if (group.limit_users < group.participant_list.length) {
          alert('The user limit must be greater or equal to the participant list.');
          isValid = false;
          return false;
        }
      }
      if (group.limit_users && typeof group.limit_users === 'string') {
        const valueStrValidation = group.limit_users.trim();
        const valueNumValidation = +valueStrValidation;
        if (typeof valueNumValidation !== 'number') {
          alert('User Limit must be numbers.');
          isValid = false;
          return false;
        }
        if (valueNumValidation < group.participant_list.length) {
          alert('The user limit must be greater or equal to the participant list.');
          isValid = false;
          return false;
        }
      }
    }

    if (!isValid) return;
    this.context.showLoading();
    try {
      const clientId = await ClientController.addClient({
        basic: this.state.basic,
        groups: this.state.groups,
      });
      if (this.context.manager && !this.context.manager.admin) {
        const userId = this.context.manager.userId;
        if (!userId) return;
        const managerUser = await ManagerController.getManagerForUserId(userId);
        const newManagerUpdate = managerUser.map((manager) => {
          return ManagerController.editManager({
            id: manager.userId,
            fullName: manager.fullName,
            email: manager.email,
            password: '',
            managerId: manager.userId,
            clientIds: [...manager.clientIds, clientId],
          });
        });
        await Promise.all(newManagerUpdate);
      }
      this.props.history.goBack();
    } catch (error) {
      alert(error.message);
    }
    this.context.hideLoading();
  };

  cancelClicked = () => {
    this.props.history.goBack();
  };

  addmoreClicked = () => {
    let { groups, inputs } = this.state;

    let last = groups[groups.length - 1];
    if (!last.name || !last.division) {
      alert('Please complete the current participant group to add more.');
      return;
    }
    groups.push(this.generateNewGroup());
    inputs.push({ name: '', email: '' });
    this.setState({ groups, inputs });
  };

  handleUpload = (id) => () => {
    this.fileInputs[id].click();
  };

  handleClearAll = (index) => () => {
    let { groups } = this.state;
    groups[index]['participant_list'] = [];
    groups[index]['number_of_participants'] = 0;
    this.setState({ groups });
  };

  handleAdd = (index) => () => {
    let { groups, inputs } = this.state;

    if (
      groups[index].limit_users &&
      +groups[index].limit_users === groups[index].participant_list.length
    ) {
      alert('The number of users has reached the limit.');
      return;
    }

    if (!inputs[index].name.trim()) {
      alert("Name can't be empty.");
      return;
    }
    if (!inputs[index].email.trim()) {
      alert("Email can't be empty.");
      return;
    }
    if (!emailRegEx.test(inputs[index].email.trim())) {
      alert('Email is not valid.');
      return;
    }

    const email = inputs[index].email.trim();
    const found = groups[index].participant_list.find((p) => p[1] === email);
    if (found) {
      alert('Email already exists.');
      return;
    }

    groups[index]['participant_list'].push([inputs[index].name.trim(), inputs[index].email.trim()]);
    groups[index]['number_of_participants'] = groups[index]['participant_list'].length;
    inputs[index] = { name: '', email: '' };

    this.setState({ groups, inputs });
  };

  fileUploadChange = (index) => (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      Papa.parse(files[0], {
        error: (error) => {
          alert(error.message);
        },
        complete: (results) => {
          let { groups } = this.state;
          groups[index]['participant_list'] = results.data;
          groups[index]['number_of_participants'] = results.data.length;
          this.setState({ groups });
        },
      });
    }
  };

  handleGroupInputChange = (key, index) => (e) => {
    let { inputs } = this.state;
    inputs[index][key] = e.target.value;
    this.setState({ inputs });
  };

  basicInfoChanged = (type) => (e) => {
    let { basic } = this.state;
    basic[type] = e.target.value;
    this.setState({ basic });
  };

  groupChange = (type, index) => (e) => {
    let { groups } = this.state;
    groups[index][type] = e.target.value;
    this.setState({ groups });
  };

  groupRemove = (index) => () => {
    let { groups } = this.state;
    groups.splice(index, 1);
    this.setState({ groups });
  };

  newCode = (indexGroup) => {
    const { groups } = this.state;
    groups[indexGroup].participant_code = this.randomCode();
    this.setState({ groups });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <BackButton history={this.props.history} />
        </div>
        <h1> Add a new client </h1>
        <div className={styles.container}>
          <h2>Basic Info</h2>
          <div className={styles.inputItem}>
            <span>Organization</span>
            <input value={this.state.basic.org} onChange={this.basicInfoChanged('org')} />
          </div>
          <div className={styles.inputItem}>
            <span>Contact</span>
            <input value={this.state.basic.contact} onChange={this.basicInfoChanged('contact')} />
          </div>
        </div>
        {this.state.groups.map((group, index) => {
          return (
            <div key={group.id} className={styles.container}>
              <div className={styles.title}>
                <h2>Participant Group</h2>
                {this.state.groups.length > 1 && (
                  <span onClick={this.groupRemove(index)}>
                    <i className={`fa fa-minus-circle ${styles.iconRemove}`} />
                  </span>
                )}
              </div>
              <div className={styles.inputItem}>
                <span>Participant Group Name</span>
                <input value={group.name} onChange={this.groupChange('name', index)} />
              </div>
              <div className={styles.inputItem}>
                <span>Participant Code</span>
                <input
                  value={group.participant_code}
                  maxLength={30}
                  minLength={5}
                  onChange={this.groupChange('participant_code', index)}
                />
                <div className={styles.codeAction}>
                  <Button
                    size="large"
                    type="primary"
                    ghost
                    className={styles.button}
                    onClick={() => this.newCode(index)}
                  >
                    New Code
                  </Button>
                </div>
              </div>
              <div className={styles.inputItem}>
                <span>Division / Location</span>
                <input value={group.division} onChange={this.groupChange('division', index)} />
              </div>
              <div className={styles.inputItem}>
                <span>Limit Users</span>
                <input
                  value={group.limit_users}
                  onChange={this.groupChange('limit_users', index)}
                />
              </div>
              <div className={styles.inputItem}>
                <span>Number of Participants</span>
                <input disabled value={group.number_of_participants} />
              </div>
              <div className={styles.inputItem}>
                <span>Participant List</span>
                <div className={styles.participantBtnGroup}>
                  <div className={styles.btnPrimary} onClick={this.handleUpload(group.id)}>
                    Upload CSV
                  </div>
                  {group['number_of_participants'] === 0 ? (
                    <div className={styles.btnPrimaryDisabled}>Download CSV</div>
                  ) : (
                    <CSVLink
                      data={group['participant_list']}
                      className={styles.btnPrimary}
                      filename={group.name}
                    >
                      Download CSV
                    </CSVLink>
                  )}
                  {group['number_of_participants'] === 0 ? (
                    <div className={styles.btnDangerDisabled}>Clear All</div>
                  ) : (
                    <div className={styles.btnDanger} onClick={this.handleClearAll(index)}>
                      Clear All
                    </div>
                  )}
                </div>
                <input
                  ref={(ref) => (this.fileInputs[group.id] = ref)}
                  type="file"
                  className={styles.file}
                  accept=".csv"
                  onChange={this.fileUploadChange(index)}
                />
              </div>
              <div className={styles.inputItem}>
                <input
                  className={styles.nameInput}
                  placeholder="Type Name"
                  value={this.state.inputs[index].name}
                  onChange={this.handleGroupInputChange('name', index)}
                />
                <input
                  className={styles.emailInput}
                  placeholder="Type Email"
                  value={this.state.inputs[index].email}
                  onChange={this.handleGroupInputChange('email', index)}
                />
                <div className={styles.btnAdd} onClick={this.handleAdd(index)}>
                  Add
                </div>
              </div>
              {group.participant_list.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.participant_list.map((participant, index) => (
                      <tr key={`${index}`}>
                        <td>{participant[0]}</td>
                        <td>{participant[1]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {index === this.state.groups.length - 1 && (
                <div className={styles.btnAddMore} onClick={this.addmoreClicked}>
                  Add More Participant Group
                </div>
              )}
            </div>
          );
        })}
        <div className={styles.btnGroup}>
          <div className={styles.btnSave} onClick={this.addClicked}>
            Save
          </div>
          <div className={styles.btnCancel} onClick={this.cancelClicked}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}

ClientAddContainer.contextType = AppContext;

ClientAddContainer.propTypes = {
  history: PropTypes.object,
};

export default ClientAddContainer;
