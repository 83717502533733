import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';

import { AppContext } from '../../../components';
import { ParticipantsController } from '../../../controllers';
import { BackButton } from '../../../components/Button';

import styles from './ParticipantListContainer.module.scss';
import { setParams, getParamsUrl } from '../../../utils/url';

class ParticipantListContainer extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = getParamsUrl();
    this.state = {
      data: [],
      keyword: searchParams && searchParams.keyword ? searchParams.keyword : '',
      loading: false,
      table: {
        page: searchParams && searchParams.page ? +searchParams.page : 1,
        size: searchParams && searchParams.size ? +searchParams.size : 10,
      },
    };

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'name'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
        sorter: (a, b) => (a.email > b.email ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'email'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        align: 'center',
        sorter: (a, b) => (a.organization > b.organization ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'organization'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Divisions / Locations',
        dataIndex: 'division',
        align: 'center',
        sorter: (a, b) => (a.division > b.division ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'division'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        align: 'center',
        sorter: (a, b) => (a.group > b.group ? 1 : -1),
        defaultSortOrder:
          searchParams && searchParams.sortOrder && searchParams.sortField === 'group'
            ? searchParams.sortOrder
            : undefined,
      },
      {
        title: 'Status',
        align: 'center',
        render: (text, record, index) => (
          <Switch onChange={() => this.handleChange(record)} checked={record.status} />
        ),
      },
    ];
  }

  async componentDidMount() {
    await this.reload();
  }

  reload = async () => {
    let filterData = [];

    this.setState({ loading: true });
    let data = await ParticipantsController.getParticipants();
    data.map(async (item) => {
      if (item.name.toLowerCase().includes(this.state.keyword)) {
        filterData.push(item);
      }
    });
    this.setState({ data: filterData, loading: false });
  };

  searchInputChanged = (e) => {
    this.setState(
      {
        keyword: e.target.value,
      },
      async () => {
        if (!this.state.keyword) {
          const newParams = setParams({ keyword: undefined });
          this.props.history.replace({
            search: `?${newParams}`,
          });
          await this.reload();
        }
      },
    );
  };

  searchInputKeyPressed = async (e) => {
    if (e.charCode === 13) {
      const newParams = setParams({ keyword: e.target.value });
      this.props.history.replace({
        search: `?${newParams}`,
      });
      await this.reload();
    }
  };

  async handleChange(participant) {
    let res = window.confirm('Do you want to change the active status of this participant?');
    if (res) {
      await ParticipantsController.changeParticipantStatus(participant);

      let { data } = this.state;
      data.forEach((item) => {
        if (
          item.email === participant.email &&
          participant.participants_group_id === item.participants_group_id
        ) {
          item.status = !participant.status;
        }
      });
      return this.setState({ data });
    }
  }

  tableChange = (pagination, sorter) => {
    const newParams = setParams({
      page: pagination.current,
      size: pagination.pageSize,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
    });
    this.props.history.replace({
      search: `?${newParams}`,
    });
  };

  render() {
    const { data, loading, table } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.searchbar}>
            <i className={`fa fa-search ${styles.iconSearch}`} />
            <input
              type="text"
              placeholder="Type participant name here and press enter to get the result..."
              value={this.state.keyword}
              onChange={this.searchInputChanged}
              onKeyPress={this.searchInputKeyPressed}
            />
          </div>
          <BackButton history={this.props.history} />
        </div>
        <Table
          onChange={(pagination, filter, sorter) => {
            this.tableChange(pagination, sorter);
          }}
          columns={this.columns}
          dataSource={data}
          rowKey={(item) => `${item.email}${item.participants_group_id}`}
          bordered
          size="middle"
          pagination={{
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            defaultCurrent: table.page,
            defaultPageSize: table.size,
          }}
          loading={loading}
        />
      </div>
    );
  }
}

ParticipantListContainer.contextType = AppContext;

ParticipantListContainer.propTypes = {
  history: PropTypes.object,
};

export default ParticipantListContainer;
